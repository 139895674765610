import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
//Other Imports
import { NGXToastrService } from '../../../services/toaster.service';
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { SensorFormComponent } from '../sensor-form/sensor-form.component';
import { SensorReplaceFormComponent } from '../sensor-replace-form/sensor-replace-form.component';
import { SensorDetailComponent } from '../sensor-detail/sensor-detail.component';
import { SensorSettingComponent } from '../sensor-setting/sensor-setting.component';
import { WidgetFormComponent } from '../../widget/widget-form/widget-form.component';
import { ModalComponent } from '../../modal/modal.component';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from '../../../services/helper.service';
import Constants from '../../../services/constants.service';
import { HttpCancelService } from '../../../services/httpcancel.service';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit {
  @ViewChild('mydatatable') mydatatable: any;
  @Input() component: any;
  componentOrigionalImageSrc: any;
  filter: any = {};
  plants: any = [];
  locations: any = [];
  assets: any = [];
  components: any = [];
  sensor: any = {};
  sensors: any = []; //collection of sensors
  sensorTypes: string[] = [];
  searchName: string = '';
  showOnDashboard: any = {};
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  loadingIcon: any;
  vibrationIcon: any;
  temperatureIcon: any;
  maintainanceIcon: any;
  pinData: any;
  groups: any;
  keys: any;
  LIMITS = [
    { key: 'Show 10', value: 10 },
    { key: 'Show 25', value: 25 },
    { key: 'Show 150', value: 150 },
    { key: 'Show 500', value: 500 }
  ];

  limit: number = this.LIMITS[0].value;
  rowLimits: Array<any> = this.LIMITS;


  constructor(
    private _sensorService: SensorService,
    private _sharedService: SharedService,
    private modalService: NgbModal,
    private router: Router,
    private _localstorageService: LocalstorageService,
    private _helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private _toasterService: NGXToastrService,
    private httpCancelService: HttpCancelService
  ) { }

  async ngOnInit() {
    this.setPinData();

     await this.getSensorTypes();

    this.loadingIcon = 'assets/images/gauges/loading-gauges.gif';

    if (this.component) {
      if (this.component.image.original) {
        this.componentOrigionalImageSrc = await this._helperService.setImage(this.component.image.original);
      }
      this.sensors = await this._sensorService.getSensorByComponentId(this.component.component_id);
      this.listSensors();
    } else {
      this.plants = await this._sharedService.getPlants();
      let componentLocalData = this._localstorageService.getComponentData('sensor-list');
      if (componentLocalData) {
        this.filter.plants = componentLocalData.filter.plants;
        this.filter.locations = componentLocalData.filter.locations;
        this.filter.assets = componentLocalData.filter.assets;
        this.filter.components = componentLocalData.filter.components;
        this.getFilteredSensors();
      }
    }
  }

  changeRowLimits(event) {
    this.limit = event.target.value;
  }

  async getPlantsLocations() {
    this.filter.locations = [];
    if (this.filter.plants) {
      this.locations = await this._sharedService.getPlantsLocations(this.filter.plants);
    }
  }

  async getLocationsAssets() {
    this.filter.assets = [];
    if (this.filter.locations) {
      this.assets = await this._sharedService.getLocationsAssets(this.filter.locations);
    }
  }

  async getAssetsComponents() {
    this.filter.components = [];
    if (this.filter.assets) {
      this.components = await this._sharedService.getAssetsComponents(this.filter.assets);
    }
  }

  async getSensorTypes() {
    this.sensorTypes = await this._sensorService.getSensorTypes();
  }

  async getFilteredSensors() {
    this.httpCancelService.cancelPendingRequests();
    const componentLocalData = {
        filter: {
            plants: this.filter.plants,
            locations: this.filter.locations,
            assets: this.filter.assets,
            components: this.filter.components,
        }
    };
    this._localstorageService.setComponentData('sensor-list', componentLocalData);

    const componentsToSend = this.filter.components?.length ? this.filter.components : undefined;
    const typesToSend = this.filter.types?.length ? this.filter.types.map(t => t.key) : undefined;
    const nameToSend = this.searchName?.trim() || undefined;
    if (componentsToSend || typesToSend || nameToSend) {
        this.sensors = await this._sharedService.getFilteredSensors(componentsToSend, typesToSend, nameToSend);
        this.listSensors();
    }
  }

  resetComponentSensors() {
    this.sensors = [];
    this.filter = {};
    this.listSensors();
    this._localstorageService.unsetComponentData('sensor-list');
  }

  listSensors() {
    this.groups = this.sensors.reduce(function (r, a) {
      r[a.group] = r[a.group] || [];
      r[a.group].push(a);
      return r;
    }, Object.create(null));
    this.keys = Object.keys(this.groups);

    for (let i = 0; i < this.keys.length; i++) {
      this.rows[this.keys[i]] = this.groups[this.keys[i]].map(d => ({ sensorId: d.sensor_id, action: d }));
      this.drawEmptyTable(this.groups[this.keys[i]], this.keys[i]);
    }

    for (let i = 0; i < this.keys.length; i++) {
      this.updateTableRowsWithMappingData(this.groups[this.keys[i]], this.keys[i]);
    }
  }

  initializeSensor() {
    this.sensor = {
      sensor_id: '',
      name: '',
      off_set: 0,
      scaling: 0,
      used_for: '',
      unit: '',
      component_id: this.component.component_id,
      data_source: {
        url: '',
        method: '',
        is_url_complete: false
      },
      image: {
        original: '',
        thumb: ''
      },
      integration_id: '',
      mapping_id: '',
      group: '',
      tabs: [],
      sensor_list: [],
      end_point: '',
      active: true
    }
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  setPinData() {
    this.pinData = {
      component_name: 'SensorComponent'
    }
  }

  image(value) {
    return value.toString().indexOf('assets/images/gauges/') != -1
  }

  active(value) {
    return (value != '' && (value == 'ACTIVE' || value == true || value > 0 || value < 0));
  }

  deActive(value) {
    return (value != '' && (value == 'DEACTIVE' || value == false || value == 0 || value == 'N/A'));
  }

  async drawEmptyTable(sensors, key) {
    this.showOnDashboard[key] = [];
    for (let i = 0; i < sensors.length; i++) {
      let mapping = await this._sensorService.getSensorMapping(sensors[i].mapping_id); // get mapping of each sensor
      let index = this.getRelatedRowToUpdateIcons(sensors[i].sensor_id, key);
      mapping.settings.filter(function (s) { // filter mapping that have show_on_dashboard true.
        return s.show_on_dashboard;
      }).map(f => {
        this.rows[key][index][f.key] = this.loadingIcon;
        if (this.showOnDashboard[key].findIndex(set => set.key == f.key && set.label == f.label) === -1) {
          this.showOnDashboard[key].push({ key: f.key, label: f.label });
        }
      });

      if (sensors[i].type == 'bluzone' || sensors[i].type == 'treon') {
        let blueZoneSettings = [{ label: 'Vibration', key: 'vibration' }, { label: 'Temperature', key: 'temperature' }, { label: 'Maintainance', key: 'maintainance' }];
        blueZoneSettings.map(bzs => {
          this.rows[key][index][bzs.key] = this.loadingIcon;
          if (this.showOnDashboard[key].findIndex(set => set.key == bzs.key && set.label == bzs.label) === -1) {
            this.showOnDashboard[key].push({ key: bzs.key, label: bzs.label });
          }
        });
      }
      this.rows[key] = [...this.rows[key]];
    }
  }

  async autoUpdate() {
    if (this.component) {
      this.sensors = await this._sensorService.getSensorByComponentId(this.component.component_id);
    } else {
      this.sensors = await this._sharedService.getFilteredSensors(this.filter.components,this.filter.types.map(t => t.key),this.searchName);
    }

    this.groups = this.sensors.reduce(function (r, a) {
      r[a.group] = r[a.group] || [];
      r[a.group].push(a);
      return r;
    }, Object.create(null));
    this.keys = Object.keys(this.groups);

    for (let i = 0; i < this.keys.length; i++) {
      this.reloadTableRowsWithMappingData(this.groups[this.keys[i]], this.keys[i]);
    }
  }

  async reloadTableRowsWithMappingData(sensors, key) {
    for (let i = 0; i < sensors.length; i++) {
      let sensorDetail = await this._sensorService.getSensorDetail(sensors[i]);
      if (sensorDetail) {
        let index = this.getRelatedRowToUpdateIcons(sensorDetail.sensor_id, key);
        this.showOnDashboard[key].map(async (sod) => { //substitution is done here
          if (sod.key != 'vibration' && sod.key != 'temperature' && sod.key != 'maintainance') {
            if (sod.key == 'high_low_value' && sensorDetail.high_low_value && sensorDetail.high_low_value != 'N/A') {
              if (sensorDetail.used_for == 'Temperature') {
                this.rows[key][index][sod.key] = this._helperService.temperatureConverter(sensorDetail) + ' ' + sensorDetail.unit;
              } else {
                this.rows[key][index][sod.key] = this._helperService.addScalingOffSet(sensorDetail) + ' ' + sensorDetail.unit;
              }
            } else {
              this.rows[key][index][sod.key] = sensorDetail[sod.key] || 'N/A';
            }
          }
        });
        this.rows[key] = [...this.rows[key]];
        if (sensors[i].type == 'bluzone' || sensors[i].type == 'treon') {
          this.updateTableRowsWithIcons(sensorDetail, key, index);
        }
      }
    }
  }

  async updateTableRowsWithMappingData(sensors, key) {
    for (let i = 0; i < sensors.length; i++) {
      let sensorDetail = await this._sensorService.getSensorDetail(sensors[i]);
      if (sensorDetail) {
        let index = this.getRelatedRowToUpdateIcons(sensorDetail.sensor_id, key);
        this.showOnDashboard[key].map(async (sod) => { //substitution is done here
          if (sod.key != 'vibration' && sod.key != 'temperature' && sod.key != 'maintainance') {
            if (sod.key == 'high_low_value' && sensorDetail.high_low_value && sensorDetail.high_low_value != 'N/A' && !isNaN(parseFloat(sensorDetail.high_low_value))) {
              if (sensorDetail.used_for == 'Temperature') {
                this.rows[key][index][sod.key] = this._helperService.temperatureConverter(sensorDetail) + ' ' + sensorDetail.unit;
              } else {
                this.rows[key][index][sod.key] = this._helperService.addScalingOffSet(sensorDetail) + ' ' + sensorDetail.unit;
              }
            } else {
              this.rows[key][index][sod.key] = sensorDetail[sod.key] || 'N/A';
            }
          }
        });
        this.rows[key] = [...this.rows[key]];
        if (sensors[i].type == 'bluzone' || sensors[i].type == 'treon') {
          this.updateTableRowsWithIcons(sensorDetail, key, index);
        }
      }
    }
  }

  async updateTableRowsWithIcons(sensor, key, index) {
    await this.getMaintenanceIcon(this.rows[key][index], sensor);
    this.rows[key] = [...this.rows[key]];
    await this.getVibrationIcon(this.rows[key][index], sensor);
    this.rows[key] = [...this.rows[key]];
    await this.getTemperatureIcon(this.rows[key][index], sensor);
    this.rows[key] = [...this.rows[key]];
  }

  getRelatedRowToUpdateIcons(sensorId, k) {
    return this.rows[k].findIndex(function (row) {
      return row.sensorId == sensorId;
    })
  }

  async getMachineStatus(sensorId, projectUniqueId) {
    return await this._sensorService.getMachineStatus(sensorId, projectUniqueId);
  }

  async getTemperaturePolicy(sensorId, type) {
    let array = await this._sensorService.getTemperaturePolicy(sensorId, type);
    if (array && array.length > 0) {
      if (type == 'high') {
        array.sort((a, b) => (a.tempValue < b.tempValue) ? 1 : -1); //Sorting High to Low
      } else {
        array.sort((a, b) => (a.tempValue < b.tempValue) ? -1 : 1); //Sorting Low to High
      }
      return array[0];
    } else {
      return false;
    }
  }

  async getTemperatureIcon(row, sensor) {
    let AMBIENT_TEMP_VALUE = 90;
    let highTemperaturePolicy = await this.getTemperaturePolicy(sensor.sensor_id, 'high');
    let lowTemperaturePolicy = await this.getTemperaturePolicy(sensor.sensor_id, 'low');
    let icon = 'N/A';

    if ((lowTemperaturePolicy || highTemperaturePolicy) && sensor.high_low_value && sensor.high_low_value != 'N/A') {
      let currentTemp = this._helperService.temperatureConverter(sensor);
      if (lowTemperaturePolicy && lowTemperaturePolicy.tempValue > currentTemp) {
        icon = 'assets/images/gauges/temp-blue.png';
      } else if (highTemperaturePolicy) {
        let result1 = highTemperaturePolicy.tempValue - AMBIENT_TEMP_VALUE;
        let result2 = currentTemp - AMBIENT_TEMP_VALUE;
        let percentage = (result2 / result1) * 100

        if (percentage < 24) {
          icon = 'assets/images/gauges/temp-green.png';
        } else if (percentage < 49) {
          icon = 'assets/images/gauges/temp-yelow-green.png';
        } else if (percentage < 74) {
          icon = 'assets/images/gauges/temp-yellow.png';
        } else if (percentage < 99) {
          icon = 'assets/images/gauges/temp-orange.png';
        } else {
          icon = 'assets/images/gauges/temp-red.png';
        }
      } else {
        icon = 'assets/images/gauges/temp-gray.png';
      }
    } else {
      icon = 'assets/images/gauges/temp-gray.png';
    }
    row.temperature = icon;
  }

  getLearnMeanAndAlertValue(clusterConfiguration) {
    let learnerMean;
    let alertValue;
    if (clusterConfiguration.settings) {
      let settingLength = Object.keys(clusterConfiguration.settings).length;

      let learnedMeanIndex: any;
      settingLength == 1 ? learnedMeanIndex = 1 : learnedMeanIndex = 2;
      let val1 = clusterConfiguration.settings[learnedMeanIndex].xRams.toFixed(3);
      let val2 = clusterConfiguration.settings[learnedMeanIndex].yRams.toFixed(3);
      let val3 = clusterConfiguration.settings[learnedMeanIndex].zRams.toFixed(3);
      if (val1 >= val2 && val1 >= val3) {
        learnerMean = val1;
      } else if (val2 >= val1 && val2 >= val3) {
        learnerMean = val2;
      } else {
        learnerMean = val3;
      }
      alertValue = (learnerMean * clusterConfiguration.threshold).toFixed(3);
    }
    return { learnerMean: learnerMean, alertValue: alertValue }
  }

  async getVibrationIcon(row, sensor) {
    let clusterConfiguration: any;
    if (sensor.type == 'bluzone') {
      clusterConfiguration = await this._sensorService.getClusterConfiguration(sensor.sensor_id, 'acceleration');
    } else if (sensor.type == 'treon') {
      clusterConfiguration = await this._sensorService.getClusterConfiguration(sensor.sensor_id, 'velocity');
    }
    let icon = 'N/A';
    if (clusterConfiguration) {
      let lmav = this.getLearnMeanAndAlertValue(clusterConfiguration);
      if (lmav.alertValue && lmav.learnerMean) {
        let dataPoint = await this._sensorService.getSensorHealthChartData(sensor.sensor_id, sensor.project_unique_id, 'current');
        if (dataPoint) {
          if (dataPoint.y < 0) {
            dataPoint = { y: 0 }
          }
          let v = dataPoint.y;
          let result1 = lmav.alertValue - lmav.learnerMean;
          let result2 = (v * lmav.learnerMean) - lmav.learnerMean;
          let percentage = (result2 / result1) * 100
          if (percentage < 24) {
            icon = 'assets/images/gauges/vibe-green.png';
          } else if (percentage < 49) {
            icon = 'assets/images/gauges/vibe-yellow-green.png';
          } else if (percentage < 74) {
            icon = 'assets/images/gauges/vibe-yellow.png';
          } else if (percentage < 99) {
            icon = 'assets/images/gauges/vibe-orange.png';
          } else {
            icon = 'assets/images/gauges/vibe-red.png';
          }
        } else {
          let previousDataPoint = await this._sensorService.getSensorHealthChartData(sensor.sensor_id, sensor.project_unique_id, 'previous');
          if (previousDataPoint) {
            icon = 'assets/images/gauges/vibe-gray.png';
          } else {
            icon = 'assets/images/gauges/robot-head-yes.png';
          }
        }
      } else {
        icon = 'assets/images/gauges/vibe-gray.png';
      }
    } else {
      icon = 'assets/images/gauges/robot-head-not.png';
    }
    row.vibration = icon;
  }

  async getMaintenanceIcon(row, sensor) {
    let activeMaintenanceLogs = await this._sensorService.getSensorActiveMaintenanceAlerts(sensor.sensor_id);
    let icon = 'N/A';
    if (activeMaintenanceLogs && activeMaintenanceLogs.length > 0) {
      icon = 'assets/images/gauges/maintenance-yellow.png';
    } else {
      icon = 'assets/images/gauges/maintenance-green.png';
    }

    row.maintainance = icon;
  }



  async openSensorFormModal(sensorUniqueId = 'add_new_sensor') {
    if (sensorUniqueId != 'add_new_sensor') {
      this.sensor = await this.getSensorBySensorUniqueId(sensorUniqueId);
      this.sensor.sensor_list = []; //this is making a decision in sensor form don't touch this
    } else {
      this.initializeSensor();
    }

    const modalRef = this.modalService.open(SensorFormComponent, { size: 'lg', scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.sensor = this.sensor;
    modalRef.componentInstance.samples = await this._sharedService.getSensorSampleData();
    modalRef.componentInstance.mappings = await this._sharedService.getMappings();
    modalRef.componentInstance.integrations = await this._sharedService.getIntegrations();
    modalRef.componentInstance.sensorList = await this._sensorService.getSensorList();
    modalRef.componentInstance.groups = await this._sensorService.getSensorGroup();
    modalRef.componentInstance.usedFor = await this._sensorService.getSensorUsedFor();
    modalRef.componentInstance.tabs = await this._sensorService.getTabs();
    modalRef.componentInstance.components = await this._sharedService.getComponents();

    modalRef.result.then(async (result) => {
      if (result.action == 'add') {
        await this.addSensor(result.sensor);
      } else {
        this.sensor = await this.updateSensor(result.sensorUniqueId, result.sensor);
        if (this.sensor) {
          let index = this.sensors.findIndex(s => s.sensor_unique_id == this.sensor.sensor_unique_id);
          this.sensors[index] = this.sensor;
        }
      }
      this.ngOnInit();
    }, (reason) => {
      console.log("Cross is Clicked")
    });
  }

  async openSensorReplaceFormModal(sensorUniqueId) {
    let sensor = await this.getSensorBySensorUniqueId(sensorUniqueId);
    const modalRef = this.modalService.open(SensorReplaceFormComponent, { scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.sensor = sensor;

    modalRef.result.then(async (result) => {
      this._toasterService.typeInfoStayOnScreen('Please stay on this screen and do not start other operations until success message appears.', 3000);
      setTimeout(() => {
        this._toasterService.typeWarningStayOnScreen('Replacing..', 600000);
      }, 3000);
      setTimeout(async () => {
        let response = await this.replaceSensor(result.replaceData);
        if (response) {
          setTimeout(() => {
            this._toasterService.clearToast();
            this.ngOnInit();
          }, 1000);
        }
      }, 6000);
    }, (reason) => {
      console.log("Cross is Clicked")
    });
  }

  openWidgetListModal(sensor: any) {
    const modalRef = this.modalService.open(WidgetFormComponent, { size: 'xl', scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.sensorUniqueId = sensor.sensorUniqueId;
  }

  async openConfirmationModal(sensorUniqueId) {
    const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.heading = "Delete Sensor";
    modalRef.componentInstance.content = "Are you sure you want to delete this sensor?";

    modalRef.result.then(async (result) => {
      this.sensor = await this.deleteSensor(sensorUniqueId);
      if (this.sensor) {
        let index = this.sensors.findIndex(p => p.sensor_unique_id == this.sensor.sensor_unique_id);
        this.sensors.splice(index, 1);
      }
      this.ngOnInit();
    }, (reason) => {
      console.log(reason)
    });
  }

  async openSensorDetails(sensor) {
    const modalRef = this.modalService.open(SensorDetailComponent, { windowClass: 'modal-full-screen', scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.sensor = sensor;
  }

  openSensorSetting(sensor) {
    const modalRef = this.modalService.open(SensorSettingComponent, { windowClass: 'modal-full-screen', scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.sensor = sensor;
  }

  async addSensor(sensor) {
    return await this._sensorService.addSensor(sensor);
  }

  async updateSensor(sensorUniqueId, sensor) {
    return await this._sensorService.updateSensor(sensorUniqueId, sensor);
  }

  async replaceSensor(sensor) {
    return await this._sensorService.replaceSensor(sensor);
  }

  async deleteSensor(sensorUniqueId) {
    return await this._sensorService.deleteSensor(sensorUniqueId);
  }

  async getSensorBySensorUniqueId(sensorUniqueId) {
    return await this._sharedService.getSensorBySensorUniqueId(sensorUniqueId);
  }

  hideOptions() {
    let user = this._localstorageService.getUserData();
    return user.role_id != Constants.role.SUPER_ADMIN_ROLE_ID && user.role_id != Constants.role.VENDOR_ROLE_ID;
  }

  selectListener(data: any) {
    if (data.type == 'click' && data.column.name != 'Action') {
      data.cellElement.blur();
      this.openSensorDetails(data.row.action);
    }
  }

}

