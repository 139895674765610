<div class="modal-header">
    <h5 class="modal-title">Work Orders</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Work Order Name *</label>
                            <input type="text" class="form-control" placeholder="Enter Work Order Name" formControlName="name" [(ngModel)]="workOrder.name" />
                            <div [hidden]="!error('name','required')" class="invalid">Work Order Name is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Type *</label>
                            <select name="type" id="type" class="form-control" formControlName="type" [(ngModel)]="workOrder.type">
                                <option value="">Select Type</option>
                                <option value="planned">Planned</option>
                                <option value="unplanned">Unplanned</option>
                            </select>
                            <div [hidden]="!error('type','required')" class="invalid">Type is required</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Due Date *</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d1="ngbDatepicker" formControlName="date" [(ngModel)]="workOrder.due.date">
                                <div class="input-group-append">
                                    <button class="btn btn-light fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                                </div>
                            </div>
                            <div [hidden]="!error('date','required')" class="invalid">Due Date is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Due Time *</label>
                            <input type="time" class="form-control" formControlName="time" min="09:00" max="18:00" required [(ngModel)]="workOrder.due.time">
                            <div [hidden]="!error('time','required')" class="invalid">Time is required</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="">Status *</label>
                            <select name="type" id="type" class="form-control" formControlName="status" [(ngModel)]="workOrder.status">
                                <option value="">Select Status</option>
                                <option value="Open">Open</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Upcoming">Upcoming</option>
                            </select>
                            <div [hidden]="!error('status','required')" class="invalid">Status is required</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Priority *</label>
                            <div class="input-group">
                                <select class="form-control" formControlName="priority_id" [(ngModel)]="workOrder.priority_id">
                                    <option value="">Select priority</option>
                                    <option *ngFor="let priority of priorities" [value]="priority.priority_id">
                                        {{priority.name }}
                                    </option>
                                </select>
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="toggleAddPriorityBlock()"><i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                            <div [hidden]="!error('priority_id','required')" class="invalid">Priority is required</div>
                            <div class=" mt-2" [hidden]="!priorityBlock">
                                <div class="row">
                                    <div class="col-12">
                                        <input type="text" class="form-control" placeholder="Enter priority name" [(ngModel)]="priorityData.name" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-12 input-group ">
                                        <input type="color" class="form-control" id="favcolor" name="favcolor" value="#ff0000" [(ngModel)]="priorityData.color" [ngModelOptions]="{standalone: true}">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-white" (click)="addPriority()">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <label class="w-100">Assigned To</label>
                        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="assignedTo" [ngModelOptions]="{standalone: true}">
                            <label ngbButtonLabel class="btn-light m-0" [ngClass]="{'active': workOrder.users.length > 0}">
                                <input ngbButton type="radio" value="user" (click)="workOrder.team_id = ''"> User
                            </label>
                            <label ngbButtonLabel class="btn-light" [ngClass]="{'active': workOrder.team_id}">
                                <input ngbButton type="radio" value="team" value="team" (click)="workOrder.users = []">Team
                            </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group" *ngIf="assignedTo == 'user'">
                            <label class="w-100">&nbsp;</label>
                            <div class="input-group">
                                <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="setMultiselectSetting('user_id','name')" [data]="users" [(ngModel)]="workOrder.users" formControlName="users" class="w-100">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="assignedTo == 'team'">
                            <label class="w-100">&nbsp;</label>
                            <div class="input-group">
                                <select class="custom-select m-0" [(ngModel)]="workOrder.team_id" formControlName="team_id">
                                    <option value="">Select Team</option>
                                    <option *ngFor="let team of teams" [value]="team.team_id">
                                        {{team.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Instructions </label>
                    <div *ngFor="let instruction of workOrder.instructions; let i = index">
                        <div class="input-group mb-1">
                            <input type="text" class="form-control" placeholder="Enter Instruction" [(ngModel)]="instruction.text" [ngModelOptions]="{standalone: true}" />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-light" (click)="removeInstruction(i)"><i class="fa fa-trash"></i></button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-white pull-right mb-3 mt-1" (click)="addInstruction()"><i class="fa fa-plus"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button *ngIf="!workOrder.work_order_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action: 'add', data: workOrder})">Save</button>
    <button *ngIf="workOrder.work_order_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action: 'update', data: workOrder})">Update</button>
</div>