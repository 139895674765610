<div class="card card-trend-data">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-lg-3 col-xl-3">
                <label>Date</label> 
                <div class="input-group">
                    <input class="form-control" placeholder="mm-dd-yyyy" [(ngModel)]="date" (ngModelChange)="getTimeSlots()" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-light fa fa-calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3 col-xl-3">
                <div class="form-group">
                    <label>Time Slot</label> 
                    <select class="form-control" [(ngModel)]="time_slot_id" (ngModelChange)="resetAxisAndFft()" id="default-select">
                        <option value="">Select Time Slot</option>
                        <option *ngFor="let item of timeSlots" [value]="item._id">{{ formatDateWithTime(item.date) }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6">
                <button type="button" class="btn btn-white px-3 mt-30-px pull-left" [disabled]="!time_slot_id" (click)="getDataForTreonFft()">Get Graph Data</button>
                <button type="button" class="btn btn-white px-3 mt-30-px ml-3 pull-left" (click)="onDemandTreonFft()">On Demand FFT</button>
            </div>
        </div>
        <div class="row" [hidden]="!fftData">
            <div class="col-12 col-lg-4 col-xl-4">
                <label>Select axis to plot graph</label><br> 
                <input type="radio" id="r1" name="axis" value="Horizontal" [(ngModel)]="axis" (ngModelChange)="createChartFFT()"><label for="r1" class="ml-2">Horizontal</label>
                <input type="radio" id="r2" name="axis" class="ml-3" value="Axial" [(ngModel)]="axis" (ngModelChange)="createChartFFT()"><label for="r2" class="ml-2">Axial</label>
                <input type="radio" id="r3" name="axis" class="ml-3" value="Radial" [(ngModel)]="axis" (ngModelChange)="createChartFFT()"><label for="r3" class="ml-2">Radial</label>
            </div>
        </div>
        
    </div>
</div>
<div class="card card-trend-data">
    <div class="card-body">
        <div class="row">
            <label class="ml-3">FFT</label>
            <div class="col-12 col-lg-12 col-xl-12">
                <app-line-chart [series]="fft.series" [xaxis]="fft.xaxis" [yaxis]="fft.yaxis"></app-line-chart>
            </div>
        </div>
    </div>
</div>