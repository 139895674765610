import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { PreventiveMaintenanceComponent } from "./preventive-maintenance.component";
import { PreventiveMaintenanceRoutingModule } from "./preventive-maintenance-routing.module";
import { PreventiveMaintenanceListComponent } from "../preventive-maintenance/preventive-maintenance-list/preventive-maintenance-list.component";
import { PreventiveMaintenanceFormComponent } from "../preventive-maintenance/preventive-maintenance-form/preventive-maintenance-form.component";
import { ScheduleFormComponent } from "../preventive-maintenance/schedule/schedule-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PreventiveMaintenanceService } from "../preventive-maintenance/preventive-maintenance.service";
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from "../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    PreventiveMaintenanceRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ExpandScreenModule,
    NgxDatatableModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [
    PreventiveMaintenanceComponent,
    PreventiveMaintenanceListComponent, 
    PreventiveMaintenanceFormComponent,
    ScheduleFormComponent
  ],
  providers: [PreventiveMaintenanceService],
  exports: [
    PreventiveMaintenanceListComponent
  ]
})
export class PreventiveMaintenanceModule { }
