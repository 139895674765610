<div class="modal-header">
    <h5 class="modal-title">Assets</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6 class="text-dark">Which Asset would you like to start this work order for?</h6>
        <div class="row">
            <div class="col-lg-12" id="accordion3">
                <div *ngFor="let asset of assets; let i = index">
                    <div class="card mb-1 border-1px accordion3-height" [ngClass]="{'bg-success' : selectedAsset(asset.asset_id) }">
                        <div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
                            <button class="btn btn-link text-dark shadow-none collapsed margin-7" (click)="getAssetsComponents(asset.asset_id)" data-toggle="collapse" [attr.data-target]="'#collapse-asset-' + i" aria-expanded="false"                                   [attr.aria-controls]="'collapse-asset-' + i">
                                <span >{{asset.name}}</span>
                                <span class="ml-2" *ngIf="!selectedAsset(asset.asset_id)" (click)="$event.stopPropagation(); selectAsset(asset)"><i class="fa fa-check-circle-o"></i>Select</span>
                                <span class="ml-2" *ngIf="selectedAsset(asset.asset_id)" (click)="$event.stopPropagation(); unselectAsset(asset)"><i class="fa fa-times-circle-o"></i>Unselect</span>
                            </button>
                        </div>
                    </div>
                    <div id="collapse-asset-{{i}}" class="collapse ml-4" [ngClass]="{'show' : selectAssetForComponents(asset.asset_id) }" >
                        <div class="card mb-1 border-1px accordion3-height" [ngClass]="{'bg-success' : selectedComponent(component.component_id) }" *ngFor="let component of assetComponents[asset.asset_id]">
                            <div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
                                <span class="btn btn-link text-dark shadow-none margin-7">
                                    <span>{{component.name}}</span>
                                    <span class="ml-2" *ngIf="!selectedComponent(component.component_id)" (click)="$event.stopPropagation(); selectComponent(component)"><i class="fa fa-check-circle-o"></i>Select</span>
                                    <span class="ml-2" *ngIf="selectedComponent(component.component_id)" (click)="$event.stopPropagation(); unselectComponent(component)"><i class="fa fa-times-circle-o"></i>Unselect</span>
                                </span>
                            </div>
                        </div>  
                    </div>                       
                </div>
            </div>
        </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Close')" >Cancel</button>
    <button type="button" class="btn btn-white pull-right" (click)="nextStep()">Done</button>
</div>