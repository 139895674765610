import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { VendorComponent } from "./vendor.component";
import { VendorRoutingModule } from "./vendor-routing.module";
import { VendorListComponent } from "../vendor/vendor-list/vendor-list.component";
import { VendorFormComponent } from "../vendor/vendor-form/vendor-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { VendorService } from "../vendor/vendor.service";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';

@NgModule({
  imports: [
    CommonModule,
    VendorRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ExpandScreenModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [
    VendorComponent,
    VendorListComponent,
    VendorFormComponent],
  providers: [
    VendorService
  ],
  exports: [
    VendorListComponent
  ]
})
export class VendorModule { }
