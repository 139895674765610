<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <ul class="nav nav-tabs nav-tabs-primary">
                    <li class="nav-item" *ngIf="showTabAndData(['bluzone','treon'])">
                        <a class="nav-link" data-toggle="tab" (click)="getVibrationAlers()" href="#tab-vibration">
                            <span>Vibration</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" (click)="getTemperatureAlers()" href="#tab-temperature">
                            <span>High/Low</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" (click)="getMaintenanceAlers()" href="#tab-maintainance">
                            <span>Maintainance</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="sensor.type == 'rio'">
                        <a class="nav-link" data-toggle="tab" (click)="getDifferentialAlers()" href="#tab-differential">
                            <span>Differential</span>
                        </a>
                    </li>
                </ul>
                <!-- Tab panes -->
                <div class="tab-content">
                    <div id="tab-vibration" class="container tab-pane" *ngIf="showTabAndData(['bluzone','treon'])">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Active Alerts</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Alert Type</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Created</th>
                                                        <th scope="col">Corrective Action Reccomended</th>
                                                        <th scope="col">Corrective Action Taken</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of vibrationAlerts">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDateWithTime(item.dateReminder) }}</td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.toTake">
                                                                <option [value]="undefined">Select Action</option>
                                                                <option *ngFor="let action of vibrationAlertActions" [value]="action">{{ action }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.taken" >
                                                                <option [value]="undefined">Select Action</option>
                                                                <option *ngFor="let action of vibrationAlertActions" [value]="action">{{ action }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <textarea [(ngModel)]="item.note"></textarea>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.clearedBy">
                                                                <option [value]="undefined">Select User</option>
                                                                <option *ngFor="let user of users" [value]="user.name">{{ user.name }}</option>
                                                            </select>
                                                        </td>
                                                        <td> 
                                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="item.clearedOn">
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-white pull-right"  (click)="item.cleared = true; updateVibrationAlerts(item)">Clear</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="vibrationAlertsCleared.length > 0">
                            <div class="col-lg-12 p-1">
                                <button type="button" class="btn btn-white pull-right"  (click)="getVibrationAlertsCleared()">Show Cleared Alerts</button>
                            </div>
                        </div>
                        <div class="row" [hidden]="!vibrationAlertsCleared.length">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Cleared Alerts</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Alert Type</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Occurred</th>
                                                        <th scope="col">Corrective Action Reccomended</th>
                                                        <th scope="col">Corrective Action Taken</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                        <th scope="col">Cleard On</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of vibrationAlertsCleared">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDateWithTime(item.createdAt) }}</td>
                                                        <td>{{ item.toTake }}</td>
                                                        <td>{{ item.taken }}</td>
                                                        <td><textarea readonly>{{ item.note }}</textarea></td>
                                                        <td>{{ item.clearedBy }}</td>
                                                        <td>{{ formatDate(item.clearedOn) }}</td>
                                                        <td>{{ formatDateWithTime(item.updatedAt) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tab-temperature" class="container tab-pane">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Active Alerts</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Action Item</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Reminder Date</th>
                                                        <th scope="col">Corrective Action Reccomended</th>
                                                        <th scope="col">Corrective Action Taken</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of temperatureAlerts">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDate(item.dateReminder) }}</td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.toTake">
                                                                <option [value]="undefined">Select Action</option>
                                                                <option *ngFor="let action of temperatureAlertActions" [value]="action">{{ action }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.taken" >
                                                                <option [value]="undefined">Select Action</option>
                                                                <option *ngFor="let action of temperatureAlertActions" [value]="action">{{ action }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <textarea [(ngModel)]="item.note"></textarea>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.clearedBy">
                                                                <option [value]="undefined">Select User</option>
                                                                <option *ngFor="let user of users" [value]="user.name">{{ user.name }}</option>
                                                            </select>
                                                        </td>
                                                        <td> 
                                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="item.clearedOn">
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-white pull-right"  (click)="item.cleared = true; updateTemperatureAlerts(item)">Clear</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" [hidden]="temperatureAlertsCleared.length > 0">
                            <div class="col-lg-12 p-1">
                                <button type="button" class="btn btn-white pull-right"  (click)="getTemperatureAlersCleared()">Show Cleared Alerts</button>
                            </div>
                        </div>        

                        <div class="row" [hidden]="!temperatureAlertsCleared.length">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Cleared Alerts</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Action Item</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Reminder Date</th>
                                                        <th scope="col">Corrective Action Reccomended</th>
                                                        <th scope="col">Corrective Action Taken</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of temperatureAlertsCleared">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDate(item.dateReminder) }}</td>
                                                        <td>{{ item.toTake }}</td>
                                                        <td>{{ item.taken }}</td>
                                                        <td><textarea readonly>{{ item.note }}</textarea></td>
                                                        <td>{{ item.clearedBy }}</td>
                                                        <td>{{ formatDate(item.clearedOn) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tab-maintainance" class="container tab-pane active">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Reminders</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Action Item</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Reminder Date</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Assigned To</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of maintenanceAlerts">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDate(item.dateReminder) }}</td>
                                                        <td>
                                                            <textarea [(ngModel)]="item.note"></textarea>
                                                        </td>
                                                        <td>{{ item.assignedTo }}</td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.clearedBy">
                                                                <option [value]="undefined">Select User</option>
                                                                <option *ngFor="let user of users" [value]="user.name">{{ user.name }}</option>
                                                            </select>
                                                        </td>
                                                        <td> 
                                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="item.clearedOn">
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-white pull-right"  (click)="item.cleared = true; updateMaintenanceAlerts(item)">Clear</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="maintenanceAlertsCleared.length > 0">
                            <div class="col-lg-12 p-1">
                                <button type="button" class="btn btn-white pull-right"  (click)="getMaintenanceAlersCleared()">Show Complete</button>
                            </div>
                        </div>   
                        <div class="row" [hidden]="!maintenanceAlertsCleared.length">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Completed</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Action Item</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Reminder Date</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Assigned To</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of maintenanceAlertsCleared">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDate(item.dateReminder) }}</td>
                                                        <td><textarea readonly>{{ item.note }}</textarea></td>
                                                        <td>{{ item.assignedTo }}</td>
                                                        <td>{{ item.clearedBy }}</td>
                                                        <td>{{ formatDate(item.clearedOn) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tab-differential" class="container tab-pane">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Active Alerts</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Action Item</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Reminder Date</th>
                                                        <th scope="col">Corrective Action Reccomended</th>
                                                        <th scope="col">Corrective Action Taken</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of differentialAlerts">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDate(item.dateReminder) }}</td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.toTake">
                                                                <option [value]="undefined">Select Action</option>
                                                                <option *ngFor="let action of differentialAlertActions" [value]="action">{{ action }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.taken" >
                                                                <option [value]="undefined">Select Action</option>
                                                                <option *ngFor="let action of differentialAlertActions" [value]="action">{{ action }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <textarea [(ngModel)]="item.note"></textarea>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" [(ngModel)]="item.clearedBy">
                                                                <option [value]="undefined">Select User</option>
                                                                <option *ngFor="let user of users" [value]="user.name">{{ user.name }}</option>
                                                            </select>
                                                        </td>
                                                        <td> 
                                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="item.clearedOn">
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-white pull-right"  (click)="item.cleared = true; updateDifferentialAlerts(item)">Clear</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" [hidden]="differentialAlertsCleared.length > 0">
                            <div class="col-lg-12 p-1">
                                <button type="button" class="btn btn-white pull-right"  (click)="getDifferentialAlersCleared()">Show Cleared Alerts</button>
                            </div>
                        </div>        

                        <div class="row" [hidden]="!differentialAlertsCleared.length">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Cleared Alerts</h5>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Action Item</th>
                                                        <th scope="col">Sensor</th>
                                                        <th scope="col">Component</th>
                                                        <th scope="col">Reminder Date</th>
                                                        <th scope="col">Corrective Action Reccomended</th>
                                                        <th scope="col">Corrective Action Taken</th>
                                                        <th scope="col">Note</th>
                                                        <th scope="col">Action Taken By</th>
                                                        <th scope="col">Action Taken Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of differentialAlertsCleared">
                                                        <td>{{ item.action }}</td>
                                                        <td>{{ item.sensor }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ formatDate(item.dateReminder) }}</td>
                                                        <td>{{ item.toTake }}</td>
                                                        <td>{{ item.taken }}</td>
                                                        <td><textarea readonly>{{ item.note }}</textarea></td>
                                                        <td>{{ item.clearedBy }}</td>
                                                        <td>{{ formatDate(item.clearedOn) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
