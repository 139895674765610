import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { NGXToastrService } from '../../../services/toaster.service';

import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { HelperService } from 'src/services/helper.service';

@Component({
  selector: 'app-sensor-import',
  templateUrl: './sensor-import.component.html',
  styleUrls: ['./sensor-import.component.scss']
})
export class SensorImportComponent implements OnInit {

  @Input() component: any;
  inputForm: FormGroup;
  sensorsCsvFile: any;
  submitted = false;
  uploading = false;
  importing = false;

  public uploader: FileUploader = new FileUploader({ autoUpload: true, itemAlias: 'file' });
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _sensorService: SensorService,
    private _toasterService: NGXToastrService,
    public _sharedService: SharedService,
    public _helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.setFormValidation();
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      sensors_csv: ['', [Validators.required]]
    });
  }

  async validateForm() {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.importing = true;
      let response = await this._sensorService.importSensors(this.sensorsCsvFile);
      if (response) {
        this.importing = false;
        this._toasterService.typeSuccess('Sensors import completed');
        this.activeModal.close();
      }
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  async onFileChanged(event: any) {
    let response = await this._sharedService.uploadFile(event.target.files[0], this._helperService.formatSaveFileUrl('component', this.component.component_id));
    if (response) {
      this.uploading = false;
      this.sensorsCsvFile = response;
      this._toasterService.typeSuccess("Attatchment Uploaded Successful")
    };
  }
}
