import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../services/helper.service';
import { NGXToastrService } from '../../services/toaster.service';
import { LocalstorageService } from '../../services/localstorage.service';
import { SharedService } from 'src/services/shared.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  @Input() obj: any;
  image: any;
  viewerTabs: any = [];
  activeTab = 'info';

  constructor(
    public activeModal: NgbActiveModal,
    private _helperService: HelperService,
    private _toasterService: NGXToastrService,
    private _localstorageService: LocalstorageService,
    public _sharedService: SharedService

  ) { }

  async ngOnInit() {
    let user = this._localstorageService.getUserData();
    this.viewerTabs = user.viewer_items;
    await this.setImage();
  }

  async setImage(){
    this.image = await this._helperService.setImage(this.obj.resource.image.original, this.obj.type, this.obj.id);
  }

  makeActive(tabId) {
    this.activeTab = tabId;
  }

  active(tabId) {
    return this.activeTab == tabId;
  }

  show(tabId) {
    let tabs = ['pms', 'wos', 'parts', 'vendors', 'timeline'];
    let types = ['plant', 'location'];
    if (tabs.includes(tabId) && types.includes(this.obj.type)) {
      return false;
    }
    return true;
  }

  async onFileChanged(event: any) {
    this._toasterService.typeInfoStayOnScreen('uploading..', 600000);
    let response = await this._sharedService.uploadImage(event.target.files[0], this._helperService.formatSaveImageUrl(true,true,this.obj.type,this.obj.id));
    if (response) {
      setTimeout(async () => {
        this._toasterService.clearToast();
        if (response) {
          this.obj.resource.image.original = response.original;
          this.obj.resource.image.thumb = response.thumb;
          this.updateRecource(this.obj.resource.image,'image');
        } else {
          this.obj.resource.image.original = '';
          this.obj.resource.image.thumb = '';
        }
        this._toasterService.typeSuccess('Uploaded Successfully');
        await this.setImage();
      }, 3000);
    };
  }

  async updateRecource(data, property) {
    this.obj.resource[property] = data;
    switch (this.obj.type) {
        case 'plant':
            await this._sharedService.updatePlant(this.obj.resource);
            break;
        case 'location':
            await this._sharedService.updateLocation(this.obj.resource);
            break;
        case 'asset':
            await this._sharedService.updateAsset(this.obj.resource);
            break;
        case 'component':
            await this._sharedService.updateComponent(this.obj.resource);
            break;
            
    }
}

}
