<div class="modal-header">
    <h5 class="modal-title mt-2">Sensor Configuration</h5>
    <div class="form-group d-flex m-0">
        <select (change)="setSampleData($event)" class="form-control font-12px bg-white">
            <option value="">Load Sample Data</option>
            <option *ngFor="let sample of samples" value="{{sample.type}}">{{sample.type}}</option>
        </select>
        <button type="button" class="close" (click)="activeModal.dismiss('Cross Click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
        </button>
    </div>
    
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group" *ngIf="sensorList.length > 0 && sensor.sensor_id == ''">
                    <label>Sensor List *</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Select Sensors'" 
                    [settings]="setMultiselectSetting('sensor_id','name')"
                    [data]="sensorList"
                    [(ngModel)]="sensor.sensor_list"
                    formControlName="sensor_list"
                    [disabled]="disableField()"
                    >
                    </ng-multiselect-dropdown> 
                    <div [hidden]="!error('sensor_list','required')" class="invalid">Tabs are required</div> 
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group" *ngIf="sensor.sensor_list.length == 0">
                            <label>Sensor ID *</label>
                            <input type="text" class="form-control" placeholder="Enter Sensor ID" formControlName="sensor_id" [(ngModel)]="sensor.sensor_id" [ngClass]="{'disable-field': disableField()}">
                            <div [hidden]="!error('sensor_id','required')" class="invalid">Sensor ID is required</div> 
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group" *ngIf="sensor.sensor_list.length == 0">
                            <label>Sensor Name</label>
                            <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [(ngModel)]="sensor.name" [ngClass]="{'disable-field': disableField()}">
                            <div [hidden]="!error('name','required')" class="invalid">Sensor Name is required</div> 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Offset</label>
                            <input type="number" class="form-control" placeholder="Enter Offset" formControlName="off_set" [(ngModel)]="sensor.off_set" [ngClass]="{'disable-field': disableField()}">
                            <div [hidden]="!error('off_set','required')" class="invalid">Offset is required</div> 
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Scaling</label>
                            <input type="number" class="form-control" placeholder="Enter Scaling" formControlName="scaling" [(ngModel)]="sensor.scaling" [ngClass]="{'disable-field': disableField()}">
                            <div [hidden]="!error('scaling','required')" class="invalid">Scaling is required</div> 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>User For</label>
                            <div class="input-group">
                                <select class="form-control" id="default-select" formControlName="used_for" [(ngModel)]="sensor.used_for" (ngModelChange)="getUnits()" [ngClass]="{'disable-field': disableField()}">
                                    <option value="">Select Used For</option>
                                    <option *ngFor="let item of usedFor" [value]="item.name">{{ item.name }}</option>
                                </select> 
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" [disabled]="disableField()" (click)="toggleAddUsedForBlock()"><i class="fa fa-plus"></i></button> 
                                </div>
                            </div>
                            <div [hidden]="!error('used_for','required')" class="invalid">Used For is required</div>
                            <div class="input-group mt-2" [hidden]="!usedForBlock">
                                <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder="Enter Used For" [(ngModel)]="usedForData.name">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="addUsedFor()">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Unit</label>
                            <div class="input-group">
                                <select class="form-control" id="default-select" formControlName="unit" [(ngModel)]="sensor.unit" [ngClass]="{'disable-field': disableField()}">
                                    <option value="">Select Unit</option>
                                    <option *ngFor="let item of units" [value]="item.name">{{ item.name }}</option>
                                </select> 
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" [disabled]="disableField()" (click)="toggleAddUnitBlock()"><i class="fa fa-plus"></i></button> 
                                </div>
                            </div>
                            <div [hidden]="!error('unit','required')" class="invalid">Unit is required</div>
                            <div class="input-group mt-2" [hidden]="!unitBlock">
                                <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder="Enter Unit" [(ngModel)]="unitData.name">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="addUnit()">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Integration *</label>
                            <select class="form-control" formControlName="integration_id" [(ngModel)]="sensor.integration_id" [ngClass]="{'disable-field': disableField()}">
                                <option value="">Select Integration</option>
                                <option *ngFor="let integration of integrations" [value]="integration.integration_id" >{{ integration.name }}</option>
                            </select>
                            <div [hidden]="!error('integration_id','required')" class="invalid">Integration is required</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Mapping *</label>
                            <select class="form-control" formControlName="mapping_id" [(ngModel)]="sensor.mapping_id" [ngClass]="{'disable-field': disableField()}">
                                <option value="">Select Mapping</option>
                                <option *ngFor="let mapping of mappings" [value]="mapping.mapping_id" >{{ mapping.name }}</option>
                            </select>
                            <div [hidden]="!error('mapping_id','required')" class="invalid">Mapping is required</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Component *</label>
                            <select class="form-control" formControlName="component_id" [(ngModel)]="sensor.component_id" [ngClass]="{'disable-field': disableField()}">
                                <option value="">Select Component</option>
                                <option *ngFor="let component of components" [value]="component.component_id" >{{ component.name }}</option>
                            </select>
                            <div [hidden]="!error('component_id','required')" class="invalid">Component is required</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Select Group</label>
                            <div class="input-group">
                                <select class="form-control" id="default-select" formControlName="group" [(ngModel)]="sensor.group" [ngClass]="{'disable-field': disableField()}">
                                    <option value="">Select Group</option>
                                    <option *ngFor="let group of groups" [value]="group.name">{{ group.name }}</option>
                                </select> 
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" [disabled]="disableField()" (click)="toggleSensorGroupBlock()"><i class="fa fa-plus"></i></button> 
                                    <button type="button" class="btn btn-white ml-1" [disabled]="disableField()" (click)="toggleSensorGroupBlock('edit')"><i class="fa fa-pencil"></i></button> 
                                </div>
                            </div>
                            <div [hidden]="!error('group','required')" class="invalid">Group is required</div>
                            <div class="input-group mt-2" [hidden]="!groupBlock">
                                <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" placeholder="Enter group name" [(ngModel)]="sensorGroupData.name">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" title="Add" [hidden]="editGroup" (click)="addSensorGroup()"><i class="fa fa-save"></i></button>
                                    <button type="button" class="btn btn-white" title="Update" [hidden]="!editGroup" (click)="editSensorGroup()"><i class="fa fa-refresh"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Tabs *</label>
                            <ng-multiselect-dropdown
                            [placeholder]="'Select Tabs'" 
                            [data]="tabs"
                            [(ngModel)]="sensor.tabs"
                            formControlName="tabs"
                            [settings]="setMultiselectSetting('tab_id','tab_text')"
                            [disabled]="disableField()"
                            >
                            </ng-multiselect-dropdown> 
                            <div [hidden]="!error('tabs','required')" class="invalid">Tabs are required</div> 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Data Source *</label>
                            <input type="text" class="form-control" placeholder="Enter Source Url" formControlName="url" [(ngModel)]="sensor.data_source.url" [ngClass]="{'disable-field': disableField()}">
                            <div [hidden]="!error('url','required')" class="invalid">Source url is required</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Method *</label>
                            <select class="form-control" formControlName="method" [(ngModel)]="sensor.data_source.method" [ngClass]="{'disable-field': disableField()}">
                                <option value="">Select Method</option>
                                <option value="get" >Get</option>
                                <option value="post" >Post</option>
                            </select>
                            <div [hidden]="!error('method','required')" class="invalid">Method is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group" [hidden]="!sensor.end_point">
                    <label>FFT End Point <small>(For Treon Sensor)</small></label>
                    <input type="text" class="form-control" placeholder="Enter FFT End Point" formControlName="end_point" [(ngModel)]="sensor.end_point" [ngClass]="{'disable-field': disableField()}">
                    <div [hidden]="!error('end_point','required')" class="invalid">FFT End Point is required</div> 
                </div>
                <div class="form-group">
                    <div class="icheck-material-white" [ngClass]="{'disable-check': disableField()}">
                        <input type="checkbox" id="is-url-complete" formControlName="is_url_complete" [(ngModel)]="sensor.data_source.is_url_complete">
                        <label for="is-url-complete">Is Url Complete</label>
                    </div>
                    <div [hidden]="!error('is_url_complete','required')" class="invalid">Is url complete is required</div>
                </div>
                <div class="form-group" [hidden]="!sensor._id">
                    <label>Image</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event)" type="file" name="file" class="form-control" #fileInput accept="image/*" />
                    </div>
                </div>
                <div class="row" [hidden]="!sensor._id">
                    <div class="col-12">
                        <img src="{{ image }}" class="image" />
                    </div>
                </div>
            </form>
        </div>
    </div>        
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button *ngIf="sensor._id" [hidden]="disableField()" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', sensor: sensor, sensorUniqueId: sensor.sensor_unique_id})">Update</button>
    <button *ngIf="!sensor._id" [hidden]="disableField()" type="button" class="btn btn-white pull-right"  (click)="validateForm({action: 'add', sensor: sensor})">Save</button>
    <button [hidden]="disableField()" type="button" class="btn btn-white pull-right mr-1" (click)="copySensor()"><i class="fa fa-copy"></i></button>
</div>  