import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sensor-replace-form',
  templateUrl: './sensor-replace-form.component.html',
  styleUrls: ['./sensor-replace-form.component.scss']
})
export class SensorReplaceFormComponent implements OnInit {

  @Input() sensor : any;
  replaceData : any = {};
  inputForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() { 
    this.setFormValidation();
  }

  setFormValidation(){
    this.inputForm = this.formBuilder.group({
      sensor_id: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  validateForm(){
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.replaceData.sensor_id = this.sensor.sensor_id;
      this.replaceData.sensor_unique_id = this.sensor.sensor_unique_id;
      this.activeModal.close({ replaceData : this.replaceData})
    }
  }

  error(comtrolName, constraint){
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }
  
}
  