import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SensorComponent } from './sensor.component';
import { SensorSettingComponent } from './sensor-setting/sensor-setting.component';

const routes: Routes = [
  {
    path: '',
    children: [   
      {
        path: '',
        component: SensorComponent,
        data: {
          title: 'List Sensors'
        }
      }, 
      {
        path: 'sensor-setting/:sensorId',
        component: SensorSettingComponent,
        data: {
          title: 'Sensor Setting'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SensorRoutingModule { }
