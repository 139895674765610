import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../../services/toaster.service';
//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-vibration-policy',
  templateUrl: './sensor-vibration-policy.component.html',
  styleUrls: ['./sensor-vibration-policy.component.scss']
})
export class SensorVibrationPolicyComponent implements OnInit {
  @Input() sensor: any;

  d1: any;
  d2: any;
  d3: any;
  d4: any;
  velocityLearnedMean: any;
  velocityAlertValue: any;
  accelerationLearnedMean: any;
  accelerationAlertValue: any;

  velocityClusterForm: FormGroup;
  accelerationClusterForm: FormGroup;
  velocityClusterSubmitted = false;
  accelerationClusterSubmitted = false;

  velocityCluster: any = {};
  accelerationCluster: any = {};
  constructor(
    private modalService: NgbModal,
    private _sensorService: SensorService,
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private _toasterService: NGXToastrService
  ) { }

  async ngOnInit() {
    this.setFormValidation();
    this.velocityCluster = await this._sensorService.getClusterConfiguration(this.sensor.sensor_id, 'velocity') || { no_of_clusters: 1, threshold: 5, time_span: '', interval: 2, status: false };
    this.accelerationCluster = await this._sensorService.getClusterConfiguration(this.sensor.sensor_id, 'acceleration') || { no_of_clusters: 1, threshold: 5, time_span: '', interval: 2, status: false };
    this.processVelocityDate();
    this.processAccelerationDate();
    this.setVelocitySetting();
    this.setAccelerationSetting();
  }

  setFormValidation() {
    this.velocityClusterForm = this.formBuilder.group({
      time_span: ['', [Validators.required]],
      threshold: ['', [Validators.required]],
      interval: ['', [Validators.required, Validators.min(1)]],
      no_of_clusters: ['', [Validators.required, Validators.min(1), Validators.max(3)]]
    });
    this.accelerationClusterForm = this.formBuilder.group({
      time_span: ['', [Validators.required]],
      threshold: ['', [Validators.required]],
      interval: ['', [Validators.required, Validators.min(1)]],
      no_of_clusters: ['', [Validators.required, Validators.min(1), Validators.max(3)]]
    });
  }

  errorVelocityCluster(comtrolName, constraint) {
    let errors = this.velocityClusterForm['controls'][comtrolName]['errors'];
    return this.velocityClusterSubmitted && errors && errors[constraint]
  }

  errorAccelerationCluster(comtrolName, constraint) {
    let errors = this.accelerationClusterForm['controls'][comtrolName]['errors'];
    return this.accelerationClusterSubmitted && errors && errors[constraint]
  }


  async addClusterConfiguration(type) {
    if (type == 'velocity') {
      this.velocityClusterSubmitted = true;
      if (!this.velocityClusterForm.invalid) {
        this.velocityCluster.type = type;
        this.velocityCluster.sensor_id = this.sensor.sensor_id;
        this.setVelocityDates();
        this.velocityCluster = await this._sensorService.addClusterConfiguration(this.velocityCluster);
        this.processVelocityDate();
        this.setVelocitySetting();
      }
    } else {
      this.accelerationClusterSubmitted = true;
      if (!this.accelerationClusterForm.invalid) {
        this.accelerationCluster.type = type;
        this.accelerationCluster.sensor_id = this.sensor.sensor_id;
        this.setAccelerationDates();
        this.accelerationCluster = await this._sensorService.addClusterConfiguration(this.accelerationCluster);
        this.processAccelerationDate();
        this.setAccelerationSetting();
      }
    }
  }

  async copyClusterConfiguration(type, copyType = '') {
    const modalRef = this.modalService.open(SelectSensorModalComponent, { backdrop: 'static' });
    let sensors = await this._sharedService.getSensors('?type='+this.sensor.type+'&used_for='+this.sensor.used_for);
    let index = sensors.findIndex(s => s.sensor_id == this.sensor.sensor_id);
    sensors.splice(index, 1);
    modalRef.componentInstance.sensors = sensors;

    modalRef.result.then(async (result) => {
      this._toasterService.typeInfoStayOnScreen('Please stay on this screen and do not start other operations until success message appears.', 3000);

      setTimeout(() => {
        this._toasterService.typeWarningStayOnScreen('Copying..', 600000);
      }, 3000);

      setTimeout(async () => {
        let copied_data: any = {};
        if (type == 'velocity') {
          this.setVelocityDates();
          copied_data = { ...this.velocityCluster };
          copied_data.alert_value = this.velocityAlertValue;
        } else {
          this.setAccelerationDates();
          copied_data = { ...this.accelerationCluster };
          copied_data.alert_value = this.accelerationAlertValue;
        }
        copied_data.type = type;
        delete copied_data._id;
        result.cluster = copied_data;

        let response = await this._sensorService.copyClusterConfiguration(result, copyType);
        if (response) {
          setTimeout(() => {
            this._toasterService.clearToast();
          }, 1000);
        }
      }, 6000);
    }, (reason) => {
      console.log(reason);
    });
  }

  async updateClusterConfiguration(type) {
    if (type == 'velocity') {
      this.velocityClusterSubmitted = true;
      if (!this.velocityClusterForm.invalid) {
        this.velocityCluster.type = type;
        this.velocityCluster.threshold = 5; //While relearning we have to make threshhold 5
        this.setVelocityDates();
        this.velocityCluster = await this._sensorService.updateClusterConfiguration(this.velocityCluster);
        this.processVelocityDate();
        this.setVelocitySetting();
      }
    } else {
      this.accelerationClusterSubmitted = true;
      if (!this.accelerationClusterForm.invalid) {
        this.accelerationCluster.type = type;
        this.accelerationCluster.threshold = 5; //While relearning we have to make threshhold 5
        this.setAccelerationDates();
        this.accelerationCluster = await this._sensorService.updateClusterConfiguration(this.accelerationCluster);
        this.processAccelerationDate();
        this.setAccelerationSetting();
      }
    }
  }

  processVelocityDate() {
    if (this.velocityCluster.time_span == 'Custom') {
      this.velocityCluster.start_date = new Date(this.velocityCluster.start_date);
      this.velocityCluster.end_date = new Date(this.velocityCluster.end_date);
    }

    if (!this.velocityCluster.end_date) {
      this.velocityCluster.end_date = new Date();
    }
    if (!this.velocityCluster.start_date) {
      this.velocityCluster.start_date = this.getDateDifference(30);
    }
  }

  processAccelerationDate() {
    if (this.accelerationCluster.time_span == 'Custom') {
      this.accelerationCluster.start_date = new Date(this.accelerationCluster.start_date);
      this.accelerationCluster.end_date = new Date(this.accelerationCluster.end_date);
    }
    if (!this.accelerationCluster.end_date) {
      this.accelerationCluster.end_date = new Date();
    }
    if (!this.accelerationCluster.start_date) {
      this.accelerationCluster.start_date = this.getDateDifference(30);
    }
  }

  setVelocityDates() {
    if (this.velocityCluster.time_span == '1 Day') {
      this.velocityCluster.start_date = this.getDateDifference(1);
      this.velocityCluster.end_date = new Date();
    } else if (this.velocityCluster.time_span == '1 Week') {
      this.velocityCluster.start_date = this.getDateDifference(7);
      this.velocityCluster.end_date = new Date();
    } else if (this.velocityCluster.time_span == '1 Month') {
      this.velocityCluster.start_date = this.getDateDifference(30);
      this.velocityCluster.end_date = new Date();
    }
  }

  setAccelerationDates() {
    if (this.accelerationCluster.time_span == '1 Day') {
      this.accelerationCluster.start_date = this.getDateDifference(1);
      this.accelerationCluster.end_date = new Date();
    } else if (this.accelerationCluster.time_span == '1 Week') {
      this.accelerationCluster.start_date = this.getDateDifference(7);
      this.accelerationCluster.end_date = new Date();
    } else if (this.accelerationCluster.time_span == '1 Month') {
      this.accelerationCluster.start_date = this.getDateDifference(30);
      this.accelerationCluster.end_date = new Date();
    }
  }

  getDateDifference(days) {
    var date = new Date();
    var last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    return last;
  };

  setVelocitySetting() {
    if (this.velocityCluster.settings) {
      let settingLength = Object.keys(this.velocityCluster.settings).length;

      let learnedMeanIndex: any;
      settingLength == 1 ? learnedMeanIndex = 1 : learnedMeanIndex = 2;
      let val1 = this.velocityCluster.settings[learnedMeanIndex].xRams.toFixed(3);
      let val2 = this.velocityCluster.settings[learnedMeanIndex].yRams.toFixed(3);
      let val3 = this.velocityCluster.settings[learnedMeanIndex].zRams.toFixed(3);
      if (val1 >= val2 && val1 >= val3) {
        this.velocityLearnedMean = val1;
      } else if (val2 >= val1 && val2 >= val3) {
        this.velocityLearnedMean = val2;
      } else {
        this.velocityLearnedMean = val3;
      }
      this.velocityAlertValue = (this.velocityLearnedMean * this.velocityCluster.threshold).toFixed(3);
    }
  }

  setAccelerationSetting() {
    if (this.accelerationCluster.settings) {
      let settingLength = Object.keys(this.accelerationCluster.settings).length;

      let learnedMeanIndex: any;
      settingLength == 1 ? learnedMeanIndex = 1 : learnedMeanIndex = 2;
      let val1 = this.accelerationCluster.settings[learnedMeanIndex].xRams.toFixed(3);
      let val2 = this.accelerationCluster.settings[learnedMeanIndex].yRams.toFixed(3);
      let val3 = this.accelerationCluster.settings[learnedMeanIndex].zRams.toFixed(3);
      if (val1 >= val2 && val1 >= val3) {
        this.accelerationLearnedMean = val1;
      } else if (val2 >= val1 && val2 >= val3) {
        this.accelerationLearnedMean = val2;
      } else {
        this.accelerationLearnedMean = val3;
      }
      this.accelerationAlertValue = (this.accelerationLearnedMean * this.accelerationCluster.threshold).toFixed(3);
    }
  }

  async updateClusterSetting(type) {
    if (type == 'velocity') {
      if (!this.velocityClusterForm.invalid) {
        this.velocityCluster.threshold = parseFloat((this.velocityAlertValue / this.velocityLearnedMean).toFixed(3));
        await this._sensorService.updateClusterSetting(this.velocityCluster);
      }
    } else {
      if (!this.accelerationClusterForm.invalid) {
        this.accelerationCluster.threshold = parseFloat((this.accelerationAlertValue / this.accelerationLearnedMean).toFixed(3));
        await this._sensorService.updateClusterSetting(this.accelerationCluster);
      }
    }
  }
}
