<!-- Breadcrumb-->
<div class="row pt-2 pb-2" id="header-alerts">
	<div class="col-3">
		<h4 class="page-title">Alerts Details</h4>
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href="javaScript:;">RAMS</a>
			</li>
			<li class="breadcrumb-item"><a href="javaScript:;">Alerts Details</a>
			</li>
		</ol>
	</div>
	<div class="col-9"> 
        <div ngbDropdown class="btn-group float-sm-right">
            <select (change)="changeRowLimits($event)" class=" btn-light mr-1 px-1"> 
                <option *ngFor="let limit of limits" [value]="limit.value">{{limit.key}}</option>
            </select>
            <button type="button" class="btn btn-light mr-1" *ngIf="showCleared" (click)="getActiveAlerts()">Show Active</button>
            <button type="button" class="btn btn-light mr-1" *ngIf="!showCleared" (click)="getClearedAlerts()">Show Cleared</button>
            <button type="button" class="btn btn-light mr-1" *ngIf="!showCleared" (click)="openConfirmationModal()">Clear All Alerts</button>
            <button type="button" class="btn btn-light" (click)="downloadFile()">Export</button>
            <app-pin [pinData]='pinData'></app-pin>
            <app-expand-screen></app-expand-screen>
        </div>
    </div>
</div>
<!-- End Breadcrumb-->

<div class="row" id="alerts-details">
	<div class="col-lg-12" >
		<div class="card card-content">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" *ngIf="!showCleared" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th scope="col">Alert Type</th>
                                <th scope="col">Sensor</th>
                                <th scope="col">Component</th>
                                <th scope="col">Created</th>
                                <th scope="col">Corrective Action Recommended</th>
                                <th scope="col">Corrective Action Taken</th>
                                <th scope="col">Note</th>
                                <th scope="col">Action Taken By</th>
                                <th scope="col">Action Taken Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of alerts | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }">
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ item.action }}</td>
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ item.sensor }}</td>
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ item.component }}</td>
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ helperService.formatDateWithTime(item.dateReminder) }}</td>
                                <td *ngIf="isTemperatureAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.toTake">
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of temperatureAlertActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td *ngIf="isTemperatureAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.taken" >
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of temperatureAlertActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td *ngIf="isVibrationAlert(item.type)"> 
                                    <select class="form-control" [(ngModel)]="item.toTake">
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of vibrationAlertActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td *ngIf="isVibrationAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.taken" >
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of vibrationAlertActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td *ngIf="isBatteryAndSignalLostAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.toTake" >
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of batteryAndSignaLostActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td *ngIf="isBatteryAndSignalLostAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.taken" >
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of batteryAndSignaLostActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td *ngIf="isDifferentialAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.toTake">
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of differentialAlertActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td  *ngIf="isDifferentialAlert(item.type)">
                                    <select class="form-control" [(ngModel)]="item.taken" >
                                        <option [value]="undefined">Select Action</option>
                                        <option *ngFor="let action of differentialAlertActions" [value]="action">{{ action }}</option>
                                    </select>
                                </td>
                                <td>
                                    <textarea [(ngModel)]="item.note"  rows="1" cols="30" class="form-control"></textarea>
                                </td>
                                <td>
                                    <select class="form-control" [(ngModel)]="item.clearedBy">
                                        <option [value]="undefined">Select User</option>
                                        <option *ngFor="let user of users" [value]="user.name">{{ user.name }}</option>
                                    </select>
                                </td>
                                <td> 
                                    <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" [(ngModel)]="item.clearedOn">
                                </td>
                                <td>
                                    <button type="button" class="btn btn-white pull-right"  (click)="item.cleared = true; updateAlerts(item)">Clear</button>
                                </td>
                            </tr>
                            <tr [hidden]="alerts.length != 0">
                                <td colspan="10">No record found.</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered" *ngIf="showCleared" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th scope="col">Alert Type</th>
                                <th scope="col">Sensor</th>
                                <th scope="col">Component</th>
                                <th scope="col">Occurred</th>
                                <th scope="col">Corrective Action Reccomended</th>
                                <th scope="col">Corrective Action Taken</th>
                                <th scope="col">Note</th>
                                <th scope="col">Action Taken By</th>
                                <th scope="col">Action Taken Date</th>
                                <th scope="col">Cleard On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of alerts | paginate: { itemsPerPage: limit, currentPage: page, totalItems: total }">
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ item.action }}</td>
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ item.sensor }}</td>
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ item.component }}</td>
                                <td class="pointer" (click)="openSensorListModal(item.beaconId)">{{ helperService.formatDateWithTime(item.dateReminder) }}</td>
                                <td>{{ item.toTake }}</td>
                                <td>{{ item.taken }}</td>
                                <td><textarea readonly>{{ item.note }}</textarea></td>
                                <td>{{ item.clearedBy }}</td>
                                <td>{{ helperService.formatDate(item.clearedOn) }}</td>
                                <td>{{ helperService.formatDateWithTime(item.updatedAt) }}</td>                            
                            </tr>
                            <tr [hidden]="alerts.length != 0">
                                <td colspan="10">No record found.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="pagination bg-white pagination-sm bordered text-dark text-right" *ngIf="limit < total">
    <pagination-controls class="w-100" (pageChange)="getPage($event)" directionLinks="true"
    autoHide="true"
    responsive="true"
    previousLabel="Previous"
    nextLabel="Next"
    screenReaderPaginationLabel="Pagination"
    screenReaderPageLabel="page"
    screenReaderCurrentLabel="You're on page"></pagination-controls>
</div>