<!-- Dashboard modal -->
<div class="modal-header">
    <h5 class="modal-title">Dashboard</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross Click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Name</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [(ngModel)]="dashboard.name">
                        <div [hidden]="!error('name','required')" class="invalid">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Short Description</label>
                    <div class="input-group">
                        <textarea rows="4" class="form-control" id="basic-textarea" placeholder="Enter Description" formControlName="description" [(ngModel)]="dashboard.description"></textarea>
                        <div [hidden]="!error('description','required')" class="invalid">Description is required</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="!dashboard.dashboard_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', dashboard: dashboard, dashboardId:dashboard.dashboard_id})">Update</button>
    <button [hidden]="dashboard.dashboard_id" type="button" class="btn btn-white pull-right"  (click)="validateForm({action: 'add', dashboard: dashboard})">Save</button>
</div>    

