<div class="modal-header">
    <h5 class="modal-title">{{assignedToData.name}} Assigned To</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span
            aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h4 class="text-dark" *ngIf="assignedToData?.users?.length > 0">Users</h4>
    <div class="card">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" id="user-card">
                        <div class="list-group"  *ngFor="let user of assignedToData.users">  
                            <a href="javaScript:;" class="list-group-item list-group-item-action flex-column align-items-start">
                                <div class="d-flex w-100 justify-content-between">
                                    <h6 class="mb-1 text-dark">{{user.name}}</h6>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
</div>