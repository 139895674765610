<div class="row">
	<div class="col-6" *ngFor="let widget of widgets">
		<div class="card">
			<div class="card-header text-uppercase icheck-material-white">
				{{ widget.name }}
			</div>
			<div class="card-body">
				<div id="{{ widget.widget_id }}"></div>
			</div>
		</div>
	</div>
</div>
