<div class="row mb-2 mx-0">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Data Comparison</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<app-pin [pinData]='pinData'></app-pin>
			<app-expand-screen></app-expand-screen>
		</div>
	</div>
</div>	
<div class="card card-trend-data">
    <div class="card-body">
        <div class="row">
            <div class="col-xl-4 col-md-4 col-sm-6">
                <div class="form-group">
                    <label>Components</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Select Components'" 
                    [settings]="setMultiselectSetting('component_id','name')"
                    [data]="components"
                    [(ngModel)]="filter.components"
                    (ngModelChange)="getComponentSSensors()">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-6">
                <div class="form-group">
                    <label>Compare</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Select Sensors'" 
                    [settings]="setMultiselectSetting('sensor_unique_id','name')"
                    [data]="sensors"
                    [(ngModel)]="postData.selected_sensors"
                    (ngModelChange)="checkSensors()">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-6">
                <div class="form-group">
                    <label>Start Date</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="mm-dd-yyyy" [maxDate]="maxDate" [(ngModel)]="postData.start_date" ngbDatepicker #d1="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-light fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-6">    
                <div class="form-group">
                    <label>End Date</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="mm-dd-yyyy" [maxDate]="maxDate" [(ngModel)]="postData.end_date" ngbDatepicker #d2="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-light fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-6" [hidden]="!showMetric">
                <div class="form-group" >
                    <label>Metric</label>
                    <select class="form-control" [(ngModel)]="postData.metric">
                        <option [value]="undefined">Select Metric</option>
                        <option value="TEMP">Temperature</option>
                        <option value="HIGH_RESOLUTION_VELOCITY">Vibration (Velocity)</option>
                        <option value="HIGH_RESOLUTION_ACCELERATION">Vibration (Acceleration)</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-6 mt-4">
                <div class="form-group">
                    <button type="button" class="btn btn-white pull-right" (click)="getWidgetData()">Create Graph</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-12 col-xl-12">
                <app-line-chart [series]="widget.series" [xaxis]="widget.xaxis"></app-line-chart>
            </div>
        </div>
    </div>
</div>

