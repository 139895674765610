import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
//Other Imports
import { WidgetService } from '../widget.service';
import { SharedService } from '../../../services/shared.service';
import { SensorWidgetFormComponent } from '../sensor-widget-form/sensor-widget-form.component';

@Component({
  selector: 'app-widget-form',
  templateUrl: './widget-form.component.html',
  styleUrls: ['./widget-form.component.scss']
})
export class WidgetFormComponent implements OnInit {

  @Input() sensorUniqueId : any = {};
  sensorMapping: any = {};
  widgets: any = [];
  sensorWidgets: any = []
  
  constructor(
    private _widgetService: WidgetService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public _sharedService:SharedService
  ) { }

  async ngOnInit(){
    this.sensorWidgets =  await this._widgetService.getSensorWidgetsBySensorUniqueId(this.sensorUniqueId);
    this.widgets = await this._widgetService.getAllWidget();
    $.getScript("./assets/js/apex-custom-script.js");
  }

  sensorWidgetsByWidgetId(widgetId){
    return this.sensorWidgets.filter(sw => sw.widget_id == widgetId);
  }

  async openSensorWidgetModal(widgetId,tabId){
  const modalRef = this.modalService.open(SensorWidgetFormComponent, {size: 'xl', scrollable: true, backdrop : 'static'});
  modalRef.componentInstance.sensorUniqueId =  this.sensorUniqueId;
  modalRef.componentInstance.widgetId = widgetId;
  modalRef.componentInstance.tabId = tabId;
  modalRef.componentInstance.samples = await this._sharedService.getWidgetSampleData();


  modalRef.result.then(async (result) => {
    if(result.action == 'add'){
      result.sensorWidget.sensor_unique_id = this.sensorUniqueId;
      result.sensorWidget.widget_id = widgetId;
      await this._widgetService.addSensorWidget(result.sensorWidget)
    } else {
      await this._widgetService.updateSensorWidget(result.sensorWidget)
    }
  }, (reason) => {
  });
  }

  
}
