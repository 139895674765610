<div class="row mb-2 mx-0">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Manage Parts</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<button type="button" class="btn btn-white" *ngIf="obj" (click)="autoAssignResource()"><i class="fa fa-plus"></i></button>
			<button type="button" class="btn btn-white" *ngIf="!obj" (click)="openPartFormModal(part)"><i class="fa fa-plus"></i></button>
			<app-expand-screen></app-expand-screen>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<ngx-datatable [scrollbarH]="true" #mydatatable class="bootstrap" [rows]="parts" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [reorderable]="true">
							<ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
							<ngx-datatable-column name="Part Number" prop="number"></ngx-datatable-column>
							<ngx-datatable-column name="Quantity">
								<ng-template ngx-datatable-cell-template let-row="row">
									<input class="w-100-px h-20-px" type="number" value="{{row.quantity}}" (blur)="updatePart(row,'quantity', $event.target.value)" >
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="Price">
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
									<input class="w-100-px h-20-px" type="number" value="{{row.price}}" (blur)="updatePart(row,'price', $event.target.value)">
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="Vendors">
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<span *ngFor="let item of value">{{item.name}}<br></span>
									<span *ngIf="row.vendors.length == 0">Unassigned<br></span>
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column name="Belongs To">
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<a href="javascript:;" class=" text-dark mr-2" (click)="openResourceHierarchyModal('add', row, rowIndex)" *ngIf="!belongsTo(row)" ><i class="fa fa-plus fx-2"></i></a>
									<a href="javascript:;" class=" text-dark mr-2" (click)="openResourceHierarchyModal('edit', row, rowIndex)" *ngIf="belongsTo(row)"><i class="fa fa-eye fx-2"></i></a>
						             <span *ngIf="row.assets.length == 0 && row.components.length == 0">Unassigned<br></span>
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column class="pull-right" name="Actions" prop="part_id">
								<ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
									<a href="javascript:;" class="text-dark mr-2" (click)="openPartFormModal(row)"><i class="fa fa-pencil"></i></a>
									<a href="javascript:;" class="text-dark" (click)="openConfirmationModal(value, rowIndex)"><i class="fa fa-trash"></i></a>
								</ng-template>
							</ngx-datatable-column>
						</ngx-datatable>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>