<div class="modal-header">
    <h5 class="modal-title">Purchase Requisition</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card p-2">
        <div class="card-body">
            <form [formGroup]="inputForm" class="p-0">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Requisition No *</label>
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="number" [(ngModel)]="orderNumber" readonly>
                                <div *ngIf="error('number','required')" class="invalid">No. is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Order Date *</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d1="ngbDatepicker" formControlName="order_date">
                                <div class="input-group-append">
                                    <button class="btn btn-light fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                                </div>
                                <div *ngIf="error('order_date','required')" class="invalid">Date is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Delivery Date*</label>
                            <div class="input-group">
                                <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d2="ngbDatepicker" formControlName="required_date">
                                <div class="input-group-append">
                                    <button class="btn btn-light fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                                </div>
                                <div *ngIf="error('required_date','required')" class="invalid">Date is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Vendor *</label>
                            <div class="input-group">
                                <select class="form-control" formControlName="vendor_id" placleholder="Select Vendor" (change)="getPartByVendor()">
                                    <option value="">Select Vendor</option>
                                    <option *ngFor="let vendor of vendors" [value]="vendor.vendor_id">
                                        {{vendor.name }}
                                    </option>
                                </select>
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="openVendorFormModal()"><i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="inputState">Department *</label>
                            <input type="text" class="form-control" formControlName="department">
                            <div *ngIf="error('department','required')" class="invalid">Department is required</div>

                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="inputState">To be delivered at *</label>
                            <input type="text" class="form-control" formControlName="delivered_at">
                            <div *ngIf="error('delivered_at','required')" class="invalid">Delivered at is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="requisition.requisitions.length > 0">
                    <table class="table">
                        <tr>
                            <th>Part Id</th>
                            <th>Description</th>
                            <th>Quantity</th>
                            <th>Purpose</th>
                            <th class="text-right">Action</th>
                        </tr>
                        <tr *ngFor="let requisition of requisition.requisitions; let i = index;">
                            <td>
                                <span *ngIf="requisition.part_id">{{getPartId(requisition.part_id)}}</span>
                                <span *ngIf="!requisition.part_id">N/A</span>
                            </td>
                            <td *ngIf="requisition.part_id">{{getPartName(requisition.part_id)}}</td>
                            <td *ngIf="requisition.service_name">{{requisition.service_name}}</td>
                            <td>
                                <span *ngIf="requisition.part_id">{{requisition.quantity}}</span>
                                <span *ngIf="!requisition.part_id">N/A</span>
                            </td>
                            <td>{{requisition.purpose}} <span *ngIf="requisition.work_order_id"> - {{ getWorkOrderName(requisition.work_order_id) }}</span></td>
                            <td class="text-right"><a href="javascript:;" (click)="removeRequisition(i)"><i class="text-dark fa fa-trash"></i></a></td>
                        </tr>
                    </table>
                </div>
                <div class="form-group">
                    <button class="btn btn-white" type="button" (click)="toggleItemBlock()"><i class="fa fa-plus"></i></button>
                    <div *ngIf="requisitionsWarning" class="invalid">Please add some requisitions</div>
                    <div *ngIf="vendorWarning" class="invalid">Please select vendor</div>
                </div>
            </form>
            <div class="form-group" *ngIf="showItemblock">
                <label class="radio-inline">
                    <input type="radio" class="mx-2" name="type" value="part" [(ngModel)]="requisitiontype">Part
                </label>
                <label class="radio-inline">
                    <input type="radio" class="mx-2" name="type" value="service" [(ngModel)]="requisitiontype">Service
                </label>
            </div>
            <div class="row">
                <div class="col-6" *ngIf="requisitiontype === 'part'">
                    <div class="form-group">
                        <label for="">Select Part</label>
                        <div class="input-group">
                            <select class="form-control" placleholder="Select Part" [(ngModel)]="requisitionItem.part_id">
                                <option [value]="undefined">Select Part</option>
                                <option *ngFor="let part of parts" [value]="part.part_id">
                                    {{part.name }}
                                </option>
                            </select>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-white" (click)="openPartFormModal()"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6" *ngIf="requisitiontype === 'service'">
                    <div class="form-group">
                        <label for="input-1">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="requisitionItem.service_name">
                    </div>
                </div>
                <div class="col-6" *ngIf="requisitiontype === 'part'">
                    <div class="form-group">
                        <label>Quantity</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="requisitionItem.quantity">
                        </div>
                    </div>
                </div>
                <div class="col-6" *ngIf="requisitiontype">
                    <div class="form-group">
                        <label>Purpose</label>
                        <select name="type" id="type" class="form-control" [(ngModel)]="requisitionItem.purpose">
                            <option [value]="undefined">Select Value</option>
                            <option value="Inventory Restock">Inventory Restock</option>
                            <option value="Work Order Request" (click)="toggleItemBlock()">Work Order Request</option>
                            
                        </select>
                    </div>
                </div>
                <div class="col-6" *ngIf="requisitiontype && requisitionItem.purpose == 'Work Order Request'">
                    <div class="form-group">
                        <label>Work Order</label>
                        <select class="form-control" placleholder="Select Work Order" [(ngModel)]="requisitionItem.work_order_id">
                            <option [value]="undefined">Select Work Order</option>
                            <option *ngFor="let workOrder of workOrders" [value]="workOrder.work_order_id">
                                {{workOrder.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12" *ngIf="requisitiontype">
                    <button class="btn btn-white pull-right" (click)="addToRequisitions()" >Add</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-white" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="requisition.requisition_id" type="button" class="btn btn-white pull-right" (click)="validateForm('add')">Save</button>
    <button [hidden]="!requisition.requisition_id" type="button" class="btn btn-white pull-right" (click)="validateForm('update')">Update</button>
</div>