import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-history-modal',
  templateUrl: './select-history-modal.component.html',
  styleUrls: ['./select-history-modal.component.scss']
})
export class SelectHistoryModalComponent implements OnInit {

  @Input() historyDates: any = [];
  selectedRecordId: any; 
  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
  }

}
