import { Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-viewer-children',
    templateUrl: './children.component.html',
    styleUrls: ['./children.component.scss']
})

export class ChildrenComponent implements OnInit{
    
    @Input() obj: any;
    constructor() {}
    
    ngOnInit(){

    }
}