import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-time-log-form',
  templateUrl: './add-time-log-form.component.html',
  styleUrls: ['./add-time-log-form.component.scss']
})
export class AddTimeLogFormComponent implements OnInit {
  inputForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.setFormValidation();

  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      hours: ['', [Validators.required, Validators.min(0), Validators.max(24)]],
      minutes: ['', [Validators.required, Validators.min(0), Validators.max(59)]],
      description: ['', [Validators.required]],

    });
  }

  validateForm() {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.activeModal.close(this.inputForm.value)
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

}
