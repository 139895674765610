<div class="row">
    <div class="col-lg-12" *ngFor="let item of highTemperaturePolicies; let i = index">
        <div class="card">
            <div class="card-header">High {{ sensor.used_for }} Policy</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label >Value</label> ({{ sensor.unit }}) *
                            <input type="number" [(ngModel)]="item.tempValue" class="form-control">
                        </div>
                    </div> 
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Users *</label>
                            <ng-multiselect-dropdown
                            [placeholder]="'Select User'"
                            [settings]="setMultiselectSetting('user_id','name')"
                            [data]="users"
                            [(ngModel)]="item.alertUsers">
                            </ng-multiselect-dropdown>  
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Send Alert As *</label>
                            <div class="input-group mb-3">
                                <select class="custom-select" [(ngModel)]="item.sendAlertAs">
                                    <option value="">Select Type</option>
                                    <option value="email">Email</option>
                                    <option value="sms">SMS</option>
                                    <option value="both">Both</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label >Alert Message</label>
                            <textarea [(ngModel)]="item.alertMessage" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="mr-2">Status </label>
                    <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="item.alertStatus" [ngModelOptions]="{standalone: true}"> <i class="fa fa-check" ></i></ui-switch>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <button type="button" class="btn-social btn-social-circle" (click)="copyHighTemperaturePolicy(item)"><i class="fa fa-copy"></i></button>
                            <button [hidden]="item._id" type="button" class="btn-social btn-social-circle w-auto px-3" (click)="addHighTemperaturePolicy(item)">Save</button>
                            <button [hidden]="!item._id" type="button" class="btn-social btn-social-circle w-auto px-3" (click)="updateHighTemperaturePolicy(item)">Update</button>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="sensor.type != 'rio'">
                        <a href="javascript:" class="btn-social btn-social-circle pull-right" (click)="addHighTemperaturePolicyItem()" [hidden]="(i+1) != highTemperaturePolicies.length"><i class="fa fa-plus"></i></a>
                        <a href="javascript:" class="btn-social btn-social-circle pull-right" (click)="deleteHighTemperaturePolicyItem(i, item._id)"><i class="icon-trash icons ml-2"></i></a>
                    </div>
                </div>           
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12" *ngFor="let item of lowTemperaturePolicies; let i = index">
        <div class="card">
            <div class="card-header">Low {{ sensor.used_for }} Policy</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label >Value</label> ({{ sensor.unit }}) *
                            <input type="number" [(ngModel)]="item.tempValue" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Users *</label>
                            <ng-multiselect-dropdown
                            [placeholder]="'Select User'"
                            [settings]="setMultiselectSetting('user_id','name')"
                            [data]="users"
                            [(ngModel)]="item.alertUsers">
                            </ng-multiselect-dropdown>  
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Send Alert As *</label>
                            <div class="input-group mb-3">
                                <select class="custom-select" [(ngModel)]="item.sendAlertAs">
                                    <option value="">Select Type</option>
                                    <option value="email">Email</option>
                                    <option value="sms">SMS</option>
                                    <option value="both">Both</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label >Alert Message</label>
                            <textarea [(ngModel)]="item.alertMessage" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                    <div class="form-group">
                        <label class="mr-2">Status </label>
                        <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="item.alertStatus"> <i class="fa fa-check" ></i></ui-switch>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <button type="button" class="btn-social btn-social-circle pull-left" (click)="copyLowTemperaturePolicy(item)"><i class="fa fa-copy"></i></button>
                                <button [hidden]="item._id" type="button" class="btn-social btn-social-circle w-auto px-3" (click)="addLowTemperaturePolicy(item)">Save</button>
                                <button [hidden]="!item._id" type="button" class="btn-social btn-social-circle w-auto px-3" (click)="updateLowTemperaturePolicy(item)">Update</button>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="sensor.type != 'rio'">
                            <a href="javascript:" class="btn-social btn-social-circle pull-right" (click)="addLowTemperaturePolicyItem()" [hidden]="(i+1) != lowTemperaturePolicies.length"><i class="fa fa-plus"></i></a>
                            <a href="javascript:" class="btn-social btn-social-circle pull-right" (click)="deleteLowTemperaturePolicyItem(i, item._id)"><i class="icon-trash icons ml-2"></i></a>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-lg-12" *ngIf="sensor.type == 'bluzone'">
        <div class="card">
            <div class="card-header">Temperature Rise Policy</div>
            <div class="card-body">
                <form [formGroup]="ambientForm">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Sensor</label>
                                <select class="form-control" id="default-select" formControlName="ambientSensor" [(ngModel)]="ambientTemperaturePolicy.ambient">
                                    <option value="">Select Ambient Sensor</option>
                                    <option *ngFor="let ambientSensor of ambientSensorList" [value]="ambientSensor.sensor_id" >{{ ambientSensor.name }}</option>
                                </select>
                                <div [hidden]="!errorAmbient('ambientSensor','required')" class="invalid">Ambient Sensor is required</div> 
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Alert Buffer (%)</label>
                                <input type="number" class="form-control" formControlName="offset_temp" [(ngModel)]="ambientTemperaturePolicy.offset_temp">
                                <div [hidden]="!errorAmbient('offset_temp','required')" class="invalid">Offset Temp is required</div> 
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Calculated Temperature Rise (({{ sensor.unit }}))</label>
                                <input type="number" class="form-control" [(ngModel)]="ambientTemperaturePolicy.learnedValue" [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>TEMPERATURE RISE ALERT VALUE (({{ sensor.unit }}))</label>
                                <input type="number" class="form-control" [(ngModel)]="ambientTemperaturePolicy.currentValue" [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="mr-2">Status</label>
                                <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="ambientTemperaturePolicy.alertStatus" [ngModelOptions]="{standalone: true}"><i class="fa fa-check" ></i></ui-switch>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="mr-2">Mode</label>
                                <ui-switch uncheckedLabel="Manual" checkedLabel="Auto" size="small" [(ngModel)]="ambientTemperaturePolicy.mode" [ngModelOptions]="{standalone: true}"><i class="fa fa-check" ></i></ui-switch>
                            </div>
                        </div>
                        <div class="col-sm-6" [hidden]="!ambientTemperaturePolicy.mode">
                            <div class="form-group">
                                <label>Learning Period</label>
                                <select class="form-control" id="default-select" [(ngModel)]="ambientTemperaturePolicy.trainingTime" [ngModelOptions]="{standalone: true}">
                                    <option value="1">Previous Day</option>
                                    <option value="7">Previous Week</option>
                                    <option value="30">Previous Month</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" class="btn-social btn-social-circle" (click)="copyAmbientTemperaturePolicy()"><i class="fa fa-copy"></i></button>
                        <button [hidden]="ambientTemperaturePolicy._id" type="button" class="btn-social btn-social-circle w-auto px-3" (click)="addAmbientTemperaturePolicy()">Save</button>
                        <button [hidden]="!ambientTemperaturePolicy._id" type="button" class="btn-social btn-social-circle w-auto px-3" (click)="updateAmbientTemperaturePolicy()">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>