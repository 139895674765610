import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-sensor-modal',
  templateUrl: './select-sensor-modal.component.html',
  styleUrls: ['./select-sensor-modal.component.scss']
})
export class SelectSensorModalComponent implements OnInit {

  @Input() sensors: any;
  selectedSensors: any = []; 
  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
  }
  
  setMultiselectSetting(idField, textField){
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}
