import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { ModalComponent } from '../../modal/modal.component';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-temperature-policy',
  templateUrl: './sensor-temperature-policy.component.html',
  styleUrls: ['./sensor-temperature-policy.component.scss']
})
export class SensorTemperaturePolicyComponent implements OnInit {
  @Input() sensor: any;
  @Input() users: any;

  ambientForm: FormGroup;
  ambientSubmitted = false;

  highTemperaturePolicies: any = [
    {
      tempValue: 0,
      alertStatus: false,
      alertUsers: [],
      sendAlertAs: '',
      alertMessage: ''
    }
  ];
  lowTemperaturePolicies: any = [
    {
      tempValue: 0,
      alertStatus: false,
      alertUsers: [],
      sendAlertAs: '',
      alertMessage: '',
      less: true
    }
  ];
  ambientTemperaturePolicy: any = {};
  ambientSensorList: any = [];
  ambientOrigionalCurrentValue: any;

  constructor(
    private modalService: NgbModal,
    private _sensorService: SensorService,
    private formBuilder: FormBuilder,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.setFormValidation();

    await this.setHighTemperaturePolicies();
    await this.setLowTemperaturePolicies();
    if (this.sensor.type == 'bluzone') {
      this.ambientSensorList = await this._sensorService.getAmbientSensorList();
      await this.setAmbientTemperaturePolicy();
    }
  }

  async setHighTemperaturePolicies() {
    let array = await this._sensorService.getTemperaturePolicy(this.sensor.sensor_id, 'high');
    if (array && array.length > 0) {
      this.highTemperaturePolicies = array;
    }
  }

  async setLowTemperaturePolicies() {
    let array = await this._sensorService.getTemperaturePolicy(this.sensor.sensor_id, 'low');
    if (array && array.length > 0) {
      this.lowTemperaturePolicies = array;
    }
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  setFormValidation() {
    if (this.sensor.type == 'bluzone') {
      this.ambientForm = this.formBuilder.group({
        ambientSensor: ['', [Validators.required]],
        offset_temp: ['', [Validators.required]]
      });
    }
  }

  errorAmbient(comtrolName, constraint) {
    let errors = this.ambientForm['controls'][comtrolName]['errors'];
    return this.ambientSubmitted && errors && errors[constraint]
  }

  async setAmbientTemperaturePolicy() {
    this.ambientTemperaturePolicy = await this._sensorService.getAmbientTemperaturePolicy(this.sensor.sensor_id) || {};

    this.ambientOrigionalCurrentValue = this.ambientTemperaturePolicy.currentValue;

    this.ambientTemperaturePolicy.ambient = this.ambientTemperaturePolicy.ambient ? this.ambientTemperaturePolicy.ambient : '';
    this.ambientTemperaturePolicy.offset_temp = this.ambientTemperaturePolicy.offset_temp ? this.ambientTemperaturePolicy.offset_temp : 10;
    this.ambientTemperaturePolicy.trainingTime = this.ambientTemperaturePolicy.trainingTime ? this.ambientTemperaturePolicy.trainingTime : "30";
    this.ambientTemperaturePolicy.alertStatus = this.ambientTemperaturePolicy.alertStatus ? this.ambientTemperaturePolicy.alertStatus : false;
    this.ambientTemperaturePolicy.mode = this.ambientTemperaturePolicy.mode ? this.ambientTemperaturePolicy.mode : false;
    this.ambientTemperaturePolicy.learnedValue = this.ambientTemperaturePolicy.differential_temp ? this.ambientTemperaturePolicy.differential_temp : null;
    this.ambientTemperaturePolicy.currentValue = this.ambientTemperaturePolicy.currentValue ? this.ambientTemperaturePolicy.currentValue ? this.getLearnedValue(this.ambientTemperaturePolicy.currentValue, this.ambientTemperaturePolicy.offset_temp) : this.ambientTemperaturePolicy.learnedValue : null;

  }

  async addHighTemperaturePolicy(highTemperaturePolicy) {
    highTemperaturePolicy.beaconId = this.sensor.sensor_id;
    let response = await this._sensorService.addTemperaturePolicy(highTemperaturePolicy);
    if (response) {
      await this.setHighTemperaturePolicies();
    }
  }

  async updateHighTemperaturePolicy(highTemperaturePolicy) {
    let response = await this._sensorService.updateTemperaturePolicy(highTemperaturePolicy);
    if (response) {
      await this.setHighTemperaturePolicies();
    }
  }

  async deleteHighTemperaturePolicy(id) {
    return await this._sensorService.deleteTemperaturePolicy(id);
  }

  async copyHighTemperaturePolicy(highTemperaturePolicy) {
    const modalRef = this.modalService.open(SelectSensorModalComponent, { backdrop: 'static' });
    let sensors = await this._sharedService.getSensors('?type='+this.sensor.type+'&used_for='+this.sensor.used_for);
    let index = sensors.findIndex(s => s.sensor_id == this.sensor.sensor_id);
    sensors.splice(index, 1);
    modalRef.componentInstance.sensors = sensors;

    modalRef.result.then(async (result) => {
      let copied_data = { ...highTemperaturePolicy };
      delete (copied_data._id);
      result.temperaturePolicy = copied_data;
      await this._sensorService.copyTemperaturePolicy(result);
    }, (reason) => {
      console.log(reason);
    });
  }


  addHighTemperaturePolicyItem() {
    this.highTemperaturePolicies.push(
      {
        tempValue: 0,
        alertStatus: false,
        alertUsers: [],
        sendAlertAs: '',
        alertMessage: ''
      }
    );
  }

  deleteHighTemperaturePolicyItem(index, id) {
    const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.heading = "Delete High Policy";
    modalRef.componentInstance.content = "Are you sure you want to delete this high policy?";

    modalRef.result.then(async (result) => {
      this.highTemperaturePolicies.splice(index, 1);
      if (id) {
        await this.deleteHighTemperaturePolicy(id);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addLowTemperaturePolicy(lowTemperaturePolicy) {
    lowTemperaturePolicy.beaconId = this.sensor.sensor_id;
    let response = await this._sensorService.addTemperaturePolicy(lowTemperaturePolicy);
    if (response) {
      await this.setLowTemperaturePolicies();
    }
  }

  async updateLowTemperaturePolicy(lowTemperaturePolicy) {
    let response = await this._sensorService.updateTemperaturePolicy(lowTemperaturePolicy);
    if (response) {
      await this.setLowTemperaturePolicies();
    }
  }

  async deleteLowTemperaturePolicy(id) {
    return await this._sensorService.deleteTemperaturePolicy(id);
  }

  async copyLowTemperaturePolicy(lowTemperaturePolicy) {
    const modalRef = this.modalService.open(SelectSensorModalComponent, { backdrop: 'static' });
    let sensors = await this._sharedService.getSensors('?type='+this.sensor.type+'&used_for='+this.sensor.used_for);
    let index = sensors.findIndex(s => s.sensor_id == this.sensor.sensor_id);
    sensors.splice(index, 1);
    modalRef.componentInstance.sensors = sensors;

    modalRef.result.then(async (result) => {
      let copied_data = { ...lowTemperaturePolicy };
      copied_data.less = true;
      delete (copied_data._id);
      result.temperaturePolicy = copied_data;
      await this._sensorService.copyTemperaturePolicy(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  addLowTemperaturePolicyItem() {
    this.lowTemperaturePolicies.push(
      {
        tempValue: 0,
        alertStatus: false,
        alertUsers: [],
        sendAlertAs: '',
        alertMessage: '',
        less: true
      }
    );
  }

  deleteLowTemperaturePolicyItem(index, id) {
    const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.heading = "Delete Low Policy";
    modalRef.componentInstance.content = "Are you sure you want to delete this low policy?";

    modalRef.result.then(async (result) => {
      this.lowTemperaturePolicies.splice(index, 1);
      if (id) {
        await this.deleteLowTemperaturePolicy(id);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addAmbientTemperaturePolicy() {
    this.ambientSubmitted = true;
    if (!this.ambientForm.invalid) {
      this.ambientTemperaturePolicy.beaconId = this.sensor.sensor_id;
      let response = await this._sensorService.addAmbientTemperaturePolicy(this.ambientTemperaturePolicy);
      if (response) {
        this.ambientOrigionalCurrentValue = response.currentValue;
        this.ambientTemperaturePolicy = response;
        this.ambientTemperaturePolicy.currentValue = this.getLearnedValue(response.currentValue, response.offset_temp)
        if (response.mode) {
          this.ambientTemperaturePolicy.learnedValue = response.differential_temp;
        }
      }
    }
  }

  async updateAmbientTemperaturePolicy() {
    this.ambientSubmitted = true;
    if (!this.ambientForm.invalid) {
      let response = await this._sensorService.updateAmbientTemperaturePolicy(this.ambientTemperaturePolicy);
      if (response) {
        this.ambientTemperaturePolicy = response;
        this.ambientTemperaturePolicy.currentValue = this.getLearnedValue(response.currentValue, response.offset_temp)
        if (response.mode) {
          this.ambientTemperaturePolicy.learnedValue = response.differential_temp;
        }
      }
    }
  }
  async copyAmbientTemperaturePolicy() {
    this.ambientSubmitted = true;
    if (!this.ambientForm.invalid) {
      const modalRef = this.modalService.open(SelectSensorModalComponent, { backdrop: 'static' });
      let sensors = await this._sharedService.getSensors('?type='+this.sensor.type);
      let index = sensors.findIndex(s => s.sensor_id == this.sensor.sensor_id);
      sensors.splice(index, 1);
      modalRef.componentInstance.sensors = sensors;

      modalRef.result.then(async (result) => {
        let copied_data = { ...this.ambientTemperaturePolicy };
        if (this.ambientOrigionalCurrentValue) {
          copied_data.currentValue = this.ambientOrigionalCurrentValue;
        }
        delete (copied_data._id);
        result.ambientTemperaturePolicy = copied_data;
        await this._sensorService.copyAmbientTemperaturePolicy(result);
      }, (reason) => {
        console.log(reason);
      });
    }
  }

  getLearnedValue(temp, offset) {
    if (temp)
      return temp + (temp * 0.01 * offset)
    else
      return 0;
  }

}
