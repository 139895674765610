<!-- Mapping modal -->
<div class="modal-header">
    <h5 class="modal-title">Gateways - {{ gatewaysData.type }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-4 mt-4" *ngFor="let item of gatewaysData.items">
            <div class="card">
                <div class="card-body p-2">
                    <div [hidden]="!item.name"><label>Name</label> : {{ item.name }}</div>
                    <div [hidden]="!item.macAddress"><label>Mac Address</label> : {{ item.macAddress }}
                        <i [hidden]="!item.latitude || !item.longitude" title="Maps" (click)="openGoogleMap(item.latitude, item.longitude)" class="zmdi zmdi-google-maps"></i>
                    </div>
                    
                    <div [hidden]="!item.imei"><label>Imei</label> : {{ item.imei }}</div>
                    <div [hidden]="!item.gateWayType"><label>GateWay Type</label> : {{ item.gateWayType }}</div>
                    <div [hidden]="!item.status"><label>Status</label> : {{ item. status}}</div>
                    <div [hidden]="!item.lastMessageSent"><label>Last Message Sent</label> : {{ item.lastMessageSent }}</div>
                    <div [hidden]="!item.ipAddress"><label>Ip Address</label> : {{ item.ipAddress }}</div>
                    <div [hidden]="!item.gwAddress"><label>Gw Address</label> : {{ item.gwAddress }}</div>
                    <div [hidden]="!item.network"><label>Network</label> : {{ item.network }}</div>
                    <div [hidden]="!item.heartBeat"><label>Heart Beat</label> : {{ item.heartBeat }}</div>
                    <div [hidden]="!item.BatteryLevel"><label>Battery Level</label> : {{ item.BatteryLevel }}</div>
                </div>
            </div>
        </div>
    </div>       
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Close Click')">Close</button>
</div>    

