import { Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-viewer-work-order',
    templateUrl: './work-order.component.html',
    styleUrls: ['./work-order.component.scss']
})

export class WorkOrderComponent implements OnInit{

    @Input() obj: any;
    constructor() {}
    
    ngOnInit(){
        
    }
}