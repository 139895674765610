import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class VendorService {
  constructor(private _httpClient: HttpClient) { }
  getVendorByVendorId(VendorId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get((<any>window).appBaseUrl + "/vendor/" + VendorId)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getVendor(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get((<any>window).appBaseUrl + "/vendor")
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getVendorByResource(type, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get((<any>window).appBaseUrl + "/vendor/" + type + "/" + id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  updateVendor(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put((<any>window).appBaseUrl + "/vendor", { ...data })
        .subscribe((response: any) => {
          resolve(response.response);
        }, reject);
    });
  }

  deleteVendor(Vendor_id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .delete((<any>window).appBaseUrl + "/vendor/" + Vendor_id)
        .subscribe((response: any) => {
          resolve(response.response);
        }, reject);
    });
  }
}
