import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';

import { ComponentLoaderComponent } from './component-loader.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        TagInputModule
    ], 
    declarations: [ 
        ComponentLoaderComponent
    ],
    exports: [
        ComponentLoaderComponent
    ]
})
export class ComponentLoaderModule { }
