<div class="modal-header">
    <h5 class="modal-title">Requisition</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span
            aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card p-2 border border-dark" id="print-section">
        <h1 class="text-dark text-center">{{project.name}}</h1>
        <h4 class="text-dark text-center">Purchase Requisition</h4>
        <div class="card-body px-4">
            <div class="row">
                <div class="col-6">
                    <b class="text-dark">Department:</b><span class="text-dark"> {{requisition.department}}</span>
                </div>
                <div class="col-6 float-right">
                    <b class="text-dark">NO:</b><span class="text-dark"> {{requisition.number}}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">To be deliver at:</b><span class="text-dark"> {{requisition.delivered_at}}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">Order Date:</b><span class="text-dark"> {{ helperService.formatDate(requisition.order_date) }}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">Required Date:</b><span class="text-dark"> {{ helperService.formatDate(requisition.required_date) }}</span>
                </div>

            </div>
            <h6 class="text-dark mt-2">Purchase the items listed below:</h6>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Part Id</th>
                        <th scope="col">Description</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Purpose</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let requisition of requisition.requisitions">
                        <td>
                            <span *ngIf="requisition.part_id">{{getPartId(requisition.part_id)}}</span>
                            <span *ngIf="!requisition.part_id">N/A</span>
                        </td>
                        <td *ngIf="requisition.part_id">{{getPartName(requisition.part_id)}}</td>
                        <td *ngIf="requisition.service_name">{{requisition.service_name}}</td>
                        <td>
                            <span *ngIf="requisition.part_id">{{requisition.quantity}}</span>
                            <span *ngIf="!requisition.part_id">N/A</span>
                        </td>
                        <td>{{requisition.purpose}} <span *ngIf="requisition.work_order_id"> - {{ getWorkOrderName(requisition.work_order_id) }}</span></td>
                    </tr>
                </tbody>
            </table>
            <h6 class="text-dark mt-2 mb-0">For Use of Purchase Department Only</h6>
            <div class="row">
                <div class="col-6">
                    <b class="text-dark">Purchase Order No:</b><span class="text-dark"> {{requisition.number}}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">Supplier:</b><span class="text-dark"> {{requisition.vendor_name}}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">Delivery Date:</b><span class="text-dark"> {{ helperService.formatDate(requisition.required_date) }}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">Requisition by:</b><span class="text-dark"> {{requisition.requisition_user}}</span>
                </div>
                <div class="col-6">
                    <b class="text-dark">Approved by:</b><span class="text-dark"> {{requisition.approved_user}}</span>
                    <h6 class="text-dark"></h6>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button class="btn btn-white" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button class="btn btn-white" (click)="print()">Print</button>

</div>