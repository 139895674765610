<div *ngIf="healthWidgetData">
    <div class="card card-trend-data">
        <div class="card-body">
            <div class="row pt-2 pb-2">
                <div class="col-sm-9">
                    <h4 class="page-title">Health Monitor <small>({{sensor.name}})</small></h4>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javaScript:;">RAMS</a>
                        </li>
                        <li class="breadcrumb-item"><a href="javaScript:;">Sensor</a>
                        </li>
                        <li class="breadcrumb-item"><a href="javaScript:;">Health Monitor</a>
                        </li>
                    </ol>
                </div>
                <div class="col-sm-3">
                    <div ngbDropdown class="btn-group float-sm-right">
                        <button type="button" class="btn btn-light" (click)="openHealthNoteModal()">Add Note</button>
                        <app-expand-screen></app-expand-screen>
                    </div>
                </div>
            </div>
            <div class="row pt-2 pb-2">
                <div class="col-4">
                    <div class="form-group">
                        <label>From</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="from" (ngModelChange)="changeFromDate($event)">
                            <div class="input-group-append">
                                <button class="btn btn-light fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row colored-graph pt-4">
                <div class="col-12 col-lg-12 col-xl-12">
                    <app-line-chart [series]="graph.series" [xaxis]="graph.xaxis" [grid]="graph.grid" [annotations]="graph.annotations"></app-line-chart>
                </div>
            </div>
        </div>
    </div>
</div>
