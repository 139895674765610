import { Component, OnInit, Input } from '@angular/core';

//Other Imports 
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-sensor-trend-data',
  templateUrl: './sensor-trend-data.component.html',
  styleUrls: ['./sensor-trend-data.component.scss']
})
export class SensorTrendDataComponent implements OnInit {
  @Input() sensor: any;
  @Input() componentLoaderData: any;
  pinData: any;
  d: any;
  doorChartTooltip = {
    enabled: true,
    theme: 'dark',
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
      return '<ul class="m-0 p-2">' +
        '<li><b>Duration </b> : ' + data.y + ' minutes</li>' +
        '<li><b>From</b> : ' + data.from + '</li>' +
        '<li><b>To</b> : ' + data.to + '</li>' +
        '</ul>';
    }
  }
  tabId: any = 'tab-trend-data';
  widgets: any = [];
  comparedSensors: any = [];
  maxDate = { month: new Date().getMonth() + 1, day: new Date().getDate() + 1, year: new Date().getFullYear() }
  loadingSimpleWidget = false;

  constructor(
    private _sensorService: SensorService,
    private _sharedService: SharedService,
    private _helperService: HelperService,
  ) { }

  async ngOnInit() {

    if (this.componentLoaderData) { //Over write the properties in case when this component will load on dashboard.
      this.sensor = this.componentLoaderData.sensor;
    }

    this.pinData = {
      component_name: 'SensorTrendDataComponent',
      sensor: this.sensor
    }

    let response = await this._sharedService.sensorWidgetsBySensorUniqueId(this.sensor.sensor_unique_id);
    for (let i = 0; i < response.length; i++) {
      let obj: any = {
        postData: {},
        highResolution: false,
        simple: {
          series: [],
          xaxis: {}
        },
        peakToPeak: {
          series: [],
          xaxis: {}
        },
        rms: {
          series: [],
          xaxis: {}
        },
        crestFector: {
          series: [],
          xaxis: {}
        },
        longestOpen: {
          series: [],
          xaxis: {
            labels: {
              show: false
            }
          },
          tooltip: this.doorChartTooltip
        },
        longestClose: {
          series: [],
          xaxis: {
            labels: {
              show: false
            }
          },
          tooltip: this.doorChartTooltip
        }
      }
      if (response[i].tab_id == 'tab-trend-data') {
        if (response[i].refresh_interval > 0) { //it will check if you set refresh interval while adding configuration
          obj.refresh_interval_id = setInterval(() => {
            if (obj.postData.sensor_unique_id) { // it will check if user fill the form and submit form atleast once
              this.getWidgetData({ ...obj, ...response[i] }, i);
            }
          }, response[i].refresh_interval * 1000);
        }
        this.widgets.push({ ...obj, ...response[i] });
      }
    }
    if (response.length > 0) {
      this.comparedSensors = await this._sensorService.getSensorsByGroup(this.sensor.group);
      let index = this.comparedSensors.findIndex(s => s.sensor_unique_id == this.sensor.sensor_unique_id);
      this.comparedSensors.splice(index, 1); // Remove current sensor from list
    }
  }

  ngOnDestroy() {

    for (let i = 0; i < this.widgets.length; i++) {
      if (this.widgets[i].refresh_interval_id) {
        clearInterval(this.widgets[i].refresh_interval_id);
      }
    }
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  async getWidgetData(obj, index) {
    this.widgets[index].postData.sensor_unique_id = obj.sensor_unique_id;
    this.widgets[index].postData.widget_id = obj.widget_id;
    this.widgets[index].postData.tab_id = obj.tab_id;
    this.widgets[index].compare = false;
    this.loadingSimpleWidget = true;

    let xaxis: any = {
      type: "datetime",
      title: {
        text: "Date / Time",
        offsetY: 15,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
        }
      },
      labels: {
        show: true,
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yyyy",
          day: 'dd MMM',
          hour: 'dd MMM hh:mm:tt',
          minute: 'hh:mm:ss:tt',
        }
      }
    }

    if (this.widgets[index].postData.metric && (this.widgets[index].postData.metric == 'HIGH_RESOLUTION_VELOCITY' || this.widgets[index].postData.metric == 'HIGH_RESOLUTION_ACCELERATION')) {
      let sensorWidgetData = await this._sensorService.getHighResolutionSensorWidgetData(this.widgets[index].postData);
      xaxis.categories = sensorWidgetData.x;

      this.widgets[index].peakToPeak.series = sensorWidgetData.peakToPeak;
      this.widgets[index].peakToPeak.xaxis = xaxis;

      this.widgets[index].rms.series = sensorWidgetData.rms;
      this.widgets[index].rms.xaxis = xaxis;

      this.widgets[index].crestFector.series = sensorWidgetData.crestFector;
      this.widgets[index].crestFector.xaxis = xaxis;
    } else {
      let sensorWidgetData: any;
      if (this.widgets[index].postData.selected_sensors && this.widgets[index].postData.selected_sensors.length > 0) {
        let findIndex = this.widgets[index].postData.selected_sensors.findIndex(s => s.sensor_unique_id == this.sensor.sensor_unique_id);
        if (findIndex == -1) {
          this.widgets[index].postData.selected_sensors.push({ sensor_unique_id: this.sensor.sensor_unique_id, name: this.sensor.name }); //Add current sensor in array to get its data also
        }
        sensorWidgetData = await this._sensorService.getBulkSensorWidgetData(this.widgets[index].postData);
        this.widgets[index].compare = true;
      } else {
        sensorWidgetData = await this._sensorService.getSensorWidgetData(this.widgets[index].postData);
      }

      if (this.sensor.type == 'imonnit' && this.widgets[index].widget_id == 'chart11') {
        let values = sensorWidgetData.y[0].data;
        let dates = sensorWidgetData.x;

        this.widgets[index].longestOpen.series = [{ data: this.calcualteLongeastOpenLogic(values, dates) }];

        this.widgets[index].longestClose.series = [{ data: this.calcualteLongeastCloseLogic(values, dates) }];
      }
      this.loadingSimpleWidget = false;
      xaxis.categories = sensorWidgetData.x;
      this.widgets[index].simple.series = sensorWidgetData.y;
      this.widgets[index].simple.xaxis = xaxis;
    }

  }

  calcualteLongeastOpenLogic(values, dates) {
    let arr = [];
    let calculataedTime = 0;
    let flag = false;
    let fromDate = this._helperService.formatDateWithTime(dates[0]);
    for (let i = 0; i < values.length - 1; i++) {
      if (values[i] == 1) {
        flag = true;
      }
      if(!flag){
        fromDate = this._helperService.formatDateWithTime(dates[i+1]);
      }
      if (values[i] == 1 && flag) {
        calculataedTime += this.getDateDifferenceInMinutes(dates[i], dates[i + 1]);
        if(values[i + 1] == 0){
          let obj = {
            y: calculataedTime.toFixed(2),
            from: fromDate,
            to: this._helperService.formatDateWithTime(dates[i + 1])
          }
          arr.push(obj);
          calculataedTime = 0;
          flag = false;
          fromDate = this._helperService.formatDateWithTime(dates[i + 1]);
        }
      }
    }
    return this.sortAndReducearray(arr);
  }

  calcualteLongeastCloseLogic(values, dates) {
    let arr = [];
    let calculataedTime = 0;
    let flag = false;
    let fromDate = this._helperService.formatDateWithTime(dates[0]);
    for (let i = 0; i < values.length - 1; i++) {
      if (values[i] == 0) {
        flag = true;
      }
      if(!flag){
        fromDate = this._helperService.formatDateWithTime(dates[i+1]);
      }
      if (values[i] == 0 && flag) {
        calculataedTime += this.getDateDifferenceInMinutes(dates[i], dates[i + 1]);
        if(values[i + 1] == 1){
          let obj = {
            y: calculataedTime.toFixed(2),
            from: fromDate,
            to: this._helperService.formatDateWithTime(dates[i + 1])
          }
          arr.push(obj);
          calculataedTime = 0;
          flag = false;
          fromDate = this._helperService.formatDateWithTime(dates[i + 1]);
        }
      }
      
    }
    return this.sortAndReducearray(arr);
  }

  sortAndReducearray(arr){
    arr.sort((a, b) => (parseFloat(a.y) < parseFloat(b.y)) ? 1 : -1); //Sorting High to Low
    arr.forEach((item, index) => {
      item.x = index + 1;
    });
    arr = arr.slice(0, 50);
    return arr;
  }

  getDateDifferenceInMinutes(date1, date2) {
    var startTime = new Date(date1);
    var endTime = new Date(date2);
    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var resultInMinutes = parseFloat((difference / 60000).toFixed(1));
    return resultInMinutes;
  }

  checkHighResolution(index) {
    if (this.widgets[index].postData.metric && (this.widgets[index].postData.metric == 'HIGH_RESOLUTION_VELOCITY' || this.widgets[index].postData.metric == 'HIGH_RESOLUTION_ACCELERATION')) {
      this.widgets[index].highResolution = true;
    } else {
      this.widgets[index].highResolution = false;
    }
  }

}
