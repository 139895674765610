import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PreventiveMaintenanceService {
  constructor(private _httpClient: HttpClient) { }
  getPmByPmId(pmId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get((<any>window).appBaseUrl + "/preventive-maintenance/" + pmId)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getPmByResource(type, id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get((<any>window).appBaseUrl + "/preventive-maintenance/" + type + "/" + id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getPm(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get((<any>window).appBaseUrl + "/preventive-maintenance")
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  addPm(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post((<any>window).appBaseUrl + "/preventive-maintenance", { ...data })
        .subscribe((response: any) => {
          resolve(response.response);
        }, reject);
    });
  }

  updatePm(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put((<any>window).appBaseUrl + "/preventive-maintenance", { ...data })
        .subscribe((response: any) => {
          resolve(response.response);
        }, reject);
    });
  }

  deletePm(pmId): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient
            .delete((<any>window).appBaseUrl + "/preventive-maintenance/" + pmId)
            .subscribe((response: any) => {
                resolve(response.response);
            }, reject);
    });
}
}
