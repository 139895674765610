<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">Coordinates</div>
            <div class="card-body">
                <div class="form-group">
                    <label>Latitude</label>
                    <input type="number" [(ngModel)]="coordinates.latitude" class="form-control">
                </div>
                <div class="form-group">
                    <label>Longitude</label>
                    <input type="number" [(ngModel)]="coordinates.longitude" class="form-control">
                </div>
                <div class="form-group">
                    <button [hidden]="coordinates._id" type="button" class="btn btn-light px-5" (click)="addCoordinates()">Save</button>
                    <button [hidden]="!coordinates._id" type="button" class="btn btn-light px-5" (click)="updateCoordinates()">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>