import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-preventive-maintenance",
  templateUrl: "./preventive-maintenance.component.html",
  styleUrls: ["./preventive-maintenance.component.scss"],
})
export class PreventiveMaintenanceComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
}
