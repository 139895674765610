<div class="modal-header">
    <h5 class="modal-title">Options</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-12 col-lg-12 col-xl-12">
			<div class="card">
				<div class="table-responsive">
					<table class="table align-items-center">
						<thead>
							<tr>
								<th>Label</th>
								<th>Key</th>
								<th>Action</th>
							</tr>
						</thead>

						<tbody>
							<tr *ngFor="let option of options; let i = index">
								<td>
									<input type="text" class="form-control" [(ngModel)]="option.label">
								</td>
								<td>
									<input type="text" class="form-control" [(ngModel)]="option.key">
								</td>
								<td>
									<button class="btn btn-sm btn-round btn-light pull-right" (click)="removeOptionRow(i)"><i class="fa fa-minus"></i></button>
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<button class="btn btn-sm btn-round btn-light pull-right" (click)="appendOptionRow()"><i class="fa fa-plus"></i></button>
								</td>
							</tr> 
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.close(options)">Save</button>
</div>