import { Component, OnInit, Input } from '@angular/core';

//Other Imports 
import { DataComparisonService } from './data-comparison.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-data-comparison',
  templateUrl: './data-comparison.component.html',
  styleUrls: ['./data-comparison.component.scss']
})
export class DataComparisonComponent implements OnInit {
  @Input() componentLoaderData: any;
  components: any = [];
  filter: any = {};
  pinData: any;
  widgetId: any = 'chart1';
  tabId: any = 'tab-trend-data';
  sensorWidget: any;
  postData: any = {};
  refresh_interval: any;
  d: any;
  highResolution = false;
  widget: any = {
    series: [],
    xaxis: {}
  };
  sensors: any;
  showMetric = false;
  d1: any;
  d2: any;
  maxDate = {month: new Date().getMonth() + 1, day: new Date().getDate() + 1, year:new Date().getFullYear()}

  constructor( 
    private _dataComparisonService: DataComparisonService,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {

    this.pinData = {
      component_name: 'DataComparisonComponent'
    } 
    this.components = await this._sharedService.getComponents();
  } 

  async getComponentSSensors(){
    if(this.filter.components && this.filter.components.length > 0){
      this.sensors = await this._sharedService.getComponentSSensors(this.filter.components);
    }
  }

  checkSensors(){
    if(this.postData.selected_sensors && this.postData.selected_sensors.length > 0){
      let selectedSensors = this.postData.selected_sensors;
      let newlist = this.sensors.filter(function(a) {
        return selectedSensors.filter(function(o) {
          return o.sensor_unique_id == a.sensor_unique_id && (a.type == 'bluzone' || a.type == 'treon')
        }).length > 0
      })
      if(newlist.length > 0){
        this.showMetric = true;
      } else {
        this.showMetric = false;
      }   
    } else {
      this.showMetric = false;
    }
  }

  ngOnDestroy() {
    if (this.refresh_interval) {
      clearInterval(this.refresh_interval);
    }
  }

  setMultiselectSetting(idField, textField){
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  async getWidgetData(){
    let allSensors = this.sensors;
    let selectedSensors = this.postData.selected_sensors;
    let newlist = allSensors.filter(function(a) {
      return selectedSensors.filter(function(o) {
        return o.sensor_unique_id == a.sensor_unique_id
      }).length > 0
    })
    this.postData.selected_sensors = newlist;
    this.postData.widget_id = this.widgetId;
    this.postData.tab_id = this.tabId;
    let result = await this._dataComparisonService.getComparisonWidgetData(this.postData);
    
    let xaxis: any = {
      type: "datetime",
      title: {
        text: "Date / Time",
        offsetY: 15,
        style: {
          fontSize:  '15px',
          fontWeight:  'bold',
        }
      },
      labels: {
        show: true,
        datetimeUTC: false,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yyyy",
          day: 'dd MMM',
          hour: 'dd MMM hh:mm:tt',
        }
      }     
    }
    xaxis.categories = result.x;
    this.widget.series = result.y;
    this.widget.xaxis = xaxis;
  }
  
}
  