import { Component, OnInit, Input } from '@angular/core';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
@Component({
  selector: 'app-sensor-alert',
  templateUrl: './sensor-alert.component.html',
  styleUrls: ['./sensor-alert.component.scss']
})
export class SensorAlertComponent implements OnInit {
  @Input() sensor: any; 
  temperatureAlerts: any = [];
  temperatureAlertsCleared: any = [];
  maintenanceAlerts: any = [];
  maintenanceAlertsCleared: any = [];
  vibrationAlerts: any = [];
  vibrationAlertsCleared: any = [];
  differentialAlerts: any = [];
  differentialAlertsCleared: any = [];
  users: any = [];

  vibrationAlertActions = ["Installed Spare", "Cleaned Cooling System", "Tightened Mounting Bolts", "Realigned", "Lubricated Bearing", "Other"]
  temperatureAlertActions = ["Change out asset", "Clean fan cover", "Clean cooling fins", "Infrared scan", "Call HVAC contractor", "Raise alert level", "Other"]
  temperatureAlertTypes = ['AMBIENT_TEMP_ALERT','LOW_TEMPERATURE_ALERT','HIGH_TEMPERATURE_ALERT'];
  vibrationAlertTypes = ['VIBRATION_ALERT','MONNIT_VIBRATION_ALERTS'];
  batteryAndSignaLostAlertTypes = ['BATTERY_ALERT','SIGNAL_LOST_ALERT'];
  differentialAlertTypes = ['DIFFERENTIAL_ALERT'];
  differentialAlertActions = ['Check Differential'];
  otherAlertTypes = ['MAINTENANCE','MAINTENANCE_ALERT','DIGITAL_ALERTS','MONNIT_DIGITAL_ALERTS'];

  constructor(
    private _sensorService: SensorService,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.users = await this._sensorService.getUsers();
    this.getMaintenanceAlers();
  }

  showTabAndData(arr){
    return arr.includes(this.sensor.type);
  }

  formatDate(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month,day,year].join('-');
  }

  formatDateWithTime(d) {

    let date = new Date(d)
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + " " + strTime;
}

  async updateTemperatureAlerts(item){
    let response  = await this._sharedService.updateAlerts(item);
    if(response){
      let index = this.temperatureAlerts.findIndex(a => a._id == item._id);
      this.temperatureAlerts.splice(index, 1);
    }
  }

  async updateDifferentialAlerts(item){
    let response  = await this._sharedService.updateAlerts(item);
    if(response){
      let index = this.differentialAlerts.findIndex(a => a._id == item._id);
      this.differentialAlerts.splice(index, 1);
    }
  }

  async updateMaintenanceAlerts(item){
    let response  = await this._sharedService.updateAlerts(item);
    if(response){
      let index = this.maintenanceAlerts.findIndex(a => a._id == item._id);
      this.maintenanceAlerts.splice(index, 1);
    }
  }

  async updateVibrationAlerts(item){
    let response  = await this._sharedService.updateAlerts(item);
    if(response){
      let index = this.vibrationAlerts.findIndex(a => a._id == item._id);
      this.vibrationAlerts.splice(index, 1);
    }
  }

  async getTemperatureAlers(){
    this.temperatureAlerts = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: false, types: this.temperatureAlertTypes});
  }

  async getTemperatureAlersCleared(){
    this.temperatureAlertsCleared = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: true, types: this.temperatureAlertTypes});
  }

  async getMaintenanceAlers(){
    this.maintenanceAlerts = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: false, types: this.otherAlertTypes});  
  }

  async getMaintenanceAlersCleared(){
    this.maintenanceAlertsCleared = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: true, types: this.otherAlertTypes});
  }

  async getDifferentialAlers(){
    this.differentialAlerts = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: false, types: this.differentialAlertTypes});  
  }

  async getDifferentialAlersCleared(){
    this.differentialAlertsCleared = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: true, types: this.differentialAlertTypes});  
  }

  async getVibrationAlers(){
    this.vibrationAlerts = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: false, types: this.vibrationAlertTypes});  
  }

  async getVibrationAlertsCleared(){
    this.vibrationAlertsCleared = await this._sensorService.getAlerts({sensorId: this.sensor.sensor_id, cleared: true, types: this.vibrationAlertTypes});
  }
  
}
  