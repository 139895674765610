import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../../services/toaster.service';
import { OptionFormComponent } from '../option-form/option-form.component';
import { WidgetService } from '../widget.service';
import { SharedService } from '../../../services/shared.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { SelectSensorModalComponent } from '../../sensor/select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-widget-form',
  templateUrl: './sensor-widget-form.component.html',
  styleUrls: ['./sensor-widget-form.component.scss']
})
export class SensorWidgetFormComponent implements OnInit {

  @Input() sensorUniqueId: any;
  @Input() widgetId: any;
  @Input() tabId: any;
  @Input() samples: any = [];

  inputForm: FormGroup;
  sensorForm: FormGroup;
  submitted = false;
  sensors: any = [];
  selectedSensors: any = [];
  selectedAttrIndex: any = 0;
  sensorTabs: any = [];
  sensorWidget: any;
  sensorMapping: any;
  sensor: any;

  attributesTypes = [
    {
      type: "textbox",
      value: "Text Box"
    },
    {
      type: "select",
      value: "Select"
    },
    {
      type: "checkbox",
      value: "Checkbox"
    },
    {
      type: "radio",
      value: "Radio"
    },
    {
      type: "date",
      value: "Date"
    }
  ]
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _widgetService: WidgetService,
    private _sharedService: SharedService,
    private _toasterService: NGXToastrService,
    private _localstorageService: LocalstorageService
  ) { }

  async ngOnInit() {
    this.initializeSensorWidget();
    this.setFormValidation();

    this.sensorWidget = await this._sharedService.getSensorWidget(this.sensorUniqueId, this.widgetId, this.tabId) || this.sensorWidget;
    this.sensor = await this._sharedService.getSensorBySensorUniqueId(this.sensorUniqueId);
    this.sensorMapping = await this._sharedService.getMappingByMappingId(this.sensor.mapping_id);
    this.sensorTabs = await this._sharedService.getSensorDetailTabs(this.sensor.tabs);
  }

  substitute(url, data) {
    let formattedData = url.replace(/{(\w+)}/g, function (_, key) {
      return data[key] ? data[key] : `{${key}}`;
    });
    return formattedData;
  }

  setSampleData(event) {
    let type = event.target.value;
    let sampleData: any = this.samples.find(s => s.type == type).sample_data;
    this.sensorWidget.attributes = sampleData.attributes;
    this.sensorWidget.axis = sampleData.axis;
    this.sensorWidget.refresh_interval = sampleData.refresh_interval;
    this.sensorWidget.configuration = sampleData.configuration;
    let project = this._localstorageService.getProjectData();
    this.sensorWidget.configuration.url = this.substitute(this.sensorWidget.configuration.url, project);
    this.sensorWidget.configuration.high_resolution_url = this.substitute(this.sensorWidget.configuration.high_resolution_url, project);
    this._toasterService.typeSuccess('Please fill remaining fields if any!');
  }

  initializeSensorWidget() {
    this.sensorWidget = {
      configuration: {
        url: '',
        method: '',
        is_url_complete: false
      },
      attributes: [],
      axis: {
        x: {
          key: '',
          label: ''
        },
        y: []
      },
      refresh_interval: 0,
      tab_id: '',

    }
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      url: ['', [Validators.required]],
      high_resolution_url: ['', []],
      method: ['', [Validators.required]],
      refresh_interval: ['', [Validators.required]],
      is_url_complete: ['', [Validators.required]],
      tab_id: ['', [Validators.required]]
    });
  }

  validateForm(obj) {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.activeModal.close(obj)
    }
  }

  validateFormWhileCopy() {
    if (!this.inputForm.invalid) {
      this.openSensorsModal();
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  appendYAxisRow() {
    this.sensorWidget.axis.y.push(
      {
        key: "",
        label: ""
      }
    )
  }

  removeYAxisRow(index) {
    this.sensorWidget.axis.y.splice(index, 1);
  }

  appendAttributesRow() {
    this.sensorWidget.attributes.push(
      {
        type: "",
        key: "",
        label: ""
      }
    )
  }

  removeAttributesRow(index) {
    this.sensorWidget.attributes.splice(index, 1);
  }

  showOptionModal(type) {
    return type == 'select' || type == 'checkbox' || type == 'radio';
  }

  openOptionModal(selectedAttrIndex) {
    const modalRef = this.modalService.open(OptionFormComponent, { size: 'xl', scrollable: true, backdrop: 'static' });
    let options = [];
    if (this.sensorWidget.attributes[selectedAttrIndex].options) {
      options = this.sensorWidget.attributes[selectedAttrIndex].options;
    }

    modalRef.componentInstance.options = options;
    console.log(options)
    modalRef.result.then(result => {
      this.sensorWidget.attributes[selectedAttrIndex].options = result;
    }, (reason) => {
      console.log(reason)
    });
  }

  async openSensorsModal() {
    const modalRef = this.modalService.open(SelectSensorModalComponent, { backdrop: 'static' });
    let sensors = await this._sharedService.getSensors('?type='+this.sensor.type+'&used_for='+this.sensor.used_for);
    let index = sensors.findIndex(s => s.sensor_unique_id == this.sensorUniqueId);
    sensors.splice(index, 1);
    modalRef.componentInstance.sensors = sensors;

    modalRef.result.then(async (result) => {
      let copied_data = { ...this.sensorWidget };
      copied_data.widget_id = this.widgetId;
      delete (copied_data._id);
      result.sensorWidget = copied_data;
      let selectedSensors = [...result.selectedSensors];
      result.selectedSensors = sensors.filter(s => selectedSensors.find(sf => sf.sensor_id == s.sensor_id)).map(ss => ss.sensor_unique_id);
      await this._widgetService.copySensorWidget(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  setSensorFormValidation() {
    this.sensorForm = this.formBuilder.group({
      sensors: ['', [Validators.required]]
    });
  }

}
