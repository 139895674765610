<div class="modal-header">
    <h5 class="modal-title">{{workOrder.name}}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
   <app-work-order-details [workOrder]="workOrder"></app-work-order-details>
</div>
<div class="modal-footer" *ngIf="workOrder.completed">
    <button type="button" class="btn btn-success border-unset" (click)="activeModal.dismiss('Exit click')">Exit</button>
</div>