import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WorkOrderService } from "../../work-order/work-order.service";
import { SharedService } from '../../../services/shared.service';

@Component({
    selector: "app-resource-hierarchy",
    templateUrl: "./resource-hierarchy.component.html",
    styleUrls: ["./resource-hierarchy.component.scss"],
})

export class ResourceHierarchyComponent implements OnInit {
    @Input() assets: any = [];
    @Input() selected: any = { assets: [], components: []};
    @Input() type: any;
    assetComponents: any = {};
    components: any = [];
    projectUniqueId: any;

    constructor(
        public _workOrderService: WorkOrderService,
        public _sharedService: SharedService,
        public activeModal: NgbActiveModal,
        private _activatedroute: ActivatedRoute
    ) { }

    async ngOnInit() {
        this.projectUniqueId = this._activatedroute.snapshot.paramMap.get("projectUniqueId");
        if (this.selected.components.length > 0) {
            for (let i = 0; i < this.selected.components.length; i++) {
                await this.getAssetsComponents(this.selected.components[i].asset_id);
            }
        }
    } 

    async getAssetsComponents(assetId) {
        if(this.projectUniqueId){
            this.assetComponents[assetId] = await this._sharedService.getAssetsComponentsByProjectUniqueId([{ asset_id: assetId }], this.projectUniqueId);
        } else {
            this.assetComponents[assetId] = await this._sharedService.getAssetsComponents([{ asset_id: assetId }]);
        }
    }

    selectAsset(asset) {
        if (this.type == 'workorder') this.selected.assets = [];
        this.selected.assets.push({ name: asset.name, asset_id: asset.asset_id });
    }

    unselectAsset(asset) {
        let index = this.selected.assets.findIndex(da => da.asset_id == asset.asset_id);
        this.selected.assets.splice(index, 1);
    }

    selectComponent(component) {
        if (this.type == 'workorder') this.selected.components = [];
        this.selected.components.push({ name: component.name, component_id: component.component_id, asset_id: component.asset_id });
    }

    unselectComponent(component) {
        let index = this.selected.components.findIndex(ca => ca.component_id == component.component_id);
        this.selected.components.splice(index, 1);
    }

    selectedAsset(assetId) {
        return this.selected.assets.find(da => da.asset_id == assetId);
    }

    selectedComponent(componentId) {
        return this.selected.components.find(ca => ca.component_id == componentId);
    }
    selectAssetForComponents(assetId) {
        return this.selected.components.find(ca => ca.asset_id == assetId);
    }

    nextStep() {
        this.activeModal.close(this.selected)
    }
}

