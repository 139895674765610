import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class PinService
{
    pinData: any;

    /* Constructo */
    constructor(
        private _httpClient: HttpClient
    ) {
    }

    pinToDashboard(dashboard_id, data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/pin-to-dashboard', { data: data, dashboard_id: dashboard_id  })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    unPinFromDashboard(dashboard_id, data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/unpin-from-dashboard', { data: data, dashboard_id: dashboard_id  })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

}
