import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RequisitionService {
    constructor(
        private _httpClient: HttpClient
    ) { }

    updateRequisition(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/requisition', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getRequisitions(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/requisition")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getLatestRequisition(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/latest-requisition")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    deleteRequisition(requisition_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/requisition/'+requisition_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }
}
