<!-- Company modal -->
<div class="modal-header">
    <h5 class="modal-title">Company</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross Click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Name *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [(ngModel)]="project.name">
                        <div [hidden]="!error('name','required')" class="invalid">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Company ID *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Company ID" formControlName="project_id" [(ngModel)]="project.project_id">
                        <div [hidden]="!error('project_id','required')" class="invalid">Company ID is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Network Id</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Network ID" formControlName="network_id" [(ngModel)]="project.network_id">
                        <div [hidden]="!error('network_id','required')" class="invalid">Network ID required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Storage Limit(MB) *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Storage Limit in MBs" formControlName="storage_limit" [(ngModel)]="project.storage_limit">
                        <div [hidden]="!error('storage_limit','required')" class="invalid">Storage Limit required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Zip Code *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Zip Code" formControlName="zip_code" [(ngModel)]="project.zip_code">
                        <div [hidden]="!error('zip_code','required')" class="invalid">Zip Code is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Support Email *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Support Email" formControlName="support_email" [(ngModel)]="project.support_email">
                        <div [hidden]="!error('support_email','required')" class="invalid">Enter Support Email is required</div>
                    </div>
                </div>
                <div class="form-group" [hidden]="project.project_unique_id">
                    <label>Integrations *</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Select Integrations'"
                    [settings]="setMultiselectSetting('integration_id','name')" 
                    [data]="integrations"
                    [(ngModel)]="setting.integrations"
                    formControlName="integrations">
                    </ng-multiselect-dropdown> 
                    <div [hidden]="!error('integrations','required')" class="invalid">Integrations are required</div> 
                </div>
                <div class="form-group" [hidden]="project.project_unique_id">
                    <label>Mappings *</label>
                    <ng-multiselect-dropdown
                    [placeholder]="'Select Mappings'" 
                    [settings]="setMultiselectSetting('mapping_id','name')"
                    [data]="mappings"
                    [(ngModel)]="setting.mappings"
                    formControlName="mappings">
                    </ng-multiselect-dropdown> 
                    <div [hidden]="!error('mappings','required')" class="invalid">Mappings are required</div> 
                </div>
                <div class="form-group">
                    <label>Short Description</label>
                    <div class="input-group">
                        <textarea rows="4" class="form-control" id="basic-textarea" placeholder="Enter Description" formControlName="description" [(ngModel)]="project.description"></textarea>
                        <div [hidden]="!error('description','required')" class="invalid">Description is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Upload Image</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event)" type="file" name="file" class="form-control" #fileInput accept="image/*" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <img src="{{ image }}" class="image" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="!project.project_unique_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', project: project, projectUniqueId:project.project_unique_id})">Update</button>
    <button [hidden]="project.project_unique_id" type="button" class="btn btn-white pull-right"  (click)="validateForm({action: 'add', project: project, setting: setting})">Save</button>
</div>    


