<div class="row mb-2 mx-0">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Purchase Requisitions</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<button type="button" class="btn btn-white" (click)="openRequisitionFormModal(requisition)">
				<i class="fa fa-plus"></i>
			</button>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-lg-12">
	  <div class="card">
			<div class="card-body">
			  <ngx-datatable [scrollbarH]="true" #mydatatable class="bootstrap" [rows]="requisitions" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [reorderable]="true">
				  <ngx-datatable-column name="Requisition No" prop='number'></ngx-datatable-column>
				 <ngx-datatable-column class="pull-right" name="Order Date" prop="order_date">
					  <ng-template ngx-datatable-cell-template let-value="value">
						 {{ helperService.formatDate(value) }}
					  </ng-template>
				  </ngx-datatable-column>
				  <ngx-datatable-column name="Department" prop='department'></ngx-datatable-column>
				  <ngx-datatable-column name="Vendor" prop='vendor_name'></ngx-datatable-column>
				  <ngx-datatable-column class="pull-right" name="Actions" prop="requisition_id">
					  <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
						<a href="javascript:;" class="text-dark mr-2" (click)="approvedBy(row)"><i class="fa fa-check" [ngClass]="{'text-success': row.approved_by}"></i></a>
						<a href="javascript:;" class="text-dark mr-2" (click)=" openRequisitionPrintModal(row)"><i class="fa fa-print"></i></a>
						<a href="javascript:;" class="text-dark mr-2" (click)="openRequisitionFormModal(row)"><i class="fa fa-pencil"></i></a>
						<a href="javascript:;" class="text-dark" (click)="openConfirmationModal(value,rowIndex)"><i class="fa fa-trash"></i></a>
					  </ng-template>
				  </ngx-datatable-column>
			  </ngx-datatable>
		  </div>
	  </div>
	</div>
</div>