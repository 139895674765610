<apx-chart
    [series]="series"
    [chart]="options.chart"
    [xaxis]="xaxis"
    [yaxis]="yaxis"
    [title]="options.title"
    [colors]="options.colors"
    [stroke]="options.stroke"
    [tooltip]="tooltip"
    [fill]="options.fill"
    [grid]="grid"
    [legend]="options.legend"
    [annotations]="annotations"
  ></apx-chart>