import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { HelperService } from '../../../services/helper.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddCommentFormComponent } from '../../shared/add-comment-form/add-comment-form.component';
import { NGXToastrService } from '../../../services/toaster.service';

@Component({
    selector: 'app-viewer-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})

export class TimeLineComponent implements OnInit {
    @Input() obj: any;
    events: any = [];
    users: any = [];
    event: any = {};
    filter: any = {
        text: '',
        file_type: '',
        user_id: ''
    };

    constructor(
        private _sharedService: SharedService,
        public helperService: HelperService,
        private _localstorageService: LocalstorageService,
        private _toasterService: NGXToastrService,
        public modalService: NgbModal
    ) { }

    async ngOnInit() {
        this.initializeEvent();
        this.filterEvents();
        this.users = await this._sharedService.getUsers();
    }

    initializeEvent() {
        this.event = {
            comment: '',
            attachment: '',
            asset_id: this.obj.type == 'asset' ? this.obj.id : '',
            component_id: this.obj.type == 'component' ? this.obj.id : '',
            user_id: this._localstorageService.getUserData().user_id,
            tags: []
        };
    }

    async openAddCommentFormModal() {
        const modalRef = this.modalService.open(AddCommentFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modalRef.componentInstance.obj = this.obj;
        modalRef.result.then(async (result) => {
            this.event.comment = result.text;
            this.event.attachment = result.attachment;
            this.event.tags = result.tags;
            this.event = await this._sharedService.addEvent(this.event);
            this.ngOnInit();
        }, (reason) => {
            console.log(reason);
        });
    }

    async filterEvents() {
        this.events = await this._sharedService.getEvents(this.obj.type, this.obj.id, this.filter);
        this.events.forEach(async item => {
            if (this.helperService.imageFile(item.attachment)){
                item.attachment_path = await this.helperService.setImage(item.attachment, this.obj.type, this.obj.id);
            }  
            else{
                item.attachment_path = await this.helperService.setFile(item.attachment, this.obj.type, this.obj.id);
                item.image_path = item.image ? await this.helperService.setImage(item.image, this.obj.type, this.obj.id) : '';
                item.video_path = item.video ? await this.helperService.setFile(item.video, this.obj.type, this.obj.id) : ''
                item.audio_path = item.audio ? await this.helperService.setFile(item.audio, this.obj.type, this.obj.id) : ''
            }
        });
    }

    creatReport(){
        this._sharedService.createReport(this.obj.type, this.obj.id, this.filter)
        this._toasterService.typeSuccess('Report is created successfully');
    }
}