import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProjectService
{
    constructor(
        private _httpClient: HttpClient
    ) { }

    getProjectByProjectUniqueId(project_unique_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/project/'+project_unique_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addProject(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/project', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    addProjectSetting(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/project-setting', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateProject(project_unique_id, data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/project/'+project_unique_id, { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deleteProject(project_unique_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/project/'+project_unique_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

}
