import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';
import { FileUploadModule } from 'ng2-file-upload';

import { PlantRoutingModule } from "./plant-routing.module";
import { PlantComponent } from './plant.component';
import { PlantFormComponent } from './plant-form/plant-form.component';
import { PlantListComponent } from './plant-list/plant-list.component';
import { PlantService } from './plant.service';

import { LocationModule } from '../location/location.module';
import { PinModule } from '../pin/pin.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';


@NgModule({
    imports: [
        CommonModule,
        PlantRoutingModule,
        NgbModule, 
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        LocationModule,
        PinModule,
        ExpandScreenModule,
        FileUploadModule
    ], 
    declarations: [ 
        PlantComponent,
        PlantFormComponent,
        PlantListComponent
    ],
    providers: [
        PlantService
    ],
    exports: [
        PlantComponent
    ]
})
export class PlantModule { }
