<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&larr;</span></button>
    <h5 class="modal-title">Sensor Setting ({{ sensor.name }})</h5>
</div>
<div class="modal-body">
	<div class="row" id="sensor-setting">
		<div class="col-lg-12">
			<div class="card">
				<div class="card-body">
					<div class="row" >
						<div class="col-md-3">
							<div class="tabs-vertical tabs-vertical-primary">
								<ul class="nav nav-tabs flex-column top-icon">
									<li class="nav-item" *ngFor="let tab of sensorTabs">
										<a class="nav-link" data-toggle="tab" (click)="makeActive(tab.tab_id)" [ngClass]="{'active': tab.tab_id == activeTab}" href="#{{ tab.tab_id }}">
											<span>{{ tab.tab_text }}</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-md-9">
							<!-- Tab panes -->
							<div class="tab-content">
								<div id="temperature-alerts" *ngIf="active('temperature-alerts')" class="container tab-pane" [ngClass]="{'active': active('temperature-alerts')}">
									<app-sensor-temperature-policy [sensor]="sensor" [users]="users"></app-sensor-temperature-policy>
								</div>
								<div id="rpm-utilization" *ngIf="active('rpm-utilization')" class="container tab-pane" [ngClass]="{'active': active('rpm-utilization')}">
									<app-sensor-rpm-utilization [sensorId]="sensor.sensor_id"></app-sensor-rpm-utilization>
								</div>
								<div id="coordinates" *ngIf="active('coordinates')" class="container tab-pane" [ngClass]="{'active': active('coordinates')}">
									<app-sensor-coordinates [sensorId]="sensor.sensor_id"></app-sensor-coordinates>
								</div>
								<div id="vibration-alerts" *ngIf="active('vibration-alerts')" class="container tab-pane" [ngClass]="{'active': active('vibration-alerts')}">
									<app-sensor-vibration-policy [sensor]="sensor"></app-sensor-vibration-policy>
								</div>
								<div id="maintenance-reminders" *ngIf="active('maintenance-reminders')" class="container tab-pane" [ngClass]="{'active': active('maintenance-reminders')}">
									<app-sensor-maintenance-policy [sensorId]="sensor.sensor_id"></app-sensor-maintenance-policy>
								</div>
								<div id="other-alerts" *ngIf="active('other-alerts')" class="container tab-pane" [ngClass]="{'active': active('other-alerts')}">
									<app-sensor-other-policy [sensorId]="sensor.sensor_id"></app-sensor-other-policy>
								</div>
								<div id="sensor-mount-position" *ngIf="active('sensor-mount-position')" class="container tab-pane" [ngClass]="{'active': active('sensor-mount-position')}">
									<app-sensor-mount-position [sensorId]="sensor.sensor_id"></app-sensor-mount-position>
								</div>
								<div id="differential-alerts" *ngIf="active('differential-alerts')" class="container tab-pane" [ngClass]="{'active': active('differential-alerts')}">
									<app-sensor-differential-policy [sensor]="sensor" [users]="users"></app-sensor-differential-policy>
								</div>
								<div id="bearing-number" *ngIf="active('bearing-number')" class="container tab-pane" [ngClass]="{'active': active('bearing-number')}">
									<app-sensor-bearing-number [sensorId]="sensor.sensor_id"></app-sensor-bearing-number>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12" *ngIf="sensorOrigionalImageSrc">
			<img src="{{ sensorOrigionalImageSrc }}" width="100%" class="img-fluid" />
		</div>
	</div>
</div>
<!--End Row-->
