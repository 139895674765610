import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';
import { FileUploadModule } from 'ng2-file-upload';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DatepickerService } from '../../services/datapicker.service';

import { DataComparisonRoutingModule } from "./data-comparison-routing.module";
import { DataComparisonComponent } from './data-comparison.component';
import { DataComparisonService } from './data-comparison.service';

import { PinModule } from '../pin/pin.module';
import { ChartModule } from '../chart/chart.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';

@NgModule({
    imports: [
        CommonModule,
        DataComparisonRoutingModule,
        NgbModule,
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule,
        PinModule,
        ExpandScreenModule,
        FileUploadModule,
        ChartModule
    ], 
    declarations: [ 
        DataComparisonComponent
    ],
    providers: [
        DataComparisonService,
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, {provide: NgbDateParserFormatter, useClass: DatepickerService }
    ],
    exports: [
        DataComparisonComponent
    ]

    
})
export class DataComparisonModule { }
