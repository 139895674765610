import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';

import { PinComponent } from './pin.component';
import { PinService } from './pin.service';

@NgModule({
    imports: [
        CommonModule,
        NgbModule, 
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule
    ], 
    declarations: [ 
        PinComponent
    ],
    providers: [
        PinService
    ],
    exports: [
        PinComponent
    ]
})
export class PinModule { }
