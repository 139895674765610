import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SidebarService } from '../../shared/sidebar/sidebar.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from '../../../services/helper.service';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit {

    constructor(
        public sidebarservice: SidebarService,
        private router: Router,
        private _localstorageService: LocalstorageService,
        private _helperService: HelperService
    ) { }
        
    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    }
    
    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }

    ngOnInit() {
        let theme = this._localstorageService.getTheme();
        if(theme){
            $('body').attr('class', theme.themeClass);
        }
        
        let localSetting = this._localstorageService.getSetting();
        this._helperService.updateFavIcon(localSetting.favIcon);
        this._helperService.updateTitle(localSetting.title);

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

    }

}