<!-- vendor modal -->
<div class="modal-header">
    <h5 class="modal-title">Vendor</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span
            aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label for="input-1">Name *</label>
                    <input type="text" class="form-control" formControlName="name" [(ngModel)]="vendor.name">
                    <div [hidden]="!error('name','required')" class="invalid">Name is required</div>
                </div>
                <div class="form-group">
                    <label for="input-1">Email *</label>
                    <input type="email" class="form-control" formControlName="email" [(ngModel)]="vendor.email">
                    <div [hidden]="!error('email','required')" class="invalid">Email is required</div>
                </div>
                <div class="form-group">
                    <label for="input-1">Address *</label>
                    <input type="text" class="form-control" formControlName="address" [(ngModel)]="vendor.address">
                    <div [hidden]="!error('address','required')" class="invalid">Address is required</div>
                </div>
                <div class="form-group">
                    <label>Contact No *</label>
                    <div class="input-group">
                        <div class="input-group-prepend"> <span class="input-group-text"><i
                                    class="fa fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder="Enter Phone" formControlName="contact_no" [(ngModel)]="vendor.contact_no">
                        <div [hidden]="!error('contact_no','required')" class="invalid">Phone is required</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="!vendor.vendor_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', vendor: vendor, vendorId:vendor.vendor_id})">Update</button>
    <button [hidden]="vendor.vendor_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action: 'add', vendor: vendor})">Save</button>
</div>
<!-- end modal -->