import { Component, OnInit, Input, ChangeDetectionStrategy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';

import { WorkOrderFormComponent } from "../work-order-form/work-order-form.component";
import { WorkOrderService } from "../work-order.service";
import { WorkOrderDetailFormComponent } from "../work-order-detail-form/work-order-detail-form.component";
import { ModalComponent } from "../../modal/modal.component";
import { SharedService } from '../../../services/shared.service';
import { AssetService } from '../../asset/asset.service';
import { SendMailComponent } from "../../shared/send-mail/send-mail.component";
import { HelperService } from "../../../services/helper.service";
import { ResourceHierarchyComponent } from "../../shared/resource-hierarchy/resource-hierarchy.component";
import { AssignToComponent } from "../../shared/assign-to/assign-to.component";

@Component({
    selector: "app-work-order-list",
    templateUrl: "./work-order-list.component.html",
    styleUrls: ["./work-order-list.component.scss"]
})

export class WorkOrderListComponent implements OnInit {
    @Input() obj: any;
    workOrders: any = [];
    workOrder: any = {};
    @Input() completed: any = false;
    progress: any;
    timeSpent = '0';
    downTime = '0';
    activeBtn = 'list';
    filter: any;
    refresh = new Subject<void>();
    activeDayIsOpen: boolean = false;

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-pencil mr-2"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fa fa-trash"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Deleted', event);
            },
        },
    ];

    events: CalendarEvent[] = [];

    constructor(
        public modalService: NgbModal,
        public _workOrderService: WorkOrderService,
        private _sharedService: SharedService,
        public _assetService: AssetService,
        public helperService: HelperService

    ) { }

    async ngOnInit() {
        this.setFilter();
        this.getWorkOrders();
    }

    async setFilter(party = '', status = 'open-in-progress') {
        this.workOrders = [];
        this.filter = {
            party: party,
            status: status,
            resource: this.obj ? this.obj.type : '',
            id: this.obj ? this.obj.id : ''
        }
    }

    async getWorkOrders() {
        this.workOrders = await this._sharedService.getWorkOrders({ ...this.filter });
        if (this.activeBtn == 'calendar') {
            this.formatEvents();
        } else if (this.activeBtn == 'user') {
            this.formatWorkOrderUsers();
        }
    }

    async intializeWorkOrder() {
        this.workOrder = {
            name: '',
            instructions: [],
            status: '',
            priority_id: '',
            due: {
                date: '',
                time: '',
            },
            type: '',
            asset_id: '',
            component_id: '',
            completion_notes: '',
            users: [],
            team_id: '',
            time_logs: [],
            down_times: [],
            comments: [],
            parts: [],
            active: true
        }
    }


    async openResourceHierarchyModal(action, workOrder: any = {}) {
        if(action == 'add') this.intializeWorkOrder();
        const modalRef = this.modalService.open(ResourceHierarchyComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
        modalRef.componentInstance.assets = await this._sharedService.getAssets();
        modalRef.componentInstance.type = 'workorder';
        modalRef.result.then(async (result) => {
            console.log(result);
            if (action == 'add') {
                this.workOrder.asset_id = result.assets[0] ? result.assets[0].asset_id : '';
                this.workOrder.component_id = result.components[0] ? result.components[0].component_id : '';
                this.openWorkOrderFormModal('add_new_workOrder');
            } else {
                let data = {
                    work_order_id: workOrder.work_order_id,
                    asset_id: result.assets[0] ? result.assets[0].asset_id : '',
                    component_id: result.components[0] ? result.components[0].component_id : ''
                }
                this.workOrder = await this._workOrderService.updateWorkOrder(data);
                await this.getWorkOrders();
            }
        }, (reason) => {
            console.log(reason);
        });
    }

    async autoAssignResource() {
        this.intializeWorkOrder();
        if(this.obj.type == 'asset') this.workOrder.asset_id = this.obj.resource.asset_id;
        if(this.obj.type == 'component') this.workOrder.component_id = this.obj.resource.component_id;
        this.openWorkOrderFormModal('add_new_workOrder');
    }

    async openWorkOrderFormModal(workOrderId = 'add_new_workOrder') {
        if (workOrderId != 'add_new_workOrder') {
            this.workOrder = await this._workOrderService.getWorkOrderById(workOrderId);
            this.workOrder.due.date = new Date(this.workOrder.due.date);
        }
        const modalRef = this.modalService.open(WorkOrderFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
        modalRef.componentInstance.workOrder = this.workOrder;
        modalRef.componentInstance.priorities = await this._sharedService.getPriority();
        modalRef.componentInstance.users = await this._sharedService.getUsers();
        modalRef.componentInstance.teams = await this._sharedService.getTeams();
        modalRef.result.then(async (result) => {
            result.data.due.date = new Date(result.data.due.date);
            if (result.action == 'add') {
                result.data.asset_id = this.workOrder.asset_id;
                result.data.component_id = this.workOrder.component_id;
                this.workOrder = await this._sharedService.addWorkOrder(result.data);
            } else if (result.action == 'update') {
                this.workOrder = await this._workOrderService.updateWorkOrder(result.data);
                if (this.workOrder) {
                    this.activeBtn == 'calendar' && (this.viewDate = new Date(this.workOrder.due.date));
                }
            }
            await this.getWorkOrders();
        }, (reason) => {
            console.log(reason);
        });
    }

    async openConfirmationModal(workOrderId) {
        const modalRef = this.modalService.open(ModalComponent, {
            scrollable: true,
            backdrop: "static",
        });
        modalRef.componentInstance.heading = "Delete Work Order";
        modalRef.componentInstance.content = "Are you sure you want to delete this work order?";
        modalRef.result.then(async (result) => {
            this.workOrder = await this._workOrderService.deleteWorkOrder(workOrderId);
            if (this.workOrder) {
                let index = this.workOrders.findIndex(w => w.workOrder_id == this.workOrder.workOrder_id);
                this.workOrders.splice(index, 1);
                this.activeBtn == 'calendar' && (this.viewDate = new Date(this.workOrder.due.date));
            }
        }, (reason) => {
            console.log(reason);
        });
    }

    openWorkOrderDetailFormModal(workOrder) {
        const modalRef = this.modalService.open(WorkOrderDetailFormComponent, { scrollable: true, backdrop: 'static', size: 'xl' })
        modalRef.componentInstance.workOrder = workOrder;
        modalRef.result.then(async (result) => {
            console.log(result);
            this.getWorkOrders();
        }, (reason) => {
            console.log(reason);
            this.getWorkOrders();
        });
    }


    makeActive(btn) {
        this.activeBtn = btn;
    }

    openSendMailModal() {
        this.modalService.open(SendMailComponent, { scrollable: true, backdrop: 'static', size: 'lg' })
    }

    calculateUserTimeSpent(workorders) {
        let timeLogs = [];
        for (let i = 0; i < workorders.length; i++) {
            if (workorders[i].time_logs.length > 0) {
                timeLogs = [...timeLogs, ...workorders[i].time_logs];
            }
        }
        return this._workOrderService.calculateTimeSum(timeLogs);
    }


    calculateUserProgress(workorders) {
        let instructions = [];
        for (let i = 0; i < workorders.length; i++) {
            if (workorders[i].instructions.length > 0) {
                instructions = [...instructions, ...workorders[i].instructions];
            }
        }
        return this._workOrderService.calculateProgress(instructions);
    }

    formatEvents() {
        this.events = [];
        this.workOrders.forEach(item => {
            let startTime = new Date(item.due.date).setHours(0, 0)
            let endTime = new Date(item.due.date).setHours(item.due.time.split(':')[0], item.due.time.split(':')[1]);
            let color = { primary: item.priority_color, secondary: item.priority_color };
            if (item.status == 'Upcoming') {
                color = { primary: '#1E90FF', secondary: '#1E90FF' };
            }
            let obj = {
                start: new Date(startTime),
                end: new Date(endTime),
                title: item.name,
                color: color,
                actions: this.actions,
                work_order_id: item.work_order_id,
                draggable: true
            }
            this.events.push(obj);
        });
    }

    formatWorkOrderUsers() {
        let workOrders = [...this.workOrders];
        this.workOrders = [];
        workOrders.forEach(item => {
            item.users.forEach(user => {
                if (!this.workOrders.find(wo => wo._id.party_id == user.user_id)) {
                    console.log(user)
                    this.workOrders.push({ _id: { party_id: user.user_id, party_name: user.name }, data: workOrders.filter(wor => wor.users.find(woru => woru.user_id == user.user_id)) });
                }
            });
        });
    }

    getUserWorkOrders(user) {
        return this.workOrders.filter(wo => wo.users.find(wou => wou.user_id == user.user_id));
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped', { ...event, start: newStart, end: newEnd });
    }

    async handleEvent(action: string, event: any) {
        if (action == 'Edited') {
            await this.openWorkOrderFormModal(event.work_order_id);
        } else if (action == 'Deleted') {
            await this.openConfirmationModal(event.work_order_id);
        } else if (action == 'Dropped') {
            let obj = {
                work_order_id: event.work_order_id,
                due: {
                    date: new Date(event.start),
                    time: '12:00'
                }
            }
            this.workOrder = await this._workOrderService.updateWorkOrder(obj);
            this.viewDate = new Date(this.workOrder.due.date)
            this.getWorkOrders();
        }
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    openAssignTo(workOrder) {
        const modalRef = this.modalService.open(AssignToComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modalRef.componentInstance.assignedToData = workOrder;
    }

}
