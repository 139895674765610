import { Component, OnInit ,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { HelperService } from '../../../services/helper.service';
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-sensor-setting',
  templateUrl: './sensor-setting.component.html',
  styleUrls: ['./sensor-setting.component.scss']
})
export class SensorSettingComponent implements OnInit {

  @Input() sensor: any;
  users: any = [];
  sensorOrigionalImageSrc: any;
  sensorData: any;
  sensorTabs: any;
  activeTab: any = 'maintenance-reminders';

  constructor(
    public activeModal: NgbActiveModal, 
    private _sensorService: SensorService,
    private _helperService: HelperService,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    if(this.sensor.image.original){
      this.sensorOrigionalImageSrc = await this._helperService.setImage(this.sensor.image.original);
    }
    this.sensorTabs = await this._sensorService.getSensorSettingTabs(this.sensor.tabs);
    this.users = await this._sharedService.getUsersBySensorUniqueId(this.sensor.sensor_unique_id);
  }

  makeActive(tabId){
    this.activeTab = tabId;
  }

  active(tabId){
    return this.activeTab == tabId;      
  }
}