import { Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-viewer-part',
    templateUrl: './part.component.html',
    styleUrls: ['./part.component.scss']
})

export class PartComponent implements OnInit{

    @Input() obj: any;
    constructor() {}
    
    ngOnInit(){
        
    }
}