import { Component, OnInit, Input } from '@angular/core';

//Other Imports
import { SensorService } from '../sensor.service'

@Component({
  selector: 'app-sensor-other-policy',
  templateUrl: './sensor-other-policy.component.html',
  styleUrls: ['./sensor-other-policy.component.scss']
})
export class SensorOtherPolicyComponent implements OnInit {
  @Input() sensorId: any;
  constructor(
    private _sensorService: SensorService
  ) { }

  async ngOnInit() {
  }
  
}
  