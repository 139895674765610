<div class="row">
    <div class="col-12 col-md-6">
        <div class="form-group">
            <label>Name *</label>
            <div class="input-group">
                <input type="text" class="form-control" (blur)="updateRecource($event, 'name')" placeholder="Enter Name" value="{{obj.resource.name}}">
            </div>
        </div>

        <div class="form-group" *ngIf="obj.type == 'location'">
            <label>Plant</label>
            <select class="form-control" [(ngModel)]="obj.resource.plant_id" (change)="updateRecource($event, 'plant_id')">
                <option value="">Select Plant</option>
                <option *ngFor="let plant of plants" [value]="plant.plant_id">{{ plant.name }}</option>
            </select>
        </div>


        <div class="form-group" *ngIf="obj.type == 'asset'">
            <label>Location</label>
            <select class="form-control" [(ngModel)]="obj.resource.location_id" (change)="updateRecource($event, 'location_id')">
                <option value="">Select Location</option>
                <option *ngFor="let location of locations" [value]="location.location_id">{{ location.name }}</option>
            </select>
        </div>


        <div class="form-group" *ngIf="obj.type == 'component'">
            <label>Asset</label>
            <select class="form-control" [(ngModel)]="obj.resource.asset_id" (change)="updateRecource($event, 'asset_id')">
                <option value="">Select Asset</option>
                <option *ngFor="let asset of assets" [value]="asset.asset_id">{{ asset.name }}</option>
            </select>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Latitude</label>
                    <div class="input-group">
                        <input type="number" class="form-control" placeholder="Enter Latitude" [(ngModel)]="obj.resource.latitude" (blur)="updateRecource($event, 'latitude')">
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Longitude</label>
                    <div class="input-group">
                        <input type="number" class="form-control" placeholder="Enter Longitude" [(ngModel)]="obj.resource.longitude" (blur)="updateRecource($event, 'longitude')">
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Short Description</label>
            <div class="input-group">
                <textarea rows="4" class="form-control" id="basic-textarea" placeholder="Enter Description" [(ngModel)]="obj.resource.description" (blur)="updateRecource($event, 'description')"></textarea>
            </div>
        </div>

        <div *ngIf="obj.type == 'component'">
            <label class="text-dark font-weight-bold text-uppercase mb-0">Name Plate Description</label>
            <hr class="bg-dark mb-2 mt-2">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">HP</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Hp" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.hp" (blur)="updateRecource($event, 'hp')">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">Frame</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Frame" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.frame" (blur)="updateRecource($event, 'frame')">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">Rpm</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Rpm" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.rpm" (blur)="updateRecource($event, 'rpm')">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">Enclosure</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Enclosure" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.enclosure" (blur)="updateRecource($event, 'enclosure')">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">Volts</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Volts" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.volts" (blur)="updateRecource($event, 'volts')">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">De Brg</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter De Brg" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.de_brg" (blur)="updateRecource($event, 'de_brg')">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">Apms</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Apms" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.apms" (blur)="updateRecource($event, 'apms')">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="m-0">Ode Brg</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Enter Ode Brg" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.resource.ode_brg" (blur)="updateRecource($event, 'ode_brg')">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>