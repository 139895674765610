import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';
import { FileUploadModule } from 'ng2-file-upload';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { LocationRoutingModule } from "./location-routing.module";
import { LocationComponent } from './location.component';
import { LocationFormComponent } from './location-form/location-form.component';
import { LocationService } from './location.service';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationModalComponent } from './location-modal/location-modal.component';

import { AssetModule } from '../asset/asset.module';
import { PinModule } from '../pin/pin.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module'; 

@NgModule({
    imports: [
        CommonModule,
        LocationRoutingModule,
        NgbModule,
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule,
        AssetModule,
        PinModule,
        ExpandScreenModule ,
        FileUploadModule
    ], 
    declarations: [ 
        LocationComponent,
        LocationListComponent,
        LocationModalComponent,
        LocationFormComponent
    ],
    providers: [
        LocationService
    ],
    exports: [
        LocationComponent,
        LocationListComponent,
    ]

    
})
export class LocationModule { }
