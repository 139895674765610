import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService
{
    constructor(
        private _httpClient: HttpClient
    ) { }

    getDashboardBydashboardId(dashboard_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/dashboard/'+dashboard_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addDashboard(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/dashboard', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    updateDashboard(dashboard_id, data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/dashboard/'+dashboard_id, { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deleteDashboard(dashboard_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/dashboard/'+dashboard_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getSensorsCount(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/get-sensors-count')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAlertsByType(type, limit = 10000000, offset = 0): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/get-alerts-by-type/'+type+'/'+limit+'/'+offset)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getUsers(): Promise<any> { 
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/user')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getGateways(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/get-gateways')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    clearAllAlerts(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/get-alerts-by-type', { ...data }) // Did mistake while creating name of this route
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    
}
