export default {
    role: {
        SUPER_ADMIN_ID: '98701092-7780-4866-923d-1a8120cb24d5',
        SUPER_ADMIN_ROLE_ID : '235d5348-2eb2-4661-a7ef-0f1b307642e7',
        VENDOR_ROLE_ID : '1ad9c7be-dabc-4587-8c65-65bfeb6507b2',
        USER_ROLE_ID : 'e331e58f-db2e-4a37-acf7-29b10cdba0c0'
    },
    project: {
        SUPER_PROJECT_UNIQUE_ID: '777c5e2a-8c94-4541-b4ed-ce8361a660b3'
    },
    sensor: {
        DEFAULT_OFFSET: 0,
        DEFAULT_TEMPERATURE_OFFSET: 0
    }
}
