<div class="modal-header">
    <h4 class="modal-title" id="modal-delete-role">{{ heading }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{ content }}</p>
    <img src="{{ imageSrc }}" class="img-fluid w-100" [hidden]="!imageSrc"/>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white" [hidden]="!imageSrc" (click)="activeModal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-white" [hidden]="imageSrc" (click)="activeModal.close('Confirm click')">Confirm</button>
</div>