import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkOrderComponent } from "./work-order.component";
import { WorkOrderRoutingModule } from "./work-order-routing.module";
import { WorkOrderListComponent } from "./work-order-list/work-order-list.component";
import { WorkOrderFormComponent } from "./work-order-form/work-order-form.component";
import { WorkOrderService } from "./work-order.service";
import { WorkOrderDetailFormComponent } from "./work-order-detail-form/work-order-detail-form.component";
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddTimeLogFormComponent } from '../work-order/add-time-log-form/add-time-log-form.component';
import { AddPartFormComponent } from '../work-order/add-part-form/add-part-form.component';
import { AddDownTimeFormComponent } from '../work-order/add-down-time-form/add-down-time-form.component';
import { WorkOrderDetailsComponent } from './work-order-details/work-order-details.component'
import { ComponentModule } from '../component/component.module';
import { FileUploadModule } from 'ng2-file-upload';
import { RequisitionModule } from '../requisition/requisition.module';

@NgModule({
    imports: [
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        CommonModule,
        WorkOrderRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ExpandScreenModule,
        NgxDatatableModule,
        NgMultiSelectDropDownModule,
        NgbModule,
        ComponentModule,
        FileUploadModule,
        RequisitionModule
    ],
    declarations: [
        WorkOrderComponent,
        WorkOrderListComponent,
        WorkOrderFormComponent,
        WorkOrderDetailFormComponent,
        AddTimeLogFormComponent,
        AddPartFormComponent,
        AddDownTimeFormComponent,
        WorkOrderDetailsComponent

    ],
    providers: [
        WorkOrderService
    ],
    exports: [
        WorkOrderListComponent,
        WorkOrderDetailFormComponent,
        WorkOrderDetailsComponent,
    ]
})
export class WorkOrderModule { }
