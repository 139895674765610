import { Component, OnInit, Input, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SensorModalComponent } from '../../sensor/sensor-modal/sensor-modal.component';
import { ModalComponent } from '../../modal/modal.component';
import { DashboardService } from '../dashboard.service';
import { SharedService } from '../../../services/shared.service';
import { HelperService } from '../../../services/helper.service';
import { removeData } from 'jquery';

@Component({
  selector: 'app-alerts-details',
  templateUrl: './alerts-details.component.html',
  styleUrls: ['./alerts-details.component.scss']
})
export class AlertsDetailsComponent implements OnInit {

  alerts: any = [];
  users: any = [];
  pinData: any;

  vibrationAlertTypes = ['VIBRATION_ALERT', 'MONNIT_VIBRATION_ALERTS'];
  vibrationAlertActions = ["Installed Spare", "Cleaned Cooling System", "Tightened Mounting Bolts", "Realigned", "Lubricated Bearing", "Other"]
  temperatureAlertTypes = ['AMBIENT_TEMP_ALERT', 'LOW_TEMPERATURE_ALERT', 'HIGH_TEMPERATURE_ALERT'];
  temperatureAlertActions = ["Change out asset", "Clean fan cover", "Clean cooling fins", "Infrared scan", "Call HVAC contractor", "Raise alert level", "Other"]
  batteryAndSignaLostAlertTypes = ['BATTERY_ALERT', 'SIGNAL_LOST_ALERT'];
  batteryAndSignaLostActions = ["Recharge Battery", "Replace Battery", "Repair Sensor", "Replace Sensor", "Other"];
  differentialAlertTypes = ['DIFFERENTIAL_ALERT'];
  differentialAlertActions = ['Check Differential'];
  otherAlertTypes = ['MAINTENANCE', 'MAINTENANCE_ALERT', 'DIGITAL_ALERTS', 'MONNIT_DIGITAL_ALERTS'];

  /* pagination */
  page: number = 1;
  limit: number = 10;
  total: number;

  recommendedActionBlock = false;
  actionTakenBlock = false;
  type = 'active';
  showCleared = false;

  limits = [
    { key: 'Show 10', value: 10 },
    { key: 'Show 25', value: 25 },
    { key: 'Show 150', value: 150 },
    { key: 'Show 500', value: 500 },
    { key: 'Show 1000', value: 1000 }
  ];

  constructor(
    private _dashboardService: DashboardService,
    private _sharedService: SharedService,
    public helperService: HelperService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    
    this.pinData = {
      component_name: 'AlertsDetailsComponent'
    }
    this.users = await this._dashboardService.getUsers();
    await this.getalerts();
  }

  async getActiveAlerts() {
    this.page = 1;
    this.type = 'active';
    this.showCleared = false;
    await this.getalerts();
  }

  async getClearedAlerts() {
    this.page = 1;
    this.type = 'cleared';
    this.showCleared = true;
    await this.getalerts();
  }

  async getalerts() {
    let offset = (this.page - 1) * this.limit;
    let response = await this._dashboardService.getAlertsByType(this.type, this.limit, offset);
    this.total = response[0] ? response[0].count : 0;
    this.alerts = response[0] ? response[0].data : [];
  }

  async changeRowLimits(event) {
    this.limit = event.target.value;
    this.page = 1;
    await this.getalerts()
  }

  async getPage(pageNo) {
    this.page = pageNo;
    await this.getalerts();
  }

  async updateAlerts(item) {
    let response = await this._sharedService.updateAlerts(item);
    if (response) {
      let index = this.alerts.findIndex(a => a._id == item._id);
      this.alerts.splice(index, 1);
      await this.getalerts();
    }
  }

  isVibrationAlert(type) {
    return this.vibrationAlertTypes.includes(type);
  }

  isTemperatureAlert(type) {
    return this.temperatureAlertTypes.includes(type);
  }

  isBatteryAndSignalLostAlert(type) {
    return this.batteryAndSignaLostAlertTypes.includes(type);
  }

  isDifferentialAlert(type) {
    return this.differentialAlertTypes.includes(type);
  }

  async openSensorListModal(sensorId) {
    let sensor = await this._sharedService.getSensorBySensorId(sensorId);
    let component = await this._sharedService.getComponentByComponentId(sensor.component_id);
    const modalRef = this.modalService.open(SensorModalComponent, { windowClass: 'modal-full-screen', scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.component = component;
    modalRef.result.then(async (result) => {
      console.log(result)
    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal() {
    const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.heading = "Clear All Alerts";
    modalRef.componentInstance.content = "Are you sure you want clear all alerts.";

    modalRef.result.then(async (result) => {
      await this._dashboardService.clearAllAlerts({ type: 'all' });
      await this.ngOnInit();
    }, (reason) => {
      console.log(reason)
    });
  }

  toggleAddRecommendedActionBlock() {
    this.recommendedActionBlock = this.recommendedActionBlock ? false : true;
  }

  toggleAddActionTakenBlock() {
    this.actionTakenBlock = this.actionTakenBlock ? false : true;
  }

  async downloadFile() {
    let response = await this._dashboardService.getAlertsByType(this.type);
    let data = response[0] ? response[0].data : [];
    let mapperData = [];
    let header = [];
    if(data.length > 0){
      if(this.type == 'active'){
        header = ['Type', 'Sensor', 'Component', 'Created'];
        mapperData = data.map(d => ({"Type": d.action, "Sensor": d.sensor, "Component": d.component, "Created": this.helperService.formatDateWithTime(d.dateReminder)}));
      } else {
        header = ['Type', 'Sensor', 'Component', 'Occured', 'Corrective Action Recommended', 'Corrective Action Taken', 'Note', 'Action Taken By', 'Action Taken Date', 'Cleared On'];
        mapperData = data.map(d => ({"Type": d.action, "Sensor": d.sensor, "Component": d.component, "Occured": this.helperService.formatDateWithTime(d.dateReminder), "Corrective Action Recommended": d.toTake ? d.toTake : '', "Corrective Action Taken": d.taken ? d.taken : '', Note: d.note ? d.note : '', "Action Taken By": d.clearedBy ? d.clearedBy : '', "Action Taken Date": this. helperService.formatDate(d.clearedOn), "Cleared On": this.helperService.formatDateWithTime(d.updatedAt)}));
      }
    }
    let csvData = this.convertToCSV(mapperData, header);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", this.type+"-"+"alerts" + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
