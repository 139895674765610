<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">Bearing Number</div>
            <div class="card-body">
                <form [formGroup]="bearingNumberForm">
                    <div class="form-group">
                        <label >Value</label>
                        <input type="text" formControlName="bearing_number" [(ngModel)]="bearingNumber.bearing_number" class="form-control">
                        <div [hidden]="!errorBearingNumber('bearing_number','required')" class="invalid">Bearing Number is required</div> 
                    </div>
                    <div class="form-group">
                        <button [hidden]="bearingNumber._id" type="button" class="btn btn-white pull-right px-5" (click)="addBearingNumber()">Save</button>
                        <button [hidden]="!bearingNumber._id" type="button" class="btn btn-white pull-right px-5" (click)="updateBearingNumber()">Update</button>
                        <button type="button" class="btn btn-white pull-right mr-1" (click)="copyBearingNumber()"><i class="fa fa-copy"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>