import { Component,ViewContainerRef,  Input, OnInit, OnDestroy, ViewChild, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import {ComponentLoaderService} from '../../services/component-loader.service';

import { ShareDataService } from '../../services/share-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-component-loader',
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.sass']
})
export class ComponentLoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() rendercomponent: any;
  @Input() data: any;
  components: any = [];
  subscription: Subscription 
  @ViewChild('componentHost', {static: true, read: ViewContainerRef} as any) componentHost: ViewContainerRef;
  private AllComponents = ComponentLoaderService.getComponent;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _shareData: ShareDataService
    ) { }
  
  ngAfterViewInit() {
    this.loadComponent();
  }

  ngOnInit() {
    this.subscription = this._shareData.manage.subscribe(pinData => { //Event is listening here using shared service
      if(pinData.action == 'unpin'){
        this.removeComponent(pinData.component_name); 
      }
      if(pinData.action == 'pin'){
        this.addComponent(pinData); 
      }
    });
  }
  
  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  removeComponent(componentName){
      let index = this.components.indexOf(componentName);
      if(index >= 0){
        this.componentHost.remove(index);
        this.rendercomponent.splice(index,1);
        this.components.splice(index,1);
      }
  }

  addComponent(pinData){
    delete pinData.action;
      let index = this.components.indexOf(pinData.component_name);
      if(index >= 0){
        return;
      } else {
        this.rendercomponent.push(pinData);
        this.components.push(pinData.component_name);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.AllComponents[pinData.component_name]);
        const componentRef = this.componentHost.createComponent(componentFactory);
        (<any>componentRef.instance).data = this.data;
        (<any>componentRef.instance).componentLoaderData = pinData;
      }
  }

  loadComponent() {
    if(typeof this.componentHost !== 'undefined'){      
      for(const component of this.rendercomponent) {
        this.components.push(component.component_name);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.AllComponents[component.component_name]);
        const componentRef = this.componentHost.createComponent(componentFactory);
        (<any>componentRef.instance).data = this.data;
        (<any>componentRef.instance).componentLoaderData = component;

      }
    }
  }


}
