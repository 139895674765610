<div class="card" *ngIf="workOrder">
    <div class="card-body">
        <div class="d-flex justify-content-between mt-3 mx-3">
            <span>0%</span> <span>50%</span><span>100%</span>
        </div>
        <div class="progress border-1px mx-3">
            <div class="progress-bar bg-success" role="progressbar" [ngStyle]="{'width': progress + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="row font-12px my-3 px-3">
            <div class="col-12">
                <div class="d-flex" >
                    <i class="fa fa-wrench text-dark my-auto"></i>
                    <a href="javascript:;" class="text-primary my-auto ml-1 mr-2">{{workOrder.name}} </a>
                    <i class="fa fa-cube text-dark my-auto mr-1"></i>
                    <span *ngIf="!workOrder.asset_name && !workOrder.component_name">Unassigned</span>
                    <a href="javascript:;" class="text-success my-auto" *ngIf="workOrder.asset_name"> {{workOrder.asset_name}} </a>
                    <span *ngIf="workOrder.asset_name && workOrder.component_name">&nbsp;-></span>
                    <a href="javascript:;" class="text-success my-auto" *ngIf="workOrder.component_name">{{workOrder.component_name}} </a>
                    <i class="fa fa-user text-dark my-auto mr-1 ml-2"></i>
                    <span class="my-auto mr-2" *ngIf="workOrder.user_id">{{workOrder.user_name}}<br></span>
                    <span class="my-auto mr-2" *ngIf="workOrder.team_id">{{workOrder.team_name}}<br></span>
                </div>
            </div>
            <div class="col-12 mt-1">
                <div class="d-flex ">
                    <div class="bg-white border-1px text-warning rounded mr-2">
                        <i class="fa fa-exclamation-circle mr-2 mx-2 my-auto font-12px" [ngStyle]="{'color': workOrder.priority_color}"></i>
                        <span class="mr-2 my-auto" [ngStyle]="{'color': workOrder.priority_color}">{{workOrder.priority_name}}</span>
                    </div>
                    <i class="fa fa-circle-notch  text-dark my-auto mr-1"></i>
                    <span class="my-auto text-dark bg-white border-1px px-2 rounded mr-2">{{workOrder.status}}</span>
                    <i class="fa fa-calendar text-dark my-auto ml-2 mr-1"></i>
                    <span class="my-auto mr-2"> {{ helperService.formatDate(workOrder.due.date) }} {{ helperService.formatTime(workOrder.due.time) }} </span>
                    <span class="my-auto text-white border-1px px-2 rounded mr-2" *ngIf="!workOrder.completed" [ngClass]="_workOrderService.computeClass(helperService.getDaysDifference(workOrder.due.date))">{{ helperService.getDaysDifference(workOrder.due.date) }}</span>
                </div>
            </div>
        </div>
        <div class="inline-block px-3">
            <p class="mb-0">Created On: <span>{{ helperService.formatDateWithTime(workOrder.createdAt)}} </span></p>
            <p class="mb-0">Last Edited: <span>{{ helperService.formatDateWithTime(workOrder.updatedAt)}}</span></p>
            <p class="mb-0">Time Spent: <b>{{ timeSpent }}</b></p>
            <p class="mb-0">Down Time: <b>{{ downTime }}</b></p>
        </div>
        <hr class="hr-work-order" *ngIf="workOrder.completed">
        <div class="inline-block px-3" *ngIf="workOrder.completed">
            <p class="mb-0">Due On: <b>{{ helperService.formatDate(workOrder.due.date) }} {{ helperService.formatTime(workOrder.due.time) }} </b></p>
            <p class="mb-0">Completed On: <b>{{ helperService.formatDateWithTime(workOrder.completed)}}</b></p>
        </div>
        <hr class="hr-work-order">
        <div class="px-3">
            <h6 class="text-dark">Instructions:</h6>
            <div *ngFor="let ins of workOrder.instructions; let i = index">
                {{i + 1}}: <input type="checkbox" [disabled]="workOrder.completed" [checked]="ins.fulfil" class="ml-2" (change)="updateProgress($event, i)"> {{ins.text}}
            </div>
        </div>
        <div *ngIf="workOrder.attachment">
        <hr class="hr-work-order">
        <div class="px-3">
            <h6 class="text-dark">Attachment:</h6>
                <p><a href="javascript:;" (click)="openAttachemnt(workOrder.attachment)" class="text-dark">{{workOrder.attachment}}</a></p>
                <p>Requestor: <span class="font-weight-bold">{{workOrder.requester_name}}</span>, {{workOrder.requester_email}}</p>
        </div>
    </div>
        <hr class="hr-work-order">
        <div class="px-3">
            <h6 class="text-dark">Parts you may need</h6>
            <p class="text-dark border mb-1 p-1" *ngFor="let part of workOrder.parts; let i = index">{{part.name_number}}:
                <input class="w-50-px h-20-px" value="{{part.quantity}}" type="number" (blur)="available($event.target.value,part,i)"><i class="fa fa-trash pull-right mt-1" *ngIf="!workOrder.completed" (click)="removePart(i)"></i>
            </p>
            <p class="text-dark border mb-1 p-1" *ngIf="workOrder.parts.length < 1">No parts to display</p>
            <div class="d-flex justify-content-end">
            <button *ngIf="!workOrder.completed && user.user_id" class="btn-list mr-2" type="button" (click)="openRequisitionFormModal()"><i class="fa fa-wpforms mr-1"></i>Add Requisition</button>
            <button *ngIf="!workOrder.completed" class="btn-list mr-2" type="button" (click)="openPartFormModal()"><i class="fa fa-plus mr-1"></i>Add Part</button>
            <button *ngIf="!workOrder.completed" class="btn-list" type="button" (click)="openAddPartFormModal()"><i class="fa fa-check mr-1"></i>Select Part</button>
            </div>


        </div>
        <hr class="hr-work-order">
        <div class="px-3">
            <h6 class="text-dark">Time Logged</h6>
            <p class="text-dark border mb-1 p-1" *ngFor="let timelog of workOrder.time_logs; let i = index"><i class="fa fa-clock-o"></i> {{timelog.hours}}:{{timelog.minutes}} {{timelog.description}}<i class="fa fa-trash pull-right mt-1" *ngIf="!workOrder.completed" (click)="removeTimeLog(i)"></i></p>
            <p class="text-dark border mb-1 p-1" *ngIf="workOrder.time_logs.length < 1">No time logs to display</p>
            <p class="text-right" *ngIf="!workOrder.completed"><button type="button" (click)="openAddTimeLogFormModal()" class="btn-list"><i class="fa fa-clock-o mr-1"></i>Log Time</button></p>
        </div>
        <hr class="hr-work-order">
        <div class="px-3">
            <h6 class="text-dark">Down Time</h6>
            <p class="text-dark border mb-1 p-1" *ngFor="let downtime of workOrder.down_times; let i = index"><i class="fa fa-clock-o"></i> {{downtime.hours}}:{{downtime.minutes}} {{downtime.description}}<i class="fa fa-trash pull-right mt-1" *ngIf="!workOrder.completed" (click)="removeDownTime(i)"></i></p>
            <p class="text-dark border mb-1 p-1" *ngIf="workOrder.down_times.length < 1">No down time to display</p>
            <p class="text-right" *ngIf="!workOrder.completed"><button type="button" (click)="openAddDownTimeFormModal()" class="btn-list"><i class="fa fa-clock-o mr-1"></i>Down Time</button></p>
        </div>
        <hr class="hr-work-order">
        <div class="px-3">
            <h6 class="text-dark">Comments:</h6>
            <div class="text-dark border mb-1 p-1" *ngFor="let comment of workOrder.comments; let i = index">
                <p class="mb-0"><i class="fa fa-comment-o mr-1"></i> {{comment.text}}<i class="fa fa-trash pull-right m-1" *ngIf="!workOrder.completed" (click)="removeComments(i)"></i></p>
                <p class="mb-0" *ngIf="comment.attachment"><i class="fa fa-file-o mr-1"></i> <a href="javascript:;" (click)="openAttachemnt(comment.attachment)" class="text-dark">{{comment.attachment}}</a></p>
            </div>
            <p class="text-dark border mb-1 p-1" *ngIf="workOrder.comments.length < 1">No comments to display</p>
            <p class="text-right" *ngIf="!workOrder.completed"><button type="button" class="btn-list mt-2" (click)="openAddCommentFormModal()"><i class="fa fa-comment-o"></i>Add Comment</button></p>
        </div>
        <hr class="hr-work-order">
        <div class="px-3 mb-2">
            <h6 class="text-dark">Completion Notes:</h6>
            <textarea class="w-100" rows="5" [(ngModel)]="workOrder.completion_notes"></textarea>
        </div>
        <div class="float-right mb-2 mr-3" *ngIf="!workOrder.completed">
            <button type="button" class="btn btn-success border-unset" (click)="completeWorkOrder(workOrder)">Complete</button>
        </div>
    </div>
</div>