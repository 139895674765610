import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalstorageService } from '../../services/localstorage.service';
import { DashboardService } from './dashboard.service';
import { SharedService } from '../../services/shared.service';
import { GatewaysDetailComponent } from './gateways-detail/gateways-detail.component';
import { mapElement } from 'googlemaps';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  dashboard: any = {};
  project: any = {};
  sensors: any = 'assets/images/gauges/loading-gauges.gif';
  bluzoneSensorsData: any = { total: 0, active: 0, type: 'B-Type' };
  treonSensorsData: any = { total: 0, active: 0, type: 'T-Type' };
  imonnitSensorsData: any = { total: 0, active: 0, type: 'M-Type' };
  rioSensorsData: any = { total: 0, active: 0, type: 'O-Type' };
  optimeSensorsData: any = { total: 0, active: 0, type: 'OPT-Type' };
  activeAlerts = 'assets/images/gauges/loading-gauges.gif';
  gateways: any = 'assets/images/gauges/loading-gauges.gif';
  bluzoneGateWaysData: any = { items: [], total: 0, active: 0, type: 'B-Type' };
  imonnitGateWaysData: any = { items: [], total: 0, active: 0, type: 'M-Type' };
  dateNow = Date.now();

  constructor(
    private cdr: ChangeDetectorRef,
    private _localstorageService: LocalstorageService,
    private _dashboardService: DashboardService,
    private _sharedService: SharedService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.dashboard = this._localstorageService.getDashboardData();
    this.project = this._localstorageService.getProjectData();
  }

  async ngOnInit() {
    setInterval(() => {
      this.dateNow = Date.now();
    }, 1000);
    this.initWeatherGadget();
    this.populateMap();
    let response = await this._dashboardService.getAlertsByType('active');
    this.activeAlerts = response[0] ? response[0].count : 0;
    this.getAndProcessSensors();
    this.getAndProcessGateways();
  }

  initWeatherGadget() {
    (<any>window).myWidgetParam ? (<any>window).myWidgetParam : (<any>window).myWidgetParam = []; (<any>window).myWidgetParam.push({ id: 15, zipCode: this.project.zip_code || '28110', appid: '7edb4cb101c59355f1d52ee4ed422fe9', units: 'imperial', containerid: 'openweathermap-widget-15', }); (function () { var script = document.createElement('script'); script.async = true; script.charset = "utf-8"; script.src = (<any>window).appBaseUrl + "/uploads/weather-widget-generator.js"; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s); })();
  }

  async getAndProcessSensors(){
    this.sensors = await this._dashboardService.getSensorsCount();
    this.bluzoneSensorsData = { total: this.sensors.bluzoneTotalSensors, active: this.sensors.bluzoneActiveSensors, type: 'B-Type' };
    this.treonSensorsData = { total: this.sensors.treonTotalSensors, active: this.sensors.treonActiveSensors, type: 'T-Type' };
    this.imonnitSensorsData = { total: this.sensors.imonnitTotalSensors, active: this.sensors.imonnitActiveSensors, type: 'M-Type' };
    this.rioSensorsData = { total: this.sensors.rioTotalSensors, active: this.sensors.rioActiveSensors, type: 'O-Type' };
    this.optimeSensorsData = { total: this.sensors.optimeTotalSensors, active: this.sensors.optimeActiveSensors, type: 'OPT-Type' };
  }
  
  async populateMap() {
    let plants = await this._sharedService.getPlants();
    let locations = await this._sharedService.getLocations();
    let assets = await this._sharedService.getAssets();
    let components = await this._sharedService.getComponents();
    let items = [...plants, ...locations, ...assets, ...components];
    items = items.filter(i => i.latitude && i.longitude);
    this.drawMap(items);
  }

  drawMap(items) {
    let MapOptions = {
      mapTypeId: google.maps.MapTypeId.SATELLITE
    };
    //Initializing the InfoWindow, Map and LatLngBounds objects.
    let InfoWindow = new google.maps.InfoWindow();
    let Latlngbounds = new google.maps.LatLngBounds();
    let Map = new google.maps.Map(document.getElementById("dvMap"), MapOptions);
    setTimeout(() => {
      Map.setZoom(16);
    }, 1000);
    //Looping through the Array and adding items.
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var myLatlng = new google.maps.LatLng(item.latitude, item.longitude);

        //Initializing the Marker object.
        let details = this.getDetails(item);
        var marker = new google.maps.Marker({
          position: myLatlng,
          map: Map,
          icon: details.icon
        });

        //Adding InfoWindow to the Marker.
        (function (marker, item) {
          google.maps.event.addListener(marker, "click", function (e) {
            InfoWindow.setContent(details.html);
            InfoWindow.open(Map, marker);
          });
        })(marker, item);

        //Plotting the Marker on the Map.
        Latlngbounds.extend(myLatlng);
      }
    }
    //Adjusting the Map for best display.
    Map.setCenter(Latlngbounds.getCenter());
    Map.fitBounds(Latlngbounds);
  }

  getDetails(item) {
    let details: any = {};
    if (item.plant_id && item.location_id) {
      details.icon = '/assets/images/icons/blue-pointer.png';
    } else if (item.location_id && item.asset_id) {
      details.icon = '/assets/images/icons/green-pointer.png';
    } else if (item.asset_id && item.component_id) {
      details.icon = '/assets/images/icons/orange-pointer.png';
    } else {
      details.icon = '/assets/images/icons/red-pointer.png';
    }
    details.name = item.name;
    details.description = item.description;
    details.image = item.image.thumb ? (<any>window).appBaseUrl + "/uploads/" + item.image.thumb : '/assets/images/icons/placeholder.png';
    details.html = "<div class=\"cmx-form-section-title\" id=\"info-name\" style=\"cursor: pointer;color: deepskyblue;/* padding: 0 10px 10px 10px; */border-bottom: solid 1px;margin-bottom: 10px;\"> ".concat(details.name, "</div>\n\n          <img class=\"cmx-thumbnail cmx-thumbnail-inline\" src=\"").concat(details.image, "\" style=\"\n            width: 30px;\n            height: 30px;\">\n          <div class=\"rtls-card-copy\" style=\"display: block;\">\n            <ul class=\"list-unstyled\"\">\n              <li id=\"info-id\" style=\"cursor: pointer;\">\n                <b>Description:</b>\n                <span>").concat(details.description, "</span>\n              </li>\n            </ul>\n          </div>\n        ");
    return details;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  async getAndProcessGateways() {
    this.gateways = await this._dashboardService.getGateways();
    if (this.gateways.bluzoneGateWays.length > 0) {
      this.gateways.bluzoneGateWays.forEach(element => {
        this.bluzoneGateWaysData.items.push({
          name: element.name,
          latitude: element.latitude,
          longitude: element.longitude,
          macAddress: element.macAddress,
          imei: false,
          gateWayType: element.deviceType.name,
          status: element.status,
          lastMessageSent: false,
          ipAddress: element.ipAddress,
          gwAddress: element.gwAddress,
          network: element.networkSelectorType,
          heartBeat: '',
          BatteryLevel: ''
        });
      });
      this.bluzoneGateWaysData.total = this.gateways.bluzoneGateWays.length;
      this.bluzoneGateWaysData.active = this.gateways.bluzoneGateWays.filter(element => element.status == 'CONNECTED').length;
    }

    if (this.gateways.imonnitGateWays.length > 0) {
      this.gateways.imonnitGateWays.forEach(element => {
        this.imonnitGateWaysData.items.push({
          name: element.Name,
          latitude: false,
          longitude: false,
          macAddress: (element.GatewayType.includes('Ethernet') ? this.macAddressFormatter(element.MacAddress) : false),
          imei : (!element.GatewayType.includes('Ethernet') ? element.MacAddress.split("|")[1] : false),
          gateWayType: element.GatewayType,
          status: false,
          lastMessageSent: this.getMinutes(element.LastCommunicationDate),
          ipAddress: element.LastInboundIPAddress.split(":")[0],
          gwAddress: false,
          network: false,
          heartBeat: element.Heartbeat,
          BatteryLevel: element.BatteryLevel
        });
      });
      this.imonnitGateWaysData.total = this.gateways.imonnitGateWays.length;
      this.imonnitGateWaysData.active = this.gateways.imonnitGateWays.filter(element => !element.IsDirty).length;
    }
  }

  macAddressFormatter(string) {
    return string.match(/.{1,2}/g).join(":")
  }

  getMinutes(date) {
    date = parseInt(date.substring(6, date.length - 2));
    let lastCommunicationDate: any = new Date(date);
    let now: any = new Date();
    let diffMs = (now - lastCommunicationDate);
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return diffMins + ' Minutes ago'
  }
  image(value) {
    return value.toString().indexOf('assets/images/gauges/') != -1
  }

  openGatewaysDetailsModal(gatewaysData) {
    const modalRef = this.modalService.open(GatewaysDetailComponent, {size: 'xl', scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.gatewaysData = gatewaysData;
    modalRef.result.then(async (result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  openAlertsDetails() {
    this.modalService.dismissAll();
    this.router.navigate(["./dashboard/alerts-details"]);
  }

}
