<!-- Component modal -->
<div class="modal-header">
    <h5 class="modal-title">Component</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Name *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [(ngModel)]="component.name">
                        <div [hidden]="!error('name','required')" class="invalid">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Asset *</label>
                    <select class="form-control" formControlName="asset_id" [(ngModel)]="component.asset_id">
                        <option value="">Select Asset</option>
                        <option *ngFor="let asset of assets" [value]="asset.asset_id" >{{ asset.name }}</option>
                    </select>
                    <div [hidden]="!error('asset_id','required')" class="invalid">Asset is required</div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Latitude</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Enter Latitude" formControlName="latitude"  [(ngModel)]="component.latitude">
                                <div [hidden]="!error('latitude','required')" class="invalid">Latitude is required</div> 
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Longitude</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Enter Longitude" formControlName="longitude"  [(ngModel)]="component.longitude">
                                <div [hidden]="!error('longitude','required')" class="invalid">Longitude is required</div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Short Description</label>
                    <div class="input-group">
                        <textarea rows="4" class="form-control" id="basic-textarea" placeholder="Enter Description" formControlName="description" [(ngModel)]="component.description"></textarea>
                        <div [hidden]="!error('description','required')" class="invalid">Description is required</div>
                    </div>
                </div>

                <label class="text-dark font-weight-bold text-uppercase mb-0">Name Plate Description</label>
                <hr class="bg-dark mb-2 mt-2">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">HP</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Hp" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.hp">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">Frame</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Frame" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.frame">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">Rpm</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Rpm" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.rpm">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">Enclosure</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Enclosure" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.enclosure">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">Volts</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Volts" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.volts">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">De Brg</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter De Brg" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.de_brg">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">Apms</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Apms" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.apms">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="m-0">Ode Brg</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Ode Brg" [ngModelOptions]="{standalone: true}" [(ngModel)]="component.ode_brg">
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="bg-dark mb-2 mt-0">

               <div class="form-group" >
                    <label>Upload Image</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event, 'image')" type="file" name="file" class="form-control" #fileInput accept="image/*" />
                    </div>
                </div>
                
                <div class="form-group" >
                    <label>Name Plate Image</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event, 'name_plate_image')" type="file" name="file" class="form-control" #fileInput accept="image/*" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <img src="{{ componentImage }}" class="image" />
                    </div>
                    <div class="col-6">
                        <img src="{{ namePlateImage }}" class="image" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="!component.component_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', component: component})">Update</button>
    <button [hidden]="component.component_id" type="button" class="btn btn-white pull-right"  (click)="validateForm({action: 'add', component: component})">Save</button>
</div>    

