import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';

import { ChartRoutingModule } from './chart-routing.module';
import { ChartComponent } from './chart.component';
import { ChartService } from './chart.service';
import { LineChartComponent } from './line-chart/line-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { StepperChartComponent } from './stepper-chart/stepper-chart.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';

@NgModule({ 
    imports: [
        CommonModule,
        NgbModule, 
        ChartRoutingModule,
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        NgApexchartsModule
    ], 
    declarations: [ 
        ChartComponent,
        LineChartComponent,
        BarChartComponent,
        StepperChartComponent,
        DonutChartComponent
    ],
    providers: [
        ChartService
    ],
    exports: [
        ChartComponent,
        LineChartComponent,
        BarChartComponent,
        DonutChartComponent,
        StepperChartComponent
    ]
})
export class ChartModule { }
