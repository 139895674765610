import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from './dashboard.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component';
import { GatewaysDetailComponent } from './gateways-detail/gateways-detail.component';
import { AlertsDetailsComponent } from './alerts-details/alerts-details.component';
import { DashboardService } from './dashboard.service';

import { PlantModule } from '../plant/plant.module';
import { SensorModule } from '../sensor/sensor.module';
import { DataComparisonModule } from '../data-comparison/data-comparison.module';
import { ComponentLoaderModule } from '../component-loader/component-loader.module';
import { PinModule } from '../pin/pin.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        NgbModule, 
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        PlantModule,
        SensorModule,
        PinModule,
        ExpandScreenModule,
        DataComparisonModule,
        ComponentLoaderModule,
        NgxPaginationModule
    ], 
    declarations: [ 
        DashboardComponent,
        DashboardCardComponent,
        DashboardFormComponent,
        GatewaysDetailComponent,
        AlertsDetailsComponent
    ],
    providers: [
        DashboardService
    ],
    exports: [
        DashboardCardComponent
    ]
})
export class DashboardModule { }
