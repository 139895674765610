<div class="row mb-2 mx-0" id="component-list-header">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Manage Components</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<button type="button" class="btn btn-white mr-2 d-none d-sm-block" [hidden]="hideOptions()" (click)="downloadCsv()" title="Download Sample CSV"><i class="fa fa-download"></i></button>
			<button type="button" class="btn btn-white mr-2 d-none d-sm-block" [hidden]="hideOptions()" (click)="openSensorsCsvModal()" title="Import Sensors"><i class="fa fa-upload"></i></button>
			<button type="button" class="btn btn-white" (click)="openComponentFormModal()"><i class="fa fa-plus"></i></button>
			<app-pin [pinData]='pinData'></app-pin>
			<app-expand-screen></app-expand-screen>
		</div>
	</div>
</div>
<div class="row mb-2" [hidden]="asset">
	<div class="col-12">
		<div class="card border-1px">
			<div class="card-body">
				<div class="row mt-3 mb-3 mb-sm-0 mx-1">
					<div class="col-sm-6 col-md-3">
						<div class="form-group">
							<ng-multiselect-dropdown [placeholder]="'Select Plant'"
								[settings]="setMultiselectSetting('plant_id','name')" [data]="plants"
								[(ngModel)]="filter.plants" (ngModelChange)="getPlantsLocations()">
							</ng-multiselect-dropdown>
						</div>
					</div>
					<div class="col-sm-6 col-md-3">
						<div class="form-group">
							<ng-multiselect-dropdown [placeholder]="'Select Location'"
								[settings]="setMultiselectSetting('location_id','name')" [data]="locations"
								[(ngModel)]="filter.locations" 	(ngModelChange)="getLocationsAssets()">
							</ng-multiselect-dropdown>
						</div>
					</div>
					<div class="col-sm-6 col-md-3">
						<div class="form-group">
							<ng-multiselect-dropdown
							[placeholder]="'Select Asset'"
							[settings]="setMultiselectSetting('asset_id','name')"
							[data]="assets"
							[(ngModel)]="filter.assets">
							</ng-multiselect-dropdown> 
						</div>
					</div>
					<div class="col-sm-6 col-md-3">
						<button type="button" class="btn btn-white mr-2" title="Filter" (click)="getAssetsComponents()"><i class="fa fa-filter"></i></button>
						<button type="button" class="btn btn-white" title="Reset" (click)="resetAssetComponents()"><i class="fa fa-refresh"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="card mb-1 border-1px bg-white" *ngFor="let component of components">
	<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
		<span class="btn btn-link text-dark shadow-none">
			<span (click)="$event.stopPropagation(); openViewerModal(component)">{{component.name}}</span>
		</span>
		<div>
			<i class="mr-2 fa fa-download cursor-pointer" (click)="$event.stopPropagation()"  (click)="creatReport(component.component_id)"></i>
			<i class="fa fa-trash cursor-pointer" (click)="openConfirmationModal(component.component_id)"></i>
		</div>
	</div>
</div>
<div class="row" *ngIf="components.length == 0">
	<div class="col-lg-12">
		<div class="card mb-1 border-1px">
			<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
				<button class="btn btn-link text-dark shadow-none">
					<span>No data to display</span>
				</button>
			</div>
		</div>
	</div>
</div>