<div class="card card-trend-data">
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                <input type="radio" id="r1" name="mount_position" [value]="axisSettings.horizontal" [(ngModel)]="mAxis"><label for="r1" class="ml-2">HORIZONTAL</label>
                <input type="radio" id="r2" name="mount_position" class="ml-3" [value]="axisSettings.axial" [(ngModel)]="mAxis"><label for="r2" class="ml-2">AXIAL</label>
                <input type="radio" id="r3" name="mount_position" class="ml-3" [value]="axisSettings.radial" [(ngModel)]="mAxis"><label for="r3" class="ml-2">RADIAL</label>
            </div>
            <div class="col-4">
                <select class="form-control" id="default-select" (change)="drawFFTCharts()" [(ngModel)]="fftType" [hidden]="fftArray.length == 0">
                    <option value="">Select Type</option>
                    <option value="acceleration">Acceleration</option>
                    <option value="velocity">Velocity</option>
                </select>
            </div>
            <div class="col-4">
                <button type="button" class="btn btn-white grey px-3 ml-2 pull-right" [disabled]="!mAxis" (click)="openSelectHistoryModal()">HISTORICAL DATA</button>
                <button type="button" class="btn btn-white px-3 pull-right" [disabled]="!mAxis" (click)="askForDBSaveFFT()">CREATE NEW GRAPHS</button>
            </div>
        </div>
    </div>
</div>
<div class="card card-trend-data">
    <div class="card-body">
        <div class="row">
            <label class="w-100">
                <div class="ml-3 pull-left">FFT</div>
                <div class="pull-right mr-3">
                    <div class="icheck-material-white">
                        <input type="checkbox" id="check-hann" [(ngModel)]="isHann" (change)="applyHannFilter()" class="custom-control-input">
                        <label for="check-hann">Apply Hanning Window</label>
                    </div>
                </div>
            </label>
            <div class="col-12 col-lg-12 col-xl-12">
                <app-line-chart [series]="fft.series" [xaxis]="fft.xaxis" [yaxis]="fft.yaxis" [tooltip]="fft.tooltip"></app-line-chart>
            </div>
        </div>
        <div class="row">
            <label class="ml-3">Time Wave Form</label>
            <div class="col-12 col-lg-12 col-xl-12">
                <app-line-chart [series]="waveForm.series" [xaxis]="waveForm.xaxis" [yaxis]="waveForm.yaxis" [tooltip]="waveForm.tooltip"></app-line-chart>
            </div>
        </div>
    </div>
</div>