import { Component, OnInit, Input } from '@angular/core';
import { PinService } from './pin.service';

import { LocalstorageService } from '../../services/localstorage.service';
import { ShareDataService } from '../../services/share-data.service';
import { NGXToastrService } from '../../services/toaster.service';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit { 

  @Input() pinData: any;
  dashboard_id: any;

  constructor(
    private _pinService: PinService,
    private _shareData: ShareDataService,
    private _localstorageService: LocalstorageService,
    private _toasterService: NGXToastrService
  ) { }

  ngOnInit() {
    let dashboard = this._localstorageService.getDashboardData();
    this.dashboard_id = dashboard.dashboard_id;
  }

  manageDashboard(pinData) {
    this._shareData.manageDashboard(pinData);
  }

  manageLocalStorage(dashboardData){
    this._localstorageService.unsetDashboardData();
    this._localstorageService.setDashboardData(dashboardData);
  }

  async pinToDashboard(){
    this.pinData.action = 'pin';
    this.manageDashboard(this.pinData); //Event is emmiting from here using shared service

    delete this.pinData.action;
    let dashboardData = await this._pinService.pinToDashboard(this.dashboard_id, this.pinData);
    if(dashboardData){
      this.manageLocalStorage(dashboardData);
      this._toasterService.typeSuccess('Pinned to Dashboard');
    }
  }

  async unPinFromDashboard(){ 
    this.pinData.action = 'unpin';
    this.manageDashboard(this.pinData); //Event is emmiting from here using shared service
    
    delete this.pinData.action;
    let dashboardData = await this._pinService.unPinFromDashboard(this.dashboard_id, this.pinData);
    if(dashboardData){
      this.manageLocalStorage(dashboardData);
      this._toasterService.typeSuccess('Removed from Dashboard');
    }
  }

}
