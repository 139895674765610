import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetService } from '../asset.service';
import { SharedService } from '../../../services/shared.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from '../../../services/helper.service';
import { AssetFormComponent } from '../asset-form/asset-form.component';
import { ModalComponent } from '../../modal/modal.component';
import { ViewerComponent } from '../../viewer/viewer.component';
import { NGXToastrService } from '../../../services/toaster.service';
import Constants from '../../../services/constants.service';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {

  @Input() location: any;
  locationOrigionalImageSrc: any;

  flags: any = [];
  filter: any = {};
  plants: any = [];
  locations: any = [];
  asset: any = {};
  assets: any = [];
  pinData: any;
  
  constructor(
    private _assetService: AssetService,
    private _sharedService: SharedService,
    private _helperService: HelperService,
    private _localstorageService: LocalstorageService,
    private modalService: NgbModal,
    private _toasterService: NGXToastrService,

    
  ) { }

  async ngOnInit() { 
    this.pinData = {
      component_name: 'AssetComponent' 
    }
    
    if(this.location){
      if(this.location.image.original){
        this.locationOrigionalImageSrc = await this._helperService.setImage(this.location.image.original);
      }
      this.assets = await this._assetService.getAssetsByLocationId(this.location.location_id);
    } else {
      this.plants = await this._sharedService.getPlants();
      let componentLocalData = this._localstorageService.getComponentData('asset-list');
      if(componentLocalData){
        this.filter.plants = componentLocalData.filter.plants;
        this.filter.locations = componentLocalData.filter.locations;
        this.getLocationsAssets();
      } else {
        this.assets = await this._sharedService.getAssets();
      }
    }
  }

  async getPlantsLocations(){
    this.filter.locations = [];
    if(this.filter.plants){
      this.locations = await this._sharedService.getPlantsLocations(this.filter.plants);
    }
  }

  async getLocationsAssets(){
    this.filter.assets = [];
    if(this.filter.locations){
      let componentLocalData = { 
        filter: {
          plants: this.filter.plants,
          locations: this.filter.locations
        }
      }
      this._localstorageService.setComponentData('asset-list', componentLocalData);
      this.assets = await this._sharedService.getLocationsAssets(this.filter.locations);
    }
  }

  async resetLocationAssets(){
    this.assets = [];
    this.filter = {};
    this._localstorageService.unsetComponentData('asset-list');
    this.assets = await this._sharedService.getAssets();
  }

  initializeAsset(){
    this.asset = {
      location_id: this.location ? this.location.location_id : '',
      image: {
        original: '',
        thumb: ''
      },
      latitude: '',
      longitude: '',
      active: true
    }
  }

  setMultiselectSetting(idField, textField){
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }
  
  async openAssetFormModal(assetId = 'add_new_asset') {
    if(assetId != 'add_new_asset'){
      this.asset = await this.getAssetByAssetId(assetId);
    } else {
      this.initializeAsset();
    }
    const modalRef = this.modalService.open(AssetFormComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.asset = this.asset;
    modalRef.componentInstance.locations = await this._sharedService.getLocations();
    modalRef.result.then(async (result) => {
      if(result.action == 'add'){
        this.asset = await this.addAsset(result.asset);
      } else {
        this.asset = await this._sharedService.updateAsset(result.asset);
      }
      if(this.asset){
        await this.ngOnInit();
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async openViewerModal(asset) {
    const modalRef = this.modalService.open(ViewerComponent, {scrollable: true, backdrop : 'static', size: 'xl'});
    modalRef.componentInstance.obj = {resource: asset, type: 'asset', id:asset.asset_id};
    modalRef.result.then(async (result) => {

    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal(assetId) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.heading = "Delete Asset";
    modalRef.componentInstance.content = "Are you sure you want to delete this asset? It will delete the components and sensors of this asset.";

    modalRef.result.then(async (result) => {
      this.asset = await this.deleteAsset(assetId);
      if(this.asset){
        let index = this.assets.findIndex(p => p.asset_id == this.asset.asset_id);
        this.assets.splice(index,1);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addAsset(asset){
    return await this._assetService.addAsset(asset); 
  }

  async deleteAsset(assetId){
    return await this._assetService.deleteAsset(assetId);
  }

  async getAssetByAssetId (assetId) {
    return  await this._assetService.getAssetByAssetId(assetId);
  }

  hideOptions(){
    let user = this._localstorageService.getUserData();
    return user.role_id != Constants.role.SUPER_ADMIN_ROLE_ID && user.role_id != Constants.role.VENDOR_ROLE_ID;
  }

  creatReport(assetId){
    this._helperService.performOperationWithToasts(async () => {
      return await this._sharedService.createReport('asset', assetId);
    });
  }

}
