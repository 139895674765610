import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import {NgxPrintModule} from 'ngx-print';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';

import { RequisitionRoutingModule } from "./requisition-routing.module";
import { RequisitionComponent } from './requisition.component';
import { RequisitionFormComponent } from './requisition-form/requisition-form.component';
import { RequisitionListComponent } from './requisition-list/requisition-list.component';
import { RequisitionPrintComponent } from './requisition-print/requisition-print.component'

import { WidgetModule} from '../widget/widget.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VendorRoutingModule } from '../vendor/vendor-routing.module';
import { PartRoutingModule } from '../part/part-routing.module';
import { FileUploadModule } from 'ng2-file-upload';

import { RequisitionService } from './requisition.service'

@NgModule({
    imports: [
        CommonModule,
        RequisitionRoutingModule,
        NgbModule,
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        ExpandScreenModule,
        TagInputModule,
        WidgetModule,
        NgxDatatableModule,
        NgxPrintModule,
        VendorRoutingModule,
        PartRoutingModule,
        FileUploadModule
    ], 
    declarations: [ 
        RequisitionComponent,
        RequisitionFormComponent,
        RequisitionListComponent,
        RequisitionPrintComponent 
    ],
    providers: [RequisitionService],
    exports: [
        RequisitionComponent,
        RequisitionFormComponent,

    ]
})
export class RequisitionModule { }
