<div class="modal-header">
    <h4 class="modal-title">History</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-12"> 
                        <div class="form-group" *ngIf="historyDates.length > 0">
                            <label>History</label>
                            <select class="form-control" name="selectedRecordId" [(ngModel)]="selectedRecordId">
                                <option [value]="undefined">Select Date</option>
                                <option *ngFor="let item of historyDates" [value]="item._id">{{ item.dateTime }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right" *ngIf="historyDates.length > 0" (click)="activeModal.close({selectedRecordId: selectedRecordId})">Okay</button>
</div>