import { Component, OnInit, Input } from '@angular/core';

//Other Imports
import { SensorService } from '../sensor.service'

@Component({
  selector: 'app-sensor-coordinates',
  templateUrl: './sensor-coordinates.component.html',
  styleUrls: ['./sensor-coordinates.component.scss']
})
export class SensorCoordinatesComponent implements OnInit {
  @Input() sensorId: any;

  coordinates: any = {};
  constructor(
    private _sensorService: SensorService
  ) { }

  async ngOnInit() {
    this.coordinates = await this._sensorService.getCoordinates(this.sensorId) || {};
  }


  async addCoordinates(){
    this.coordinates.sensorID = this.sensorId;
    return await this._sensorService.addCoordinates(this.coordinates); 
  }

  async updateCoordinates(){
    return await this._sensorService.updateCoordinates(this.coordinates); 
  }
  
}
  