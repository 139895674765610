import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';

import { ExpandScreenComponent } from './expand-screen.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule, 
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        
        TagInputModule 
    ], 
    declarations: [ 
        ExpandScreenComponent
    ],
    exports: [
        ExpandScreenComponent
    ]
})
export class ExpandScreenModule { }
