import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-rpm-utilization',
  templateUrl: './sensor-rpm-utilization.component.html',
  styleUrls: ['./sensor-rpm-utilization.component.scss']
})
export class SensorRpmUtilizationComponent implements OnInit {
  @Input() sensorId: any;

  rpmForm: FormGroup;
  utilizationForm: FormGroup;
  rpmSubmitted = false;
  utilizationSubmitted = false;

  rpmPolicy: any = {};
  utilizationPolicy: any = {};
  constructor(
    private modalService: NgbModal,
    private _sensorService: SensorService,
    private formBuilder: FormBuilder,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.setFormValidation();
    this.rpmPolicy = await this._sensorService.getRpmPolicy(this.sensorId) || {};
    this.utilizationPolicy = await this._sensorService.getUtilizationPolicy(this.sensorId) || {};
  }

  setFormValidation(){
    this.rpmForm = this.formBuilder.group({
      rpmValue: ['', [Validators.required]]
    });
    this.utilizationForm = this.formBuilder.group({
      utilizationValue: ['', [Validators.required]]
    });
  }

  errorRpm(comtrolName, constraint){
    let errors = this.rpmForm['controls'][comtrolName]['errors'];
    return this.rpmSubmitted && errors && errors[constraint]
  }

  errorUtilization(comtrolName, constraint){
    let errors = this.utilizationForm['controls'][comtrolName]['errors'];
    return this.utilizationSubmitted && errors && errors[constraint]
  }

  async addRpmPolicy(){
    this.rpmSubmitted = true;
    if (!this.rpmForm.invalid) {
      this.rpmPolicy.beaconId = this.sensorId;
      this.rpmPolicy = await this._sensorService.addRpmPolicy(this.rpmPolicy); 
    }
  }

  async copyRpmPolicy(){
    this.rpmSubmitted = true;
    if (!this.rpmForm.invalid) {
      const modalRef = this.modalService.open(SelectSensorModalComponent, {backdrop : 'static'});
      let sensor = await this._sharedService.getSensorBySensorId(this.sensorId);
      let sensors = await this._sharedService.getSensors('?type='+sensor.type+'&used_for='+sensor.used_for);
      let index =  sensors.findIndex(s => s.sensor_id == this.sensorId);
      sensors.splice(index,1);
      modalRef.componentInstance.sensors = sensors;
    
      modalRef.result.then(async (result) => {
        let copied_data = {...this.rpmPolicy};
        delete(copied_data._id);
        result.rpmPolicy = copied_data;
        await this._sensorService.copyRpmPolicy(result);
      }, (reason) => {
          console.log(reason);
      });
    }
  }

  async updateRpmPolicy(){
    if (!this.rpmForm.invalid) {
      this.rpmSubmitted = true;
      this.rpmPolicy = await this._sensorService.updateRpmPolicy(this.rpmPolicy); 
    }
  }
  
  async addUtilizationPolicy(){
    this.utilizationSubmitted = true;
    if (!this.utilizationForm.invalid) {
      this.utilizationPolicy.beaconId = this.sensorId;
      this.utilizationPolicy = await this._sensorService.addUtilizationPolicy(this.utilizationPolicy); 
    }
  }

  async copyUtilizationPolicy(){
    this.utilizationSubmitted = true;
    if (!this.utilizationForm.invalid) {
      const modalRef = this.modalService.open(SelectSensorModalComponent, {backdrop : 'static'});
      let sensor = await this._sharedService.getSensorBySensorId(this.sensorId);
      let sensors = await this._sharedService.getSensors('?type='+sensor.type+'&used_for='+sensor.used_for);
      let index = sensors.findIndex(s => s.sensor_id == this.sensorId);
      sensors.splice(index,1);
      modalRef.componentInstance.sensors = sensors;
    
      modalRef.result.then(async (result) => {
        let copied_data = {...this.utilizationPolicy};
        delete(copied_data._id);
        result.utilizationPolicy = copied_data;
        await this._sensorService.copyUtilizationPolicy(result);
      }, (reason) => {
          console.log(reason);
      });
    }
  }

  async updateUtilizationPolicy(){
    this.utilizationSubmitted = true;
    if (!this.utilizationForm.invalid) {
      this.utilizationPolicy = await this._sensorService.updateUtilizationPolicy(this.utilizationPolicy); 
    }
  }
  
}
  