import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from "../../../services/shared.service";
@Component({
  selector: 'app-add-part-form',
  templateUrl: './add-part-form.component.html',
  styleUrls: ['./add-part-form.component.scss']
})
export class AddPartFormComponent implements OnInit {
  @Input() selectedParts: any = [];
  parts: any = [];
  inputForm: FormGroup;
  submitted = false;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.setFormValidation();
    let response = await this._sharedService.getParts();
    if (response.length > 0) {
      this.parts = response.map(r => ({ name_number: r.name + ' - ' + r.number, ...r }));
    }
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      parts: [this.selectedParts, [Validators.required]]
    });
  }

  validateForm() {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.inputForm.value.parts.forEach(item => {
        item.quantity = this.getQuantity(item.part_id)
      });
      this.activeModal.close(this.inputForm.value)
    }
  }

  getQuantity(partId) {
    let quantity = 1;
    if (this.selectedParts.length > 0) {
      let p1 = this.selectedParts.find(sp => sp.part_id == partId);
      if (p1) {
        quantity = p1.quantity
      }
    }
    let p2 = this.parts.find(p => p.part_id == partId);
    if (p2.quantity <= 0) {
      quantity = 0;
    }
    return quantity;
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
}