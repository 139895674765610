import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../../services/toaster.service';
import { HelperService } from '../../../services/helper.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit {

  @Input() project: any = {};
  @Input() mappings: any = [];
  @Input() integrations: any = [];
  setting: any = {};
  inputForm: FormGroup;
  submitted = false;
  image: any;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _helperService: HelperService,
    private _toasterService: NGXToastrService,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.setFormValidation();
    await this.setImage();
  }

  async setImage(){
    this.image = await this._helperService.setImage(this.project.image.original, 'project', this.project.project_unique_id);
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      project_id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      storage_limit: ['', [Validators.required]],
      network_id: ['', []],
      integrations: ['', []],
      mappings: ['', []],
      zip_code: ['', [Validators.required]],
      support_email: ['', [Validators.required]],
      description: ['', []]
    });
  }

  validateForm(obj) {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.activeModal.close(obj)
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  async onFileChanged(event: any) {
    this._toasterService.typeInfoStayOnScreen('uploading..', 600000);
    let response = await this._sharedService.uploadImage(event.target.files[0], this._helperService.formatSaveImageUrl(true,true,'project',this.project.project_unique_id));
    if (response) {
      setTimeout(async () => {
        this._toasterService.clearToast();
        if (response) {
          this.project.image.original = response.original;
          this.project.image.thumb = response.thumb;
        } else {
          this.project.image.original = '';
          this.project.image.thumb = '';
        }
        this._toasterService.typeSuccess('Uploaded Successfully');
        await this.setImage();
      }, 3000);
    };
  }

}
