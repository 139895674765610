import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-bearing-number',
  templateUrl: './sensor-bearing-number.component.html',
  styleUrls: ['./sensor-bearing-number.component.scss']
})
export class SensorBearingNumberComponent implements OnInit {
  @Input() sensorId: any;

  bearingNumberForm: FormGroup;
  bearingNumberSubmitted = false;
  bearingNumber: any = {};
  
  constructor(
    private modalService: NgbModal,
    private _sensorService: SensorService,
    private formBuilder: FormBuilder,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.setFormValidation();

    await this.setBearingNumber(); 
  }

  setFormValidation(){
    this.bearingNumberForm = this.formBuilder.group({
      bearing_number: ['', [Validators.required]]
    });
  }

  errorBearingNumber(comtrolName, constraint){
    let errors = this.bearingNumberForm['controls'][comtrolName]['errors'];
    return this.bearingNumberSubmitted && errors && errors[constraint]
  }


  async setBearingNumber(){
    this.bearingNumber = await this._sensorService.getBearingNumber(this.sensorId) || { bearing_number: ''};
  }

  async addBearingNumber(){
    this.bearingNumberSubmitted = true;
    if (!this.bearingNumberForm.invalid) {
      this.bearingNumber.sensor_id = this.sensorId;
      let response = await this._sensorService.addBearingNumber(this.bearingNumber); 
      if(response){
        this.bearingNumber = response;
      }
    }
  }

  async updateBearingNumber(){
    this.bearingNumberSubmitted = true;
      if (!this.bearingNumberForm.invalid) {
      let response = await this._sensorService.updateBearingNumber(this.bearingNumber); 
      if(response){
        this.bearingNumber = response;
      }
    }
  }

  async copyBearingNumber(){
    this.bearingNumberSubmitted = true;
    if (!this.bearingNumberForm.invalid) {
      const modalRef = this.modalService.open(SelectSensorModalComponent, {backdrop : 'static'});
      let sensor = await this._sharedService.getSensorBySensorId(this.sensorId);
      let sensors = await this._sharedService.getSensors('?type='+sensor.type+'&used_for='+sensor.used_for);
      let index =  sensors.findIndex(s => s.sensor_id == this.sensorId);
      sensors.splice(index,1);
      modalRef.componentInstance.sensors = sensors;
    
      modalRef.result.then(async (result) => {
        let copied_data = {...this.bearingNumber};
        delete(copied_data._id);
        result.bearingNumber = copied_data;
        await this._sensorService.copyBearingNumber(result);
      }, (reason) => {
          console.log(reason);
      });
    }
  }
}
  