import { Injectable, InjectionToken } from '@angular/core';
import { ProjectComponent } from '../app/project/project.component';
import { PlantComponent } from '../app/plant/plant.component';
import { LocationComponent } from '../app/location/location.component';
import { AssetComponent } from '../app/asset/asset.component';
import { ComponentComponent } from '../app/component/component.component';
import { SensorComponent } from '../app/sensor/sensor.component';
import { SensorTrendDataComponent } from '../app/sensor/sensor-trend-data/sensor-trend-data.component';
import { DataComparisonComponent } from '../app/data-comparison/data-comparison.component';
import { AlertsDetailsComponent } from '../app/dashboard/alerts-details/alerts-details.component';

@Injectable({ providedIn: 'root' })
export class ComponentLoaderService
{
    
    constructor() {}

    public static getComponent =  {        
        'ProjectComponent': ProjectComponent,
        'PlantComponent': PlantComponent,
        'LocationComponent': LocationComponent,        
        'AssetComponent': AssetComponent,        
        'ComponentComponent': ComponentComponent,        
        'SensorComponent': SensorComponent,
        'SensorTrendDataComponent': SensorTrendDataComponent,      
        'DataComparisonComponent': DataComparisonComponent,
        'AlertsDetailsComponent': AlertsDetailsComponent  
    }

}
