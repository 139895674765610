import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../../services/toaster.service';
import { HelperService } from '../../../services/helper.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-plant-form',
  templateUrl: './plant-form.component.html',
  styleUrls: ['./plant-form.component.scss']
})
export class PlantFormComponent implements OnInit {

  @Input() plant : any = {};
  inputForm: FormGroup;
  
  submitted = false;
  image: any;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _helperService: HelperService,
    private _toasterService: NGXToastrService,
    private _sharedService: SharedService,
  ) { }

  async ngOnInit() { 
    this.setFormValidation();
    await this.setImage();
  }

  async setImage(){
    this.image = await this._helperService.setImage(this.plant.image.original, 'plant', this.plant.plant_id);
  }

  setFormValidation(){
    this.inputForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      latitude: ['', []],
      longitude: ['', []],
      description: ['', []]
    });
  }

  validateForm(obj){
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.activeModal.close(obj)
    }
  }

  error(comtrolName, constraint){
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  async onFileChanged(event: any) {
    this._toasterService.typeInfoStayOnScreen('uploading..', 600000);
    let response = await this._sharedService.uploadImage(event.target.files[0], this._helperService.formatSaveImageUrl(true,true,'plant',this.plant.plant_id));
    if (response) {
      setTimeout(async () => {
        this._toasterService.clearToast();
        if (response) {
          this.plant.image.original = response.original;
          this.plant.image.thumb = response.thumb;
        } else {
          this.plant.image.original = '';
          this.plant.image.thumb = '';
        }
        this._toasterService.typeSuccess('Uploaded Successfully');
        await this.setImage();
      }, 3000);
    };
  }
  
}