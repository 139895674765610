import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";

import { LocalstorageService } from '../../../services/localstorage.service';
import { DashboardService } from '../dashboard.service';
import { SharedService } from '../../../services/shared.service';
import { DashboardFormComponent } from '../dashboard-form/dashboard-form.component';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  dashboards: any = [];
  selectedDashboard: any = {};
  dashboard: any = {};
  constructor(
    private _dashboardService: DashboardService,
    private _sharedService: SharedService,
    private modalService: NgbModal,
    private router: Router,
    private _localstorageService: LocalstorageService
  ) { }

  async ngOnInit() {
    this.selectedDashboard = this._localstorageService.getDashboardData();
    this.dashboards = await this._sharedService.getDashboards();
  }

  initializeDashboard(){
    this.dashboard = {
      dashboard_components: [],
      active: true
    }
  }

  async openDashboardFormModal(dashboardId = 'add_new_dashboard') {
    this.initializeDashboard();
    if(dashboardId != 'add_new_dashboard'){
      this.dashboard = await this.getDashboardBydashboardId(dashboardId);
    }
    const modalRef = this.modalService.open(DashboardFormComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.dashboard = this.dashboard;
    modalRef.result.then(async (result) => {
      if(result.action == 'add'){
        this.dashboard = await this.addDashboard(result.dashboard);
        if(this.dashboard){
          this.dashboards.push(this.dashboard);
        }
      } else {
        this.dashboard = await this.updateDashboard(result.dashboardId, result.dashboard);
        if(this.dashboard){
          if(this.dashboard.dashboard_id == this.selectedDashboard.dashboard_id){
            this.selectedDashboard = this.dashboard;
            this.resetDashboard(this.dashboard);
          }
          let index = this.dashboards.findIndex(p => p.dashboard_id == this.dashboard.dashboard_id);
          this.dashboards[index] = this.dashboard;
        }
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal(dashboardId) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.heading = "Delete Dashboard";
    modalRef.componentInstance.content = "Are you sure you want to delete this dashboard?";

    modalRef.result.then(async (result) => {
      this.dashboard = await this.deleteDashboard(dashboardId);
      if(this.dashboard){
        let index = this.dashboards.findIndex(p => p.dashboard_id == this.dashboard.dashboard_id);
        this.dashboards.splice(index,1);
        
        if(this.dashboard.dashboard_id == this.selectedDashboard.dashboard_id){
          this.switchDashboard(this.dashboards[0]);
        }
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addDashboard(dashboard){
    return await this._dashboardService.addDashboard(dashboard); 
  }

  async updateDashboard(dashboardId,dashboard){
    return await this._dashboardService.updateDashboard(dashboardId,dashboard);
  }

  async deleteDashboard(dashboardId){
    return await this._dashboardService.deleteDashboard(dashboardId);
  }

  async getDashboardBydashboardId (dashboardId) {
    return  await this._dashboardService.getDashboardBydashboardId(dashboardId);
  }

  showDashboard(){
    this.router.navigate(['/dashboard']);
  }

  switchDashboard(dashboard){
    this._localstorageService.setDashboardData(dashboard);
      this.selectedDashboard = dashboard;
      window.location.reload();
  }

  resetDashboard(dashboard){
    this._localstorageService.unsetDashboardData();
    this._localstorageService.setDashboardData(dashboard);
  }
  
}
