import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { WorkOrderService } from "../work-order.service";
import { NGXToastrService } from '../../../services/toaster.service';
import { SharedService } from '../../../services/shared.service';

@Component({
    selector: "app-work-order-form",
    templateUrl: "./work-order-form.component.html",
    styleUrls: ["./work-order-form.component.scss"],
})

export class WorkOrderFormComponent implements OnInit {
    @Input() workOrder: any = {};
    @Input() priorities: any = [];
    @Input() users: any = [];
    @Input() teams: any = [];
    assignedTo = '';
    flags: any = [];
    components: any = [];
    inputForm: FormGroup;
    submitted = false;
    priorityBlock = false;
    priorityData: any = {};
    name = 'Angular 4';
    urls = [];

    constructor(
        public _workOrderService: WorkOrderService,
        public _sharedService: SharedService,
        public activeModal: NgbActiveModal,
        private formBuider: FormBuilder,
        private _toasterService: NGXToastrService
    ) { }

    ngOnInit(): void {
        this.setFormValidation();
        this.initializePriorityData();
        if (this.workOrder.users.length > 0) {
            this.assignedTo = 'user';
        } else if (this.workOrder.team_id) {
            this.assignedTo = 'team';
        } else {
            this.assignedTo = '';
        }
    }

    setFormValidation() {
        this.inputForm = this.formBuider.group({
            name: ["", [Validators.required]],
            priority_id: ["", [Validators.required]],
            date: ["", [Validators.required]],
            time: ["", [Validators.required]],
            type: ["", [Validators.required]],
            status: ["", [Validators.required]],
            users: ["", []],
            team_id: ["", []]
        });
    }

    error(controlName, constraint) {
        let errors = this.inputForm["controls"][controlName]["errors"];
        return this.submitted && errors && errors[constraint];
    }

    validateForm(obj) {
        this.submitted = true;
        if (this.inputForm.invalid) {
            console.log("please fill form completely");
        } else {
            obj.data.instructions = this.filterInstructions(obj.data.instructions);
            this.activeModal.close(obj);
        }
    }

    filterInstructions(instructions) {
        if (instructions.length > 0) {
            return instructions.filter(i => i.text)
        }
        return [];
    }

    toggleAddPriorityBlock() {
        this.priorityBlock = this.priorityBlock ? false : true;
    }

    initializePriorityData() {
        this.priorityData = {
            name: '',
            color: '',
        }
    }

    async addPriority() {
        if (this.priorityData.name != '' && this.priorityData.color != '') {
            this.priorityData = await this._sharedService.addPriority(this.priorityData);
            if (this.priorityData) {
                this.priorities.push(this.priorityData);
            }
            this.initializePriorityData();
            this.toggleAddPriorityBlock();
        } else {
            this._toasterService.typeError('Please enter name and select color')
        }
    }

    addInstruction() {
        this.workOrder.instructions.push({
            text: '',
            fulfil: false
        });
    }

    removeInstruction(index) {
        this.workOrder.instructions.splice(index, 1)
    }

    setMultiselectSetting(idField, textField) {
        return {
            singleSelection: false,
            idField: idField,
            textField: textField,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
}
