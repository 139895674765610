
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService) { 
        this.toastr.clear()
     }

    // Success Type
    typeSuccess(successMessage) {
        this.toastr.success(successMessage, '',  { positionClass: 'toast-bottom-right', closeButton:true, enableHtml: true });
    }

    // Success Type
    typeInfo(infoMessage) {
        this.toastr.info(infoMessage, '', { positionClass: 'toast-bottom-right', closeButton:true, enableHtml: true });
    }

    // Success Type
    typeWarningStayOnScreen(infoMessage, timeout) {
        this.toastr.warning('<i class="zmdi zmdi-spinner zmdi-hc-spin"></i> '+infoMessage,'', { "timeOut": timeout, positionClass: 'toast-bottom-right', closeButton:true, enableHtml: true });
    }

    // Success Type
    typeInfoStayOnScreen(infoMessage, timeout) {
        this.toastr.info(infoMessage,'', { "timeOut": timeout, positionClass: 'toast-bottom-right', closeButton:true, enableHtml: true });
    }

    // Success Type
    typeWarning(warningMessage) {
        this.toastr.warning(warningMessage, '', { positionClass: 'toast-bottom-right', closeButton:true, enableHtml: true });
    }

    // Success Type
    typeError(errMessage) {
        this.toastr.error(errMessage, '', { positionClass: 'toast-bottom-right', closeButton:true, enableHtml: true });
    }

    // Custom Type
    typeCustom() {
        this.toastr.success('<span class="text-danger">Message in red.</span>', null, { enableHtml: true });
    }

    //Progress bar
    progressBar() {
        this.toastr.info('We do have the Kapua suite available.', 'Turtle Bay Resort', { "progressBar": true });
    }

    // Timeout
    timeout() {
        this.toastr.error('I do not think that word means what you think it means.', 'Timeout!', { "timeOut": 2000 });
    }

    //Dismiss toastr on Click
    dismissToastOnClick() {
        this.toastr.info('We do have the Kapua suite available.', 'Turtle Bay Resort', { "tapToDismiss": true });
    }
    // Remove current toasts using animation
    clearToast() {
        this.toastr.clear()
    }

    // Show close button
    showCloseButton() {
        this.toastr.info('Have fun storming the castle!', 'Miracle Max Says', { closeButton: true });
    }
    // Enable  HTML
    enableHtml() {
        this.toastr.info('<i>Have fun <b>storming</b> the castle!</i>', 'Miracle Max Says', { enableHtml: true });
    }
    // Title Class
    titleClass() {
        this.toastr.info('Have fun storming the castle!', 'Miracle Max Says', { titleClass: 'h3' });
    }
    // Message Class
    messageClass() {
        this.toastr.info('Have fun storming the castle!', 'Miracle Max Says', { messageClass: 'text-uppercase' });
    }

}