import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../../services/toaster.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import Constants from '../../../services/constants.service';

import { SensorService } from '../sensor.service'
import { SharedService } from '../../../services/shared.service';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-sensor-form',
  templateUrl: './sensor-form.component.html',
  styleUrls: ['./sensor-form.component.scss']
})
export class SensorFormComponent implements OnInit {

  @Input() sensor: any = {};
  @Input() sensorList: any = [];
  @Input() mappings: any = [];
  @Input() integrations: any = [];
  @Input() tabs: any = [];
  @Input() groups: any = [];
  @Input() usedFor: any = [];
  @Input() components: any = [];
  @Input() samples: any = [];

  units: any = [];
  inputForm: FormGroup;
  submitted = false;
  image: any;
  groupBlock = false;
  sensorGroupData: any = {};
  editGroup = false;
  usedForBlock = false;
  usedForData: any = {};
  unitBlock = false;
  unitData: any = {};


  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _sharedService: SharedService,
    private _sensorService: SensorService,
    private _helperService: HelperService,
    private _toasterService: NGXToastrService,
    private _localstorageService: LocalstorageService,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() {
    this.setFormValidation();
    this.initializeGroupData();
    this.initializeUsedForData();
    this.initializeUnitData();
    await this.setImage();
  }

  
  async setImage(){
    this.image = await this._helperService.setImage(this.sensor.image.original, 'sensor', this.sensor.sensor_unique_id);
  }

  substitute(url, data) {
    let formattedData = url.replace(/{(\w+)}/g, function (_, key) {
      return data[key] ? data[key] : `{${key}}`;
    });
    return formattedData;
  }

  setSampleData(event) {
    let type = event.target.value;
    let sampleData: any = this.samples.find(s => s.type == type).sample_data;
    this.sensor.end_point = sampleData.end_point || '';
    this.sensor.tabs = sampleData.tabs;
    this.sensor.data_source = sampleData.data_source;
    let project = this._localstorageService.getProjectData();
    this.sensor.data_source.url = this.substitute(this.sensor.data_source.url, project);
    this._toasterService.typeSuccess('Please fill remaining fields if any!');
  }

  initializeGroupData() {
    this.sensorGroupData = {
      name: '',
      active: true
    }
    this.editGroup = false;
  }

  initializeUsedForData() {
    this.usedForData = {
      name: '',
      active: true
    }
  }

  initializeUnitData() {
    this.unitData = {
      used_for: this.sensor.used_for,
      name: '',
      active: true
    }
  }

  toggleSensorGroupBlock(type='add') {
    this.initializeGroupData();
    this.groupBlock = this.groupBlock ? false : true;
    if(type == 'edit'){
      this.sensorGroupData.name = this.sensor.group;
      this.editGroup = true;
    }
  }

  toggleAddUsedForBlock() {
    this.usedForBlock = this.usedForBlock ? false : true;
  }

  toggleAddUnitBlock() {
    this.unitBlock = this.unitBlock ? false : true;
  }

  disableField() {
    let user = this._localstorageService.getUserData();
    return user.role_id != Constants.role.SUPER_ADMIN_ROLE_ID && user.role_id != Constants.role.VENDOR_ROLE_ID;
  }

  async copySensor() {
    this.submitted = true;
    if (!this.inputForm.invalid) {
      const modalRef = this.modalService.open(SelectSensorModalComponent, { backdrop: 'static' });
      let sensors = await this._sharedService.getSensors('?type='+this.sensor.type+'&used_for='+this.sensor.used_for);
      let index = sensors.findIndex(s => s.sensor_id == this.sensor.sensor_id);
      sensors.splice(index, 1);
      modalRef.componentInstance.sensors = sensors;

      modalRef.result.then(async (result) => {
        let copied_data = {
          data_source: this.sensor.data_source,
          group: this.sensor.group,
          off_set: this.sensor.off_set,
          scaling: this.sensor.scaling,
          tabs: this.sensor.tabs,
          used_for: this.sensor.used_for,
          unit: this.sensor.unit
        };
        result.sensor = copied_data;
        await this._sensorService.copySensor(result);
      }, (reason) => {
        console.log(reason);
      });
    }
  }

  async addSensorGroup() {
    if (this.sensorGroupData.name != '') {
      this.sensorGroupData = await this._sensorService.addSensorGroup(this.sensorGroupData);
      if (this.sensorGroupData) {
        this.groups.push(this.sensorGroupData);
      }
      this.initializeGroupData();
      this.toggleSensorGroupBlock();
    } else {
      this._toasterService.typeError('Please enter group name');
    }
  }

  async editSensorGroup() {
    if (this.sensorGroupData.name != '') {
      let currentSensorGroupData = this.groups.find((item)=>item.name == this.sensor.group);
      this.sensorGroupData.sensor_group_id = currentSensorGroupData.sensor_group_id;
      this.sensorGroupData.previousName = this.sensor.group;
      let response = await this._sensorService.editSensorGroup(this.sensorGroupData);
      if (response) {
        currentSensorGroupData.name = response.name;
        this.sensor.group = response.name;
      }
      this.initializeGroupData();
      this.toggleSensorGroupBlock();
    } else {
      this._toasterService.typeError('Please enter group name');
    }
  }

  async getUnits() {
    if (this.sensor.used_for != '') {
      this.units = await this._sensorService.getunit(this.sensor.used_for);
    }
  }

  async addUsedFor() {
    if (this.usedForData.name != '') {
      this.usedForData = await this._sensorService.addUsedFor(this.usedForData);
      if (this.usedForData) {
        this.usedFor.push(this.usedForData);
      }
      this.initializeUsedForData();
      this.toggleAddUsedForBlock();
    } else {
      this._toasterService.typeError('Please enter used for');
    }
  }

  async addUnit() {
    if (this.unitData.name != '' && this.sensor.used_for) {
      this.unitData.used_for = this.sensor.used_for;
      this.unitData = await this._sensorService.addUnit(this.unitData);
      if (this.unitData) {
        this.units.push(this.unitData);
      }
      this.initializeUnitData();
      this.toggleAddUnitBlock();
    } else {
      this._toasterService.typeError('Select used for and enter unit')
    }
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      sensor_list: ['', []],
      sensor_id: ['', { disabled: true }, []],
      name: ['', []],
      off_set: ['', [Validators.required]],
      scaling: ['', [Validators.required]],
      used_for: ['', [Validators.required]],
      unit: ['', [Validators.required]],
      component_id: ['', [Validators.required]],
      integration_id: ['', [Validators.required]],
      mapping_id: ['', [Validators.required]],
      url: ['', [Validators.required]],
      method: ['', [Validators.required]],
      is_url_complete: ['', [Validators.required]],
      tabs: ['', [Validators.required]],
      group: ['', [Validators.required]],
      end_point: ['', []]
    });
  }

  validateForm(obj) {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.activeModal.close(obj)
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  async onFileChanged(event: any) {
    this._toasterService.typeInfoStayOnScreen('uploading..', 600000);
    let response = await this._sharedService.uploadImage(event.target.files[0], this._helperService.formatSaveImageUrl(true,true,'sensor',this.sensor.sensor_unique_id));
    if (response) {
      setTimeout(async () => {
        this._toasterService.clearToast();
        if (response) {
          this.sensor.image.original = response.original;
          this.sensor.image.thumb = response.thumb;
        } else {
          this.sensor.image.original = '';
          this.sensor.image.thumb = '';
        }
        this._toasterService.typeSuccess('Uploaded Successfully');
        await this.setImage();
      }, 3000);
    };
  }

}
