import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '../project.service';
import { SharedService } from '../../../services/shared.service';

import { ProjectFormComponent } from '../project-form/project-form.component';
import { ModalComponent } from '../../modal/modal.component';
import Constants from '../../../services/constants.service';
import { NGXToastrService } from '../../../services/toaster.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from 'src/services/helper.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit { 
  project: any = {};
  flags: any = [];
  selectedProject: any = {};
  projects: any = [];
  pinData: any;
  superProjectUniqueId: any;
  
  constructor(
    private _projectService: ProjectService,
    private _sharedService: SharedService,
    private _localstorageService: LocalstorageService,
    private _toasterService: NGXToastrService,
    private _helperService: HelperService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    this.pinData = {
      component_name: 'ProjectComponent'
    };
    this.superProjectUniqueId = Constants.project.SUPER_PROJECT_UNIQUE_ID;
    this.projects = await this._sharedService.getProjects();
  }

  initializeProject(){
    this.project = {
      image: {
        original: '',
        thumb: ''
      },
      support_email: 'RAMSSupport@arewind.com',
      active: true
    }
  }

  async openProjectFormModal(projectUniqueId = 'add_new_project') {
    this.initializeProject();
    if(projectUniqueId != 'add_new_project'){
      this.project = await this.getProjectByProjectUniqueId(projectUniqueId);
    }
    const modalRef = this.modalService.open(ProjectFormComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.project = this.project;
    modalRef.componentInstance.mappings = await this._sharedService.getMappings();
    modalRef.componentInstance.integrations = await this._sharedService.getIntegrations();
    modalRef.result.then(async (result) => {
      if(result.action == 'add'){
        this.project = await this.addProject(result.project);
        result.setting.project_unique_id = this.project.project_unique_id;
        await this.addProjectSetting(result.setting);
        if(this.project){
          this.projects.push(this.project);
        }
        window.location.reload();
      } else {
        this.project = await this.updateProject(result.projectUniqueId, result.project);
        if(this.project){
          let index = this.projects.findIndex(m => m.project_unique_id == this.project.project_unique_id);
          this.projects[index] = this.project;
        }
        this.selectedProject = this._localstorageService.getProjectData();
        if(this.selectedProject.project_unique_id == this.project.project_unique_id){
          this._localstorageService.setProjectData(this.project);
        }
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal(projectUniqueId) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.heading = "Delete Company";
    modalRef.componentInstance.content = "Are you sure you want to delete this company? It will delete the plants, locations, assets, components and sensors of this company.";

    modalRef.result.then(async (result) => {
      this.project = await this.deleteProject(projectUniqueId);
      if(this.project){
        let index = this.projects.findIndex(p => p.project_unique_id == this.project.project_unique_id);
        this.projects.splice(index,1);
        window.location.reload();
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addProject(project){
    return await this._projectService.addProject(project); 
  }

  async addProjectSetting(projectSetting){
    return await this._projectService.addProjectSetting(projectSetting); 
  }

  async updateProject(projectUniqueId,project){
    return await this._projectService.updateProject(projectUniqueId,project);
  }

  async deleteProject(projectUniqueId){
    return await this._projectService.deleteProject(projectUniqueId);
  }

  async getProjectByProjectUniqueId (projectUniqueId) {
    return  await this._projectService.getProjectByProjectUniqueId(projectUniqueId);
  }

  creatReport(projectUniqueId){ 
    this._helperService.performOperationWithToasts(async () => {
      return await this._sharedService.createReport('project', projectUniqueId);
    });
  }

}
