<div>
    <div class="mb-4" *ngIf="obj.type == 'plant'">
        <app-location-list  [plant]="obj.resource"></app-location-list>
    </div>
    <div class="mb-4" *ngIf="obj.type == 'location'">
        <app-asset-list  [location]="obj.resource"></app-asset-list>
    </div>
    <div class="mb-4" *ngIf="obj.type == 'asset'">
        <app-component-list  [asset]="obj.resource"></app-component-list>
    </div>
    <div class="mb-4" *ngIf="obj.type == 'component'">
        <app-sensor-list  [component]="obj.resource"></app-sensor-list>
    </div>
</div>

