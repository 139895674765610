<!-- Breadcrumb-->
<div class="row pt-2 pb-2">
	<div class="col-sm-9">
		<h4 class="page-title">Chart</h4>
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href="javaScript:;">RAMS</a>
			</li>
			<li class="breadcrumb-item"><a href="javaScript:;">Plant</a>
			</li>
		</ol>
	</div>
	<div class="col-sm-3">
		<div ngbDropdown class="btn-group float-sm-right">
			<button type="button" class="btn btn-light"><i class="fa fa-cog mr-1"></i> Setting</button>
			<button type="button" class="btn btn-light" id="dropdownBasic5" ngbDropdownToggle> <span class="caret"></span>
			</button>
			<div ngbDropdownMenu aria-labelledby="dropdownBasic5"> 
        		<a href="javaScript:;" ngbDropdownItem>Action</a>
				<a href="javaScript:;" ngbDropdownItem>Another action</a>
				<a href="javaScript:;" ngbDropdownItem>Something else here</a>
        		<div class="dropdown-divider"></div> 
        		<a href="javaScript:;" ngbDropdownItem>Separated link</a>
			</div>
		</div>
	</div>
</div>
<!-- End Breadcrumb-->