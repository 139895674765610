import { Component, OnInit, Input } from '@angular/core';

//Other Imports
import { SensorService } from '../sensor.service'

@Component({
  selector: 'app-sensor-utilization',
  templateUrl: './sensor-utilization.component.html',
  styleUrls: ['./sensor-utilization.component.scss']
})
export class SensorUtilizationComponent implements OnInit {
  @Input() sensor: any;
  constructor(
    private _sensorService: SensorService
  ) { }

  async ngOnInit() {
  }
  
}
  