import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-mount-position',
  templateUrl: './sensor-mount-position.component.html',
  styleUrls: ['./sensor-mount-position.component.scss']
})
export class SensorMountPositionComponent implements OnInit {
  @Input() sensorId: any;
  axis_model = {};
  axisSettings:any = {};
  axis = [
    { value: 1, text: "Top mount, parallel (default)" },
    { value: 2, text: "Top mount perpinduclar" },
    { value: 3, text: "End mount parallel" },
    { value: 4, text: "End mount perpindicular" }
  ];

  constructor(
    private _sensorService: SensorService,
    private modalService: NgbModal,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.axisSettings = await this._sensorService.getAxisSettings(this.sensorId) || {};
    if(this.axisSettings.namedAs) {
      this.axis_model = this.axisSettings.namedAs;
    } else {
      this.axis_model = 1;
    }
  }

  async addAxisSettings() {

    let formatedAxisSettings: any = this.formateAxisShit(this.axis_model);
    formatedAxisSettings.sensor_id = this.sensorId;
    formatedAxisSettings.namedAs = this.axis_model;

    this.axisSettings = await this._sensorService.addAxisSettings(formatedAxisSettings);
    if(this.axisSettings) {
      this.axis_model = this.axisSettings.namedAs;
    } else {
      this.axis_model = 1;
    }
  }

  async updateAxisSettings() {
    let formatedAxisSettings: any = this.formateAxisShit(this.axis_model);
    formatedAxisSettings.sensor_id = this.sensorId;
    formatedAxisSettings.namedAs = this.axis_model;

    this.axisSettings = await this._sensorService.updateAxisSettings(formatedAxisSettings);
    if(this.axisSettings) {
      this.axis_model = this.axisSettings.namedAs;
    } else {
      this.axis_model = 1;
    }
  }

  async copyAxisSettings(){
    const modalRef = this.modalService.open(SelectSensorModalComponent, {backdrop : 'static'});
    let sensor = await this._sharedService.getSensorBySensorId(this.sensorId);
    let sensors = await this._sharedService.getSensors('?type='+sensor.type+'&used_for='+sensor.used_for);
    let index =  sensors.findIndex(s => s.sensor_id == this.sensorId);
    sensors.splice(index,1);
    modalRef.componentInstance.sensors = sensors;
   
    modalRef.result.then(async (result) => {
      let copied_data = {...this.axisSettings};
      delete(copied_data._id);
      result.axisSettings = copied_data;
      await this._sensorService.copyAxisSettings(result);
    }, (reason) => {
        console.log(reason);
    });
  }

  formateAxisShit(id) {
    switch (id) {
      case 1:
        return {
          axial: "HIGH_RES#Y_ACCEL",
          horizontal: "HIGH_RES#X_ACCEL",
          radial: "HIGH_RES#Z_ACCEL"
        }
        break;
      case 2:
        return {
          axial: "HIGH_RES#X_ACCEL",
          horizontal: "HIGH_RES#Y_ACCEL",
          radial: "HIGH_RES#Z_ACCEL"
        }
        break;
      case 3:
        return {
          axial: "HIGH_RES#Z_ACCEL",
          horizontal: "HIGH_RES#Y_ACCEL",
          radial: "HIGH_RES#X_ACCEL"
        }
        break;
      case 4:
        return {
          axial: "HIGH_RES#Z_ACCEL",
          horizontal: "HIGH_RES#X_ACCEL",
          radial: "HIGH_RES#Y_ACCEL"
        }
        break;
      default:
        return {
          axial: "HIGH_RES#Y_ACCEL",
          horizontal: "HIGH_RES#X_ACCEL",
          radial: "HIGH_RES#Z_ACCEL"
        }
    }
  }

}
