import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';
import { FileUploadModule } from 'ng2-file-upload';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ComponentRoutingModule } from "./component-routing.module";
import { ComponentComponent } from './component.component';
import { ComponentFormComponent } from './component-form/component-form.component';
import { ComponentService } from './component.service';
import { ComponentListComponent } from './component-list/component-list.component';
import { ComponentModalComponent } from './component-modal/component-modal.component';

import { PinModule } from '../pin/pin.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';

import { SensorModule } from '../sensor/sensor.module';

@NgModule({
    imports: [
        CommonModule,
        ComponentRoutingModule,
        NgbModule,
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule,
        SensorModule,
        PinModule,
        ExpandScreenModule,
        FileUploadModule
    ], 
    declarations: [ 
        ComponentComponent,
        ComponentListComponent,
        ComponentModalComponent,
        ComponentFormComponent
    ],
    providers: [
        ComponentService
    ],
    exports: [
        ComponentComponent,
        ComponentListComponent
    ]

    
})
export class ComponentModule { }
