import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  options: any = {};
  @Input() series: any = [];
  @Input() xaxis: any = {};
  @Input() yaxis: any = {};
  @Input() annotations: any = {};
  @Input() curve: any = 'smooth';
  @Input() tooltip: any = {
    enabled: true,
    theme: 'dark',
    x : {
      format: "dd MMM yyyy hh:mm:tt"
    }
  };
  @Input() grid: any = {
    show: true
  };
  constructor() { }

  ngOnInit(): void {
    this.options = {
      chart: {
        height: 300,
        type: 'line',   
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true || '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        },
      },
      stroke: {
        width: 2,
        curve: this.curve,
      },
      markers: {
        size: 1
      },
      labels:{
        show: true
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetY: 20,
      },
      colors: ["#000000","#77B6EA", "#E94335", "#7FF256","#DFFF00","#FFBF00","#FF7F50","#DE3163","#9FE2BF","#40E0D0","#6495ED","#CCCCFF","#800080","#FF00FF","#008080","#808000"]
    }
  }
  
}
