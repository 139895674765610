import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gateways-detail',
  templateUrl: './gateways-detail.component.html',
  styleUrls: ['./gateways-detail.component.scss']
})
export class GatewaysDetailComponent implements OnInit {

  @Input() gatewaysData: any = {};

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {

  }

  openGoogleMap(latitude, longitude) {
    var url = 'http://www.google.com/maps/place/' + latitude + ',' + longitude;
    window.open(url, '_blank', 'location=yes');
  }

}
