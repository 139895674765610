import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NGXToastrService } from '../../../services/toaster.service';
import { HelperService } from '../../../services/helper.service';
import { PartService } from "../part.service";
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: "app-part-form",
  templateUrl: "./part-form.component.html",
  styleUrls: ["./part-form.component.scss"],
})
export class PartFormComponent implements OnInit {
  @Input() part: any = {};
  @Input() categories: any = [];
  @Input() vendors: any = [];
  @Input() assets: any = [];
  components: any = [];
  image: any;
  inputForm: FormGroup;
  submitted = false;
  categoryData: any = [];
  categoryBlock = false;
  partExist:any;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuider: FormBuilder,
    private _toasterService: NGXToastrService,
    private _helperService: HelperService,
    public _sharedService: SharedService,
    public _partService: PartService
  ) { }
  async ngOnInit() {
    this.setFormValidation();
    this.initializeCategoryData();
    this.image = await this._helperService.setImage(this.part.image.original);
  }

  setFormValidation() {
    this.inputForm = this.formBuider.group({
      name: ["", [Validators.required]],
      number: ["", [Validators.required]],
      price: ["", [Validators.required]],
      quantity: ["", [Validators.required]],
      alert_amount: ["", [Validators.required]]

    });
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  error(controlName, constraint) {
    let errors = this.inputForm["controls"][controlName]["errors"];
    return this.submitted && errors && errors[constraint];
  }

  validateForm(obj) {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Please fill part form completely");
    } else {
      this.activeModal.close(obj);
    }
  }

  async getAssetsComponents() {
    this.part.components = [];
    if(this.part.assets.length > 0){
      this.components = await this._sharedService.getAssetsComponents(this.part.assets);
    }
  }

  async onFileChanged(event: any) {
    this._toasterService.typeInfoStayOnScreen('uploading..', 600000);
    let response = await this._sharedService.uploadImage(event.target.files[0], this._helperService.formatSaveImageUrl(true,true));
    if (response) {
      setTimeout(async () => {
        this._toasterService.clearToast();
        if (response) {
          this.part.image.original = response.original;
          this.part.image.thumb = response.thumb;
        } else {
          this.part.image.original = '';
          this.part.image.thumb = '';
        }
        this._toasterService.typeSuccess('Uploaded Successfully');
        this.image = await this._helperService.setImage(this.part.image.original);
      }, 3000);
    };
  }

  toggleAddCategoryBlock(){
    this.categoryBlock = this.categoryBlock ? false : true;
  }

  initializeCategoryData(){
    this.categoryData = {
      name: ''
    }
  }

  async addCategory(){
    if(this.categoryData.name != ''){
      this.categoryData = await this._partService.addCategory(this.categoryData);
      if(this.categoryData){
        this.categories.push(this.categoryData);
      }
      this.initializeCategoryData();
      this.toggleAddCategoryBlock();
    } else {
      this._toasterService.typeError('Please enter category name');
    }
  }
  
  async getPartByNumber() { 
    this.partExist = await this._partService.getPartByNumber(this.part.number);
  }
}