import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class WorkOrderService {
    constructor(private _httpClient: HttpClient) { }

    updateWorkOrder(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put((<any>window).appBaseUrl + "/work-order/", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deleteWorkOrder(workOrderId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete((<any>window).appBaseUrl + "/work-order/" + workOrderId)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getWorkOrderById(workOrder_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/work-order/" + workOrder_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    computeClass(value) {
        if (value == 1) {
            return 'bg-warning';
        } else if (value > 1) {
            return 'bg-success';
        } else if (value <= 0) {
            return 'bg-danger';
        }
    }

    calculateTimeSum(timeLogs) {
        let hours = 0;
        let minutes = 0;
        if (timeLogs.length > 0) {
            timeLogs.forEach(item => {
                hours += item.hours;
                minutes += item.minutes;
            });
        }
        if (minutes >= 60) {
            hours += Number(Math.floor(minutes / 60));
            minutes = Number(Math.floor(minutes % 60));
        }
        return (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes)
    }

    calculateProgress(instructions) {
        let progress = 0;
        if (instructions.length > 0) {
            let interval = 100 / instructions.length;
            for (let i = 0; i < instructions.length; i++) {
                if (instructions[i].fulfil) {
                    progress = progress + interval;
                }
            }
        }
        return progress;
    }

}