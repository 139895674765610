import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild
{
    constructor(
        private _router: Router,
        private _localstorageService: LocalstorageService
    ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check(state.url);
    }

    private _check(redirectURL: string): Observable<boolean>
    {
        let route = redirectURL.replace(/^\/+/g, '');
        let menuItems = this._localstorageService.getUserData().menu_items;
        menuItems.push('dashboard', 'dashboard/alerts-details');
        if(menuItems.includes(route)){
            return of(true);
        } else {
            this._router.navigate(['error-404']);
            return of(false);
        }            
    }
}
