<div class="modal-header">
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&larr;</span></button>
    <h5 class="modal-title">Sensor Detail ({{ sensor.name }})</h5>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div [ngClass]="{'w-60-pull-left': sensorOrigionalImageSrc}">
                        <ul class="nav nav-tabs nav-tabs-primary">
                            <li class="nav-item" *ngFor="let tab of sensorTabs">
                                <a class="nav-link" data-toggle="tab" (click)="makeActive(tab.tab_id)" [ngClass]="{'active': tab.tab_id == activeTab}" href="#{{ tab.tab_id }}">
                                    <span>{{ tab.tab_text }}</span>
                                </a>
                            </li>
                        </ul>
                        <!-- Tab panes -->
                        <div class="tab-content tab-details">
                            <div id="tab-alerts" *ngIf="active('tab-alerts')" class="container tab-pane" [ngClass]="{'active': active('tab-alerts')}">
                                <app-sensor-alert [sensor] ='sensor'></app-sensor-alert>
                            </div> 
                            <div id="tab-trend-data" *ngIf="active('tab-trend-data')" class="container tab-pane" [ngClass]="{'active': active('tab-trend-data')}">
                                <app-sensor-trend-data [sensor] ='sensor'></app-sensor-trend-data>
                            </div> 
                            <div id="tab-utilization" *ngIf="active('tab-utilization')" class="container tab-pane" [ngClass]="{'active': active('tab-utilization')}">
                                <app-sensor-utilization [sensor] ='sensor'></app-sensor-utilization>
                            </div> 
                            <div id="tab-fft" *ngIf="active('tab-fft')" class="container tab-pane" [ngClass]="{'active': active('tab-fft')}">
                                <app-sensor-fft [sensor] ='sensor'></app-sensor-fft>
                            </div> 
                            <div id="tab-treon-fft" *ngIf="active('tab-treon-fft')" class="container tab-pane" [ngClass]="{'active': active('tab-treon-fft')}">
                                <app-sensor-treon-fft [sensor] ='sensor'></app-sensor-treon-fft>
                            </div> 
                            <div id="tab-info" *ngIf="active('tab-info')" class="container tab-pane" [ngClass]="{'active': active('tab-info')}">
                                <app-sensor-info [sensor] ='sensor'></app-sensor-info>
                            </div> 
                            <div id="tab-health" *ngIf="active('tab-health')" class="container tab-pane" [ngClass]="{'active': active('tab-health')}">
                                <app-sensor-health [sensor] ='sensor'></app-sensor-health>
                            </div>
                        </div>
                    </div>
                    <div class="w-40-pull-left" *ngIf="sensorOrigionalImageSrc">
                        <h6 class="text-uppercase text-center p-3 m-0 text-dark">{{ sensor.name }}</h6>
                        <img src="{{ sensorOrigionalImageSrc }}" width="100%" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End Row-->  