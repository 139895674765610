import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { AddCommentFormComponent} from "../shared/add-comment-form/add-comment-form.component";
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { ResourceHierarchyComponent } from './resource-hierarchy/resource-hierarchy.component';
import { AssignToComponent } from './assign-to/assign-to.component'

import { ProjectModule } from '../project/project.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SendMailComponent } from './send-mail/send-mail.component';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ColorSwitcherComponent,
        NgbModule,
        SendMailComponent,
        AssignToComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        PerfectScrollbarModule,
        ProjectModule,
        DashboardModule,
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ColorSwitcherComponent,
        SendMailComponent,
        AddCommentFormComponent,
        ResourceHierarchyComponent,
        AssignToComponent

        
    ],
    providers: [ ],
})
export class SharedModule { }
