import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-stepper-chart',
  templateUrl: './stepper-chart.component.html',
  styleUrls: ['./stepper-chart.component.scss']
})
export class StepperChartComponent implements OnInit {
  options: any = {};
  @Input() series: any = [];
  @Input() xaxis: any = {};
  @Input() yaxis: any = {};
  @Input() annotations: any = {};
  @Input() unit: any;
  @Input() tooltip: any = {
    enabled: true,
    theme: 'dark',
    x: {
      format: "dd MMM yyyy hh:mm:ss:tt"
    }
  };
  @Input() grid: any = {
    show: true
  };
  constructor() {
    this.options = {
      chart: {
        height: 300,
        type: 'line',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true || '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom'
        },
      },
      stroke: {
        width: 2,
        curve: 'stepline',
        lineCap: "sqaure"
      },
      markers: {
        size: 1
      },
      labels: {
        show: true
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        offsetY: 20
      },
      colors: ["#000000","#77B6EA", "#E94335", "#7FF256","#DFFF00","#FFBF00","#FF7F50","#DE3163","#9FE2BF","#40E0D0","#6495ED","#CCCCFF","#800080","#FF00FF","#008080","#808000"]
    }
  }

  ngOnInit() {
    const unit = this.unit;
    this.tooltip.y = {
      formatter: function(value) {
        const labels = {
          'Open-Closed': { '0': 'Closed', '1': 'Open' },
          'Trouble-NoTrouble': { '0': 'No Trouble', '1': 'Trouble' },
          'GasDetected-NoGasDetected': { '0': 'No Gas Detected', '1': 'Gas Detected' }
        };
        return (labels[unit] && labels[unit][value]) || '';
      }
    }
  }

}
