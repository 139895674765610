import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service';
import { SharedService } from '../../../services/shared.service';

import { LocalstorageService } from '../../../services/localstorage.service';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {
  projects: any = [];
  selectedProject: any = {};
  constructor(
    private _projectService: ProjectService,
    private _sharedService: SharedService,
    private _localstorageService: LocalstorageService
  ) { }

  async ngOnInit() {
    this.selectedProject = this._localstorageService.getProjectData();
    this.projects = await this._sharedService.getProjects();
  }

  switchProject(project){
      this._localstorageService.unsetComponentData('location-list');
      this._localstorageService.unsetComponentData('asset-list');
      this._localstorageService.unsetComponentData('component-list');
      this._localstorageService.unsetComponentData('sensor-list');
      this._localstorageService.setProjectData(project);
      this.selectedProject = project;
      window.location.reload();
  }
  
}
