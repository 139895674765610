import { Routes } from '@angular/router';
import { WorkOrderDetailsComponent } from 'src/app/work-order/work-order-details/work-order-details.component';
//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        loadChildren: () => import('../../viewer/viewer.module').then(m => m.ViewerModule)
    },
    {
        path: '',
        loadChildren: () => import('../../content-pages/content-pages.module').then(m => m.ContentPagesModule)
    },
    {
        path: 'work-request/:projectUniqueId',
        loadChildren: () => import('../../submit-work-request/submit-work-request.module').then(m => m.SubmitWorkRequestModule)
    },
    {
        path: 'work-order-summary/:workOrderId',
        loadChildren: () => import('../../work-order-summary/work-order-summary.module').then(m => m.WorkOrderSummaryModule)

    }
];