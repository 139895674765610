import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AssetService
{
    assetData: any;

    constructor(
        private _httpClient: HttpClient
    ) {}
    
    getAssetsByLocationId(location_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/location-asset/'+location_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAssetByAssetId(asset_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/asset/'+asset_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
    
    addAsset(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/asset', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }
    
    deleteAsset(asset_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/asset/'+asset_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

}
