<div ngbDropdown class="btn-group m-1" >
    <button type="button" class="btn btn-light" (click)="showDashboard()">{{ selectedDashboard.name }}</button>
    <button type="button" class="btn btn-light split-btn-light" id="dropdownSplit1" ngbDropdownToggle> <span class="caret"></span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownSplit1">
        <p ngbDropdownItem *ngFor="let dashboard of dashboards">
            <span class="mr-2">{{ dashboard.name }} </span>
            <a href="javaScript:;" [hidden]="selectedDashboard.dashboard_id == dashboard.dashboard_id" (click)="switchDashboard(dashboard)" class="ml-2"> <i class="icon-eye icons"></i></a>
            <a href="javaScript:;" (click)="openDashboardFormModal(dashboard.dashboard_id)" class="ml-2"> <i class="icon-pencil icons"></i></a>
            <a href="javaScript:;" [hidden]="selectedDashboard.dashboard_id == dashboard.dashboard_id" (click)="openConfirmationModal(dashboard.dashboard_id)" class="ml-2"> <i class="icon-trash icons"></i></a>
        </p> 
    </div>
    <button type="button" class="btn btn-light d-none d-sm-block" (click)="openDashboardFormModal()">Create Dashboard</button>
</div>