import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-option-form',
  templateUrl: './option-form.component.html',
  styleUrls: ['./option-form.component.scss']
})
export class OptionFormComponent implements OnInit {

  @Input() options: any;
  constructor(
    public activeModal: NgbActiveModal 
  ) { }

  async ngOnInit(){ }

  appendOptionRow(){ 
    this.options.push(
      {
            label : "",
            key: ""
      }
    )
  }

  removeOptionRow(index){
    this.options.splice(index, 1);
  }
  
}
