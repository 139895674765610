<div class="modal-header">
    <h5 class="modal-title">Time Log</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Hours *</label>
                    <div class="input-group">
                        <input type="number" class="form-control" formControlName="hours">
                        <div [hidden]="!error('hours','required')" class="invalid">Hours is required</div>
                        <div [hidden]="!error('hours','min')" class="invalid">Minimum value is 0</div> 
                        <div [hidden]="!error('hours','max')" class="invalid">Maximum value is 24</div> 
                    </div>
                </div>
                <div class="form-group">
                    <label>Minutes *</label>
                    <input type="number" class="form-control" formControlName="minutes">
                    <div [hidden]="!error('minutes','required')" class="invalid">Minutes is required</div>
                    <div [hidden]="!error('minutes','min')" class="invalid">Minimum value is 0</div> 
                    <div [hidden]="!error('minutes','max')" class="invalid">Maximum value is 59</div> 
                </div>
                <div class="form-group">
                    <label>Description *</label>
                    <div class="input-group">
                        <textarea rows="4" class="form-control" formControlName="description"></textarea>
                        <div [hidden]="!error('description','required')" class="invalid">Description is required</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right" (click)="validateForm()">Save</button>
</div>