import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'project',
        loadChildren: () => import('../../project/project.module').then(m => m.ProjectModule)
    },
    {
        path: 'plant',
        loadChildren: () => import('../../plant/plant.module').then(m => m.PlantModule)
    },
    {
        path: 'location',
        loadChildren: () => import('../../location/location.module').then(m => m.LocationModule)
    },
    {
        path: 'asset',
        loadChildren: () => import('../../asset/asset.module').then(m => m.AssetModule)
    },
    {
        path: 'component',
        loadChildren: () => import('../../component/component.module').then(m => m.ComponentModule)
    },
    {
        path: 'sensor',
        loadChildren: () => import('../../sensor/sensor.module').then(m => m.SensorModule)
    },
    {
        path: 'data-comparison',
        loadChildren: () => import('../../data-comparison/data-comparison.module').then(m => m.DataComparisonModule)
    },
    {
        path: 'integration',
        loadChildren: () => import('../../integration/integration.module').then(m => m.IntegrationModule)
    },
    {
        path: 'mapping',
        loadChildren: () => import('../../mapping/mapping.module').then(m => m.MappingModule)
    },
    {
        path: 'widget',
        loadChildren: () => import('../../widget/widget.module').then(m => m.WidgetModule)
    },
    {
        path: 'user',
        loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
    },
    {
        path: 'role',
        loadChildren: () => import('../../role/role.module').then(m => m.RoleModule)
    },
    {
        path: 'setting',
        loadChildren: () => import('../../setting/setting.module').then(m => m.SettingModule)
    },
    {
        path: 'shift',
        loadChildren: () => import('../../shift/shift.module').then(m => m.ShiftModule)
    },
    {
        path: 'report',
        loadChildren: () => import('../../report/report.module').then(m => m.ReportModule)
    },
    {
        path: 'documentation',
        loadChildren: () => import('../../documentation/documentation.module').then(m => m.DocumentationModule)
    },
    {
        path: 'work-order',
        loadChildren: () => import('../../work-order/work-order.module').then(m => m.WorkOrderModule)
    },
    {
        path: 'part',
        loadChildren: () => import('../../part/part.module').then(m => m.PartModule)
    },
    {
        path: 'preventive-maintenance',
        loadChildren: () => import('../../preventive-maintenance/preventive-maintenance.module').then(m => m.PreventiveMaintenanceModule)
    },
    {
        path: 'vendor',
        loadChildren: () => import('../../vendor/vendor.module').then(m => m.VendorModule)
    },
    {
        path: 'completed-task',
        loadChildren: () => import('../../completed-task/completed-task.module').then(m => m.CompletedTaskModule)
    },
    {
        path: 'cmms-dashboard',
        loadChildren: () => import('../../cmms-dashboard/cmms-dashboard.module').then(m => m.CmmsDashboardModule)
    },
    {
        path: 'submit-work-request',
        loadChildren: () => import('../../submit-work-request/submit-work-request.module').then(m => m.SubmitWorkRequestModule)
    },
    {
        path: 'requisition',
        loadChildren: () => import('../../requisition/requisition.module').then(m => m.RequisitionModule)
    }
    
];