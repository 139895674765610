import { Injectable } from "@angular/core";


@Injectable()
export class IntializerService {

    constructor(

    ) { }

    initializeRequisition() {
        return {
            number: "",
            order_date: "",
            required_date: "",
            vendor_id: "",
            department: "",
            delivered_at: "",
            requisitions: []
        };
    }

    intializePart() {
        return {
            image: {
                original: '',
                thumb: ''
            },
            name: "",
            number: "",
            price: "",
            location: "",
            quantity: "",
            vendors: [],
            category_id: "",
            assets: [],
            components: [],
            alert_amount: '',
            active: true
        }
    }


    initializeVendor() {
        return {
            name: "",
            email: "",
            contact: "",
            address: ""
        };
    }

}