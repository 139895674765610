import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: "app-vendor-form",
  templateUrl: "./vendor-form.component.html",
  styleUrls: ["./vendor-form.component.scss"],
})
export class VendorFormComponent implements OnInit {
  @Input() vendor: any = {};
  @Input() assets: any = [];
  inputForm: FormGroup;
  submitted = false;
  components: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public _sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.setFormValidation();
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      contact_no: ["", [Validators.required]],
      address: ["", [Validators.required]]
    });
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  validateForm(obj) {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly");
    } else {
      this.activeModal.close(obj);
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm["controls"][comtrolName]["errors"];
    return this.submitted && errors && errors[constraint];
  }

  async getAssetsComponents() {
    this.vendor.components = [];
    if(this.vendor.assets.length > 0){
      this.components = await this._sharedService.getAssetsComponents(this.vendor.assets);
    }
  }
}
