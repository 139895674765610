<div class="row mb-2 mx-0">
    <div class="col-6 p-0 my-auto">
        <h4 class="m-0 text-dark">Manage WOs</h4>
    </div>
    <div class="col-6 p-0">
        <div class="btn-group pull-right">
            <button type="button" class="btn btn-white" *ngIf="obj" (click)="autoAssignResource()"><i class="fa fa-plus"></i></button>
            <button type="button" class="btn btn-white" *ngIf="!obj" (click)="openResourceHierarchyModal('add')"><i class="fa fa-plus"></i></button>
                
            
            <app-expand-screen></app-expand-screen>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body p-0">
                <div class="mx-2">
                    <div class="mt-3 mb-2">
                        <button type="button" class="btn-list" [ngClass]="{'bg-success text-white': activeBtn == 'user'}" (click)="makeActive('user'); setFilter('user'); getWorkOrders()"><i class="fa fa-user"></i>By User</button>
                        <button type="button" class="btn-list mx-1" [ngClass]="{'bg-success text-white': activeBtn == 'team'}" (click)="makeActive('team'); setFilter('team'); getWorkOrders()"><i class="fa fa-users"></i>By Team</button>
                        <button type="button" class="btn-list mx-1" [ngClass]="{'bg-success text-white': activeBtn == 'calendar'}" (click)="makeActive('calendar'); setFilter('','open-in-progress-upcoming');  getWorkOrders()"><i class="fa fa-calendar"></i>Calendar</button>
                        <button type="button" class="btn-list" [ngClass]="{'bg-success text-white': activeBtn == 'list'}" (click)="makeActive('list'); setFilter(); getWorkOrders()"><i class="fa fa-list"></i>List</button>
                    </div>
                </div>
                <div *ngIf="activeBtn == 'list'">
                    <ngx-datatable [scrollbarH]="true" #mydatatable class="bootstrap" [rows]="workOrders" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [reorderable]="true">
                        <ngx-datatable-column name="Name">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <a href="javascript:;" class="text-primary my-auto" (click)="openWorkOrderDetailFormModal(row)">{{row.name}} </a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Belongs To">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <a href="javascript:;" class="text-dark mr-2" (click)="openResourceHierarchyModal('edit', row)"><i class="fa fa-pencil"></i></a>
                                <span *ngIf="row.asset_id" class="text-primary">{{row.asset_name}}</span>
                                <span *ngIf="row.asset_name && row.component_name">&nbsp;->&nbsp;</span>
                                <span *ngIf="row.component_id" class="text-success">{{row.component_name}}</span>
                                <span *ngIf="!row.asset_id && !row.component_id">Unassigned</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Priority">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <span *ngIf="!row.priority_id">Unassigned</span>
                                <div *ngIf="row.priority_id" class="bg-white border-1px text-warning rounded text-center mr-2 w-100-px">
                                    <i class="fa fa-exclamation-circle mr-2 mx-2 my-auto font-12px" [ngStyle]="{'color':row.priority_color}"></i>
                                    <span class="mr-2 my-auto" [ngStyle]="{'color':row.priority_color}">{{row.priority_name}}</span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Status">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <span class="my-auto text-dark bg-white border-1px px-2 rounded mr-2">{{row.status.charAt(0)}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Due">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                {{ helperService.formatDate(row.due.date) }} {{ helperService.formatTime(row.due.time) }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Assigned To">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <span *ngIf="row.team_id">{{row.team_name}}<br></span>
                                <span *ngIf="row.users.length == 0  && !row.team_id">Unassigned<br></span>
                                <a href="javascript:;" *ngIf="row.users.length > 0"><i class="fa fa-eye text-dark" (click)="openAssignTo(row)"></i></a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column class="pull-right" name="Actions" prop="work_order_id">
                            <ng-template ngx-datatable-cell-template let-value="value">
                                <a href="javascript:;" class="text-dark mr-2" (click)="openWorkOrderFormModal(value)"><i class="fa fa-pencil"></i></a>
                                <a href="javascript:;" class="text-dark" (click)="openConfirmationModal(value)"><i class="fa fa-trash"></i></a>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
                <div *ngIf="activeBtn == 'calendar'">
                    <div class="row text-center p-2 mt-3">
                        <div class="col-md-4">
                          <div class="btn-group pull-left">
                            <div class="btn btn-light active" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Previous</div>
                            <div class="btn btn-light" mwlCalendarToday [(viewDate)]="viewDate">Today</div>
                            <div class="btn btn-light active" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Next</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <h3 class="text-dark">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                        </div>
                        <div class="col-md-4">
                          <div class="btn-group pull-right">
                            <div class="btn btn-light" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">Month</div>
                            <div class="btn btn-light" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">Week</div>
                          </div>
                        </div>
                      </div>
                      <div [ngSwitch]="view" class="p-2">
                        <mwl-calendar-month-view
                          *ngSwitchCase="CalendarView.Month"
                          [viewDate]="viewDate"
                          [events]="events"
                          [refresh]="refresh"
                          [activeDayIsOpen]="activeDayIsOpen"
                          (dayClicked)="dayClicked($event.day)"
                          (eventClicked)="handleEvent('Clicked', $event.event)"
                          (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-month-view>
                        <mwl-calendar-week-view
                          *ngSwitchCase="CalendarView.Week"
                          [viewDate]="viewDate"
                          [events]="events"
                          [refresh]="refresh"
                          (eventClicked)="handleEvent('Clicked', $event.event)"
                          (eventTimesChanged)="eventTimesChanged($event)">
                        </mwl-calendar-week-view>
                      </div>
                </div>
                <div class="mx-2 mb-3" *ngIf="(activeBtn == 'user' || activeBtn == 'team') && workOrders.length > 0">
                    <div *ngFor="let item of workOrders; let i = index">
                        <ul class="list-group">
                            <li class="list-group-item py-2 border-1px bg-light-grey">
                                <div class="row">
                                    <div class="col-7 text-dark my-auto font-weight-bold">
                                        <img src="../../../../../placeholder.jpg" alt="">
                                        {{item._id.party_name}}
                                    </div>
                                    <div class="col-5 text-dark" style="font-size: 14px;">
                                        <b>8 Hour</b> work day | Time Spent : {{calculateUserTimeSpent(item.data)}}
                                        <div class="progress border">
                                            <div class="progress-bar bg-success" role="progressbar" [ngStyle]="{'width': calculateUserProgress(item.data) + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="row font-12px my-2" *ngFor="let workOrder of item.data">
                            <div class="col-12 col-xl-7 mt-2 mt-xl-0">
                                <div class="d-flex">
                                    <div class="bg-white border-1px text-warning rounded mr-2">
                                        <i class="fa fa-exclamation-circle mr-2 mx-2 my-auto font-12px" [ngStyle]="{'color':workOrder.priority_color}"></i>
                                        <span class="mr-2 my-auto" [ngStyle]="{'color':workOrder.priority_color}">{{workOrder.priority_name}}</span>
                                    </div>
                                    <span class="my-auto text-dark bg-white border-1px px-2 rounded mr-2">{{workOrder.status.charAt(0)}}</span>
                                    <i class="fa fa-wrench text-dark my-auto"></i>
                                    <a href="javascript:;" class="text-primary my-auto ml-1 mr-2" (click)="openWorkOrderDetailFormModal(workOrder)">{{workOrder.name}} </a>
                                    <i class="fa fa-cube text-dark my-auto mr-1"></i>
                                    <span *ngIf="!workOrder.asset_name && !workOrder.component_name">Unassigned</span>
                                    <a href="javascript:;" class="text-success my-auto" *ngIf="workOrder.asset_name"> {{workOrder.asset_name}} </a>
                                    <span *ngIf="workOrder.asset_name && workOrder.component_name">&nbsp;->&nbsp;</span>
                                    <a href="javascript:;" class="text-success my-auto" *ngIf="workOrder.component_name">{{workOrder.component_name}} </a>
                                </div>
                            </div>
                            <div class="col-9 col-sm-6 col-xl-3 mt-2 mt-xl-0">
                                <div class="d-flex">
                                    <i class="fa fa-calendar text-dark my-auto ml-2 mr-1"></i>
                                    <span class="my-auto mr-2"> {{ helperService.formatDate(workOrder.due.date) }} {{ helperService.formatTime(workOrder.due.time) }} </span>
                                    <span class="my-auto text-white border-1px px-2 rounded mr-2" [ngClass]="_workOrderService.computeClass(helperService.getDaysDifference(workOrder.due.date))">{{ helperService.getDaysDifference(workOrder.due.date) }}</span>
                                    <span class="my-auto text-dark bg-white mr-2 cursor-pointer"><i class="fa fa-envelope" (click)="openSendMailModal()"></i></span>
                                </div>
                            </div>        
                            <div class="col-3 col-sm-6 col-xl-2 mt-2 mt-xl-0">
                                <div class="d-flex pull-right">
                                    <span class="my-auto mr-2">{{item._id.party_name}}</span>
                                    <a href="javascript:;" class="text-primary my-auto mr-2" (click)="openConfirmationModal(workOrder.work_order_id)"><i class="fa fa-trash text-dark"></i></a>
                                </div>
                            </div>        
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>