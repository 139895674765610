<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">Differential Policy</div>
            <div class="card-body">
                <form [formGroup]="differentialForm">
                    <div class="form-group" [hidden]="true">
                        <label >Offset</label>
                        <input type="number" formControlName="offset" [(ngModel)]="differentialPolicy.offset" class="form-control">
                        <div [hidden]="!errorDifferential('offset','required')" class="invalid">Offset Value is required</div> 
                    </div>
                    <div class="form-group" [hidden]="true">
                        <label >Interval</label>
                        <input type="number" formControlName="interval" [(ngModel)]="differentialPolicy.interval" class="form-control">
                        <div [hidden]="!errorDifferential('interval','required')" class="invalid">Differential Value is required</div> 
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label >Differential</label>
                                <input type="number" formControlName="differential" [(ngModel)]="differentialPolicy.differential" class="form-control">
                                <div [hidden]="!errorDifferential('differential','required')" class="invalid">Differential Value is required</div> 
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Users *</label>
                                <ng-multiselect-dropdown
                                [placeholder]="'Select User'"
                                [settings]="setMultiselectSetting('user_id','name')"
                                [data]="users"
                                [(ngModel)]="differentialPolicy.alertUsers"
                                formControlName="alertUsers">
                                </ng-multiselect-dropdown>  
                                <div [hidden]="!errorDifferential('alertUsers','required')" class="invalid">Alert users are required</div> 
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label >Tolerance</label>
                                <input type="number" formControlName="tolerance" [(ngModel)]="differentialPolicy.tolerance" class="form-control">
                                <div [hidden]="!errorDifferential('tolerance','required')" class="invalid">Tolerance Value is required</div>
                                <div [hidden]="!errorDifferential('tolerance','min')" class="invalid">Value must be in range(1-20)</div>
                                <div [hidden]="!errorDifferential('tolerance','max')" class="invalid">Value must be in range(1-20)</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Send Alert As *</label>
                                <div class="input-group mb-3">
                                    <select class="custom-select" formControlName="sendAlertAs"  [(ngModel)]="differentialPolicy.sendAlertAs">
                                        <option value="">Select Type</option>
                                        <option value="email">Email</option>
                                        <option value="sms">SMS</option>
                                        <option value="both">Both</option>
                                    </select>
                                </div>
                                <div [hidden]="!errorDifferential('sendAlertAs','required')" class="invalid">Send Alert As is required</div> 
                            </div>
                        </div>
                    </div>    
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label >Alert Message</label>
                                <textarea [(ngModel)]="differentialPolicy.alertMessage" [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="mr-2">Status </label>
                                <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="differentialPolicy.alertStatus" [ngModelOptions]="{standalone: true}"> <i class="fa fa-check" ></i></ui-switch>
                            </div>
                        </div>
                    </div> 
                    <div class="form-group">
                        <button [hidden]="differentialPolicy._id" type="button" class="btn btn-white pull-right px-5" (click)="addDifferentialPolicy()">Save</button>
                        <button [hidden]="!differentialPolicy._id" type="button" class="btn btn-white pull-right px-5" (click)="updateDifferentialPolicy()">Update</button>
                        <button type="button" class="btn btn-white pull-right mr-1" (click)="copyDifferentialPolicy()"><i class="fa fa-copy"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>