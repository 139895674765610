import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { SensorRoutingModule } from "./sensor-routing.module";
import { SensorComponent } from './sensor.component';
import { SensorListComponent } from './sensor-list/sensor-list.component';
import { SensorModalComponent } from './sensor-modal/sensor-modal.component';
import { SelectSensorModalComponent } from './select-sensor-modal/select-sensor-modal.component';
import { SelectHistoryModalComponent } from './select-history-modal/select-history-modal.component';
import { HealthNoteModalComponent } from './health-note-modal/health-note-modal.component';
import { SensorFormComponent } from './sensor-form/sensor-form.component';
import { SensorReplaceFormComponent } from './sensor-replace-form/sensor-replace-form.component';
import { SensorImportComponent } from './sensor-import/sensor-import.component';
import { SensorDetailComponent } from './sensor-detail/sensor-detail.component';
import { SensorAlertComponent } from './sensor-alert/sensor-alert.component';
import { SensorTrendDataComponent } from './sensor-trend-data/sensor-trend-data.component';
import { SensorUtilizationComponent } from './sensor-utilization/sensor-utilization.component';
import { SensorFftComponent } from './sensor-fft/sensor-fft.component';
import { SensorTreonFftComponent } from './sensor-treon-fft/sensor-treon-fft.component';
import { SensorInfoComponent } from './sensor-info/sensor-info.component';
import { SensorHealthComponent } from './sensor-health/sensor-health.component';
import { SensorSettingComponent } from './sensor-setting/sensor-setting.component';
import { SensorTemperaturePolicyComponent } from './sensor-temperature-policy/sensor-temperature-policy.component';
import { SensorDifferentialPolicyComponent } from './sensor-differential-policy/sensor-differential-policy.component';
import { SensorBearingNumberComponent } from './sensor-bearing-number/sensor-bearing-number.component';
import { SensorRpmUtilizationComponent } from './sensor-rpm-utilization/sensor-rpm-utilization.component';
import { SensorCoordinatesComponent } from './sensor-coordinates/sensor-coordinates.component';
import { SensorVibrationPolicyComponent } from './sensor-vibration-policy/sensor-vibration-policy.component';
import { SensorMaintenancePolicyComponent } from './sensor-maintenance-policy/sensor-maintenance-policy.component';
import { SensorMountPositionComponent } from './sensor-mount-position/sensor-mount-position.component';
import { SensorOtherPolicyComponent } from './sensor-other-policy/sensor-other-policy.component';
import { SensorService } from './sensor.service'; 

import { WidgetModule } from '../widget/widget.module';
import { ChartModule } from '../chart/chart.module';
import { PinModule } from '../pin/pin.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module'; 
import { DatepickerService } from '../../services/datapicker.service';

@NgModule({
    imports: [
        CommonModule,
        SensorRoutingModule,
        NgbModule, 
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        TagInputModule,
        WidgetModule, 
        ChartModule,
        FileUploadModule,
        NgxDatatableModule,
        PinModule,
        ExpandScreenModule,
        NgMultiSelectDropDownModule
    ], 
    declarations: [ 
        SensorComponent, 
        SensorListComponent,
        SensorModalComponent,
        SelectSensorModalComponent,
        HealthNoteModalComponent,
        SelectHistoryModalComponent,
        SensorFormComponent,
        SensorReplaceFormComponent,
        SensorImportComponent,
        SensorDetailComponent,
        SensorAlertComponent,
        SensorTrendDataComponent,
        SensorUtilizationComponent,
        SensorFftComponent,
        SensorTreonFftComponent,
        SensorInfoComponent,
        SensorHealthComponent,
        SensorSettingComponent,
        SensorTemperaturePolicyComponent,
        SensorDifferentialPolicyComponent,
        SensorBearingNumberComponent,
        SensorRpmUtilizationComponent,
        SensorCoordinatesComponent,
        SensorVibrationPolicyComponent,
        SensorMaintenancePolicyComponent,
        SensorMountPositionComponent,
        SensorOtherPolicyComponent
    ],
    providers: [
        SensorService,
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }, {provide: NgbDateParserFormatter, useClass: DatepickerService }
    ],
    exports: [
        SensorComponent,
        SensorListComponent
    ]
})
export class SensorModule { }
