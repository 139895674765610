<!--Start topbar header-->
<header class="topbar-nav">
	<nav class="navbar navbar-expand fixed-top">
		<div class="toggle-menu" (click)="toggleSidebar()"> <i class="zmdi zmdi-menu"></i>
		</div>
		<app-project-card></app-project-card>
		<app-dashboard-card></app-dashboard-card>
		<ul class="navbar-nav align-items-center right-nav-link ml-auto  d-none d-md-flex">
			<li ngbDropdown class="nav-item language d-none d-sm-block"> 
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic3" ngbDropdownToggle><i class="flag-icon flag-icon-us align-middle"></i></a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdown-menu-right">
					<li ngbDropdownItem><i class="flag-icon flag-icon-us mr-3"></i>English</li>
				</ul>
			</li>
			<li ngbDropdown class="nav-item">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic4" ngbDropdownToggle> <span class="user-profile"><img src="{{ user.image.thumb_path }}" type="user" class="img-circle" alt="user avatar" /></span>
				</a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu-right">
					<li class="dropdown-item user-details">
						<a href="javaScript:;">
							<div class="media">
								<div class="avatar">
									<img src="{{ user.image.thumb_path }}" type="user" class="align-self-start mr-3" alt="user avatar" />
								</div>
								<div class="media-body">
									<h6 class="mt-2 user-title">{{ user.name }}</h6>
									<p class="user-subtitle">{{ user.email }}</p>
								</div>
							</div>
						</a>
					</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><a href="javaScript:;" (click)="logOut()"><i class="zmdi zmdi-power mr-3"></i>Logout</a></li>
				</ul>
			</li>
		</ul>
	</nav>
</header>
<!--End topbar header-->