import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../location.service';
import { SharedService } from '../../../services/shared.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from '../../../services/helper.service';
import { LocationFormComponent } from '../location-form/location-form.component';
import { AssetModalComponent } from '../../asset/asset-modal/asset-modal.component';
import { ModalComponent } from '../../modal/modal.component';
import { ViewerComponent } from '../../viewer/viewer.component';
import { NGXToastrService } from '../../../services/toaster.service';
import Constants from '../../../services/constants.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {

  @Input() plant: any;
  plantOrigionalImageSrc: any;
  
  flags: any = [];
  filter: any = {};
  plants: any = [];
  location: any = {};
  locations: any = [];
  pinData: any;
  
  constructor(
    private _locationService: LocationService,
    private _sharedService: SharedService,
    private _helperService: HelperService,
    private _localstorageService: LocalstorageService,
    private modalService: NgbModal,
    private _toasterService: NGXToastrService,

  ) { }

  async ngOnInit(){
    this.pinData = {
      component_name: 'LocationComponent' 
    }
    
    if(this.plant){
      if(this.plant.image.original){
        this.plantOrigionalImageSrc = await this._helperService.setImage(this.plant.image.original);
      }
      this.locations = await this._locationService.getLocationsByPlantId(this.plant.plant_id);
    } else {
      this.plants = await this._sharedService.getPlants();
      let componentLocalData = this._localstorageService.getComponentData('location-list');
      if(componentLocalData){
        this.filter.plants = componentLocalData.filter.plants;
        this.getPlantsLocations();
      } else {
        this.locations = await this._sharedService.getLocations();
      }
    }
  }

  async getPlantsLocations(){
    if(this.filter.plants){
      let componentLocalData = { 
        filter: {
          plants: this.filter.plants
        }
      }
      this._localstorageService.setComponentData('location-list',componentLocalData);
      this.locations = await this._sharedService.getPlantsLocations(this.filter.plants);
    }
  }

  async resetPlantLocations(){
    this.locations = [];
    this.filter = {};
    this._localstorageService.unsetComponentData('location-list');
    this.locations = await this._sharedService.getLocations();
  }

  initializeLocation(){
    this.location = {
      plant_id: this.plant ? this.plant.plant_id : '',
      image: {
        original: "",
        thumb: ""
      },
      latitude: '',
      longitude: '',
      active: true
    }
  }

  setMultiselectSetting(idField, textField){
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  openAssetListModal(location){
    const modalRef = this.modalService.open(AssetModalComponent, {windowClass : 'modal-full-screen', scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.location = location;
    modalRef.result.then(async (result) => {
      console.log(result)
    }, (reason) => {
      console.log(reason)
    });
  }

  async openLocationFormModal(locationId = 'add_new_location') {
    if(locationId != 'add_new_location'){
      this.location = await this.getLocationByLocationId(locationId);
    } else {
      this.initializeLocation();
    }
    
    const modalRef = this.modalService.open(LocationFormComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.location = this.location;
    modalRef.componentInstance.plants = await this._sharedService.getPlants();
    modalRef.result.then(async (result) => {
      if(result.action == 'add'){
        this.location = await this.addLocation(result.location);
      } else {
        this.location = await this._sharedService.updateLocation(result.location);
      }
      if(this.location){
        await this.ngOnInit();
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async openViewerModal(location) {
    const modalRef = this.modalService.open(ViewerComponent, {scrollable: true, backdrop : 'static', size: 'xl'});
    modalRef.componentInstance.obj = {resource: location, type: 'location', id:location.location_id};
    modalRef.result.then(async (result) => {

    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal(locationId) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.heading = "Delete Location";
    modalRef.componentInstance.content = "Are you sure you want to delete this location? It will delete the assets, components and sensors of this location.";

    modalRef.result.then(async (result) => {
      this.location = await this.deleteLocation(locationId);
      if(this.location){
        let index = this.locations.findIndex(p => p.location_id == this.location.location_id);
        this.locations.splice(index,1);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addLocation(location){
    return await this._locationService.addLocation(location); 
  }

  async deleteLocation(locationId){
    return await this._locationService.deleteLocation(locationId);
  }

  async getLocationByLocationId (locationId) {
    return  await this._locationService.getLocationByLocationId(locationId);
  }

  hideOptions(){
    let user = this._localstorageService.getUserData();
    return user.role_id != Constants.role.SUPER_ADMIN_ROLE_ID && user.role_id != Constants.role.VENDOR_ROLE_ID;
  }

  creatReport(locationId){
    this._helperService.performOperationWithToasts(async () => {
      return await this._sharedService.createReport('location', locationId);
    });
  }

}
