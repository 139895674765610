import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-differential-policy',
  templateUrl: './sensor-differential-policy.component.html',
  styleUrls: ['./sensor-differential-policy.component.scss']
})
export class SensorDifferentialPolicyComponent implements OnInit {
  @Input() sensor: any;
  @Input() users: any;

  differentialForm: FormGroup;
  differentialSubmitted = false;
  differentialPolicy: any = {};
  
  constructor(
    private modalService: NgbModal,
    private _sensorService: SensorService,
    private formBuilder: FormBuilder,
    private _sharedService: SharedService
  ) { }

  async ngOnInit() {
    this.setFormValidation();

    await this.setDifferentialPolicy();
  }

  setFormValidation(){
    this.differentialForm = this.formBuilder.group({
      alertUsers: ['', [Validators.required]],
      sendAlertAs: ['', [Validators.required]],
      offset: ['', [Validators.required]],
      differential: ['', [Validators.required]],
      interval: ['', [Validators.required]],
      tolerance: ['', [Validators.required, Validators.min(1), Validators.max(20)]]
    });
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  
  errorDifferential(comtrolName, constraint){
    let errors = this.differentialForm['controls'][comtrolName]['errors'];
    return this.differentialSubmitted && errors && errors[constraint]
  }


  async setDifferentialPolicy(){
    this.differentialPolicy = await this._sensorService.getDifferentialPolicy(this.sensor.sensor_id) || { alertUsers: [], sendAlertAs: '', alertMessage: '', offset: 0, differential: 0.1, interval: 3600, tolerance: 3, alertStatus: false};
  }

  async addDifferentialPolicy(){
    this.differentialSubmitted = true;
    if (!this.differentialForm.invalid) {
      this.differentialPolicy.beaconId = this.sensor.sensor_id;
      let response = await this._sensorService.addDifferentialPolicy(this.differentialPolicy); 
      if(response){
        this.differentialPolicy = response;
      }
    }
  }

  async updateDifferentialPolicy(){
    this.differentialSubmitted = true;
      if (!this.differentialForm.invalid) {
      let response = await this._sensorService.updateDifferentialPolicy(this.differentialPolicy); 
      if(response){
        this.differentialPolicy = response;
      }
    }
  }

  async copyDifferentialPolicy(){
    this.differentialSubmitted = true;
    if (!this.differentialForm.invalid) {
      const modalRef = this.modalService.open(SelectSensorModalComponent, {backdrop : 'static'});
      let sensors = await this._sharedService.getSensors('?type='+this.sensor.type+'&used_for='+this.sensor.used_for);
      let index =  sensors.findIndex(s => s.sensor_id == this.sensor.sensor_id);
      sensors.splice(index,1);
      modalRef.componentInstance.sensors = sensors;
    
      modalRef.result.then(async (result) => {
        let copied_data = {...this.differentialPolicy};
        delete(copied_data._id);
        result.differentialPolicy = copied_data;
        await this._sensorService.copyDifferentialPolicy(result);
      }, (reason) => {
          console.log(reason);
      });
    }
  }
}
  