import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { AddDownTimeFormComponent } from "../add-down-time-form/add-down-time-form.component";
import { AddCommentFormComponent } from "../../shared/add-comment-form/add-comment-form.component";
import { AddPartFormComponent } from "../add-part-form/add-part-form.component";
import { AddTimeLogFormComponent } from "../add-time-log-form/add-time-log-form.component";
import { RequisitionFormComponent } from "../../requisition/requisition-form/requisition-form.component";
import { PartFormComponent } from "../../part/part-form/part-form.component";

import { SharedService } from "../../../services/shared.service";
import { HelperService } from "../../../services/helper.service";
import { NGXToastrService } from "../../../services/toaster.service";
import { WorkOrderService } from "../work-order.service";
import { IntializerService } from "src/services/intializer.service";
import { LocalstorageService } from "../../../services/localstorage.service";

@Component({
    selector: 'app-work-order-details',
    templateUrl: './work-order-details.component.html',
    styleUrls: ['./work-order-details.component.scss']
})
export class WorkOrderDetailsComponent implements OnInit {
    @Input() workOrder: any;
    instructions: []
    due: {
        date: '',
        time: '',
    }
    time_logs: []
    down_times: []
    comments: []
    parts: any = []
    progress: any;
    timeSpent = '0';
    downTime = '0';
    workOrderId: any;
    user: any = {};

    constructor(
        public helperService: HelperService,
        public modalService: NgbModal,
        public _workOrderService: WorkOrderService,
        public _sharedService: SharedService,
        public _toasterService: NGXToastrService,
        private _activatedroute: ActivatedRoute,
        private _localstorageService: LocalstorageService,
        public _intializerService:IntializerService
    ) { }

    async ngOnInit() {
        this.user = this._localstorageService.getUserData();
        const workOrderId = this._activatedroute.snapshot.paramMap.get("workOrderId");
        if (workOrderId) {
            this.workOrder = await this._sharedService.getWorkOrders({ work_order_id: workOrderId });
            if (this.workOrder.length > 0) {
                this.workOrder = this.workOrder[0];
            }
        }
        this.progress = this._workOrderService.calculateProgress(this.workOrder.instructions);
        this.timeSpent = this._workOrderService.calculateTimeSum(this.workOrder.time_logs);
        this.downTime = this._workOrderService.calculateTimeSum(this.workOrder.down_times);
    }

    async updateWorkOrder(data, property) {
        let response = await this._workOrderService.updateWorkOrder(data);
        if (response) {
            this.workOrder[property] = response[property];
        }
    }

    async openAddTimeLogFormModal() {
        const modalRef = this.modalService.open(AddTimeLogFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modalRef.result.then(async (result) => {
            this.workOrder.time_logs.push(result);
            const data = {
                work_order_id: this.workOrder.work_order_id,
                time_logs: this.workOrder.time_logs,
            }
            await this._workOrderService.updateWorkOrder(data);
            this.timeSpent = this._workOrderService.calculateTimeSum(this.workOrder.time_logs);
        }, (reason) => {
            console.log(reason);
        });
    }

    async openAddDownTimeFormModal() {
        const modalRef = this.modalService.open(AddDownTimeFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modalRef.result.then(async (result) => {
            this.workOrder.down_times.push(result);
            let data = {
                work_order_id: this.workOrder.work_order_id,
                down_times: this.workOrder.down_times,
            }
            await this.updateWorkOrder(data, 'down_times');
            this.downTime = this._workOrderService.calculateTimeSum(this.workOrder.down_times);
        }, (reason) => {
            console.log(reason);
        });
    }

    async completeWorkOrder(workOrder) {
        let obj = {
            work_order_id: workOrder.work_order_id,
            status: 'Completed',
            completion_notes: workOrder.completion_notes,
            completed: new Date()
        }

        let response = await this._workOrderService.updateWorkOrder(obj);
        if(response){ // we did the becuase there are no lookup query in update method.
            this.workOrder.completed = response.completed;
            this.workOrder.status = response.status;
        }
    }

    async openAddCommentFormModal() {
        const modalRef = this.modalService.open(AddCommentFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modalRef.result.then(async (result) => {
            this.workOrder.comments.push(result);
            let data = {
                work_order_id: this.workOrder.work_order_id,
                comments: this.workOrder.comments,
            }
            await this.updateWorkOrder(data, 'comments');
        }, (reason) => {
            console.log(reason);
        });
    }

    async openAddPartFormModal() {
        const modelRef = this.modalService.open(AddPartFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modelRef.componentInstance.selectedParts = this.workOrder.parts;
        modelRef.result.then(async (result) => {
            this.workOrder.parts = [...result.parts];
            let data = {
                work_order_id: this.workOrder.work_order_id,
                parts: this.workOrder.parts,
            }
            await this.updateWorkOrder(data, 'parts');
        }, (reason) => {
            console.log(reason);
        });
    }

    async openPartFormModal() {
        let part = this._intializerService.intializePart();
        const modalRef = this.modalService.open(PartFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
        modalRef.componentInstance.part = part;
        modalRef.componentInstance.categories = await this._sharedService.getCategory();
        modalRef.componentInstance.vendors = await this._sharedService.getVendor();
        modalRef.result.then(async (result) => {
            if (result.action == 'add') {
                part = await this._sharedService.addPart(result.data);
                part && this.parts.push(part);
            } 
        }, async (reason) => {
            console.log(reason);
        });

    }

    async openRequisitionFormModal() {
        let requisition = this._intializerService.initializeRequisition();
        const modalRef = this.modalService.open(RequisitionFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' })
        modalRef.componentInstance.requisition = requisition;
        modalRef.componentInstance.vendors = await this._sharedService.getVendor();
        modalRef.result.then(async (result) => {
          if (result.action == "add") {
            result.data.requisition_by = this.user.user_id;
            await this._sharedService.addRequisition(result.data);
          }
        }, (reason) => {
          console.log(reason);
        });

      }

    async getWorkOrderVendors() {
        let vendors = [];
        if (this.workOrder.asset_id && this.workOrder.component_id) {
            let response1 = await this._sharedService.getVendorByResource('component', this.workOrder.component_id);
            let response2 = await this._sharedService.getVendorByResource('asset', this.workOrder.asset_id);
            vendors = _.unionBy(response2, response1, 'vendor_id');
        } else if (this.workOrder.asset_id && !this.workOrder.component_id) {
            vendors = await this._sharedService.getVendorByResource('asset', this.workOrder.asset_id);
        } else if (!this.workOrder.asset_id && this.workOrder.component_id) {
            vendors = await this._sharedService.getVendorByResource('component', this.workOrder.component_id);
        }
        return vendors;
    }

    async removeComments(index) {
        this.workOrder.comments.splice(index, 1);
        let data = {
            work_order_id: this.workOrder.work_order_id,
            comments: this.workOrder.comments,
        }
        await this.updateWorkOrder(data, 'comments');
    }

    async removeTimeLog(index) {
        this.workOrder.time_logs.splice(index, 1)
        const data = {
            work_order_id: this.workOrder.work_order_id,
            time_logs: this.workOrder.time_logs,
        }
        await this.updateWorkOrder(data, 'time_logs');
        this.timeSpent = this._workOrderService.calculateTimeSum(this.workOrder.time_logs);
    }

    async removeDownTime(index) {
        this.workOrder.down_times.splice(index, 1)
        const data = {
            work_order_id: this.workOrder.work_order_id,
            down_times: this.workOrder.down_times,
        }
        await this.updateWorkOrder(data, 'down_times');
        this.downTime = this._workOrderService.calculateTimeSum(this.workOrder.down_times);

    }

    async removePart(index) {
        this.workOrder.parts.splice(index, 1)
        let data = {
            work_order_id: this.workOrder.work_order_id,
            parts: this.workOrder.parts,
        }
        await this.updateWorkOrder(data, 'parts');
    }

    openAttachemnt(attachemnt) {
        let url = (<any>window).appBaseUrl + '/uploads/project-'+ this.workOrder.project_unique_id +'/' + attachemnt;
        window.open(url, "_blank");
    }

    async updateProgress($event, index) {
        this.workOrder.instructions[index].fulfil = $event.target.checked;
        let data = {
            work_order_id: this.workOrder.work_order_id,
            instructions: this.workOrder.instructions
        }
        await this.updateWorkOrder(data, 'instructions');
        this.progress = this._workOrderService.calculateProgress(this.workOrder.instructions);
    }

    async available(newQuantity, part, index) {
        let dbPart = await this._sharedService.getPartByPartId(part.part_id);
        if (Number(dbPart.quantity) < Number(newQuantity)) {
            this._toasterService.typeError(`${dbPart.quantity} units available of ${part.name}`);
            this.workOrder.parts[index].quantity = part.quantity;
        } else {
            this.workOrder.parts[index].quantity = newQuantity;
            let obj1 = {
                work_order_id: this.workOrder.work_order_id,
                parts: this.workOrder.parts
            }
            let response = await this._workOrderService.updateWorkOrder(obj1)
            if (response) {
                let obj2 = {
                    part_id: part.part_id,
                    quantity: dbPart.quantity - newQuantity
                }
                await this._sharedService.updatePart(obj2);
            }
        }
    }
}