import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { SharedService } from '../../../services/shared.service';
import { ModalComponent } from '../../modal/modal.component';
import { SelectSensorModalComponent } from '../select-sensor-modal/select-sensor-modal.component';

@Component({
  selector: 'app-sensor-maintenance-policy',
  templateUrl: './sensor-maintenance-policy.component.html',
  styleUrls: ['./sensor-maintenance-policy.component.scss']
})
export class SensorMaintenancePolicyComponent implements OnInit {
  @Input() sensorId: any;
  reminderTypes: any = [];
  maintenanceALertPolicies: any = [
    {
      alertStatus: false,
      oneTimeOption: false,
      noOfDays: 1,
      noOfHours: 1,
      type: 'byDay',
      machcine_alert_type: '',
      machcine_alert_type_other: '',
    }
  ];
  reminderBlock = false;
  reminderTypeData: any = {};
  
  constructor(
    private _sensorService: SensorService,
    private _sharedService: SharedService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    this.initializeReminderTypeData();
    this.reminderTypes = await this._sensorService.getReminderType();
    await this.setMaintenancePolicies();
  }

  initializeReminderTypeData(){
    this.reminderTypeData = {
      name: '',
      active: true
    }
  }

  toggleAddReminderBlock(){
    this.reminderBlock = this.reminderBlock ? false : true;
  }

  async addReminderType(){
    if(this.reminderTypeData.name != ''){
      this.reminderTypeData = await this._sensorService.addReminderType(this.reminderTypeData);
      if(this.reminderTypeData){
        this.reminderTypes.push(this.reminderTypeData);
      }
      this.initializeReminderTypeData();
      this.toggleAddReminderBlock();
    }
  }

  async setMaintenancePolicies(){
    let array = await this._sensorService.getMaintenancePolicy(this.sensorId) || [];
    if(array && array.length > 0){
      this.maintenanceALertPolicies = [];
      array.some((item)=>{
        item._id = item._id;
        item.alertStatus =item.alertStatus ? item.alertStatus : false;
        item.type =item.type ? item.type : '';
        item.noOfDays =item.noOfDays ? item.noOfDays : 1;
        item.oneTimeOption =item.oneTimeOption ? item.oneTimeOption : false;
        item.noOfHours =item.noOfHours ? item.noOfHours : 1;
        item.machcine_alert_type_other =item.machcine_alert_type_other ? item.machcine_alert_type_other : '';
        item.machcine_alert_type =item.machcine_alert_type ? item.machcine_alert_type : '';
        this.maintenanceALertPolicies.push(item);
      });
     }
  }

  async addMaintenancePolicy(maintenancePolicy){
    maintenancePolicy.beaconId = this.sensorId;
    let response = await this._sensorService.addMaintenancePolicy(maintenancePolicy);
    if(response){
      await this.setMaintenancePolicies();
    }
  }

  async copyMaintenancePolicy(maintenancePolicy){
    const modalRef = this.modalService.open(SelectSensorModalComponent, {backdrop : 'static'});
    let sensor = await this._sharedService.getSensorBySensorId(this.sensorId);
    let sensors = await this._sharedService.getSensors('?type='+sensor.type+'&used_for='+sensor.used_for);
    let index =  sensors.findIndex(s => s.sensor_id == this.sensorId);
    sensors.splice(index,1);
    modalRef.componentInstance.sensors = sensors;
   
    modalRef.result.then(async (result) => {
      let copied_data = {...maintenancePolicy};
      delete(copied_data._id);
      result.maintenancePolicy = copied_data;
      await this._sensorService.copyMaintenancePolicy(result);
    }, (reason) => {
        console.log(reason);
    });
  }

  async updateMaintenancePolicy(maintenancePolicy){
    let response = await this._sensorService.updateMaintenancePolicy(maintenancePolicy); 
    if(response){
      await this.setMaintenancePolicies();
    }
  }

  addItem(){
    this.maintenanceALertPolicies.push(
      {
        alertStatus: false,
        oneTimeOption: false,
        noOfDays: 1,
        noOfHours: 1,
        type: 'byDay',
        machcine_alert_type: '',
        machcine_alert_type_other: '',
      }
    );
  }

  deleteItem(index, id){
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.heading = "Delete Setting";
    modalRef.componentInstance.content = "Are you sure you want to delete this setting?";

    modalRef.result.then(async (result) => {
      this.maintenanceALertPolicies.splice(index, 1);
      if(id){
        await this.deleteMaintenancePolicy(id);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async deleteMaintenancePolicy(id){
    return await this._sensorService.deleteMaintenancePolicy(id);
  }

  
}
  