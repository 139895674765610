import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  options: any = {};
  @Input() series: any = [];
  constructor() { 
    this.options = {
      chart: {
        height: 200,
        type: 'donut',   
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: true
      },
      colors: ["#77B6EA", "#E94335"],
      legend: {
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            height: 330,
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

  ngOnInit(): void {
  }
  
}
