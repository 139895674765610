import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlantService } from '../plant.service';
import { SharedService } from '../../../services/shared.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { PlantFormComponent } from '../plant-form/plant-form.component';
import { LocationModalComponent } from '../../location/location-modal/location-modal.component';
import { ModalComponent } from '../../modal/modal.component';
import { ViewerComponent } from '../../viewer/viewer.component';
import Constants from '../../../services/constants.service';
import { HelperService } from 'src/services/helper.service';

@Component({
  selector: 'app-plant-list',
  templateUrl: './plant-list.component.html',
  styleUrls: ['./plant-list.component.scss']
})
export class PlantListComponent implements OnInit { 

  flags: any = [];
  plants: any = [];
  plant: any = {};
  pinData: any;

  constructor(
    private _plantService: PlantService,
    private _sharedService: SharedService,
    private _localstorageService: LocalstorageService,
    private modalService: NgbModal,
    private _helperService: HelperService,

  ) { }

  async ngOnInit() {
    this.pinData = {
      component_name: 'PlantComponent'
    };
    this.plants = await this._sharedService.getPlants();
  }

  initializePlant(){
    this.plant = {
      image: {
        original: '',
        thumb: ''
      },
      latitude: '',
      longitude: '',
      active: true
    }
  }

  openLocationListModal(plant){
    const modalRef = this.modalService.open(LocationModalComponent, {windowClass : 'modal-full-screen', scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.plant = plant;
    modalRef.result.then(async (result) => {
      console.log(result)
    }, (reason) => {
      console.log(reason)
    }); 
  }

  async openPlantFormModal(plantId = 'add_new_plant') {
    this.initializePlant();
    if(plantId != 'add_new_plant'){
      this.plant = await this.getPlantByPlantId(plantId);
    }
    const modalRef = this.modalService.open(PlantFormComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.plant = this.plant;
    modalRef.result.then(async (result) => {
      if(result.action == 'add'){
        this.plant = await this.addPlant(result.plant);
        if(this.plant){
          this.plants.push(this.plant);
        }
      } else {
        this.plant = await this._sharedService.updatePlant(result.plant);
        if(this.plant){
          let index = this.plants.findIndex(m => m.plant_id == this.plant.plant_id);
          this.plants[index] = this.plant;
        }
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async openViewerModal(plant) {
    const modalRef = this.modalService.open(ViewerComponent, {scrollable: true, backdrop : 'static', size: 'xl'});
    modalRef.componentInstance.obj = {resource: plant, type: 'plant', id:plant.plant_id};
    modalRef.result.then(async (result) => {

    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal(plantId) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.heading = "Delete Plant";
    modalRef.componentInstance.content = "Are you sure you want to delete this plant? It will delete the locations, assets, components and sensors of this plant.";

    modalRef.result.then(async (result) => {
      this.plant = await this.deletePlant(plantId);
      if(this.plant){
        let index = this.plants.findIndex(p => p.plant_id == this.plant.plant_id);
        this.plants.splice(index,1);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addPlant(plant){
    return await this._plantService.addPlant(plant); 
  }

  async deletePlant(plantId){
    return await this._plantService.deletePlant(plantId);
  }

  async getPlantByPlantId (plantId) {
    return  await this._plantService.getPlantByPlantId(plantId);
  }

  hideOptions(){
    let user = this._localstorageService.getUserData();
    return user.role_id != Constants.role.SUPER_ADMIN_ROLE_ID && user.role_id != Constants.role.VENDOR_ROLE_ID;
  }

  creatReport(plantId){
    this._helperService.performOperationWithToasts(async () => {
      return await this._sharedService.createReport('plant', plantId);
    });
  }
  

}
