<!-- Preventive Maintenance modal -->
<div class="modal-header">
    <h5 class="modal-title">Schedule</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card mb-2" *ngFor="let schedule of pm.schedules; let i = index">
        <div class="card-body p-2">
            <div class="row">
                <div class="col-8">
                    <span><input type="radio" name="recurrence_{{i}}" value="daily" [(ngModel)]="schedule.recurrence" (click)="showSettingBlock('daily', i)"> Daily</span>
                    <span class="ml-3"><input type="radio" name="recurrence_{{i}}" value="weekly" [(ngModel)]="schedule.recurrence" (click)="showSettingBlock('weekly', i)"> Weekly</span>
                    <span class="ml-3"><input type="radio" name="recurrence_{{i}}" value="monthly" [(ngModel)]="schedule.recurrence" (click)="removeSetting(i)"> Monthly</span>
                </div>
                <div class="col-4">
                    <a href="javascript:;" class="text-dark pull-right" (click)="removeSchedule(i)"><i class="fa fa-trash"></i></a>
                </div>
            </div>
            <div class="form-group mb-2 mt-3" *ngIf="schedule.recurrence == 'monthly'">
                <span><input type="radio" name="type_{{i}}" value="weekday" [(ngModel)]="schedule.type" (click)="showSettingBlock('monthly', i, 'weekday')"> Weekday</span>
                <span class="ml-3"><input type="radio" name="type_{{i}}" value="numeric" [(ngModel)]="schedule.type" (click)="showSettingBlock('monthly', i, 'numeric')"> Numeric</span>
            </div>
            <div class="form-group mb-2 mt-3" *ngIf="schedule.setting">
                Repeat Every 
                <input class="w-50-px h-20-px pl-1" type="number" min="1" [(ngModel)]="schedule.setting.repeat_every" /> {{schedule.setting.entity}}

                <span *ngIf="schedule.setting.time"> at <input class="pl-1" type="time" [(ngModel)]="schedule.setting.time" /> </span>

                <span *ngIf="schedule.setting.day || schedule.setting.date">On </span>
                
                <select class="w-100-px h-20-px mr-2" *ngIf="schedule.setting.date" [(ngModel)]="schedule.setting.date">
                    <option *ngFor="let item of dates" value="{{item.value}}">{{item.label}}</option>
                </select>

                <select class="w-100-px h-20-px mr-2" *ngIf="schedule.setting.occurance" [(ngModel)]="schedule.setting.occurance">
                    <option *ngFor="let item of occurance" value="{{item.value}}">{{item.label}}</option>
                </select>
                
                <select class="w-100-px h-20-px" *ngIf="schedule.setting.day || schedule.type == 'weekday'" [(ngModel)]="schedule.setting.day">
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                </select>
            </div>
        </div>
    </div>
    <button class="btn btn-sm btn-white pull-right m-2" (click)="addSchedule()"><i class="fa fa-plus"></i></button>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="pm.schedules.length == 0" type="button" class="btn btn-white pull-right" (click)="submit()">Update</button>
    <button [hidden]="pm.schedules.length > 0" type="button" class="btn btn-white pull-right" (click)="submit()">Save</button>
</div>
<!-- end modal -->