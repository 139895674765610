import { Component, OnInit } from '@angular/core';
//Other Imports
import { WidgetService } from '../widget.service';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss']
})
export class WidgetListComponent implements OnInit {

  widgets: any = [];
  constructor(
    private _widgetService: WidgetService
  ) { }

  async ngOnInit(){
    this.widgets = await this._widgetService.getAllWidget();
    $.getScript("./assets/js/apex-custom-script.js");
    
   }
  
}
