import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-assign-to",
    templateUrl: "./assign-to.component.html",
    styleUrls: ["./assign-to.component.scss"],
})
export class AssignToComponent implements OnInit {
    @Input() assignedToData: any = {};


    constructor(
        public activeModal: NgbActiveModal
    ) { }
    ngOnInit(): void { }
}