import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from '../../../services/shared.service';
import { NGXToastrService } from '../../../services/toaster.service';

@Component({
  selector: "app-preventive-maintenance-form",
  templateUrl: "./preventive-maintenance-form.component.html",
  styleUrls: ["./preventive-maintenance-form.component.scss"],
})
export class PreventiveMaintenanceFormComponent implements OnInit {
  @Input() pm: any = {};
  @Input() priorities: any = [];
  @Input() users: any = [];
  @Input() teams: any = [];
  inputForm: FormGroup;
  submitted = false;
  assignedTo = '';
  priorityBlock = false;
  priorityData: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public _sharedService: SharedService,
    private _toasterService: NGXToastrService
  ) { }

  async ngOnInit() {
    this.setFormValidation();
    this.initializePriorityData();
    if (this.pm.users.length > 0) {
      this.assignedTo = 'user';
    } else if (this.pm.team_id) {
      this.assignedTo = 'team';
    } else {
      this.assignedTo = '';
    }
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      priority_id: ["", [Validators.required]],
      users: [""],
      team_id: [""],
    });
  }

  validateForm(obj) {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly");
    } else {
      obj.data.instructions = this.filterInstructions(obj.data.instructions);
      this.activeModal.close(obj);
    }
  }

  filterInstructions(instructions) {
    if (instructions.length > 0) {
      return instructions.filter(i => i.text)
    }
    return [];
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm["controls"][comtrolName]["errors"];
    return this.submitted && errors && errors[constraint];
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  addInstruction() {
    this.pm.instructions.push({
      text: '',
      fulfil: false
    });
  }

  removeInstruction(index) {
    this.pm.instructions.splice(index, 1)
  }

  toggleAddPriorityBlock() {
    this.priorityBlock = this.priorityBlock ? false : true;
  }

  initializePriorityData() {
    this.priorityData = {
        name: '',
        color: '',
    }
}

  async addPriority() {
    if (this.priorityData.name != '' && this.priorityData.color != '') {
        this.priorityData = await this._sharedService.addPriority(this.priorityData);
        if (this.priorityData) {
            this.priorities.push(this.priorityData);
        }
        this.initializePriorityData();
        this.toggleAddPriorityBlock();
    } else {
        this._toasterService.typeError('Please enter name and select color')
    }
  }
}
