import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { SharedService } from '../../../services/shared.service';

@Component({
    selector: 'app-viewer-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})

export class InfoComponent implements OnInit {

    @Input() obj: any = {};
    plants: any = [];
    locations: any = [];
    assets: any = [];
    components: any = []

    constructor(
        private _sharedService: SharedService,
    ) { }

    async ngOnInit() {
        switch (this.obj.type) {
            case 'location':
                this.plants = await this._sharedService.getPlants();
                break;
            case 'asset':
                this.locations = await this._sharedService.getLocations();
                break;
            case 'component':
                this.assets = await this._sharedService.getAssets();
                break;
        }
    }

    setMultiselectSetting(idField, textField) {
        return {
            singleSelection: false,
            idField: idField,
            textField: textField,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };
    }

    async getPlantsLocations() {
        this.locations = [];
        if (location) {
            this.locations = await this._sharedService.getPlantsLocations(this.locations);
        }
    }

    async updateRecource(event, property) {
        this.obj.resource[property] = event.target.value;
        switch (this.obj.type) {
            case 'plant':
                await this._sharedService.updatePlant(this.obj.resource);
                break;
            case 'location':
                await this._sharedService.updateLocation(this.obj.resource);
                break;
            case 'asset':
                await this._sharedService.updateAsset(this.obj.resource);
                break;
            case 'component':
                await this._sharedService.updateComponent(this.obj.resource);
                break;
                
        }
    }
}