<div class="modal-header">
    <h5 class="modal-title">Parts</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body bg-white">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Parts *</label>
                    <ng-multiselect-dropdown [placeholder]="'Select Parts'" [settings]="setMultiselectSetting('part_id','name_number')" [data]="parts" formControlName="parts">
                    </ng-multiselect-dropdown>
                    <div [hidden]="!error('parts','required')" class="invalid">Part is required</div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right" (click)="validateForm()">Save</button>
</div>