<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">Machine Start</div>
            <div class="card-body">
                <div class="form-group">
                    <div class="icheck-material-white">
                        <input type="radio" id="radio-7" value="" name="aaaa" class="custom-control-input">
                        <label for="radio-7">Machine Start, 10 Seconds</label>
                    </div>
                    <div class="icheck-material-white">
                        <input type="radio" id="radio-8" value="" name="aaaa" class="custom-control-input">
                        <label for="radio-8">Machine Start, 20 Seconds</label>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-light px-5">Save</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">Machine Stop</div>
            <div class="card-body">
                <div class="form-group">
                    <div class="icheck-material-white">
                        <input type="radio" id="radio-9" value="" name="aaaa" class="custom-control-input">
                        <label for="radio-9">Machine Stop, 10 Mintues</label>
                    </div>
                    <div class="icheck-material-white">
                        <input type="radio" id="radio-10" value="" name="aaaa" class="custom-control-input">
                        <label for="radio-10">Machine Stop, 20 Mintues</label>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-light px-5">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>