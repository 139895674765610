import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { WidgetRoutingModule } from './widget-routing.module';
import { WidgetComponent } from './widget.component';
import { WidgetListComponent } from './widget-list/widget-list.component';
import { WidgetFormComponent } from './widget-form/widget-form.component';
import { SensorWidgetFormComponent } from './sensor-widget-form/sensor-widget-form.component';
import { OptionFormComponent } from './option-form/option-form.component';
import { WidgetService } from './widget.service';

@NgModule({
  imports: [
    CommonModule,
    WidgetRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    NgApexchartsModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [
    WidgetComponent,
    WidgetListComponent,
    WidgetFormComponent,
    SensorWidgetFormComponent,
    OptionFormComponent
  ],
  providers: [
    WidgetService
  ],
  entryComponents:[
    WidgetFormComponent,
    SensorWidgetFormComponent,
    OptionFormComponent
  ]
})
export class WidgetModule { }
