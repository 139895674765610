
<div class="row">
    <div class="col-sm-6 col-md-3">
        <div class="card card-widget">
            <div class="card-header text-center">
                Gateways
            </div>
            <div class="card-body">
                <label class="ml-2 mt-3 mb-0" [hidden]="bluzoneGateWaysData.items.length == 0">Bluzone</label>
                <label class="mr-2 mt-3 mb-0 pull-right" [hidden]="bluzoneGateWaysData.total == 0">  {{ bluzoneGateWaysData.active }} / {{ bluzoneGateWaysData.total }}</label>
                <div class="row pointer" [hidden]="bluzoneGateWaysData.items.length == 0" (click)="openGatewaysDetailsModal(bluzoneGateWaysData)">
                    <div class="col-12">
                        <div class="progress-wrapper mt-2 mb-2 mx-2">
                            <div class="progress" title="{{bluzoneGateWaysData.type}}" style="height:7px; border: solid 1px #45B6FF">
                                <div class="progress-bar bg-info" [style.width.%]="bluzoneGateWaysData.active / bluzoneGateWaysData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <label class="ml-2 mt-4 mb-0" [hidden]="imonnitGateWaysData.items.length == 0">Imonnit</label>
                <label class="mr-2 mt-3 mb-0 pull-right" [hidden]="imonnitGateWaysData.total == 0">   {{ imonnitGateWaysData.active }} / {{ imonnitGateWaysData.total }}</label>

                <div class="row mb-4 pointer" [hidden]="imonnitGateWaysData.items.length == 0" (click)="openGatewaysDetailsModal(imonnitGateWaysData)">
                    <div class="col-12">
                        <div class="progress-wrapper mt-2 mb-2 mx-2">
                            <div class="progress" title="{{imonnitGateWaysData.type}}" style="height:7px; border: solid 1px #F78802">
                                <div class="progress-bar bg-warning" [style.width.%]="imonnitGateWaysData.active / imonnitGateWaysData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                       
                    </div>
                </div>    
                <h1 class="text-center mt-5 mb-5" *ngIf="image(gateways)"><img src="{{ gateways }}" height="40" /></h1>
            </div>
        </div>
    </div>    
    <div class="col-sm-6 col-md-3">
        <div class="card card-widget">
            <div class="card-header text-center">
                Sensors
            </div>
            <div class="card-body">
                <label class="ml-2 mt-3 mb-0" [hidden]="bluzoneSensorsData.total == 0">Bluzone</label>
                <label class="mr-2 mt-3 mb-0 pull-right" [hidden]="bluzoneSensorsData.total == 0">{{ bluzoneSensorsData.active }} / {{ bluzoneSensorsData.total }}</label>
                <div class="row pointer" [hidden]="bluzoneSensorsData.total == 0">
                    <div class="col-12">
                        <div class="progress-wrapper mt-2 mb-2 mx-2">
                            <div class="progress" title="{{ bluzoneSensorsData.title }}" style="height:7px; border: solid 1px #45B6FF">
                                <div class="progress-bar bg-info" [style.width.%]="bluzoneSensorsData.active / bluzoneSensorsData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <label class="ml-2 mt-3 mb-0" [hidden]="treonSensorsData.total == 0">Treon</label>
                <label class="mr-2 mt-3 mb-0 pull-right"  [hidden]="treonSensorsData.total == 0">{{ treonSensorsData.active }} / {{ treonSensorsData.total }}</label>
                <div class="row pointer" [hidden]="treonSensorsData.total == 0">
                    <div class="col-12">
                        <div class="progress-wrapper mt-2 mb-2 mx-2">
                            <div class="progress" title="{{ treonSensorsData.title }}" style="height:7px; border: solid 1px #7934f3">
                                <div class="progress-bar bg-primary" [style.width.%]="treonSensorsData.active / treonSensorsData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <label class="ml-2 mt-4 mb-0" [hidden]="imonnitSensorsData.total == 0">Imonnit</label>
                <label class="mr-2 mt-4 mb-0 pull-right" [hidden]="imonnitSensorsData.total == 0">{{ imonnitSensorsData.active }} / {{ imonnitSensorsData.total }}</label>
                <div class="row pointer" [hidden]="imonnitSensorsData.total == 0">
                    <div class="col-12">
                        <div class="progress-wrapper mt-2 mb-2 mx-2">
                            <div class="progress" title="{{imonnitSensorsData.type}}" style="height:7px; border: solid 1px #F78802">
                                <div class="progress-bar bg-warning" [style.width.%]="imonnitSensorsData.active / imonnitSensorsData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                </div>    

                <label class="ml-2 mt-4 mb-0" [hidden]="rioSensorsData.total == 0">Rio</label>
                <label class="mr-2 mt-4 mb-0 pull-right" [hidden]="rioSensorsData.total == 0">{{ rioSensorsData.active }} / {{ rioSensorsData.total }}</label>
                <div class="row mb-4 pointer" [hidden]="rioSensorsData.total == 0">
                    <div class="col-12">
                        <div class="progress-wrapper mt-2 mb-2 mx-2">
                            <div class="progress" title="{{rioSensorsData.type}}" style="height:7px; border: solid 1px #5EBA64">
                                <div class="progress-bar bg-success" [style.width.%]="rioSensorsData.active / rioSensorsData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                </div>    

                <label class="ml-2 mt-4 mb-0" [hidden]="optimeSensorsData.total == 0">Optime</label>
                <div class="row mb-4 pointer" [hidden]="optimeSensorsData.total == 0">
                    <div class="col-md-9">
                        <div class="progress-wrapper mt-2 mb-2 ml-2">
                            <div class="progress" title="{{optimeSensorsData.type}}" style="height:7px; border: solid 1px #5EBA64">
                                <div class="progress-bar bg-success" [style.width.%]="optimeSensorsData.active / optimeSensorsData.total * 100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        {{ optimeSensorsData.active }} / {{ optimeSensorsData.total }}
                    </div>
                </div>    
                <h1 class="text-center mt-5 mb-5" *ngIf="image(sensors)"><img src="{{ sensors }}" height="40" /></h1>
            </div>
        </div>
    </div>  
    <div class="col-sm-6 col-md-3">
        <div class="card card-widget">
            <div class="card-header text-center">
                Active Alerts
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h1 class="text-center mt-4 mb-4" *ngIf="!image(activeAlerts)">{{ activeAlerts }}</h1>
                        <h1 class="text-center mt-4 mb-4" *ngIf="image(activeAlerts)"><img src="{{ activeAlerts }}" height="40" /></h1>
                    </div>
                </div>
            </div>
            <div class="card-footer text-center">
                <span (click)="openAlertsDetails()" class="pointer">Click for details</span>
            </div>
        </div>
    </div> 
    <div class="col-sm-6 col-md-3">
        <div class="card card-widget">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h6 class="text-center mt-4">{{dateNow | date: 'EEEE, HH:mm:ss'}}</h6>
                        <h5 class="text-center mt-4">{{dateNow | date: 'MMMM'}}</h5>
                        <h2 class="text-center">{{dateNow | date: 'd'}}</h2>
                        <h5 class="text-center mb-4">{{dateNow | date: 'y'}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>   
<div class="row mt-3">
    <div class="col-md-6">
        <div class="card card-widget">
            <div class="card-body text-center">
                <div id="dvMap" style="width:100%;height:400px"></div>
            </div>
        </div>
    </div>  
    <div class="col-md-6">
            <div id="openweathermap-widget-15"></div>
    </div>    
</div>   
<div class="row mt-3">
    <div class="col-12">
        <app-component-loader *ngIf="dashboard.dashboard_components"
        [rendercomponent]="dashboard.dashboard_components"></app-component-loader> 
    </div>   
</div>   