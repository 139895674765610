import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SidebarService } from "../sidebar/sidebar.service";
import { LocalstorageService } from '../../../services/localstorage.service';

import * as $ from 'jquery';


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    
    public menuItems: any[];
    public logo: any[];

  
    constructor( 
        public sidebarservice: SidebarService,
        private router: Router,
        private _localstorageService: LocalstorageService
        ) {

        router.events.subscribe( (event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd && $(window).width() < 1025 && ( document.readyState == 'complete' || false ) ) {

                this.toggleSidebar();
                // Hide loading indicator
               
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                console.log(event.error);
            }
        });

    }

        
    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    }

    getSideBarState() {
        return this.sidebarservice.getSidebarState();
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true);
    }
    

    ngOnInit() {
        let setting = this._localstorageService.getSetting();
        this.logo = setting.logo;
        let user = this._localstorageService.getUserData();
        this.menuItems = ROUTES.filter(menuItem => {
            if(menuItem.submenu.length > 0){
                menuItem.submenu = menuItem.submenu.filter(subMenuItem => {
                    return user.menu_items.includes(subMenuItem.path.replace(/\//g, ''))
                });
            }
            return user.menu_items.includes(menuItem.path.replace(/\//g, ''))
        });

        if(user.menu_items.includes('support')) {
            let project = this._localstorageService.getProjectData();
            this.menuItems.push(
            { 
                path: 'mailto:'+project.support_email, title: 'Support', icon: 'zmdi zmdi-help-outline', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] 
            },
            );
            
            
        }
        $.getScript('./assets/js/app-sidebar.js');

    }

    logOut(){
        this._localstorageService.clearStorage();
        this.router.navigate(['/auth/signin']);
    }

}
