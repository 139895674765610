import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { PartFormComponent } from "../../part/part-form/part-form.component";
import { VendorFormComponent } from "../../vendor/vendor-form/vendor-form.component";

import { SharedService } from "../../../services/shared.service";
import { RequisitionService } from "../requisition.service";
import { IntializerService} from "../../../services/intializer.service"

@Component({
    selector: "app-requisition-form",
    templateUrl: "./requisition-form.component.html",
    styleUrls: ["./requisition-form.component.scss"]
})

export class RequisitionFormComponent implements OnInit {
    @Input() requisition: any = {};
    @Input() vednors: any = {};
    @Input() workOrders: any = [];
    inputForm: FormGroup;
    submitted = false;
    showItemblock = false;
    vendors: any = []
    parts: any = []
    requisitiontype: any
    user: any;
    orderNumber = 1;
    vendorWarning = false;
    requisitionsWarning = false;
    requisitionItem: any = {};

    constructor(
        public activeModal: NgbActiveModal,
        public _sharedService: SharedService,
        public _requisitionService: RequisitionService,
        private formBuider: FormBuilder,
        public modalService: NgbModal,
        public _intializerService:IntializerService
    ) { }

    async ngOnInit() {
        this.setFormValidation();
            if(!this.requisition.number){
            let latestRequisition = await this._requisitionService.getLatestRequisition();
            latestRequisition && (this.orderNumber = latestRequisition.number + 1);
        }
    }

    setFormValidation() {
        this.inputForm = this.formBuider.group({
            number: [this.requisition.number, [Validators.required]],
            order_date: [ new Date(this.requisition.order_date), [Validators.required]],
            required_date: [ new Date(this.requisition.order_date), [Validators.required]],
            vendor_id: [this.requisition.vendor_id, [Validators.required]],
            department: [this.requisition.department, [Validators.required]],
            delivered_at: [this.requisition.delivered_at, [Validators.required]]
        });
    }

    error(controlName, constraint) {
        let errors = this.inputForm["controls"][controlName]["errors"];
        return this.submitted && errors && errors[constraint];
    }

    async validateForm(action) {
        this.submitted = true;
        if (!this.inputForm.invalid && !this.requisitionsWarning) {
            let data = this.inputForm.value;
            data.requisitions = this.requisition.requisitions;
            this.activeModal.close({ action: action, data: data });
        } else {
            this.requisitionsWarning = true;
            console.log("Please fill part form completely");
        }
    }

    async addToRequisitions() {
        if (this.requisitionItem.part_id || this.requisitionItem.service_name) {
            this.requisition.requisitions.push(this.requisitionItem);
            this.requisitionItem = {};
            this.requisitionsWarning = false;
            this.toggleItemBlock();
        }
    }

    toggleItemBlock() {
        if (this.inputForm.value.vendor_id) {
            this.showItemblock = this.showItemblock ? false : true;
            this.requisitiontype = undefined;
        } else {
            this.vendorWarning = true;
        }

    }

    async getPartByVendor() {
        this.parts = await this._sharedService.getParts(`?vendor_id=${this.inputForm.value.vendor_id}`);
        this.vendorWarning = false;
        this.requisition.requisitions = [];
    }

    async getWorkorder(){
        this.workOrders = await this._sharedService.getWorkOrders({})
    }

    getPartName(part_id) {
        if(this.parts.length > 0){
            return  this.parts.find(p => p.part_id == part_id).name;
        }
    } 

    getWorkOrderName(work_order_id) {
        if(this.workOrders.length > 0){
            return  this.workOrders.find(w => w.work_order_id == work_order_id).name;
        }
    } 

    getPartId(part_id) {
        if(this.parts.length > 0){
            return  this.parts.find(p => p.part_id == part_id).number;
        }
    } 

    removeRequisition(index) {
        this.requisition.requisitions.splice(index, 1)
    }


    async openPartFormModal() {
        let part = this._intializerService.intializePart();
        const modalRef = this.modalService.open(PartFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
        modalRef.componentInstance.part = part;
        modalRef.componentInstance.categories = await this._sharedService.getCategory();
        modalRef.componentInstance.vendors = this.vendors;
        modalRef.componentInstance.assets = await this._sharedService.getAssets();
        modalRef.componentInstance.workOrders = this.workOrders;
        modalRef.result.then(async (result) => {
            if (result.action == 'add') {
                part = await this._sharedService.addPart(result.data);
                part && this.parts.push(part);
            } 
        }, async (reason) => {
            console.log(reason);
        });

    }

    async openVendorFormModal() {
        let vendor = this._intializerService.initializeVendor();
        const modalRef = this.modalService.open(VendorFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
        modalRef.componentInstance.vendor = vendor;
        modalRef.result.then(
          async (result) => {
            if (result.action == "add") {
              vendor = await this._sharedService.addVendor(result.vendor);
              vendor && this.vendors.push(vendor);
            } 
          }, async (reason) => {
            console.log(reason);
          }
        );
    }

}
