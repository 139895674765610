<div class="row pt-2 pb-2">
    <div class="col-sm-9">
        <h4 class="page-title">Trend Data <small>({{sensor.name}})</small></h4>
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javaScript:;">RAMS</a>
            </li>
            <li class="breadcrumb-item"><a href="javaScript:;">Sensor</a>
            </li>
            <li class="breadcrumb-item"><a href="javaScript:;">Trend Data</a>
            </li>
        </ol>
    </div>
    <div class="col-sm-3">
        <div ngbDropdown class="btn-group float-sm-right">
            <app-pin [pinData]='pinData'></app-pin>
            <app-expand-screen></app-expand-screen>
        </div>
    </div>
</div>
<div class="card card-trend-data" *ngFor="let sensorWidget of widgets; let i = index">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-lg-4 col-xl-4" *ngFor="let swa of sensorWidget.attributes">
                <div class="form-group" *ngIf="swa.type == 'textbox'">
                    <label>{{ swa.label }}</label>
                    <input type="text" class="form-control" [(ngModel)]="sensorWidget.postData[swa.key]">
                </div>

                <div class="form-group" *ngIf="swa.type == 'select'" [hidden]="sensorWidget.highResolution && swa.key=='interval'">
                    <label>{{ swa.label }}</label>
                    <select class="form-control" *ngIf="swa.type == 'select'" [(ngModel)]="sensorWidget.postData[swa.key]" (change)="checkHighResolution(i)">
                        <option [value]="undefined">Select {{ swa.label }}</option>
                        <option *ngFor="let option of swa.options" [value]="option.key">{{ option.label }}</option>
                    </select>
                </div>

                <div class="form-group" *ngIf="swa.type == 'checkbox'">
                    <label>{{ swa.label }}</label>
                    <div class="icheck-material-white" *ngFor="let option of swa.options">
                        <input type="checkbox" id="check-{{ swa.key }}" [(ngModel)]="sensorWidget.postData[swa.key]" />
                        <label for="check-{{ swa.key }}">{{ option.label }}</label>
                    </div>
                </div>
                <div class="form-group" *ngIf="swa.type == 'radio'">
                    <label>{{ swa.label }}</label>
                    <div class="icheck-material-white" *ngFor="let option of swa.options">
                        <input type="radio" id="radio-{{ swa.key }}{{ option.key }}" value="{{ option.key }}" name="{{ swa.key }}" [(ngModel)]="sensorWidget.postData[swa.key]" class="custom-control-input">
                        <label for="radio-{{ swa.key }}{{ option.key }}">{{ option.label }}</label>
                    </div>
                </div>
                <div class="form-group" *ngIf="swa.type == 'date'">
                    <label>{{ swa.label }}</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="mm-dd-yyyy" [maxDate]="maxDate" [(ngModel)]="sensorWidget.postData[swa.key]" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-light fa fa-calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-4" [hidden]="sensorWidget.highResolution || sensorWidget.widget_id == 'chart11' || comparedSensors.length == 0">
                <div class="form-group">
                    <label>Compare With</label>
                    <ng-multiselect-dropdown [placeholder]="'Select Sensors'" [settings]="setMultiselectSetting('sensor_unique_id','name')" [data]="comparedSensors" [(ngModel)]="sensorWidget.postData.selected_sensors">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="form-group">
                    <button type="button" class="btn btn-white pull-right" (click)="getWidgetData(sensorWidget, i)">Create Graph</button>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="sensorWidget.highResolution">
            <div class="col-12 col-lg-12 col-xl-12">
                <div *ngIf="loadingSimpleWidget" class="text-center"><img src="assets/images/gauges/loading-gauges.gif" height="300" /></div>
                <app-line-chart *ngIf="!loadingSimpleWidget && sensorWidget.widget_id == 'chart1' && !sensorWidget.compare" [series]="sensorWidget.simple.series" [xaxis]="sensorWidget.simple.xaxis"></app-line-chart>
                <app-line-chart *ngIf="!loadingSimpleWidget && sensorWidget.widget_id == 'chart1' && sensorWidget.compare" [series]="sensorWidget.simple.series" [xaxis]="sensorWidget.simple.xaxis" curve="straight"></app-line-chart>
                <div *ngIf="!loadingSimpleWidget && sensorWidget.widget_id == 'chart11' && sensor.type == 'imonnit'">
                    <label class="ml-3">Time Bases</label>
                    <app-stepper-chart [series]="sensorWidget.simple.series" [xaxis]="sensorWidget.simple.xaxis" [unit]="sensor.unit"></app-stepper-chart>
                    <label class="ml-3" *ngIf="sensor.unit == 'Open-Closed'">Longest Open</label>
                    <app-bar-chart *ngIf="sensor.unit == 'Open-Closed'" [series]="sensorWidget.longestOpen.series" [xaxis]="sensorWidget.longestOpen.xaxis" [tooltip]="sensorWidget.longestOpen.tooltip"></app-bar-chart>
                    <label class="ml-3" *ngIf="sensor.unit == 'Open-Closed'">Longest Close</label>
                    <app-bar-chart *ngIf="sensor.unit == 'Open-Closed'" [series]="sensorWidget.longestClose.series" [xaxis]="sensorWidget.longestClose.xaxis" [tooltip]="sensorWidget.longestClose.tooltip"></app-bar-chart>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!sensorWidget.highResolution">
            <div class="col-12 col-lg-12 col-xl-12">
                <label class="ml-3">Peak To Peak</label>
                <app-line-chart [series]="sensorWidget.peakToPeak.series" [xaxis]="sensorWidget.peakToPeak.xaxis"></app-line-chart>
                <label class="ml-3">Rms</label>
                <app-line-chart [series]="sensorWidget.rms.series" [xaxis]="sensorWidget.rms.xaxis"></app-line-chart>
                <label class="ml-3">Crest Factor</label>
                <app-line-chart [series]="sensorWidget.crestFector.series" [xaxis]="sensorWidget.crestFector.xaxis"></app-line-chart>
            </div>
        </div>
    </div>
</div>