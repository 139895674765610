import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PartService {
    constructor(private _httpClient: HttpClient) { }
  
    deletePart(partId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .delete((<any>window).appBaseUrl + "/part/" + partId)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    addCategory(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/category', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getCategory(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/category")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPartByNumber(partNumber): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/part-by-number/" + partNumber)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
