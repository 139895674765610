import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShareDataService {

   obj: any = {};
   manage = new BehaviorSubject(this.obj);

  constructor() { }

  manageDashboard(pinData: object) {
    this.manage.next(pinData)
  }

}