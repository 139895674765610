import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { PreventiveMaintenanceFormComponent } from "../preventive-maintenance-form/preventive-maintenance-form.component";
import { ScheduleFormComponent } from "../schedule/schedule-form.component";
import { ResourceHierarchyComponent } from "../../shared/resource-hierarchy/resource-hierarchy.component";
import { PreventiveMaintenanceService } from "../preventive-maintenance.service";
import { ModalComponent } from "../../modal/modal.component";
import { AssignToComponent } from "src/app/shared/assign-to/assign-to.component";
import { SharedService } from '../../../services/shared.service';
import { HelperService } from '../../../services/helper.service';
import { AssetService } from '../../asset/asset.service';
import { cloneDeep } from "lodash";

@Component({
  selector: "app-preventive-maintenance-list",
  templateUrl: "./preventive-maintenance-list.component.html",
  styleUrls: ["./preventive-maintenance-list.component.scss"],
})
export class PreventiveMaintenanceListComponent implements OnInit {
  @Input() obj: any;
  pms: any = [];
  pm: any = {};
  users: any = [];
  teams: any = [];

  constructor(
    private modalService: NgbModal,
    private _pmService: PreventiveMaintenanceService,
    private _sharedService: SharedService,
    private _helperService: HelperService,
    public _assetService: AssetService
  ) { }

  async ngOnInit() {
    this.initializePm();
    this.users = await this._sharedService.getUsers();
    this.teams = await this._sharedService.getTeams();
    if (this.obj) {
      this.pms = await this._pmService.getPmByResource(this.obj.type, this.obj.id);
    } else {
      this.pms = await this._pmService.getPm();
    }
  }

  async initializePm() {
    this.pm = {
      name: '',
      assets: [],
      components: [],
      schedules: [],
      instructions: [],
      priority_id: '',
      users: [],
      team_id: '',
    };
  }

  async autoAssignResource() {
    this.initializePm();
    if (this.obj.type == 'asset') this.pm.assets = [{ asset_id: this.obj.resource.asset_id, name: this.obj.resource.name }];
    if (this.obj.type == 'component') this.pm.components = [{ component_id: this.obj.resource.component_id, name: this.obj.resource.name }];
    this.openPreventiveMaintenanceFormModal(this.pm);
  }

  async openPreventiveMaintenanceFormModal(pm, index = 0) {
    const modalRef = this.modalService.open(PreventiveMaintenanceFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
    modalRef.componentInstance.pm = { ...pm };
    modalRef.componentInstance.priorities = await this._sharedService.getPriority();
    modalRef.componentInstance.users = await this._sharedService.getUsers();
    modalRef.componentInstance.teams = await this._sharedService.getTeams();
    modalRef.result.then(
      async (result) => {
        if (result.action == "add") {
          pm = await this._pmService.addPm(result.data);
          pm && this.pms.push(pm);
        } else {
          pm = await this._pmService.updatePm(result.data);
          pm && (this.pms[index] = pm);
        }
        this.pms = [...this.pms];
        this.initializePm();
      }, async (reason) => {
        console.log(reason);
      });
  }

  async openScheduleModal(pm, index = 0) {
    const modalRef = this.modalService.open(ScheduleFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.pm = cloneDeep(pm);
    modalRef.result.then(
      async (result) => {
        pm = await this._pmService.updatePm(result.data);
        pm && (this.pms[index] = pm);
        this.pms = [...this.pms]
      }, async (reason) => {
        console.log(reason)
      }
    );
  }

  async openConfirmationModal(pmId, index = 0) {
    const modalRef = this.modalService.open(ModalComponent, {
      scrollable: true,
      backdrop: "static",
    });
    modalRef.componentInstance.heading = "Delete Preventive Maintenance";
    modalRef.componentInstance.content = "Are you sure you want to delete this Preventive Maintenance?";
    modalRef.result.then(async (result) => {
      let pm = await this._pmService.deletePm(pmId);
      pm && this.pms.splice(index, 1);
      this.pms = [...this.pms];
    }, (reason) => {
      console.log(reason);
    });
  }

  getName(id, attr, arr) {
    if (id && attr && arr.length > 0)
      return arr.find(a => a[attr] == id).name;

    return "";
  }

  mapp(number, label) {
    if (number == 1) {
      return label;
    } else {
      return label + 's';
    }
  }

  getScheduleStatement(setting) {
    if (!setting)
      return '';
    let str = ` Repeat Every ${setting.repeat_every} ${this.mapp(setting.repeat_every, setting.entity)}`;
    setting.time && (str += ' at ');
    setting.time && (str += this._helperService.formatTime(setting.time));
    (setting.date || setting.day) && (str += ' On ');
    setting.occurance && (str += this._helperService.formatNumber(setting.occurance));
    setting.date && (str += this._helperService.formatNumber(setting.date));
    setting.day && (str += setting.day);
    return str;
  }

  async openResourceHierarchyModal(action, pm) {
    let selected = { assets: [], components: [] };
    if (action == 'edit') {
      selected.assets = pm.assets;
      selected.components = pm.components;
    }
    const modalRef = this.modalService.open(ResourceHierarchyComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.selected = selected;
    modalRef.componentInstance.assets = await this._sharedService.getAssets();

    modalRef.result.then(async (result) => {
      let data = {
        pm_id: pm.pm_id,
        assets: result.assets,
        components: result.components
      }
      pm = await this._pmService.updatePm(data);
      if (pm) {
        let index = this.pms.findIndex(m => m.pm_id == pm.pm_id);
        this.pms[index] = pm;
      }
      this.pms = [...this.pms];
    }, (reason) => {
      console.log(reason);
    });
  }

  belongsTo(pm) {
    if (pm.assets.length > 0 || pm.components.length > 0)
      return true;
    else
      return false
  }

  openAssignTo(pm) {
    const modalRef = this.modalService.open(AssignToComponent, { scrollable: true, backdrop: 'static', size: 'md' });
    modalRef.componentInstance.assignedToData = pm;
  }
}
