import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../../../services/shared.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from "../../../services/helper.service";

@Component({
  selector: 'app-requisition-print',
  templateUrl: './requisition-print.component.html',
  styleUrls: ['./requisition-print.component.scss']
})
export class RequisitionPrintComponent implements OnInit {
  @Input() requisition: any = {};
  @Input() workOrders: any = [];
  parts: any = [];
  project: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    public _sharedService: SharedService,
    public _localstorageService: LocalstorageService,
    public helperService: HelperService,

  ) { }

  async ngOnInit() {
    this.project = this._localstorageService.getProjectData();
    await this.getPartByVendor();
  }

  print() {
    let printContents = document.getElementById('print-section').innerHTML;
    const win = window.open();
    win.document.body.innerHTML = `<html><head>${$('head').html()}</head><body>${printContents}</body></html>`;
    win.print();
    win.close();

  }

  async getPartByVendor() {
    this.parts = await this._sharedService.getParts(`?vendor_id=${this.requisition.vendor_id}`);
  }

  getPartName(part_id) {
    return this.parts.length > 0 ? this.parts.find(p => p.part_id == part_id).name : '';
  }

  getPartId(part_id) {
    return this.parts.length > 0 ? this.parts.find(p => p.part_id == part_id).number : '';
  }

  getWorkOrderName(work_order_id) {
    if(this.workOrders.length > 0){
        return  this.workOrders.find(w => w.work_order_id == work_order_id).name;
    }
} 
}
