import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { NGXToastrService } from '../../../services/toaster.service';

@Component({
  selector: 'app-sensor-treon-fft',
  templateUrl: './sensor-treon-fft.component.html',
  styleUrls: ['./sensor-treon-fft.component.scss']
})
export class SensorTreonFftComponent implements OnInit {
  @Input() sensor: any;
  axis: any = '';
  date: any = '';
  time_slot_id: any = '';
  fftData: any;
  timeSlots: any = [];

  fft: any = {
    series: [],
    xaxis: {},
    yaxis: {}
  };


  constructor(
    private _sensorService: SensorService,
    private _toasterService: NGXToastrService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
  }

  resetAxisAndFft() {
    this.axis = '';
    this.fftData = null;
    this.fft = {
      series: [],
      xaxis: {},
      yaxis: {}
    };
  }

  async getTimeSlots() {
    this.resetAxisAndFft();
    this.time_slot_id = '';
    this.timeSlots = await this._sensorService.getTreonFftTimeSlots({ sensor_id: this.sensor.sensor_id, date: this.date });
    if (this.timeSlots.length == 0) {
      this._toasterService.typeError('No FFT data available for this date');
    }
  }

  async getDataForTreonFft() {
    this._toasterService.typeInfoStayOnScreen('Please stay on this screen and do not start other operations until success message appears.', 3000);

    setTimeout(() => {
      this._toasterService.typeWarningStayOnScreen('Getting fft data...', 600000);
    }, 3000);

    setTimeout(async () => {
      this.fftData = await this._sensorService.getDataForTreonFft({ sensor_id: this.sensor.sensor_id, time_slot_id: this.time_slot_id });
      if (this.fftData) {
        this._toasterService.clearToast();
        this._toasterService.typeSuccess('Please select axis to draw fft graph');
      }
    }, 6000);

  }

  createChartFFT() {
    if (this.fftData) {
      let y = [];
      switch (this.axis) {
        case 'Horizontal': {
          y = this.fftData.X;
          break;
        }
        case 'Axial': {
          y = this.fftData.Y;
          break;
        }
        case 'Radial': {
          y = this.fftData.Z;
          break;
        }
      }

      let ymax = Math.max(...y);
      let ymin = Math.min(...y);

      let xaxis: any = {
        min: 0,
        max: y.length - 1,
        type: "numeric",
        title: {
          text: "Frequency (Hz)",
          offsetY: 15,
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
          }
        },
        labels: {
          show: true,
          formatter: function (value) {
            return value.toFixed(0) + ' Hz';
          }
        }
      }

      let yaxis: any = {
        min: ymin,
        max: ymax,
        title: {
          text: 'Amplitude (in/s)',
          offsetY: 15,
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
          }
        },
        labels: {
          show: true,
          formatter: function (value) {
            return value.toFixed(4);
          }
        }
      }

      this.fft.xaxis = xaxis;
      this.fft.yaxis = yaxis;
      this.fft.series = [{ data: y.map((v, i) => ({ x: i, y: v })), name: 'Amplitude (in/s)' }];
      this._toasterService.typeSuccess(this.axis + ' Graph Plotted Successfully');
    }
  }

  formatDateWithTime(d) {
    let date = new Date(d)
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + " " + strTime;
  }

  async onDemandTreonFft() {
    let response = await this._sensorService.onDemandTreonFft(this.sensor);
    if (response) {
      this._toasterService.typeSuccess('Job created successfully <br> Check back in 15 minutes');
    } else {
      this._toasterService.typeError('Something bad happened');
    }
  }

}
