<div class="modal-header">
    <h5 class="modal-title">Widget List</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-12" *ngFor="let widget of widgets">
			<div class="card">
				<div class="card-header text-uppercase">
					<button class="btn btn-light" *ngFor="let sensorWidget of sensorWidgetsByWidgetId(widget.widget_id)" (click)="openSensorWidgetModal(widget.widget_id, sensorWidget.tab_id)">Edit {{ sensorWidget.tab_id  }} {{ widget.name }}</button>

					<button (click)="openSensorWidgetModal(widget.widget_id, 'new')" class="btn btn-light">Add New {{ widget.name }}</button>
				</div>
				<div class="card-body">
					<div id="{{ widget.widget_id }}" style="background-color: #2D323E;"></div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cross click')">Close</button>
</div>