import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NGXToastrService } from '../../../services/toaster.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: "app-schedule-form",
  templateUrl: "./schedule-form.component.html",
  styleUrls: ["./schedule-form.component.scss"],
})
export class ScheduleFormComponent implements OnInit {
  @Input() pm: any = {};
  occurance: any = [];
  dates: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    public _toasterService: NGXToastrService,
    private helperService: HelperService,
  ) { }

  ngOnInit(): void {
    for (let i = 1; i <= 31; i++) {
      if (i <= 4) {
        this.occurance.push({ value: i, label: this.helperService.formatNumber(i) })
      }
      this.dates.push({ value: i, label: this.helperService.formatNumber(i) })
    }
  }

  removeSetting(index) {
    delete this.pm.schedules[index].type;
    delete this.pm.schedules[index].setting;
  }

  showSettingBlock(value, index, type = '') {
    let setting: any = {};
    if (value == 'daily') {
      setting.repeat_every = 1;
      setting.time = '12:00';
      setting.entity = 'Day';
    } else if (value == 'weekly') {
      setting.repeat_every = 1;
      setting.day = 'Monday';
      setting.entity = 'Week';
    } else if (value == 'monthly' && type != '') {
      setting.repeat_every = 1;
      setting.entity = 'Month';
      if (type == 'weekday') {
        setting.occurance = '1';
        setting.day = 'Monday';
      } else if (type == 'numeric') {
        setting.date = '1';
      }
    }
    this.pm.schedules[index].recurrence = value;
    this.pm.schedules[index].setting = setting;
  }

  addSchedule() {
    this.pm.schedules.push({})
  }

  removeSchedule(index) {
    this.pm.schedules.splice(index, 1)
  }

  submit() {
    if (!this.pm.schedules[0]) {
      this._toasterService.typeError('Please add schedule')
    } else if (!this.pm.schedules[0].recurrence) {
      this._toasterService.typeError('Please configure schedule')
    } else {
      this.activeModal.close({ data: this.pm });
    }
  }
}
