import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Other Imports
import { SensorService } from '../sensor.service';
import { ModalComponent } from '../../modal/modal.component';
import { NGXToastrService } from '../../../services/toaster.service';
import { SelectHistoryModalComponent } from '../select-history-modal/select-history-modal.component';

@Component({
  selector: 'app-sensor-fft',
  templateUrl: './sensor-fft.component.html',
  styleUrls: ['./sensor-fft.component.scss']
})
export class SensorFftComponent implements OnInit {
  @Input() sensor: any;
  axisSettings: any = {};
  mAxis: any = '';
  saveIt: any;
  jobId: any;
  jobStatus: any;
  fftArray: any = [];

  fft: any = {
    series: [],
    xaxis: {},
    yaxis: {}
  };

  waveForm: any = {
    series: [],
    xaxis: {},
    yaxis: {}
  };

  jobStatusArray = ["PENDING", "CONNECTING", "READING", "SCHEDULED", "CONNECTED"];
  isHann = false;
  fftData: any = [];
  fftType = 'acceleration';

  constructor(
    private _sensorService: SensorService,
    private _toasterService: NGXToastrService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    this.axisSettings = await this._sensorService.getAxisSettings(this.sensor.sensor_id) || { horizontal: 'HIGH_RES#X_ACCEL', axial: 'HIGH_RES#Y_ACCEL', radial: 'HIGH_RES#Z_ACCEL' };
  }

  async openSelectHistoryModal() {
    let data: any = {
      sensor_id: this.sensor.sensor_id,
      axis: this.mAxis
    }
    let historyDates = await this._sensorService.getFftHistoryDates(data);
    if (historyDates.length == 0) {
      this._toasterService.typeInfo('No FFT data available for this sensor');
    } else {
      const modalRef = this.modalService.open(SelectHistoryModalComponent, { backdrop: 'static' });
      modalRef.componentInstance.historyDates = historyDates;

      modalRef.result.then(async (result) => {
        let history = await this._sensorService.getFftHistory(result.selectedRecordId);
        this.fftArray = history.data;
        this.drawFFTCharts();
      }, (reason) => {
        console.log(reason);
      });
    }
  }

  askForDBSaveFFT() {
    const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.heading = "Would you like to save FFT data in database for this sensor ?";
    modalRef.componentInstance.content = "Next time data will be pulled from database for this sensor, if you choose confirm";

    modalRef.result.then(async (result) => {
      this.saveIt = true;
      this.getGraphDataFFT();
    }, (reason) => {
      this.saveIt = false;
      this.getGraphDataFFT();
    });
  }

  async getGraphDataFFT() {
    let sensorDetail = await this._sensorService.getSensorDetail(this.sensor);
    if(sensorDetail.rssi){
      if (sensorDetail.rssi <= -85) {
        this._toasterService.typeError('Unable to pull high speed data due to weak signal strength of ' + sensorDetail.rssi + ' dBm');
        return;
      } else if (sensorDetail.rssi <= -80) {
        this._toasterService.typeError('High speed data pull may take longer due to weak signal strength of ' + sensorDetail.rssi + ' dBm');
      }
    } else {
      this._toasterService.typeError('RSSI value required');
      return;
    }
    // let getRpm = await this._sensorService.getRpmPolicy(this.sensor.sensor_id);
    // let RPM = getRpm ? getRpm.machineRPM : 1800;
    // let samples = parseInt(((8 / (RPM / 60)) * 25600).toFixed(2));
    let samples = 25600;
    this.jobStatus = true;

    let response = await this._sensorService.addFftJob({ "sensor_id": this.sensor.sensor_id, accl: this.mAxis.split("#")[1].split("_")[0].toLowerCase(), samples: samples });
    if (response) {
      this.jobId = response.deviceJobId;
      this.jobStatus = true;
      this._toasterService.typeSuccess('Job Created Successfully');
      this._toasterService.typeInfo('Waiting for job completion');
      this.onJobReady();
    } else {
      this._toasterService.typeError('Something bad happened');
      this.getGraphDataFFT();
    }
  }

  async jobReady(sensorJobRecord) {
    let data = {
      reportUrl: sensorJobRecord.reportUrl,
      sensor_id: this.sensor.sensor_id,
      saveIt: this.saveIt,
      axis: this.mAxis
    }
    return this._sensorService.getDataForFft(data);
  }

  async onJobReady() {
    let response = await this.getJobStatus();
    if (response) {
      let jobStatusText = response.currentState;
      this._toasterService.typeInfo(jobStatusText);
      if (response.currentState == "COMPLETED") {
        // The Job is ready, notify calling code
        this.checkIfJobAdded();
      } else if (this.jobStatusArray.includes(response.currentState)) {
        // Not ready yet, wait a 10th of a second
        setTimeout(() => { this.onJobReady(); }, 10000);
      } else {
        this.jobStatus = false;
        this._toasterService.typeError('Somemthing bad happend');
        return;
      }
    }
  }

  async checkIfJobAdded() {
    let response = await this.pollIndexJobs();
    if (response) {
      this._toasterService.typeInfo('Preparing');
      let flag = response.content.find((o) => {
        return o.deviceJobId == this.jobId
      });
      if (flag) {
        this._toasterService.typeInfo('Formating data to plot');
        let res = await this.jobReady(flag);
        if (res) {
          this._toasterService.typeSuccess('Formatted Successfully');
          this.fftArray = res;
          this.drawFFTCharts();
          this.jobStatus = false;
        }
      }
      else {
        setTimeout(() => { this.checkIfJobAdded(); }, 10000);
      }
    }
  }

  async getJobStatus() {
    return await this._sensorService.getJobStatus({ sensor_id: this.sensor.sensor_id, job_id: this.jobId });
  }

  async pollIndexJobs() {
    return await this._sensorService.getFftJob(this.sensor.sensor_id);
  }

  async drawFFTCharts() {
    this._toasterService.typeInfo('Plotting Graphs');
    if (this.fftType == 'acceleration') {
      this.createChartFFT(this.fftArray[0]);
      this.createChartTimeBased(this.fftArray[1]);
    } else {
      this.createChartFFT(this.fftArray[2]);
      this.createChartTimeBased(this.fftArray[3]);
    }
  }

  createChartFFT(dataArray) {
    let x = [];
    let y = [];
    if (this.isHann) {
      this._toasterService.typeInfo('Filtering Hann data');
      for (var i = 0; i < dataArray.length; i++) {
        x.push(dataArray[i].freq);
        y.push(dataArray[i].fft);
      }
    } else {
      for (var i = 0; i < dataArray.length; i++) {
        x.push(dataArray[i].freq);
        y.push(dataArray[i].hann);
      }
    }

    let xmax = Math.max(...x);
    let xmin = 0;

    let ymax = Math.max(...y);
    let ymin = Math.min(...y);

    let xaxis: any = {
      min: xmin,
      max: xmax,
      type: "numeric",
      title: {
        text: "Frequency Hz",
        offsetY: 15,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
        }
      },
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(0) + ' Hz';
        }
      }
    }

    let yaxis: any = {
      min: ymin,
      max: ymax,
      title: {
        text: this.fftType == 'acceleration' ? 'Amplitude (g)' : 'Amplitude (in/s)',
        offsetY: 15,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
        }
      },
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(5);
        }
      }
    }

    let tooltip: any = {
      enabled: true,
      theme: 'dark',
      x: {
        formatter: function (value) {
          return 'Frequency (Hz) : ' + value;
        }
      }

    };

    xaxis.categories = x;
    this.fft.xaxis = xaxis;
    this.fft.yaxis = yaxis;
    this.fft.tooltip = tooltip;
    this.fft.series = [{ data: y, name: this.fftType == 'acceleration' ? 'Amplitude (g)' : 'Amplitude (in/s)' }]

  }

  createChartTimeBased(dataArray) {
    let x = [];
    let y = [];
    for (var i = 0; i < dataArray.length; i++) {
      // x.push(dataArray[i].x);
      y.push(dataArray[i].y);
    }

    let ymax = Math.max(...y);
    let ymin = Math.min(...y);

    let xaxis: any = {
      type: "numeric",
      title: {
        text: "Time(milliseconds)",
        offsetY: 15,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
        }
      },
      labels: {
        show: false,
        formatter: function (value) {
          return value.toFixed(0);
        }
      },
      tooltip: {
        enabled: false,
      },
    }

    let yaxis: any = {
      min: ymin,
      max: ymax,
      title: {
        text: this.fftType == 'acceleration' ? 'Amplitude (g)' : 'Amplitude (in/s)',
        offsetY: 15,
        style: {
          fontSize: '15px',
          fontWeight: 'bold',
        }
      },
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(5);
        },
      }
    }

    let tooltip: any = {
      enabled: true,
      theme: 'dark',
      x: {
        show: false
      }
    };

    xaxis.categories = x;
    this.waveForm.xaxis = xaxis;
    this.waveForm.yaxis = yaxis;
    this.waveForm.tooltip = tooltip;
    this.waveForm.series = [{ data: y, name: this.fftType == 'acceleration' ? 'Amplitude (g)' : 'Amplitude (in/s)' }];
  }

  applyHannFilter() {
    if (this.fftType == 'acceleration') {
      this.createChartFFT(this.fftArray[0]);
    } else {
      this.createChartFFT(this.fftArray[2]);
    }
  }

  roundMeOff(number) {
    return Math.round((number + Number.EPSILON) * 10000) / 10000;
  }


}
