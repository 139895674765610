import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentService } from '../component.service';
import { SharedService } from '../../../services/shared.service';
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from '../../../services/helper.service';
import { ComponentFormComponent } from '../component-form/component-form.component';
import { SensorImportComponent } from '../../sensor/sensor-import/sensor-import.component';
import { ModalComponent } from '../../modal/modal.component';
import { ViewerComponent } from '../../viewer/viewer.component';
import { NGXToastrService } from '../../../services/toaster.service';
import Constants from '../../../services/constants.service';

@Component({
  selector: 'app-component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.scss']
})
export class ComponentListComponent implements OnInit {

  @Input() asset: any;
  assetOrigionalImageSrc: any;
  filter: any = {};
  plants: any = [];
  locations: any = [];
  assets: any = [];
  component: any = {};
  components: any = [];
  pinData: any;
  
  constructor(
    private _componentService: ComponentService,
    private _sharedService: SharedService,
    private _helperService: HelperService,
    private _localstorageService: LocalstorageService,
    private modalService: NgbModal,
    private _toasterService: NGXToastrService,

  ) { }

  async ngOnInit() {
    this.pinData = {
      component_name: 'ComponentComponent'
    }
    
    if(this.asset){
      if(this.asset.image.original){
        this.assetOrigionalImageSrc = await this._helperService.setImage(this.asset.image.original);
      }
      this.components = await this._sharedService.getComponentByAssetId(this.asset.asset_id);
    } else {
      this.plants = await this._sharedService.getPlants();
      let componentLocalData = this._localstorageService.getComponentData('component-list');
      if(componentLocalData){
        this.filter.plants = componentLocalData.filter.plants;
        this.filter.locations = componentLocalData.filter.locations;
        this.filter.assets = componentLocalData.filter.assets;
        this.getAssetsComponents();
      } else {
        this.components = await this._sharedService.getComponents();
      }
    } 
  }

  async getPlantsLocations(){
    this.filter.locations = [];
    if(this.filter.plants){
      this.locations = await this._sharedService.getPlantsLocations(this.filter.plants);
    }
  }

  async getLocationsAssets(){
    this.filter.assets = [];
    if(this.filter.locations){
      this.assets = await this._sharedService.getLocationsAssets(this.filter.locations);
    }
  }

  async getAssetsComponents(){
    this.filter.components = [];
    if(this.filter.assets){
      let componentLocalData = { 
        filter: {
          plants: this.filter.plants,
          locations: this.filter.locations,
          assets: this.filter.assets
        }
      }
      this._localstorageService.setComponentData('component-list', componentLocalData);
      this.components = await this._sharedService.getAssetsComponents(this.filter.assets);
    }
  }

  async resetAssetComponents(){
    this.components = [];
    this.filter = {};
    this._localstorageService.unsetComponentData('component-list');
  }

  initializeComponent(){
    this.component = {
      asset_id: this.asset ? this.asset.asset_id : "",
      image: {
        original: "",
        thumb: ""
      },
      name_plate_image: {
        original: "",
        thumb: ""
      },
      latitude: '',
      longitude: '',
      hp: '',
      frame: '',
      rpm: '',
      enclosure: '',
      volts: '',
      de_brg: '',
      apms: '',
      ode_brg: '',
      active: true
    }
  }

  setMultiselectSetting(idField, textField){
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  async openViewerModal(component) {
    const modalRef = this.modalService.open(ViewerComponent, {scrollable: true, backdrop : 'static', size: 'xl'});
    modalRef.componentInstance.obj = {resource: component, type: 'component', id:component.component_id};
    modalRef.result.then(async (result) => {

    }, (reason) => {
      console.log(reason)
    });
  }

  async openComponentFormModal(componentId = 'add_new_component') {
    if(componentId != 'add_new_component'){
      this.component = await this.getComponentByComponentId(componentId);
      if(!this.component.name_plate_image){ // Because name plate image was added after many time so to handle the error I did this here.
        this.component.name_plate_image = {
          original: '',
          thumb: ''
        }
      }
    } else {
      this.initializeComponent();
    }
    const modalRef = this.modalService.open(ComponentFormComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.component = this.component;
    modalRef.componentInstance.assets = await this._sharedService.getAssets();
    modalRef.result.then(async (result) => {
      if(result.action == 'add'){
        this.component = await this.addComponent(result.component);
      } else {
        this.component = await this._sharedService.updateComponent(result.component);
      }
      if(this.component){
        await this.ngOnInit();
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async openConfirmationModal(componentId) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.content = "Are you sure you want to delete this component? It will delete the sensors of this component.";

    modalRef.result.then(async (result) => {
      this.component = await this.deleteComponent(componentId);
      if(this.component){
        let index = this.components.findIndex(p => p.component_id == this.component.component_id);
        this.components.splice(index,1);
      }
    }, (reason) => {
      console.log(reason)
    });
  }

  async addComponent(component){
    return await this._componentService.addComponent(component); 
  }

  async deleteComponent(componentId){
    return await this._componentService.deleteComponent(componentId);
  }

  async getComponentByComponentId (componentId) {
    return  await this._sharedService.getComponentByComponentId(componentId);
  }

  downloadCsv(){
    let link = document.createElement("a");
    link.download = "sensors-sample.csv";
    link.href = "assets/csv/sensors-sample.csv";
    link.click();
  }

  async openSensorsCsvModal() {
    const modalRef = this.modalService.open(SensorImportComponent, { scrollable: true, backdrop : 'static'});
    modalRef.componentInstance.component = this.component;
    modalRef.result.then(async (result) => {
      console.log(result);
    }, (reason) => {
        console.log(reason);
    });
  }

  hideOptions(){
    let user = this._localstorageService.getUserData();
    return user.role_id != Constants.role.SUPER_ADMIN_ROLE_ID && user.role_id != Constants.role.VENDOR_ROLE_ID;
  }

  creatReport(componentId){
    this._helperService.performOperationWithToasts(async () => {
      return await this._sharedService.createReport('component', componentId);
    });
  }

}
