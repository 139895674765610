<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">Sensor Mount Position</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-3 radios">
                        <input type="radio" id="r1" name="mount_position" [(ngModel)]="axis_model" [value]="axis[0].value">
                        <label for="r1" class="radio"><div style="display: flex; align-items: center;"><div class="first_dir"><div class="first_dir_1"></div></div><div class="first_dir_2"></div></div><div style="margin-top: 1rem;">{{ axis[0].text }}</div></label>
                    </div>
                    <div class="col-3 radios">
                        <input type="radio" id="r2" name="mount_position" [(ngModel)]="axis_model" [value]="axis[1].value">
                        <label for="r2" class="radio"><div style="display: flex; align-items: center;"><div class="first_dir"><div class="first_dir_3"></div></div><div class="first_dir_2"></div></div><div style="margin-top: 1rem;">{{ axis[1].text }}</div></label>
                    </div>
                    <div class="col-3 radios">
                        <input type="radio" id="r3" name="mount_position" [(ngModel)]="axis_model" [value]="axis[2].value">
                        <label for="r3" class="radio"><div class="circle_dir" style="margin-left: 25%;"><div class="circle_dir_1"></div><div class="circle_dir_2"></div></div><div style="margin-top: 1rem;">{{ axis[2].text }}</div></label>
                    </div>
                    <div class="col-3 radios">
                        <input type="radio" id="r4" name="mount_position" [(ngModel)]="axis_model" [value]="axis[3].value">
                        <label for="r4" class="radio"><div class="circle_dir" style="margin-left: 35%;"><div class="circle_dir_3"></div><div class="circle_dir_2"></div></div><div style="margin-top: 1rem;">{{ axis[3].text }}</div></label>
                    </div>
                </div>
                
                <div class="form-group">
                    <button [hidden]="axisSettings._id" type="button" class="btn btn-white pull-right px-5" (click)="addAxisSettings()">Save</button>
                    <button [hidden]="!axisSettings._id" type="button" class="btn btn-white pull-right px-5" (click)="updateAxisSettings()">Update</button>
                    <button type="button" class="btn btn-white pull-right mr-1" (click)="copyAxisSettings()"><i class="fa fa-copy"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>