<div class="row mb-2 mx-0" id="plant-list-header">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Manage Plants</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<button type="button" class="btn btn-white" (click)="openPlantFormModal()">
				<i class="fa fa-plus"></i>
			</button>
			<app-pin [pinData]='pinData'></app-pin>
			<app-expand-screen></app-expand-screen>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-lg-12" id="accordion1">
		<div *ngFor="let plant of plants; let i = index">
			<div class="card mb-1 border-1px">
				<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
					<button class="btn btn-link text-dark shadow-none collapsed" (click)="flags[plant.plant_id] = true"
						data-toggle="collapse" [attr.data-target]="'#collapse-' + i" aria-expanded="false"
						[attr.aria-controls]="'collapse-' + i">
						<span (click)="$event.stopPropagation(); openViewerModal(plant)">{{plant.name}}</span>
					</button>
					<div>
						<i class="mr-2 fa fa-download cursor-pointer" (click)="$event.stopPropagation()"  (click)="creatReport(plant.plant_id)"></i>
						<i class="fa fa-trash cursor-pointer" (click)="openConfirmationModal(plant.plant_id)"></i>
					</div>
	
				</div>
			</div>
			<div id="collapse-{{i}}" class="collapse ml-4" data-parent="#accordion1">
				<app-location-list *ngIf="flags[plant.plant_id]" [plant]="plant"></app-location-list>
			</div>
		</div>
	</div>
</div>
<div class="row" *ngIf="plants.length == 0">
	<div class="col-lg-12">
		<div class="card mb-1 border-1px">
			<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
				<button class="btn btn-link text-dark shadow-none">
					<span>No data to display</span>
				</button>
			</div>
		</div>
	</div>
</div>