import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-expand-screen',
  templateUrl: './expand-screen.component.html',
  styleUrls: ['./expand-screen.component.scss']
})
export class ExpandScreenComponent implements OnInit {

  expandIcon: any;
  originalIcon: any;
  constructor(@Inject(DOCUMENT) private document: any) { }
  elem;

  ngOnInit() {
    this.elem = document.documentElement;
    this.expandIcon = true;
    this.originalIcon = false;
  }

  openFullscreen() {
    this.originalIcon = true;
    this.expandIcon = false;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  
  /* Close fullscreen */
  closeFullscreen() {
    this.originalIcon = false;
    this.expandIcon = true;
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }


}
