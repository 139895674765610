<div class="modal-header" *ngIf="sensor">
    <h5 class="modal-title">Replace Sensor ({{sensor.name}})</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Sensor ID *</label>
                    <input type="text" class="form-control" placeholder="Enter Sensor ID" formControlName="sensor_id" [(ngModel)]="replaceData.sensor_replaced_id">
                    <div [hidden]="!error('sensor_id','required')" class="invalid">Sensor ID is required</div> 
                </div>
                <div class="form-group"> 
                    <label>Sensor Name *</label>
                    <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [(ngModel)]="replaceData.replaced_name">
                    <div [hidden]="!error('name','required')" class="invalid">Sensor Name is required</div> 
                </div>
            </form>
        </div>
    </div>        
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right"  (click)="validateForm()">Replace</button>
</div>  