import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ComponentService
{
    constructor(
        private _httpClient: HttpClient
    ) {}

    addComponent(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/component', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }
    
    deleteComponent(component_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/component/'+component_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

}
