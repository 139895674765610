<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">RPM</div>
            <div class="card-body">
                <form [formGroup]="rpmForm">
                    <div class="form-group">
                        <label>Value</label>
                        <input type="number" [(ngModel)]="rpmPolicy.machineRPM" formControlName="rpmValue" class="form-control">
                        <div [hidden]="!errorRpm('rpmValue','required')" class="invalid">Rpm value is required</div>
                    </div>
                    <div class="form-group">
                        <button [hidden]="rpmPolicy._id" type="button" class="btn btn-white pull-right px-5" (click)="addRpmPolicy()">Save</button>
                        <button [hidden]="!rpmPolicy._id" type="button" class="btn btn-white pull-right px-5" (click)="updateRpmPolicy()">Update</button>
                        <button type="button" class="btn btn-white pull-right mr-1" (click)="copyRpmPolicy()"><i class="fa fa-copy"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">Utilization</div>
            <div class="card-body">
                <form [formGroup]="utilizationForm">
                    <div class="form-group">
                        <label>Value (Hour/Week)</label>
                        <input type="number" [(ngModel)]="utilizationPolicy.utilVal" formControlName="utilizationValue" class="form-control">
                        <div [hidden]="!errorUtilization('utilizationValue','required')" class="invalid">Utilization value is required</div> 
                    </div>
                    <div class="form-group">
                        <button [hidden]="utilizationPolicy._id" type="button" class="btn btn-white pull-right px-5" (click)="addUtilizationPolicy()">Save</button>
                        <button [hidden]="!utilizationPolicy._id" type="button" class="btn btn-white pull-right px-5" (click)="updateUtilizationPolicy()">Update</button>
                        <button type="button" class="btn btn-white pull-right mr-1" (click)="copyUtilizationPolicy()"><i class="fa fa-copy"></i></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>