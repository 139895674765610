import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TagInputModule } from 'ngx-chips';
import { FileUploadModule } from 'ng2-file-upload';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AssetRoutingModule } from "./asset-routing.module";
import { AssetComponent } from './asset.component';
import { AssetFormComponent } from './asset-form/asset-form.component';
import { AssetService } from './asset.service';
import { AssetListComponent } from './asset-list/asset-list.component';
import { AssetModalComponent } from './asset-modal/asset-modal.component';

import { PinModule } from '../pin/pin.module';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';
import { ComponentModule } from '../component/component.module';
import { PreventiveMaintenanceModule } from '../preventive-maintenance/preventive-maintenance.module';
import { WorkOrderModule } from '../work-order/work-order.module';
import { PartModule } from '../part/part.module';
import { VendorModule } from '../vendor/vendor.module';

@NgModule({
    imports: [
        CommonModule,
        AssetRoutingModule,
        NgbModule,
        UiSwitchModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        TagInputModule,
        ComponentModule,
        PinModule,
        ExpandScreenModule,
        FileUploadModule,
        PreventiveMaintenanceModule,
        WorkOrderModule,
        PartModule,
        VendorModule
    ], 
    declarations: [ 
        AssetComponent,
        AssetListComponent,
        AssetModalComponent,
        AssetFormComponent
    ],
    providers: [
        AssetService
    ],
    exports: [
        AssetComponent,
        AssetListComponent
    ]

    
})
export class AssetModule { }
