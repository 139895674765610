import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WidgetService
{

    constructor(
        private _httpClient: HttpClient
    ) {}

    getAllWidget(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/widget')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorWidgetsBySensorUniqueId(sensorUniqueId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-widget/'+sensorUniqueId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addSensorWidget(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-widget', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    copySensorWidget(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/copy-sensor-widget', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateSensorWidget(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/sensor-widget', { ...data })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

}
