<div class="row">
    <div class="col-6">
        <form [formGroup]="velocityClusterForm" class="p-0">
            <div class="card">
                <div class="card-header">Vibration Learning(Velocity)</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Time Span</label>
                                <select class="form-control" id="default-select" formControlName="time_span" [(ngModel)]="velocityCluster.time_span">
                                    <option value="">Select</option>
                                    <option value="1 Day">1 Day</option>
                                    <option value="1 Week">1 Week</option>
                                    <option value="1 Month">1 Month</option>
                                    <option value="Custom">Custom</option>
                                </select>
                                <div [hidden]="!errorVelocityCluster('time_span','required')" class="invalid">Time Span is required</div>
                            </div>
                            <div class="row" [hidden]="velocityCluster.time_span != 'Custom'">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Start Date</label>
                                        <div class="input-group">
                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="velocityCluster.start_date" [ngModelOptions]="{standalone: true}">
                                            <div class="input-group-append">
                                                <button class="btn btn-light fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>End Date</label>
                                        <div class="input-group">
                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d2="ngbDatepicker" [(ngModel)]="velocityCluster.end_date" [ngModelOptions]="{standalone: true}">
                                            <div class="input-group-append">
                                                <button class="btn btn-light fa fa-calendar" (click)="d2.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hidden">
                                <label>Threshold</label>
                                <input type="number" formControlName="threshold" [(ngModel)]="velocityCluster.threshold" class="form-control">
                                <div [hidden]="!errorVelocityCluster('threshold','required')" class="invalid">Threshold is required</div>
                            </div>
                            <div class="form-group">
                                <label>No. of Clusters</label>
                                <input type="number" formControlName="no_of_clusters" [(ngModel)]="velocityCluster.no_of_clusters" class="form-control">
                                <div [hidden]="!errorVelocityCluster('no_of_clusters','required')" class="invalid">No Of Clusters is required</div>
                                <div [hidden]="!errorVelocityCluster('no_of_clusters','min')" class="invalid">Value must be in range(1-3)</div>
                                <div [hidden]="!errorVelocityCluster('no_of_clusters','max')" class="invalid">Value must be in range(1-3)</div>
                            </div>
                            <div class="form-group">
                                <button [hidden]="velocityCluster._id" type="button" class="btn btn-white pull-right px-5" (click)="addClusterConfiguration('velocity')">Save</button>
                                <button [hidden]="!velocityCluster._id" type="button" class="btn btn-white pull-right px-5" (click)="updateClusterConfiguration('velocity')">Update</button>
                                <button type="button" class="btn btn-white pull-right mr-1" (click)="copyClusterConfiguration('velocity', 'learning')"><i class="fa fa-copy"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" [hidden]="!velocityCluster.settings">
                <div class="card-header">Vibration Alerts(Velocity)</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="velocityClusterForm">
                                <div class="form-group">
                                    <label>Learned Mean</label> (in/s)
                                    <input type="number" [(ngModel)]="velocityLearnedMean" [ngModelOptions]="{standalone: true}" class="form-control" disabled>
                                </div>
                                <div class="form-group">
                                    <label>Alert Value</label> (in/s)
                                    <div class="row">
                                        <div class="col-10">
                                            <input type="number" [(ngModel)]="velocityAlertValue" [ngModelOptions]="{standalone: true}" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <span *ngIf="sensor.type != 'treon'"><label>Time Above Threshold Before Alert</label> (Hours)</span>
                                    <label *ngIf="sensor.type == 'treon'">Data Points Above Threshold Before Alert</label>
                                    <input type="number" formControlName="interval" [(ngModel)]="velocityCluster.interval" class="form-control">
                                    <div [hidden]="!errorVelocityCluster('interval','required')" class="invalid">Time Above Threshold Before Alert</div>
                                    <div [hidden]="!errorVelocityCluster('interval','min')" class="invalid">Value should be 1 or greater</div>
                                </div>
                                <div class="form-group">
                                    <label class="mr-2">Status </label>
                                    <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="velocityCluster.status" [ngModelOptions]="{standalone: true}"> <i class="fa fa-check"></i></ui-switch>
                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-white pull-right px-5" (click)="updateClusterSetting('velocity')">Update</button>
                                    <button type="button" class="btn btn-white pull-right mr-1" (click)="copyClusterConfiguration('velocity', 'alert')"><i class="fa fa-copy"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-6" *ngIf="sensor.type == 'bluzone'">
        <form [formGroup]="accelerationClusterForm" class="p-0">
            <div class="card">
                <div class="card-header">Vibration Learning(Acceleration)</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Time Span</label>
                                <select class="form-control" id="default-select" formControlName="time_span" [(ngModel)]="accelerationCluster.time_span">
                                    <option value="">Select</option>
                                    <option value="1 Day">1 Day</option>
                                    <option value="1 Week">1 Week</option>
                                    <option value="1 Month">1 Month</option>
                                    <option value="Custom">Custom</option>
                                </select>
                                <div [hidden]="!errorAccelerationCluster('time_span','required')" class="invalid">Time Span is required</div>
                            </div>
                            <div class="row" [hidden]="accelerationCluster.time_span != 'Custom'">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Start Date</label>
                                        <div class="input-group">
                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d3="ngbDatepicker" [(ngModel)]="accelerationCluster.start_date" [ngModelOptions]="{standalone: true}">
                                            <div class="input-group-append">
                                                <button class="btn btn-light fa fa-calendar" (click)="d3.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>End Date</label>
                                        <div class="input-group">
                                            <input class="form-control" placeholder="mm-dd-yyyy" ngbDatepicker #d4="ngbDatepicker" [(ngModel)]="accelerationCluster.end_date" [ngModelOptions]="{standalone: true}">
                                            <div class="input-group-append">
                                                <button class="btn btn-light fa fa-calendar" (click)="d4.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hidden">
                                <label>Threshold</label>
                                <input type="number" [(ngModel)]="accelerationCluster.threshold" formControlName="threshold" class="form-control">
                                <div [hidden]="!errorAccelerationCluster('threshold','required')" class="invalid">Threshold is required</div>
                            </div>
                            <div class="form-group">
                                <label>No. of Clusters</label>
                                <input type="number" [(ngModel)]="accelerationCluster.no_of_clusters" formControlName="no_of_clusters" class="form-control">
                                <div [hidden]="!errorAccelerationCluster('no_of_clusters','required')" class="invalid">No Of Clusters is required</div>
                                <div [hidden]="!errorAccelerationCluster('no_of_clusters','min')" class="invalid">Minimum value should be 1</div>
                                <div [hidden]="!errorAccelerationCluster('no_of_clusters','max')" class="invalid">Maximum value should be 3</div>
                            </div>
                            <div class="form-group">
                                <button [hidden]="accelerationCluster._id" type="button" class="btn btn-white pull-right px-5" (click)="addClusterConfiguration('acceleration')">Save</button>
                                <button [hidden]="!accelerationCluster._id" type="button" class="btn btn-white pull-right px-5" (click)="updateClusterConfiguration('acceleration')">Update</button>
                                <button type="button" class="btn btn-white pull-right mr-1" (click)="copyClusterConfiguration('acceleration', 'learning')"><i class="fa fa-copy"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" [hidden]="!accelerationCluster.settings">
                <div class="card-header">Vibration Alerts(Acceleration)</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="accelerationClusterForm">
                                <div class="form-group">
                                    <label>Learned Mean</label> (g)
                                    <input type="number" class="form-control" value="{{ accelerationLearnedMean }}" disabled>
                                </div>
                                <div class="form-group">
                                    <label>Alert Value</label> (g)
                                    <input type="number" [(ngModel)]="accelerationAlertValue" [ngModelOptions]="{standalone: true}" class="form-control">
                                </div>
                                <div class="form-group">
                                    <label>Time Above Threshold Before Alert</label> (Hours)
                                    <input type="number" formControlName="interval" [(ngModel)]="accelerationCluster.interval" class="form-control">
                                    <div [hidden]="!errorAccelerationCluster('interval','required')" class="invalid">Time Above Threshold Before Alert</div>
                                    <div [hidden]="!errorAccelerationCluster('interval','min')" class="invalid">Value should be 1 or greater</div>
                                </div>
                                <div class="form-group">
                                    <label class="mr-2">Status </label>
                                    <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="accelerationCluster.status" [ngModelOptions]="{standalone: true}"> <i class="fa fa-check"></i></ui-switch>
                                </div>
                                <div class="form-group">
                                    <button [hidden]="!accelerationCluster._id" type="button" class="btn btn-white pull-right px-5" (click)="updateClusterSetting('acceleration')">Update</button>
                                    <button type="button" class="btn btn-white pull-right mr-1" (click)="copyClusterConfiguration('acceleration', 'alert')"><i class="fa fa-copy"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>