import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SharedService {

    constructor(
        private _httpClient: HttpClient
    ) { }

    getDashboards(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/dashboard')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getVendor(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/vendor")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getWorkOrders(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + `/work-order?resource=${filter.resource ?? ''}&id=${filter.id}&party=${filter.party ?? ''}&status=${filter.status ?? ''}&work_order_id=${filter.work_order_id ?? ''}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getCategory(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/category")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getProjects(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/project')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPlants(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/plant')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updatePlant(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/plant', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getLocations(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/location')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateLocation(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/location', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getAssets(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/asset')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAssetByProjectUniqueId(projectUniqueId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/asset-by-project/' + projectUniqueId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateAsset(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/asset', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getComponents(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/component')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateComponent(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/component', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getComponentByComponentId(component_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/component/' + component_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensors(query=''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor' + query)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getUsers(query = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/user' + query)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getTeams(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/team')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getUsersBySensorUniqueId(sensor_unique_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/users-by-sensor-unique-id/' + sensor_unique_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getRoles(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/role')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getShifts(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/shift')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getProjectsPlants(projects): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/projects-plants', { projects: projects })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPlantsLocations(plants): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/plants-locations', { plants: plants })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getLocationsAssets(locations): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/locations-assets', { locations: locations })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAssetsComponents(assets): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/assets-components', { assets: assets })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAssetsComponentsByProjectUniqueId(assets, projectUniqueId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/assets-components/' + projectUniqueId, { assets: assets })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getComponentSSensors(components): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/components-sensors', { components: components })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getFilteredSensors(components,types,name): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/filter-sensors', { components: components,types:types,name:name })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorWidget(sensorUniqueId, widgetId, tabId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-widget/' + sensorUniqueId + '/' + widgetId + '/' + tabId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    sensorWidgetsBySensorUniqueId(sensorUniqueId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-widget/' + sensorUniqueId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorBySensorId(sensor_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-by-sensor-id/' + sensor_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorBySensorUniqueId(sensor_unique_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor/' + sensor_unique_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorDetailTabs(tabs): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/sensor-detail-tabs', tabs)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getMappings(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/mapping')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getMappingByMappingId(mapping_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/mapping/' + mapping_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getIntegrations(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/integration')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSetting(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/setting')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getAllSetting(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/setting/all')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updateAlerts(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put((<any>window).appBaseUrl + '/alerts', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getMenuItems(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/menu-item')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getViewerItems(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/viewer-item')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPriority(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/priority")
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPriorityByProjectUniqueId(projectUniqueId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/priority/" + projectUniqueId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getParts(query = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/part" + query)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addEvent(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/event", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getEvents(type, id, filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/filter-event?" + type + '=' + id, { ...filter })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPartByPartId(part_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/part/" + part_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getPartByResource(type, id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/part/" + type + "/" + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getVendorByResource(type, id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/vendor/" + type + "/" + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    updatePart(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put((<any>window).appBaseUrl + "/part", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getMaintenanceStatus(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/maintenance-stats', { ...filter })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getEpcompStats(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/employee-completed-stats', { ...filter })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getEpcurrStats(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/employee-current-stats', { ...filter })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getTotalDownTime(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/total-down-time')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDownTimeStats(filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/down-time-stats', { ...filter })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addWorkOrder(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/work-order", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    addWorkRequest(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/work-request", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getComponentByAssetId(asset_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/asset-component/' + asset_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getTeamByParams(property, value): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + `/team?${property}=${value}`)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    restPassword(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/reset-password", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    newPassword(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/new-password", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getParamsFromURLHashed(key) {
        const url = window.location.href;
        const params = url.split("?")[1].split("&").reduce(function (result, param) {
            var [key, value] = param.split("=");
            result[key] = value;
            return result;
        }, {});

        return params[key];
    }

    reminderEmail(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/reminder", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    getUsersByTeamId(teamId): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/team-users/' + teamId)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getFile(fileName, query = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/get-file/' + fileName + query)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addVendor(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/vendor", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    addPart(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/part", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    addRequisition(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/requisition', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    uploadImage(file: File, query: any): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/upload-image' + query, formData)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    uploadFile(file: File, query: any): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/upload-file' + query, formData)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getSensorSampleData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/sensor-sample')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getWidgetSampleData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/widget-sample')
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createEPReport(type, filter): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/create-report/' + type, { ...filter })
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    createReport(type,id, filter = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get((<any>window).appBaseUrl + "/export-data?" + type + '=' + id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addPriority(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post((<any>window).appBaseUrl + "/priority", { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }
}
