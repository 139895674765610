<div class="modal-header">
    <h5 class="modal-title">Comment</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Comment *</label>
                    <div class="input-group">
                        <textarea rows="4" class="form-control" formControlName="text"></textarea>
                        <div [hidden]="!error('text','required')" class="invalid">Comment is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Add Tags *</label>
                    <tag-input formControlName="tags" class="p-0" [theme]="'foundation-theme'" [modelAsStrings]="true"></tag-input>
                    <div [hidden]="!error('tags','required')" class="invalid">Tags are required</div>
                </div>
                <div class="form-group">
                    <label>Attachment</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event)" type="file" name="file" class="form-control" #fileInput accept="*" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right" (click)="validateForm()">Save</button>
</div>