<!-- Company modal -->
<div class="modal-header">
    <h5 class="modal-title">Import Sensors</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross Click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Upload CSV File</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event)" type="file" name="file" formControlName="sensors_csv" class="form-control" #fileInput accept="image/*" />

                    </div>
                    <div [hidden]="!error('sensors_csv','required')" class="invalid">Select csv file that contain sensors</div>
                    <div [hidden]="!uploading" class="mt-1">Uploading...</div>
                    <div [hidden]="!importing" class="mt-1">Importing...</div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer"> 
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right" [ngClass]="{disabled: uploading}"  (click)="validateForm()">Import</button>
</div>    


