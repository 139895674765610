<div class="row">
    <div class="col-lg-12" *ngFor="let item of maintenanceALertPolicies; let i = index">
        <div class="card">
            <div class="card-header">Maintenance Reminder</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Select Reminder Type</label>
                            <div class="input-group">
                                <select class="form-control" id="default-select" [(ngModel)]="item.machcine_alert_type">
                                    <option value="">Select Reminder Type</option>
                                    <option *ngFor="let reminderType of reminderTypes" [value]="reminderType.name">{{ reminderType.name }}</option>
                                    <option value="Other">Other</option>
                                </select> 
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="toggleAddReminderBlock()"><i class="fa fa-plus"></i> </button>
                                </div>
                            </div>
                            <div class="input-group mt-2" [hidden]="!reminderBlock">
                                <input type="text" class="form-control" [(ngModel)]="reminderTypeData.name">
                                <button type="button" class="btn btn-light" (click)="addReminderType()">Add</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" [hidden]="item.machcine_alert_type != 'Other'">    
                        <div class="form-group">
                            <label>Other Reminder Type</label>
                            <input type="text" class="form-control" [(ngModel)]="item.machcine_alert_type_other">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Select Type</label>
                            <select class="form-control" id="default-select" [(ngModel)]="item.type">
                                <option value="">Select Type</option>
                                <option value="byDay">By Day</option>
                                <option value="byHours">By Hours Running</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group" [hidden]="item.type != 'byDay'">
                            <label>No of Days</label>
                            <input type="number" class="form-control" [(ngModel)]="item.noOfDays">
                        </div>
                        <div class="form-group" [hidden]="item.type != 'byHours'">
                            <label>No of Hours</label>
                            <input type="number" class="form-control" [(ngModel)]="item.noOfHours">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <div class="icheck-material-white">
                                <input type="checkbox" id="check-{{ i }}" class="custom-control-input" [(ngModel)]="item.oneTimeOption">
                                <label for="check-{{ i }}">One Time</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="mr-2">Status</label>
                            <ui-switch uncheckedLabel="off" checkedLabel="on" size="small" [(ngModel)]="item.alertStatus"><i class="fa fa-check" ></i></ui-switch>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <button type="button" class="btn btn-white mr-1" (click)="copyMaintenancePolicy(item)"><i class="fa fa-copy"></i></button>
                            <button [hidden]="item._id" type="button" class="btn btn-white px-5" (click)="addMaintenancePolicy(item)">Save</button>
                            <button [hidden]="!item._id" type="button" class="btn btn-white px-5" (click)="updateMaintenancePolicy(item)">Update</button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <a href="javascript:" class="btn-social btn-social-circle pull-right" (click)="addItem()" [hidden]="(i+1) != maintenanceALertPolicies.length"><i class="fa fa-plus"></i></a>
                        <a href="javascript:" class="btn-social btn-social-circle pull-right" (click)="deleteItem(i, item._id)"><i class="icon-trash icons ml-2"></i></a>
                    </div>
                </div>        
               
            </div>
        </div>
    </div>
</div>