import{ Component,OnInit} from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from '../../../services/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: "app-send-mail-component",
    templateUrl: "./send-mail.component.html",
    styleUrls: ["./send-mail.component.scss"],
})
export class SendMailComponent implements OnInit {
  inputForm: FormGroup;
  submitted = false;
  sendMail:any
  users: any;
  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public _sharedService: SharedService,

  ) { }

  async ngOnInit() {
    this.setFormValidation();
    this.users = await this._sharedService.getUsers()
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      users: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  async validateForm() {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      await this._sharedService.reminderEmail(this.inputForm.value)
      this.activeModal.close()
    }
  } 
  
  error(controlName, constraint) {
    let errors = this.inputForm['controls'][controlName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  setMultiselectSetting(idField, textField) {
    return {
      singleSelection: false,
      idField: idField,
      textField: textField,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
}
