<div class="modal-header">
	<h5 class="modal-title mt-2">Sensor Widget</h5>
	<div class="form-group d-flex m-0">
		<select (change)="setSampleData($event)" class="form-control font-12px bg-white">
            <option value="">Widget Sample Data</option>
            <option *ngFor="let sample of samples" value="{{sample.type}}">{{sample.type}}</option>
        </select>
		<button type="button" class="close" (click)="activeModal.dismiss('Cross Click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body" *ngIf="sensorWidget && sensor && sensorMapping && sensorTabs">
	<div class="card">
        <div class="card-body">
			<form [formGroup]="inputForm">
				<div class="row">
					<div class="col-6">
						<div class="form-group">
							<label for="input-1">Url</label>
							<input type="text" class="form-control" formControlName="url" [(ngModel)]="sensorWidget.configuration.url">
							<div [hidden]="!error('url','required')" class="invalid">Url is required</div> 
						</div>
					</div>
					<div class="col-6" [hidden]="sensor.type != 'bluzone'">
						<div class="form-group">
							<label for="input-1">High Resolution Url</label>
							<input type="text" class="form-control" formControlName="high_resolution_url" [(ngModel)]="sensorWidget.configuration.high_resolution_url">
							<div [hidden]="!error('high_resolution_url','required')" class="invalid">High resolution Url is required</div> 
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label for="input-1">Method</label>
							<select class="form-control"  formControlName="method" [(ngModel)]="sensorWidget.configuration.method">
								<option value="">Select Method</option>
								<option value="get">Get</option>
								<option value="post">Post</option>
							</select>
							<div [hidden]="!error('method','required')" class="invalid">Method is required</div> 
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label for="input-1">Show On Tab</label>
							<select class="form-control"  formControlName="tab_id" [(ngModel)]="sensorWidget.tab_id">
								<option value="">Select Tab</option>
								<option value="tab-trend-data">Trend Data</option>
								<!-- <option *ngFor="let tab of sensorTabs" [value]="tab.tab_id">{{ tab.tab_text }}</option> TO DO if have to display charts on other tabs --> 
							</select>
							<div [hidden]="!error('tab_id','required')" class="invalid">Show On Tab is required</div> 
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label for="input-1">Refresh Interval(sec)</label>
							<input type="text" class="form-control" formControlName="refresh_interval" [(ngModel)]="sensorWidget.refresh_interval">
							<div [hidden]="!error('refresh_interval','required')" class="invalid">Refresh Interval is required</div> 
						</div>
					</div>
					<div class="col-6">
						<div class="form-group">
							<label>&nbsp;</label>
							<div class="icheck-material-white">
								<input type="checkbox" id="is-url-complete" formControlName="is_url_complete" [(ngModel)]="sensorWidget.configuration.is_url_complete"/>
								<label for="is-url-complete">Is Url Complete</label>
							</div>
							<div [hidden]="!error('is_url_complete','required')" class="invalid">Is url complete is required</div> 
						</div>	
					</div>
				</div>
			</form>	
			<div class="row">
				<div class="col-12 col-lg-12 col-xl-12">
					<div class="card">
						<h5 class="modal-title">Configure Axis</h5>
						<div class="table-responsive">
							<table class="table align-items-center">
								<thead>
									<tr>
										<th>Label</th>
										<th>Key</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr>	
										<td>
											<input type="text" class="form-control" [(ngModel)]="sensorWidget.axis.x.label">
										</td>
										<td>
											<select class="form-control"  [(ngModel)]="sensorWidget.axis.x.key">
												<option value="">Select Data Key</option>
												<option *ngFor="let setting of sensorMapping.settings" [value]="setting.value" >{{ setting.value }}</option>
											</select>
										</td>
										<td>
											Set the key and label for X-Axis Here.
										</td>
									</tr>
									<tr *ngFor="let sway of sensorWidget.axis.y; let i = index">
										<td>
											<input type="text" class="form-control" [(ngModel)]="sway.label">
										</td>
										<td>
											<select class="form-control"  [(ngModel)]="sway.key">
												<option value="">Select Data Key</option>
												<option *ngFor="let setting of sensorMapping.settings" [value]="setting.value" >{{ setting.value }}</option>
											</select>
										</td>
										<td>
											Set the key and label for Y-Axis Here.
											<button class="btn btn-sm btn-round btn-light pull-right" (click)="removeYAxisRow(i)"><i class="fa fa-minus"></i></button>
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<button class="btn btn-sm btn-round btn-light pull-right" (click)="appendYAxisRow()"><i class="fa fa-plus"></i></button>
										</td>
									</tr> 
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-lg-12 col-xl-12">
					<div class="card">
						<h5 class="modal-title">Configure Attributes</h5>
						<div class="table-responsive">
							<table class="table align-items-center">
								<thead>
									<tr>
										<th>Type</th>
										<th>Key</th>
										<th>Label</th>
										<th>Required</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let swa of sensorWidget.attributes; let i = index">
										<td>
											<div class="input-group">
												<select class="form-control" [(ngModel)]="swa.type">
													<option value="">Select Attribute</option>
													<option *ngFor="let at of attributesTypes" [value]="at.type" >{{ at.value }}</option>
												</select>
												<div class="input-group-append"> 
													<button class="btn btn-light" *ngIf="showOptionModal(swa.type)" (click)="openOptionModal(i)"><i class="fa fa-plus" title="Add Options"></i></button>
												</div>
											</div>
										</td>
										<td>
											<input type="text" class="form-control" [(ngModel)]="swa.key">
										</td>
										<td>
											<input type="text" class="form-control" [(ngModel)]="swa.label">
										</td>
										<td class="icheck-material-white">
											<input type="checkbox" id="required-{{ i }}" [(ngModel)]="swa.required"/>
											<label for="required-{{ i }}"></label>
										</td>
										<td>
											<button class="btn btn-sm btn-round btn-light pull-right" (click)="removeAttributesRow(i)"><i class="fa fa-minus"></i></button>
										</td>
									</tr>
									<tr>
										<td colspan="5">
											<button class="btn btn-sm btn-round btn-light pull-right" (click)="appendAttributesRow()"><i class="fa fa-plus"></i></button>
										</td>
									</tr> 
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="row" [hidden]="!(sensorWidget.configuration.method == 'post')">
				<div class="col-12 col-lg-12 col-xl-12">
					<div class="card">
						<h5 class="modal-title">Configure Body</h5>
						<textarea rows="5" type="text" class="form-control" [(ngModel)]="sensorWidget.body">{{ sensorWidget.body }}</textarea>
					</div>
				</div>
			</div>
		</div>
	</div>		
</div>
<div class="modal-footer" *ngIf="sensorWidget">
	<button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
	<button type="button" [hidden]="!sensorWidget.sensor_unique_id" class="btn btn-white pull-right" (click)="validateForm({action: 'update', sensorWidget: sensorWidget})">Update</button>
	<button type="button" [hidden]="sensorWidget.sensor_unique_id" class="btn btn-white pull-right" (click)="validateForm({action: 'add', sensorWidget: sensorWidget})" >Save</button>
	<button type="button" class="btn btn-white pull-right" (click)="validateFormWhileCopy()" >Copy</button>
</div>