import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VendorFormComponent } from "../vendor-form/vendor-form.component";
import { ResourceHierarchyComponent } from "../../shared/resource-hierarchy/resource-hierarchy.component";

import { VendorService } from "../vendor.service";
import { ModalComponent } from "../../modal/modal.component";
import { SharedService } from '../../../services/shared.service';
import { AssetService } from '../../asset/asset.service';

@Component({
  selector: "app-vendor-list",
  templateUrl: "./vendor-list.component.html",
  styleUrls: ["./vendor-list.component.scss"],
})
export class VendorListComponent implements OnInit {
  @Input() obj: any;
  vendors: any = [];
  vendor: any = {};

  constructor(
    private modalService: NgbModal,
    private _vendorService: VendorService,
    public _sharedService: SharedService,
    public _assetService: AssetService
  ) { }

  async ngOnInit() {
    if (this.obj) {
      this.vendors = await this._vendorService.getVendorByResource(this.obj.type, this.obj.id);
    } else {
      this.vendors = await this._vendorService.getVendor();
    }
  }

  async initializeVendor() {
    this.vendor = {
      name: "",
      email: "",
      contact: "",
      address: ""
    };
  }

  async autoAssignResource() {
    this.initializeVendor();
    if (this.obj.type == 'asset') this.vendor.assets = [{ asset_id: this.obj.resource.asset_id, name: this.obj.resource.name }];
    if (this.obj.type == 'component') this.vendor.components = [{ component_id: this.obj.resource.component_id, name: this.obj.resource.name }];
    this.openVendorFormModal(this.vendor);
  }

  async openVendorFormModal(vendor, index = 0) {
    const modalRef = this.modalService.open(VendorFormComponent, { scrollable: true, backdrop: 'static', size: 'md' });
    modalRef.componentInstance.vendor = { ...vendor };
    modalRef.componentInstance.assets = await this._sharedService.getAssets();
    modalRef.result.then(
      async (result) => {
        if (result.action == "add") {
          vendor = await this._sharedService.addVendor(result.vendor);
          vendor && this.vendors.push(vendor);
        } else {
          vendor = await this._vendorService.updateVendor(result.vendor);
          vendor && (this.vendors[index] = vendor);
        }
        this.vendors = [...this.vendors];
        this.initializeVendor();
      }, async (reason) => {
        console.log(reason);
      }
    );
  }

  async openConfirmationModal(vendorId, index = 0) {
    const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: "static" });
    modalRef.componentInstance.heading = "Delete Vendor";
    modalRef.componentInstance.content = "Are you sure you want to delete this Vendor?";

    modalRef.result.then(async (result) => {
      let vendor = await this._vendorService.deleteVendor(vendorId);
      vendor && this.vendors.splice(index, 1);
      this.vendors = [...this.vendors]
    }, (reason) => {
      console.log(reason);
    }
    );
  }

  async openResourceHierarchyModal(action, vendor, index = 0) {
    let selected = { assets: [], components: [] };
    if (action == 'edit') {
      selected.assets = vendor.assets;
      selected.components = vendor.components;
    }
    const modalRef = this.modalService.open(ResourceHierarchyComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.selected = selected;
    modalRef.componentInstance.assets = await this._sharedService.getAssets();

    modalRef.result.then(async (result) => {
      vendor.assets = result.assets;
      vendor.components = result.components;
      vendor = await this._vendorService.updateVendor(vendor);
      vendor && (this.vendors[index] = vendor);
      this.vendors = [...this.vendors];
    }, (reason) => {
      console.log(reason);
    });
  }

  belongsTo(vendor) {
    if (vendor.assets.length > 0 || vendor.components.length > 0)
      return true;
    else
      return false
  }
}
