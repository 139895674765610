
<div class="row mb-2 mx-0" id="location-list-header">
	<div class="col-6 p-0 my-auto">
		<h4 class="m-0 text-dark">Manage Locations</h4>
	</div>
	<div class="col-6 p-0">
		<div class="btn-group pull-right">
			<button type="button" class="btn btn-white" (click)="openLocationFormModal()">
				<i class="fa fa-plus"></i>
			</button>
			<app-pin [pinData]='pinData'></app-pin>
			<app-expand-screen></app-expand-screen>
		</div>
	</div>
</div>
<div class="row mb-2" [hidden]="plant">
	<div class="col-12">
		<div class="card border-1px">
			<div class="card-body">
				<div class="row mt-3 mb-3 mb-sm-0 mx-1">
					<div class="col-12 col-sm-6">
						<div class="form-group">
							<ng-multiselect-dropdown [placeholder]="'Select Plant'"
								[settings]="setMultiselectSetting('plant_id','name')" [data]="plants"
								[(ngModel)]="filter.plants">
							</ng-multiselect-dropdown>
						</div>
					</div>
					<div class="col-12 col-sm-6">
						<button type="button" class="btn btn-white pull-left ml-2" title="Filter" (click)="getPlantsLocations()"><i class="fa fa-filter"></i></button>
						<button type="button" class="btn btn-white pull-left ml-2" title="Reset" (click)="resetPlantLocations()"><i class="fa fa-refresh"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
<div class="row">
	<div class="col-lg-12" id="accordion2">
		<div *ngFor="let location of locations; let i = index">
			<div class="card mb-1 border-1px">
				<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
					<button class="btn btn-link text-dark shadow-none collapsed" (click)="flags[location.location_id] = true" data-toggle="collapse" [attr.data-target]="'#collapse-location-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-location-' + i">
						<span (click)="$event.stopPropagation(); openViewerModal(location)">{{location.name}}</span>
					</button>
					<div>
						<i class="mr-2 fa fa-download cursor-pointer" (click)="$event.stopPropagation()"  (click)="creatReport(location.location_id)"></i>
						<i class="fa fa-trash cursor-pointer" (click)="openConfirmationModal(location.location_id)"></i>
					</div>
				</div>
			</div>
			<div id="collapse-location-{{i}}" class="collapse ml-4" data-parent="#accordion2">
				<app-asset-list *ngIf="flags[location.location_id]" [location]="location"></app-asset-list>
			</div>
		</div>
	</div>
</div>
<div class="row" *ngIf="locations.length == 0">
	<div class="col-lg-12">
		<div class="card mb-1 border-1px">
			<div class="card-header d-flex justify-content-between align-items-center py-1 pl-0">
				<button class="btn btn-link text-dark shadow-none">
					<span>No data to display</span>
				</button>
			</div>
		</div>
	</div>
</div>