import { Injectable } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Constants from './constants.service';
import { Meta, Title } from '@angular/platform-browser';
import { ModalComponent } from '../app/modal/modal.component';
import * as moment from 'moment';
import { SharedService } from '../services/shared.service';
import { NGXToastrService } from "./toaster.service";
@Injectable()
export class HelperService {

  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  constructor(
    private modalService: NgbModal,
    private _title: Title,
    private _meta: Meta,
    private _sharedService: SharedService,
    private _toasterService: NGXToastrService,
  ) { }

  updateFavIcon(path) {
    this.favIcon.href = path;
  }
  updateTitle(title) {
    this._title.setTitle(title);
  }

  updateOgUrl(url) {
    this._meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc) {
    this._meta.updateTag({ name: 'description', content: desc })
  }

  async setFile(file, resourceType = '', id = '') {
    if (!file) {
      return '';
    }
    let response = await this._sharedService.getFile(file, this.formatSaveFileUrl(resourceType, id));
    file = (<any>window).appBaseUrl + response;
    return file;
  }

  async setImage(image, resourceType = '', id = '') {   
    if (!image || image == '') {
      image = (<any>window).appBaseUrl + "/uploads/placeholder.png";
    } else {
      let response = await this._sharedService.getFile(image, this.formatSaveFileUrl(resourceType, id));
      image = (<any>window).appBaseUrl + response;
    }
    return image;
  }

  showImage(heading, imgSrc) {
    const modalRef = this.modalService.open(ModalComponent, { size: 'lg', scrollable: true, backdrop: 'static' });
    modalRef.componentInstance.heading = heading;
    modalRef.componentInstance.imageSrc = imgSrc;

    modalRef.result.then(async (result) => {
    }, (reason) => {
      console.log(reason)
    });
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  getWeatherScript() {
    let file = (<any>window).appBaseUrl + "/public/weather-widget-generator.js";
    return file;
  }

  convertToC(temp) {
    return ((temp - 32) * 5 / 9);
  }

  convertToF(temp) {
    return ((temp * 1.8) + 32);
  }

  temperatureConverterWhenSourceInC(sensorDetail) {
    let sensorType = sensorDetail.type;
    let sensorUnit = sensorDetail.unit;
    let temperature = parseFloat(sensorDetail.high_low_value);
    let offSet = parseFloat(sensorDetail.off_set);
    let scaling = parseFloat(sensorDetail.scaling);

    // specific case for bluzone
    if (sensorType == 'bluzone' && sensorUnit == '˚F') {
      temperature = this.convertToF(temperature);
      if (temperature > 70 && temperature <= 175) {
        temperature = temperature + (((temperature - 70) / 105) * 15);
      }
      return parseFloat(temperature.toFixed(2));
    }
    // specific case for bluzone ends here

    if (scaling && scaling > 0) {
      temperature = temperature * scaling;
    }

    if (offSet) {
      temperature = temperature + offSet;
    } else {
      temperature = temperature + Constants.sensor.DEFAULT_TEMPERATURE_OFFSET;
    }

    if (sensorUnit == '˚F') {
      temperature = this.convertToF(temperature);
    }

    return parseFloat(temperature.toFixed(2));
  }

  temperatureConverterWhenSourceInF(sensorDetail) {
    let sensorUnit = sensorDetail.unit;
    let temperature = parseFloat(sensorDetail.high_low_value);
    let offSet = parseFloat(sensorDetail.off_set);
    let scaling = parseFloat(sensorDetail.scaling);

    if (scaling && scaling > 0) {
      temperature = temperature * scaling;
    }

    if (offSet) {
      temperature = offSet + temperature;
    } else {
      temperature = temperature + Constants.sensor.DEFAULT_TEMPERATURE_OFFSET;
    }

    if (sensorUnit == '˚C') {
      temperature = this.convertToC(temperature);
    }

    return parseFloat(temperature.toFixed(2));
  }

  temperatureConverter(sensorDetail) {
    if (sensorDetail.type == 'bluzone' || sensorDetail.type == 'optime') { //in future if there is any sensor type that give data in ˚C add this type here in condition using || operator
      return this.temperatureConverterWhenSourceInC(sensorDetail);
    } else {
      return this.temperatureConverterWhenSourceInF(sensorDetail);
    }
  }

  addScalingOffSet(sensorDetail) {
    let highLowValue = parseFloat(sensorDetail.high_low_value);
    let offSet = parseFloat(sensorDetail.off_set);
    let scaling = parseFloat(sensorDetail.scaling);

    if (scaling && scaling > 0) {
      highLowValue = highLowValue * scaling;
    }

    if (offSet) {
      highLowValue = offSet + highLowValue;
    } else {
      highLowValue = highLowValue + Constants.sensor.DEFAULT_OFFSET;
    }

    return parseFloat(highLowValue.toFixed(2));
  }

  formatTime(time) {
    if (!time)
      return '';
    let arr = time.split(':');
    let hours: any = arr[0];
    let minutes: any = arr[1];
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatDate(date) {
    if (!date)
      return '';
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  formatDateForInput(date, seperator) {
    if (!date)
      return '';
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join(seperator);
  }

  formatDateWithTime(d) {
    if (!d)
      return '';
    let date = new Date(d)
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + " " + strTime;
  }

  getDaysDifference(dueDate) {
    let start = moment(new Date());
    let end = moment(dueDate);
    return end.diff(start, "days") + 1;
  }

  formatNumber(day) {
    const map = {
      1: `${day}st      `,
      2: `${day}nd `,
      3: `${day}rd `
    }
    return map[day] ? map[day] : day + 'th '
  }

  imageFile(filename) {
    return filename.match(/.(jpg|jpeg|png|gif)$/i);
  }

  getWeekRanges(obj) {
    const pad = num => ("0" + num).slice(-2);
    const makeDate = d => new Date(`${pad(d.getMonth() + 1)}-${pad(d.getDate())}-${d.getFullYear()}`);

    let from = new Date(obj.from);
    let to = new Date(obj.to);
    let aDay = 24 * 60 * 60 * 1000;
    let weeks = [];
    for (let i = from.getTime(), n = to.getTime(); i <= n; i += aDay) {
      let d = new Date(i);
      let dateObj: any = { from: '', to: '' };
      if (d.getDay() === 1 || weeks.length === 0) {
        dateObj.from = new Date(makeDate(d).setHours(0, 0, 0)); //from
        dateObj.to = new Date(makeDate(d).setHours(23, 99, 99));//to
        weeks.push(dateObj);
      }
      else if (d.getDay() === 0 || i === n) {
        weeks[weeks.length - 1].to = new Date(makeDate(d).setHours(23, 59, 59));//over write to
      }
    }
    return weeks;
  }

  getMonthRanges(obj) {
    let from = new Date(obj.from);
    let to = new Date(obj.to);
    let months = [];
    let tempFrom = from;
    let tempTo = new Date(from.getFullYear(), from.getMonth() + 1, 0, 23, 59, 59);
    do {
      months.push({ from: tempFrom, to: tempTo });
      if (tempTo.getMonth() === 11) {
        tempFrom = new Date(tempTo.getFullYear() + 1, 0, 1);
      } else {
        tempFrom = new Date(tempTo.getFullYear(), tempTo.getMonth() + 1, 1);
      }
      tempTo = new Date(tempTo.getFullYear(), tempFrom.getMonth() + 1, 0, 23, 59, 59);
    } while (tempTo <= to);
    months.push({ from: tempFrom, to: tempTo });
    return months;
  }

  formatSaveImageUrl(resize = false, thumb=false, resourceType = '', id = ''){
    let url = `?thumb=${thumb}&resize=${resize}`;
    if(resourceType)
      url += `&resourceType=${resourceType}`;
    if(id)
      url += `&id=${id}`;

    return url;
  }

  formatSaveFileUrl(resourceType = '', id = ''){
    let url = '';
    if(resourceType)
      url += `?resourceType=${resourceType}`;
    if(id)
      url += `&id=${id}`;

    return url;
  }

  performOperationWithToasts(callback) {
    this._toasterService.typeInfoStayOnScreen('Please stay on this screen and do not start other operations until success message appears.', 3000);
    
    setTimeout(() => {
      this._toasterService.typeWarningStayOnScreen('Creating..', 600000);
    }, 3000);
  
    setTimeout(async () => {
      try {
        let response = await callback();
        if (response) {
          this._toasterService.clearToast();
          this._toasterService.typeSuccess('Process executed successfully');
          setTimeout(() => {
            window.open((<any>window).appBaseUrl + '/' + response.attachmentPath, '_blank');
          }, 1000);
        } else {
          this._toasterService.clearToast();
          this._toasterService.typeError('Something bad happened');
        }
      } catch (error) {
        this._toasterService.clearToast();
        this._toasterService.typeError('An error occurred: ' + error.message);
      }
    }, 6000);
  }
}