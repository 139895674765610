import { Injectable } from "@angular/core";

@Injectable()
export class LocalstorageService {

    constructor() {}

    clearStorage(){
        localStorage.clear();
    }

    setUserData(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    getUserData() {
        let user = localStorage.getItem("user");
        if (user) {
            return JSON.parse(user);
        }
        return { token: "" }
    }

    setProjectData(project) {
        localStorage.setItem('project', JSON.stringify(project));
    }

    setComponentData(component, data) {
        localStorage.setItem(component, JSON.stringify(data));
    }

    getComponentData(component) {
        let componentData = localStorage.getItem(component);
        return JSON.parse(componentData);
    }

    unsetComponentData(component) {
        localStorage.removeItem(component);
    }

    unsetProjectData() {
        localStorage.removeItem('project');
    }

    getProjectData() {
        let project = localStorage.getItem("project");
        return JSON.parse(project);
    }

    setDashboardData(dashboard) {
        localStorage.setItem('dashboard', JSON.stringify(dashboard));
    }

    unsetDashboardData() {
        localStorage.removeItem('dashboard');
    }

    getDashboardData() {
        let dashboard = localStorage.getItem("dashboard");
        return JSON.parse(dashboard);
    }

    setTheme(theme) {
        localStorage.setItem('theme', JSON.stringify(theme));
    }

    unsetTheme() {
        localStorage.removeItem('theme');
    }

    getTheme() {
        let theme = localStorage.getItem("theme");
        return JSON.parse(theme);
    }

    setSetting(setting) {
        localStorage.setItem('setting', JSON.stringify(setting));
    }

    unsetSetting() {
        localStorage.removeItem('setting');
    }

    getSetting() {
        let setting = localStorage.getItem("setting");
        return JSON.parse(setting);
    }
    
}