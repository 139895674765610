import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-health-note-modal',
  templateUrl: './health-note-modal.component.html',
  styleUrls: ['./health-note-modal.component.scss']
})
export class HealthNoteModalComponent implements OnInit {

  healthData: any = {};
  inputForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() { 
    this.setFormValidation();
  }

  setFormValidation(){
    this.inputForm = this.formBuilder.group({
      x: ['', [Validators.required]],
      note: ['', [Validators.required]], 
    });
  }

  validateForm(obj){
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      this.activeModal.close(obj)
    }
  }

  error(comtrolName, constraint){
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }
  
}
  