import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-work-order-detail',
    templateUrl: './work-order-detail-form.component.html',
    styleUrls: ['./work-order-detail-form.component.scss']
})

export class WorkOrderDetailFormComponent implements OnInit {
    @Input() workOrder: any = {};

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {}
}
