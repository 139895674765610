import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXToastrService } from '../../../services/toaster.service';
import { HelperService } from "../../../services/helper.service";
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-add-comment-form',
  templateUrl: './add-comment-form.component.html',
  styleUrls: ['./add-comment-form.component.scss']
})
export class AddCommentFormComponent implements OnInit {
  @Input() obj: any;
  inputForm: FormGroup;
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private _toasterService: NGXToastrService,
    public helperService: HelperService,
    public _sharedService: SharedService


  ) { }

  ngOnInit(): void {
    this.setFormValidation();
  }

  setFormValidation() {
    this.inputForm = this.formBuilder.group({
      text: ['', [Validators.required]],
      tags: ['', [Validators.required]],
      attachment: [''],
    });
  }

  validateForm() {
    this.submitted = true;
    if (this.inputForm.invalid) {
      console.log("Fill form properly")
    } else {
      console.log(this.inputForm.value);
      this.activeModal.close(this.inputForm.value)
    }
  }

  error(comtrolName, constraint) {
    let errors = this.inputForm['controls'][comtrolName]['errors'];
    return this.submitted && errors && errors[constraint]
  }

  async onFileChanged(event:any) {
    let response = await this._sharedService.uploadFile(event.target.files[0], this.helperService.formatSaveFileUrl(this.obj.type, this.obj.id));
      this.inputForm.controls.attachment.setValue(response);
      this._toasterService.typeSuccess("Attatchment Uploaded Successful")
  };
  
}

