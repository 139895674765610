<div class="modal-header">
    <h5 class="modal-title">Sensor Configuration</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Select Date</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="mm-dd-yyyy" formControlName="x" ngbDatepicker #d1="ngbDatepicker" [(ngModel)]="healthData.x">
                        <div class="input-group-append">
                            <button class="btn btn-light fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                        </div>
                        <div [hidden]="!error('x','required')" class="invalid">Date is required</div> 
                    </div>
                </div>
                <div class="form-group">
                    <label>Note</label>
                    <textarea type="text" class="form-control" placeholder="Enter Note" formControlName="note" rows="10" [(ngModel)]="healthData.note"></textarea>
                    <div [hidden]="!error('note','required')" class="invalid">Note is required</div> 
                </div>
            </form>
        </div>
    </div>        
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right"  (click)="validateForm(healthData)">Update</button>
</div>  