import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PlantService
{

    constructor(
        private _httpClient: HttpClient
    ) { }

    getPlantByPlantId(plant_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((<any>window).appBaseUrl + '/plant/'+plant_id)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addPlant(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post((<any>window).appBaseUrl + '/plant', { ...data })
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

    deletePlant(plant_id): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.delete((<any>window).appBaseUrl + '/plant/'+plant_id)
                .subscribe((response: any) => {
                    resolve(response.response);
                }, reject);
        });
    }

}
