import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sensor-modal',
  templateUrl: './sensor-modal.component.html',
  styleUrls: ['./sensor-modal.component.scss']
})
export class SensorModalComponent implements OnInit {

  @Input() component: any;
  constructor(
    public activeModal: NgbActiveModal
  ) {}
  ngOnInit(): void { }

}
