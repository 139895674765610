<!-- Plant modal -->
<div class="modal-header">
    <h5 class="modal-title">Plant</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross Click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Name *</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name"  [(ngModel)]="plant.name">
                        <div [hidden]="!error('name','required')" class="invalid">Name is required</div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Latitude</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Enter Latitude" formControlName="latitude"  [(ngModel)]="plant.latitude">
                                <div [hidden]="!error('latitude','required')" class="invalid">Latitude is required</div> 
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Longitude</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Enter Longitude" formControlName="longitude"  [(ngModel)]="plant.longitude">
                                <div [hidden]="!error('longitude','required')" class="invalid">Longitude is required</div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Short Description</label>
                    <div class="input-group">
                        <textarea rows="4" class="form-control" id="basic-textarea" placeholder="Enter Description" formControlName="description" [(ngModel)]="plant.description"></textarea>
                        <div [hidden]="!error('description','required')" class="invalid">Description is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Upload Image</label> 
                    <div class="input-group">
                        <input (change)="onFileChanged($event)" type="file" name="file" class="form-control" #fileInput accept="image/*" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <img src="{{ image }}" class="image" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="!plant.plant_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', plant: plant})">Update</button>
    <button [hidden]="plant.plant_id" type="button" class="btn btn-white pull-right"  (click)="validateForm({action: 'add', plant: plant})">Save</button>
</div>    

