import { Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'app-viewer-pm',
    templateUrl: './pm.component.html',
    styleUrls: ['./pm.component.scss']
})

export class PmComponent implements OnInit{

    @Input() obj: any;
    constructor() {}
    
    ngOnInit(){
        
    }
}