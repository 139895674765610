import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '../../../services/helper.service';

//Other Imports
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-sensor-detail',
  templateUrl: './sensor-detail.component.html',
  styleUrls: ['./sensor-detail.component.scss']
})
export class SensorDetailComponent implements OnInit {

  @Input() sensor: any;
  sensorOrigionalImageSrc: any;
  sensorTabs: any;
  activeTab: any = 'tab-trend-data';

  constructor( 
    public activeModal: NgbActiveModal, 
    private _helperService: HelperService,
    private _sharedService: SharedService,
  ) { }

  async ngOnInit(){  
    if(this.sensor.image.original){
      this.sensorOrigionalImageSrc = await this._helperService.setImage(this.sensor.image.original);
    }
    this.sensorTabs = await this._sharedService.getSensorDetailTabs(this.sensor.tabs);
  }

  makeActive(tabId){
    this.activeTab = tabId;
  }

  active(tabId){
    return this.activeTab == tabId;      
  }
  
}
  