import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RequisitionFormComponent } from "../requisition-form/requisition-form.component";
import { ModalComponent } from "../../modal/modal.component";
import { RequisitionPrintComponent } from "../requisition-print/requisition-print.component";

import { RequisitionService } from "../requisition.service";
import { SharedService } from "src/services/shared.service";
import { LocalstorageService } from '../../../services/localstorage.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: "app-requisition-list",
  templateUrl: "./requisition-list.component.html",
  styleUrls: ["./requisition-list.component.scss"]
})
export class RequisitionListComponent implements OnInit {
  requisition: any = {}
  requisitions: any = []
  requisitiontype: any;
  printContent: any = ''

  constructor(
    public modalService: NgbModal,
    public _sharedService: SharedService,
    public _requisitionService: RequisitionService,
    private _localstorageService: LocalstorageService,
    public helperService: HelperService,
  ) { }
  async ngOnInit() {
    this.initializeRequisition();
    this.requisitions = await this._requisitionService.getRequisitions();
  }

  async initializeRequisition() {
    this.requisition = {
      number: "",
      order_date: "",
      required_date: "",
      vendor_id: "",
      department: "",
      delivered_at: "",
      requisitions: []
    };
  }

  async openRequisitionFormModal(requisition) {
    const modalRef = this.modalService.open(RequisitionFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' })
    modalRef.componentInstance.requisition = requisition;
    modalRef.componentInstance.vendors = await this._sharedService.getVendor();
    modalRef.componentInstance.workOrders = await this._sharedService.getWorkOrders({});
    modalRef.componentInstance.parts = requisition.requisition_id ? await this._sharedService.getParts(`?vendor_id=${this.requisition.vendor_id}`) : [];
    modalRef.result.then(async (result) => {
      if (result.action == "add") {
        let user = this._localstorageService.getUserData();
        result.data.requisition_by = user.user_id;
        await this._sharedService.addRequisition(result.data);
      } else {
        result.data.requisition_id = requisition.requisition_id;
        await this._requisitionService.updateRequisition(result.data);
      }
      this.ngOnInit();
    }, (reason) => {
      console.log(reason);
      this.initializeRequisition();
    });

  }

  async openConfirmationModal(requisitionId, index = 0) {
    const modalRef = this.modalService.open(ModalComponent, {scrollable: true, backdrop: "static"});
    modalRef.componentInstance.heading = "Delete Requisition";
    modalRef.componentInstance.content = "Are you sure you want to delete this Requisition?";
    modalRef.result.then(async (result) => {
      let requisition = await this._requisitionService.deleteRequisition(requisitionId);
      requisition && this.requisitions.splice(index, 1);
      this.requisitions = [...this.requisitions];
    }, (reason) => {
      console.log(reason);
    });
  }

  async openRequisitionPrintModal(requisition){
    const modalRef =  this.modalService.open(RequisitionPrintComponent, {backdrop : 'static', size: 'lg'})
    modalRef.componentInstance.requisition = requisition;
    modalRef.componentInstance.workOrders = await this._sharedService.getWorkOrders({});;
    
  }
  async approvedBy(requisition){
    let data = {
      requisition_id: requisition.requisition_id,
      approved_by : this._localstorageService.getUserData().user_id
    }
    await this._requisitionService.updateRequisition(data);
    this.ngOnInit();
  }
}