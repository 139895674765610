import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ChartService
{
    constructor(
        private _httpClient: HttpClient
    ) { }
 
}
