import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PartFormComponent } from "../part-form/part-form.component";
import { PartService } from "../part.service";
import { ModalComponent } from "../../modal/modal.component";
import { ResourceHierarchyComponent } from "../../shared/resource-hierarchy/resource-hierarchy.component";
import { SharedService } from '../../../services/shared.service';
import { AssetService } from '../../asset/asset.service';

@Component({
    selector: "app-part-list",
    templateUrl: "./part-list.component.html",
    styleUrls: ["./part-list.component.scss"],
})
export class PartListComponent implements OnInit {
    @Input() obj: any;
    parts: any[];
    part: any = {};

    constructor(
        public modalService: NgbModal,
        public _partService: PartService,
        public _sharedService: SharedService,
        public _assetService: AssetService
    ) { }

    async ngOnInit() {
        this.intializePart();
        if (this.obj) {
            this.parts = await this._sharedService.getPartByResource(this.obj.type, this.obj.id);
        } else {
            this.parts = await this._sharedService.getParts();
        }
    }

    async intializePart() {
        this.part = {
            image: {
                original: '',
                thumb: ''
            },
            name: "",
            number: "",
            price: "",
            quantity: "",
            vendors: [],
            category_id: "",
            assets: [],
            components: [],
            alert_amount: '',
            active: true
        }
    }

    async autoAssignResource() {
        this.intializePart();
        if (this.obj.type == 'asset') this.part.assets = [{ asset_id: this.obj.resource.asset_id, name: this.obj.resource.name }];
        if (this.obj.type == 'component') this.part.components = [{ component_id: this.obj.resource.component_id, name: this.obj.resource.name }];
        this.openPartFormModal(this.part);
    }

    async openPartFormModal(part) {
        const modalRef = this.modalService.open(PartFormComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
        modalRef.componentInstance.part = part;
        modalRef.componentInstance.categories = await this._sharedService.getCategory();
        modalRef.componentInstance.vendors = await this._sharedService.getVendor();
        modalRef.componentInstance.assets = await this._sharedService.getAssets();
        modalRef.result.then(async (result) => {
            if (result.action == 'add') {
                part = await this._sharedService.addPart(result.data);
                part && this.parts.push(part);
            } else if (result.action == 'update') {
                part = await this._sharedService.updatePart(result.data);
            }
            this.parts = [...this.parts];
            this.intializePart();
        }, async (reason) => {
            this.intializePart();
        });

    }

    async openConfirmationModal(partId, index = 0) {
        const modalRef = this.modalService.open(ModalComponent, { scrollable: true, backdrop: "static" });
        modalRef.componentInstance.heading = "Delete Part";
        modalRef.componentInstance.content = "Are you sure you want to delete this Part?";
        modalRef.result.then(async (result) => {
            let part = await this._partService.deletePart(partId);
            part && this.parts.splice(index, 1);
            this.parts = [...this.parts];
        }, (reason) => {
            console.log(reason);
        });
    }

    async updatePart(part, property, value) {
        part[property] = value;
        await this._sharedService.updatePart(part);
    }

    async openResourceHierarchyModal(action, part, index = 0) {
        let selected = { assets: [], components: [] };
        if (action == 'edit') {
            selected.assets = part.assets;
            selected.components = part.components;
        }
        const modalRef = this.modalService.open(ResourceHierarchyComponent, { scrollable: true, backdrop: 'static', size: 'lg' });
        modalRef.componentInstance.selected = selected;
        modalRef.componentInstance.assets = await this._sharedService.getAssets();

        modalRef.result.then(async (result) => {
            let data = {
                part_id: part.part_id,
                assets: result.assets,
                components: result.components
            }
            part = await this._sharedService.updatePart(data);
            part && (this.parts[index] = part);
            this.parts = [...this.parts];
        }, (reason) => {
            console.log(reason);
        });
    }

    belongsTo(part) {
        if (part.assets.length > 0 || part.components.length > 0)
            return true;
        else
            return false
    }
}
