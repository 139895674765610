import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//Other Imports
import { SensorService } from '../sensor.service';
import { HealthNoteModalComponent } from '../health-note-modal/health-note-modal.component';

@Component({
  selector: 'app-sensor-health',
  templateUrl: './sensor-health.component.html',
  styleUrls: ['./sensor-health.component.scss']
})
export class SensorHealthComponent implements OnInit {
  @Input() sensor: any;
  healthWidgetData: any;
  from: any;
  graph: any = {
    series: [],
    xaxis: {},
    grid: { show: false }
  };
  constructor(
    private _sensorService: SensorService,
    private modalService: NgbModal
  ) { }

  async ngOnInit() {
    var startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    this.from  = startDate;
    
    this.processDataForGraph();
  }

  async processDataForGraph(){
    this.healthWidgetData = await this._sensorService.getHealthWidgetData(this.sensor.sensor_id, this.sensor.project_unique_id, this.from);
    if (this.healthWidgetData.x.length > 0) {
      let xaxis: any = {
        categories: this.healthWidgetData.x,
        type: "datetime",
        title: {
          text: "Date / Time",
          offsetY: 15,
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
          }
        }
      }

      let annotations: any = {
        points: []
      }
      this.healthWidgetData.annotations.forEach(item => {
          annotations.points.push(
            {
              x: new Date(item.x).getTime(),
              y: item.y,
              marker: {
                size: 4,
                fillColor: "#fff",
                strokeColor: "#43A2B8",
                radius: 1,
                cssClass: "apexcharts-custom-class"
              },
              label: {
                borderColor: "#43A2B8",
                offsetY: 0,
                style: {
                  fontSize: "13px",
                  color: "#fff",
                  background: "#43A2B8",
                  padding: {
                    left: 5,
                    right: 5,
                    top: 5,
                    bottom: 7,
                  }
                },
                cssClass: "note",
                text: item.note
              }
            }
          );
      });

      this.graph.xaxis = xaxis;
      this.graph.annotations = annotations;
      this.graph.series = this.healthWidgetData.y;
    }
  }

  async changeFromDate(event: any) {
    await this.processDataForGraph();
  }

  openHealthNoteModal(){
    const modalRef = this.modalService.open(HealthNoteModalComponent, { scrollable: true, backdrop: 'static', size: 'md' });
    modalRef.result.then(async (result) => {
      result.sensor_id = this.sensor.sensor_id;
      let response = await this._sensorService.updateHealthWidgetData(result);
      if(response){
        this.ngOnInit();
      }
    }, (reason) => {
      console.log("Cross is Clicked")
    });
  }

}
