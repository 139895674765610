<div class="modal-header">
    <h4 class="modal-title">Sensors</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-12"> 
                        <div class="form-group">
                            <h5 class="text-danger">Important Note</h5>
                            <p class="text-danger">Please select only related sensors.</p>
                            <p>Follwoing properties will be copied to selected sensors</p>
                            <p>=> Data Source, Group, Offset, Scalling, Tabs, Used For, Unit</p>
                        </div>
                        <div class="form-group">
                            <label>Sensors</label>
                            <ng-multiselect-dropdown
                            [placeholder]="'Select Sensor'"
                            [data]="sensors"
                            [(ngModel)]="selectedSensors"
                            name="sensors"
                            [settings]="setMultiselectSetting('sensor_id','name')">
                            </ng-multiselect-dropdown> 
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right"  (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.close({selectedSensors: selectedSensors})">Okay</button>
</div>