<!-- send-mail modal -->
<div class="modal-header">
    <h5 class="modal-title">Send a Reminder</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body bg-white ">
    <div class="card ">
        <div class="card-body">
            <h6 class="text-dark">This will send an email reminder and push notification to the users you select.</h6>
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label>Users</label>
                    <ng-multiselect-dropdown [placeholder]="'Select User'" formControlName="users" [settings]="setMultiselectSetting('user_id','name')" [data]="users">
                    </ng-multiselect-dropdown>
                    <div [hidden]="!error('users','required')" class="invalid">User is required</div>
                </div>
                <div class="form-group">
                    <label for="">Subject:</label>
                    <input type="text" class="form-control" formControlName="subject">
                    <div [hidden]="!error('subject','required')" class="invalid">Subject is required</div>
                </div>
                <div class="form-group">
                    <label for="">Message:</label>
                    <textarea class="form-control" id="" cols="120" rows="6" formControlName="message"></textarea>
                    <div [hidden]="!error('message','required')" class="invalid">Message is required</div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white bg-success pull-right" (click)="validateForm()">Send Message</button>
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>

</div>
<!-- end modal -->