<div class="row mb-2 mx-0">
	<div class="col-6 p-0 my-auto">
	  <h4 class="m-0 text-dark">Manage Companies</h4>
	</div>
	<div class="col-6 p-0">
	  <div class="btn-group pull-right">
		<button type="button" class="btn btn-white" (click)="openProjectFormModal()">
		  <i class="fa fa-plus"></i>
		</button>
		<app-pin [pinData]='pinData'></app-pin>
		<app-expand-screen></app-expand-screen>
	  </div>
	</div>
</div>
<!--List Group with Custom content-->
<div class="row">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="list-group" *ngFor="let project of projects">
					<a href="javaScript:;" (click)="openProjectFormModal(project.project_unique_id)" class="list-group-item list-group-item-action flex-column align-items-start">
						<div class="d-flex w-100 justify-content-between align-items-center">
							<div>
								<h6 class="mb-1 text-white">{{project.name}}</h6> 
								<p class="mb-1 small-font">{{ project.description }}</p>
							</div>
							<div>
								<i class="mr-2 fa fa-download cursor-pointer" (click)="$event.stopPropagation()"  (click)="creatReport(project.project_unique_id)"></i>
								<i class="fa fa-trash cursor-pointer" (click)="$event.stopPropagation(); openConfirmationModal(project.project_unique_id)"></i>

							</div>
						</div>
					</a>
				</div> 
				<div class="list-group" *ngIf="projects.length == 0">
					<a href="javaScript:;" class="list-group-item list-group-item-action flex-column align-items-start">
						<p class="mb-1 small-font">No record found.</p>
					</a>
				</div> 
			</div>
		</div>
	</div>
</div>
<!--End Row-->