<!-- Preventive Maintenance modal -->
<div class="modal-header">
    <h5 class="modal-title">Preventive Maintenance</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <form [formGroup]="inputForm">
                <div class="form-group">
                    <label for="input-1">Name *</label>
                    <input type="text" class="form-control" formControlName="name" [(ngModel)]="pm.name">
                    <div [hidden]="!error('name','required')" class="invalid">Name is required</div>
                </div>
                <div class="form-group">
                    <label>Priority *</label>
                    <div class="input-group">
                        <select class="form-control" formControlName="priority_id" [(ngModel)]="pm.priority_id">
                            <option value="">Select priority</option>
                            <option *ngFor="let priority of priorities" [value]="priority.priority_id">
                                {{priority.name }}
                            </option>
                        </select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-white" (click)="toggleAddPriorityBlock()"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                    <div [hidden]="!error('priority_id','required')" class="invalid">Priority is required</div>
                    <div class=" mt-2" [hidden]="!priorityBlock">
                        <div class="row">
                            <div class="col-12">
                                <input type="text" class="form-control" placeholder="Enter priority name" [(ngModel)]="priorityData.name" [ngModelOptions]="{standalone: true}">
                            </div>
                            <div class="col-12 input-group ">
                                <input type="color" class="form-control" id="favcolor" name="favcolor" value="#ff0000" [(ngModel)]="priorityData.color" [ngModelOptions]="{standalone: true}">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-white" (click)="addPriority()">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-6">
                            <label class="w-100">Assigned To</label>
                            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="assignedTo" [ngModelOptions]="{standalone: true}">
                                <label ngbButtonLabel class="btn-light m-0" [ngClass]="{'active': pm.users.length > 0}">
                                    <input ngbButton type="radio" value="user" (click)="pm.team_id = ''"> User
                                </label>
                                <label ngbButtonLabel class="btn-light" [ngClass]="{'active': pm.team_id}">
                                    <input ngbButton type="radio" value="team" (click)="pm.users = []">Team
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group" *ngIf="assignedTo == 'user'">
                                <label class="w-100">&nbsp;</label>
                                <div class="input-group">
                                    <ng-multiselect-dropdown [placeholder]="'Select Users'" [settings]="setMultiselectSetting('user_id','name')" [data]="users" [(ngModel)]="pm.users" formControlName="users" class="w-100">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="assignedTo == 'team'">
                                <label class="w-100">&nbsp;</label>
                                <div class="input-group">
                                    <select class="custom-select m-0" [(ngModel)]="pm.team_id" formControlName="team_id">
                                        <option value="">Select Team</option>
                                        <option *ngFor="let team of teams" [value]="team.team_id">
                                            {{team.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Instructions </label>
                    <div *ngFor="let instruction of pm.instructions; let i = index">
                        <div class="input-group mb-1">
                            <input type="text" class="form-control" placeholder="Enter Instruction" [(ngModel)]="instruction.text" [ngModelOptions]="{standalone: true}" />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-light" (click)="removeInstruction(i)"><i class="fa fa-trash"></i></button>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-white pull-right mb-3 mt-1" (click)="addInstruction()"><i class="fa fa-plus"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-white pull-right" (click)="activeModal.dismiss('Cancel Click')">Cancel</button>
    <button [hidden]="!pm.pm_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action:'update', data: pm})">Update</button>
    <button [hidden]="pm.pm_id" type="button" class="btn btn-white pull-right" (click)="validateForm({action: 'add', data: pm})">Save</button>
</div>
<!-- end modal -->