import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { PartRoutingModule } from "./part-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PartComponent } from "./part.component"
import { PartFormComponent } from "./part-form/part-form.component";
import { PartListComponent } from "./part-list/part-list.component";
import { PartService } from './part.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FileUploadModule } from 'ng2-file-upload';
import { ExpandScreenModule } from '../expand-screen/expand-screen.module';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        PartRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        NgxDatatableModule,
        FileUploadModule,
        ExpandScreenModule
    ],
    declarations: [
        PartComponent,
        PartFormComponent,
        PartListComponent
    ],
    providers: [
        PartService
    ],
    exports: [
        PartListComponent
    ]
})

export class PartModule { }